<template>
  <div class="dashboard-page" v-if="currentUser">
    <div class="dashboard__intro">
      <div class="page-title">Dashboard</div>
      <div class="page-subtitle">
        Welcome to your personal Dashboard. Here you can find featured
        opportunities and creators. Check out the left sidebar to update any of
        your info!
      </div>
    </div>
    <div>
      <div class="dashboard">
        <div class="dashboard__notification-center">
          <ProfileNotificationCenter />
        </div>
        <HmDashboard
          v-if="currentUser.roles.find((t) => t.name == 'hiring_manager')"
        />
        <div class="dashboard__featured-opps">
          <OpportunityGroup
            type="featured"
            title="Featured Opportunities"
            titleLink
            swiper
          />
        </div>
        <div v-if="featuredCreators">
          <CreatorsSection
            :preview="true"
            :value="featuredCreators"
            titleLink
            light
            featured
          />
        </div>
        <!-- <a
          class="dashboard__learnmore"
          href="https://campusmoviefest.com/support"
          target="_blank"
        >
          <div class="dashboard__learnmore__label">Keep Learning</div>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import HmDashboard from "./dashboard/HmDashboard";

export default {
  name: "DashboardPage",
  components: { HmDashboard },
  head() {
    let meta = [...this.currentProject.meta_tags];

    meta = _.uniqBy(meta, function (tag) {
      return (tag.name || "") + "_" + (tag.property || "");
    });

    return {
      title: "Dashboard",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        {
          rel: "icon",
          href:
            this.currentProject?.favicon.url ||
            this.activeTheme.favicon.url ||
            "/favicon.ico",
        },
      ],
    };
  },
  data() {
    return {
      featuredCreators: null,
    };
  },
  mounted() {
    this.fetchFeaturedCreators();
  },
  methods: {
    async fetchFeaturedCreators() {
      const { data } = await this.$api.Creator.sections({
        section_name: "featured",
      });
      this.featuredCreators = data[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-page {
  .dashboard {
    display: flex;
    flex-direction: column;

    &-page {
      @media screen and (max-width: 960px) {
        padding: 38px 31px;
      }
    }

    &__intro {
      margin-bottom: 61px;
      .page-subtitle {
        margin-bottom: 0;
      }
    }

    &-opportunity {
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &::v-deep .creators-section__header--featured {
      background: linear-gradient(
        98deg,
        #ee3624 6.5%,
        #4282ff 93.73%
      ) !important;
    }

    &__learnmore {
      background: white;
      border: 2px solid #262729;
      border-radius: 10px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      &__label {
        padding: 24px 25px;
        color: var(--v-primary-base);
        font-size: 20px;
        font-weight: 900;
        flex-grow: 1;
      }
      &::after {
        content: "";
        display: block;
        background-color: #56565a;
        width: 25%;
        border-radius: 0 8px 8px 0;
        background-image: url("data:image/svg+xml,%3Csvg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0834 29.0003L41.6875 29.0003L29 16.3128L30.6047 14.5003L46.313 30.2087L30.6047 45.917L29 44.1045L41.6875 31.417L12.0834 31.417L12.0834 29.0003Z' fill='white'/%3E%3C/svg%3E%0A");
        background-position: center;
      }
    }
    a.dashboard__learnmore {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .dashboard-page {
    .dashboard {
      &__intro {
        margin-bottom: 43px;
      }
    }
  }
}
</style>
