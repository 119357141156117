<template>
  <v-form
    @submit.prevent="submitCallback"
    v-model="valid"
    ref="form"
    class="dynamic-form"
    :disabled="disabled"
  >
    <div :key="field.uuid" v-for="field in fields">
      <component
        :is="`Dynamic${field.type}`"
        v-bind="field"
        v-model="form.data[field.uuid]"
        :rules="field.required ? [validationRules.required] : []"
        :mark-required="markRequired"
        v-show="showField(field)"
        :label="field.label"
        :show-label="showLabel"
        :addon-data="addonData(field)"
        :file-input="field.file_input"
        :disabled="claimAccountData && field.type == 'EmailField'"
        outlined
      ></component>
    </div>
    <div
      class="dynamic-form__description"
      v-if="description"
      v-html="description"
    ></div>
    <div class="dynamic-form__buttons">
      <Button
        class="btn_back-to-login"
        :refs="$refs"
        v-if="showBackToLogin && !currentUser"
        @click="$emit('backToLogin')"
        light
      >
        Already a member?
      </Button>
      <Button :refs="$refs" type="submit" :disabled="!valid || loading" light>
        {{ btnText || "Submit" }}
      </Button>
    </div>
  </v-form>
</template>

<script>
import DynamicCheckbox from "./DynamicCheckbox";
import DynamicDateField from "./DynamicDateField";
import DynamicEmailField from "./DynamicEmailField";
import DynamicHtmlBlock from "./DynamicHtmlBlock";
import DynamicNumberField from "./DynamicNumberField";
import DynamicRadio from "./DynamicRadio";
import DynamicTextArea from "./DynamicTextArea";
import DynamicTextField from "./DynamicTextField";
import DynamicMediaField from "./DynamicMediaField";
import DynamicPasswordField from "./DynamicPasswordField";
import DynamicLinkField from "./DynamicLinkField";
import DynamicFileField from "./DynamicFileField";
import DynamicSelect from "./DynamicSelect";
import DynamicHiddenField from "./DynamicHiddenField";
import validationRulesMixin from "@/mixins/validation-rules.mixin";

export default {
  mixins: [validationRulesMixin],
  props: {
    fields: Array,
    value: Object,
    submit: Function,
    markRequired: Boolean,
    showLabel: Boolean,
    description: String,
    btnText: String,
    disabled: Boolean,
    showBackToLogin: Boolean,
    claimAccountData: Object,
    hideContactFields: Boolean,
  },
  data() {
    return {
      valid: true,
      loading: false,
      form: { ...this.value },
    };
  },
  mounted() {
    if (this.claimAccountData) {
      Object.entries(this.claimAccountData).forEach((entry) => {
        const [key, value] = entry;
        const field = this.fields.find((field) => field.short_name == key);
        if (field && value) this.form.data[field.uuid] = value;
      });
    }
  },
  methods: {
    async submitCallback() {
      this.loading = true;
      try {
        await this.submit();
      } finally {
        this.loading = false;
      }
    },
    showField(field) {
      if (!field.conditional) {
        if (!this.hideContactFields) return true;

        return !["first_name", "last_name", "email"].includes(field.short_name);
      }

      const conditionalField = this.fields.find(
        (f) => f.uuid == field.conditional_uuid
      );
      if (conditionalField.type == "Radio") {
        const conditionalFormOption = conditionalField.options.find((o) => {
          return o.uuid == this.form.data[field.conditional_uuid];
        });
        const conditionalFormValue = conditionalFormOption
          ? conditionalField.options.find((o) => {
              return o.uuid == this.form.data[field.conditional_uuid];
            }).uuid
          : "Option not found";
        return field.conditional_value.includes(conditionalFormValue);
      } else if (conditionalField.type == "Checkbox") {
        return (
          field.conditional_value == this.form.data[field.conditional_uuid]
        );
      } else {
        const conditionalFormValue = this.form.data[field.conditional_uuid];
        const validValues = field.conditional_value.split(",").map((v) => {
          const value = v.trim();
          switch (value) {
            case "true":
              return true;
            case "false":
              return false;
            default:
              return value;
          }
        });
        return validValues.includes(conditionalFormValue);
      }
    },
    addonData(field) {
      if (field.tpe == "EmailField")
        return this.currentProject.addon_emailpasswordlogin_data;
      else return false;
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.form)) {
          this.$emit("input", { ...this.form });
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
  },
  components: {
    DynamicCheckbox,
    DynamicDateField,
    DynamicEmailField,
    DynamicHtmlBlock,
    DynamicNumberField,
    DynamicRadio,
    DynamicTextArea,
    DynamicTextField,
    DynamicMediaField,
    DynamicPasswordField,
    DynamicLinkField,
    DynamicFileField,
    DynamicSelect,
    DynamicHiddenField,
  },
};
</script>
<style lang="scss" scoped>
.dynamic-form {
  &__description {
    font-size: 14px;
  }
}
</style>
