<template>
  <component :is="swiper ? 'swiper-slide' : 'div'" class="opportunity">
    <div class="opportunity__header">
      <div class="opportunity__title">
        {{ value.title }}
      </div>
      <div class="opportunity__subtitle" v-if="value.subtitle">
        {{ value.subtitle }}
      </div>
    </div>
    <div class="opportunity__body">
      <div class="opportunity__description">
        <div v-html="value.description"></div>
        <div class="opportunity__dates" v-if="typeof type !== 'undefined'">
          <div class="opportunity__date" v-if="value.opening_date">
            <span style="color: #13b219">OPENING</span>
            <span>
              {{
                value.opening_date
                  | moment("timezone", currentTimezone, "MMM D, YYYY h:mm A zz")
              }}
            </span>
          </div>
          <div class="opportunity__date" v-if="value.closing_date">
            <span style="color: #e12d1b">CLOSING</span>
            <span>{{
              value.closing_date
                | moment("timezone", currentTimezone, "MMM D, YYYY h:mm A zz")
            }}</span>
          </div>
        </div>
        <div class="opportunity__eligibilities">
          <b>ELIGIBLE</b>
          <Eligibles
            v-if="value.eligibilities.length"
            :value="value.eligibilities"
          />
          <span v-else>All Community Creators</span>
        </div>
      </div>
      <div class="opportunity__description2">
        <a class="opportunity__preview" :href="value.site" target="_blank">
          <div
            class="opportunity__preview-img"
            v-if="value.preview && value.preview.url"
            :style="
              value.preview && value.preview.url
                ? {
                    backgroundImage: `url(${value.preview.url})`,
                  }
                : {}
            "
          ></div>
          <v-btn class="opportunity__preview-btn" color="primary"
            >Learn More</v-btn
          >
        </a>
        <div class="opportunity__eligibilities-mobile">
          <b>ELIGIBLE</b>
          <Eligibles
            v-if="value.eligibilities.length"
            :value="value.eligibilities"
          />
          <span v-else>All Community Creators</span>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    value: Object,
    swiper: Boolean,
    type: String,
  },
};
</script>

<style scoped lang="scss">
.opportunity {
  background: var(--v-background-lighten1);
  border: 2px solid var(--v-dark-base);
  padding: 40px;
  margin-top: -2px;
  @media screen and (max-width: 960px) {
    padding: 19px 12px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__header {
    width: 100%;
    margin-bottom: 1.25em;
    @media screen and (max-width: 960px) {
      margin-bottom: 14.5px;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    color: var(--v-dark-base);
    line-height: 1.3;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 700;
  }

  &__eligibilities {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 960px) {
      display: none;
    }

    b {
      margin-right: 7px;
      color: var(--v-text-base);
    }
  }
  &__eligibilities-mobile {
    display: none;

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 17px;
      margin-right: -20px;
      font-size: 14px;
      > b {
        margin-bottom: 11px;
      }
      .eligibles {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__dates {
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  &__date {
    font-weight: bold;
    margin-right: 30px;

    span {
      padding: 0 3px 0 0;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    @media screen and (max-width: 960px) {
      flex-direction: column-reverse;
    }
  }

  &__description {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    padding-right: 50px;
    @media screen and (max-width: 960px) {
      padding-right: 0;
    }
  }

  &__description2 {
    text-align: center;
    display: flex;
    align-items: center;
    @media screen and (max-width: 960px) {
      margin-bottom: 25px;
      align-items: flex-start;
    }
  }

  &__preview {
    width: 140px;
    min-width: 140px;
    margin-top: -36px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &-img {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: var(--v-grey-base);
    }

    @media screen and (max-width: 960px) {
      margin-top: 0;
      width: 125px;
      min-width: 125px;
    }

    &-img {
      width: 100%;
      padding-bottom: 100%;
      background-size: cover;
      background-position: center;
      //background-image: url("data:image/svg+xml,%3Csvg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect x='0.5' y='0.5' width='139' height='139' rx='9.5' fill='url(%23pattern0)' stroke='%23DCDCE1'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0' transform='scale(0.015625)'/%3E%3C/pattern%3E%3Cimage id='image0' width='64' height='64' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3C/svg%3E%0A");
    }

    &::v-deep {
      .v-btn {
        border-radius: 0 0 10px 10px !important;
        box-shadow: none;
      }
    }

    &-btn {
      width: 100%;
    }
  }
}

.disabled-theme .opportunity {
  &__title {
    color: #262729;
  }
  &__eligibilities b {
    color: #56565a;
  }

  &__preview-img {
    border-color: #dcdce1;
  }
}
</style>

<style lang="scss">
@media screen and (max-width: 960px) {
  .opportunity__eligibilities-mobile .eligible {
    margin-bottom: 18px;
  }
}
</style>
