<template>
  <tr class="payments-row">
    <td>
      <TableRowAvatar :user="item" :path="`/p/${item.username || item.id}`" />
    </td>
    <td>
      <a :href="`mailto:${item.email}`" target="_blank">{{ item.email }}</a>
    </td>
    <td class="center-align">
      {{ accountTypes[item.account_type] }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${
        item.onboarding_details.has_hr ? 'Yes' : 'No'
      }`"
    >
      {{ item.onboarding_details.has_hr ? "Yes" : "No" }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${item.onboarding_details.w9_status}`"
    >
      {{ statuses[item.onboarding_details.w9_status] }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${item.onboarding_details.ach_status}`"
    >
      {{ statuses[item.onboarding_details.ach_status] }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${item.onboarding_details.msa_status}`"
    >
      {{ statuses[item.onboarding_details.msa_status] }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${item.confirmed_at ? 'Yes' : 'No'}`"
    >
      {{ item.confirmed_at ? "Yes" : "No" }}
    </td>
    <td
      class="center-align onboarding-status"
      :class="`onboarding-status--${
        item.onboarding_details.qb_is_linked ? 'Yes' : 'No'
      }`"
    >
      {{ item.onboarding_details.qb_is_linked ? "Yes" : "No" }}
    </td>
    <td class="center-align">
      {{ item.created_at ? dateFormatted(item.created_at, "MM/DD/YYYY") : "" }}
    </td>
  </tr>
</template>

<script>
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  components: { TableRowAvatar },
  props: {
    item: Object,
  },
  data() {
    return {
      statuses: {
        NONE: "None",
        SIGNED: "Signed",
        OUT_FOR_SIGNATURE: "Sent",
      },
      accountTypes: {
        individual: "Sole Proprietor",
        business: "Business",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.onboarding-status {
  font-weight: bold;
  &--NONE,
  &--No {
    color: #e12d1b;
  }
  &--OUT_FOR_SIGNATURE {
    color: #4282ff;
  }
  &--SIGNED,
  &--Yes {
    color: #61b90c;
  }
}
</style>
