import sharedTableActions from "@/mixins/shared-table-actions.mixin";
import _ from "lodash";

export default {
  mixins: [sharedTableActions],
  data() {
    return {
      dateParams: ["created_at", "signed_at"],
      listOptions: this.getDefaultListOptions(),
    };
  },
  methods: {
    getDefaultListOptions() {
      return {
        agreement_type: [
          { name: "msa_contractor", label: "MSA, Contractor", value: true },
          { name: "msa_crew", label: "MSA, Crew", value: true },
          { name: "gig_contract", label: "Gig Contract", value: true },
          { name: "gig_extension", label: "Gig Extension", value: true },
          { name: "w9", label: "W-9", value: true },
          {
            name: "ach_authorization",
            label: "ACH Authorization",
            value: true,
          },
          { name: "other", label: "Other", value: true },
        ],
        status: [
          { name: "CREATED", label: "Created", value: true },
          { name: "OUT_FOR_SIGNATURE", label: "Delivered", value: true },
          { name: "SIGNED", label: "Signed", value: true },
          { name: "CANCELLED", label: "Canceled", value: false },
          { name: "EXPIRED", label: "Expired", value: false },
        ],
        created_at: [null, null],
        signed_at: [null, null],
        search_text: null,
        user_id: null,
        internal_project_id: null,
        order_by: "created_at",
        order_direction: "DESC",
        skill_id: null,
        page: 1,
      };
    },
    resetListOptions() {
      this.listOptions = this.getDefaultListOptions();
      this.searchInput = null;
      this.fetchList();
      this.reloadKey++;
    },
    resetFilter(fieldName) {
      switch (fieldName) {
        case "agreement_type":
        case "status":
          this.listOptions[fieldName] = this.getDefaultListOptions()[fieldName];
          break;
        case "signed_at":
        case "created_at":
          this.listOptions[fieldName] = [null, null];
          break;
        default:
          this.listOptions[fieldName] = null;
      }
      this.optionsUpdate();
    },
    activeListOption(fieldName) {
      switch (fieldName) {
        case "agreement_type": {
          return (
            this.listOptions.agreement_type.filter((type) => !type.value)
              .length != 0
          );
        }
        case "status": {
          const enabledStatusNames = this.listOptions.status
            .filter((type) => type.value)
            .map((type) => type.name);
          return !_.isEqual(enabledStatusNames, [
            "CREATED",
            "OUT_FOR_SIGNATURE",
            "SIGNED",
          ]);
        }
        case "signed_at":
        case "created_at":
          return this.listOptions[fieldName].filter((date) => date).length > 0;
        default:
          return this.listOptions[fieldName];
      }
    },
  },
  computed: {
    listParams() {
      return {
        agreement_type: this.listOptions.agreement_type
          .filter((type) => type.value)
          .map((type) => type.name),
        status: this.listOptions.status
          .filter((type) => type.value)
          .map((type) => type.name),
        order_by: this.listOptions.order_by,
        order_direction: this.listOptions.order_direction,
        user_id: this.listOptions.user_id,
        internal_project_id: this.listOptions.internal_project_id,
        search_text: this.listOptions.search_text,
        created_at: this.dateParam(this.listOptions.created_at),
        signed_at: this.dateParam(this.listOptions.signed_at),
        skill_id: this.listOptions.skill_id,
      };
    },
    anyActiveListOptions() {
      return (
        [
          "user_id",
          "internal_project_id",
          "search_text",
          "agreement_type",
          "status",
          "created_at",
          "signed_at",
          "skill_id",
        ].filter((fieldName) => this.activeListOption(fieldName)).length > 0 ||
        this.listOptions.order_by != "created_at" ||
        this.listOptions.order_direction != "DESC"
      );
    },
  },
};
