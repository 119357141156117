import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./modules/auth";
import user from "./modules/user";
import application from "./modules/application";
import datasources from "./modules/datasources";
import opportunity from "./modules/opportunity";
import theme from "./modules/theme";
import project from "./modules/project";
import invoice from "./modules/invoice";
import agreement from "./modules/agreement";

export default new Vuex.Store({
  modules: {
    application,
    auth,
    user,
    datasources,
    opportunity,
    theme,
    project,
    invoice,
    agreement
  },
});
