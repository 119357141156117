<template>
  <div class="monday-on">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>

    <div class="page-title" v-if="form">
      {{ `Hire Request #${form.monday_com_item_id}` }}
    </div>

    <div class="monday-on__issue-msg" v-if="agreementWarning">
      Some or all gigs in this hire request already have signed agreements.
      Consider creating a new gig agreement or hire request to avoid confusion.
    </div>

    <div v-if="loading">
      <Spinner />
    </div>
    <div v-else-if="form && form.id">
      <HireRequestForm
        v-model="form"
        @addNewGig="addNewGig"
        @removeGig="removeGig($event)"
        @submit="submit($event)"
        @input="form = $event"
      />
    </div>
    <div v-else>Hire Request not found.</div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import HireRequestForm from "./monday-onboarding/HireRequestForm";

export default {
  components: {
    HireRequestForm,
  },
  mixins: [InvoiceLabelsMixin],
  data() {
    return {
      loading: true,
      form: null,
      mondayComItemId: null,
      agreementWarning: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.mondayComItemId = this.$route.params.id;
      this.fetchHireRequest();
    }
  },
  methods: {
    async fetchHireRequest() {
      try {
        const response = await this.$api.HireRequest.get({
          monday_com_item_id: this.mondayComItemId,
        });
        this.form = response.data;
        if (!this.agreementNeededForAll()) this.agreementWarning = true;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$alert("Something went wrong", "error");
      }
    },
    agreementNeededForAll() {
      const waCount = this.form.work_assignments.length;
      const noneCount = this.form.work_assignments.filter(
        (wa) => wa.agreement_status == "NONE"
      ).length;
      return waCount > 0 && waCount == noneCount;
    },
    addNewGig() {
      this.form.work_assignments.push({});
      this.form.work_assignments = [...this.form.work_assignments];
    },
    removeGig(idx) {
      this.form.work_assignments.splice(idx, 1);
      this.form.work_assignments = [...this.form.work_assignments];
    },
    async submit(form) {
      const params = { ...form };
      try {
        const response = await this.$api.HireRequest.update(params);
        this.form = response.data;
        this.$router.push("/monday-onboarding/" + this.form.monday_com_item_id);
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.monday-on {
  margin: 0 auto;
  max-width: 1400px;
  .form-label {
    text-align: left;
    font-weight: bold;
    color: #56565a;
  }

  &__issue-msg {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    &--emphasis {
      color: #e12d1b;
    }
  }
  .submit-footer {
    border: none !important;
    background: none !important;
    padding: 4em 0 0 !important;
  }
}
</style>
