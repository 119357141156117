<template>
  <v-app-bar
    class="elevation-0 app-bar"
    :class="{ 'app-bar--theme-disabled': disableTheme }"
    :height="['sm', 'xs'].includes($size) ? 70 : 83"
    color="background"
    app
    clipped-left
  >
    <v-toolbar-title class="toolbar-title">
      <a :href="currentUser ? '/' : homeUrl" class="logo-link">
        <img
          v-if="['sm', 'xs'].includes($size)"
          :src="headerLogoMobile"
          :width="246"
          alt="Logo"
        />
        <img v-else :src="headerLogo" width="100%" alt="Logo" />
      </a>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <Search v-if="false" min-width="400px" placeholder="Search" />
    <v-spacer></v-spacer>

    <v-btn icon @click="$emit('toggleSidebar')" class="app-bar__hamburger">
      <v-icon
        size="34"
        :color="$vuetify.theme.dark && !disableTheme ? 'white' : 'primary'"
        >$menu</v-icon
      >
      <transition name="fade">
        <div v-if="newOpportunityCount > 0" class="app-bar__opp-count">
          {{ newOpportunityCount }}
        </div>
      </transition>
    </v-btn>
  </v-app-bar>
</template>

<script>
import Search from "./Search";
import { profileToFrontend } from "@/lib/domains";
import { mapGetters } from "vuex";

export default {
  name: "AppBar",
  components: {
    Search,
  },
  props: {
    drawer: Boolean,
    disableTheme: Boolean,
  },
  data() {
    return {
      homeUrl: profileToFrontend()[window.location.origin],
      logoDesktop: require("@/assets/logo-header.svg"),
      logoDesktopDark: require("@/assets/logo-header-dark.svg"),
      logoDesktopMobile: require("@/assets/logo-header-mobile.png"),
      logoDesktopDarkMobile: require("@/assets/logo-header-dark-mobile.png"),
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
  },
  computed: {
    ...mapGetters("opportunity", {
      newOpportunityCount: "newCount",
    }),
    headerLogo() {
      return this.$vuetify.theme.dark && !this.disableTheme
        ? this.logoDesktopDark
        : this.logoDesktop;
    },
    headerLogoMobile() {
      return this.$vuetify.theme.dark && !this.disableTheme
        ? this.logoDesktopDarkMobile
        : this.logoDesktopMobile;
    },
  },
};
</script>

<style scoped lang="scss">
.toolbar-title {
  padding: 0 25px;
}

.theme--light.v-app-bar.v-toolbar.v-sheet.app-bar,
#app.disabled-theme .app-bar {
  background-color: #ffffff !important;
}
.app-bar {
  &::v-deep .v-toolbar__content {
    padding: 0 20px 0 0 !important;
    @media screen and (max-width: 960px) {
      padding: 0 30px 0 0 !important;
    }
    border-bottom: 2px solid var(--v-text-base);
  }
  &__full-name {
    line-height: 1.1;
    margin-bottom: -2px;
    font-size: 13px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__team {
    line-height: 1.1;
    font-size: 10px;
    font-weight: bold;
    background: white;
    color: var(--v-primary-base);
    padding: 2px 10px;
    display: inline-block;
  }
  &__item {
    padding: 0 30px !important;
    display: flex;
    align-items: center;
    color: white;

    &-menu {
      margin-top: 5px;

      &-item {
        padding: 7px 15px;
        line-height: 1.1;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: var(--v-text-base);

        &:hover {
          opacity: 0.8;
        }
      }

      .v-divider {
        margin: 8px 0 !important;
      }

      &-label {
        font-size: 13px;
        font-weight: bold;
        padding: 0 15px;
        margin-top: 10px;
      }

      &__new-team {
        color: var(--v-primary-base);
      }
    }

    &-create {
      height: 40px !important;
      letter-spacing: 1px;
      font-weight: normal;
      padding: 0 20px !important;
      margin-right: 20px;
      color: #333 !important;
    }

    &-profile {
      cursor: pointer;
      text-decoration: none;
      @media screen and (max-width: 960px) {
        display: none;
      }
    }

    &-chevron_down {
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  &__opp-count {
    position: absolute;
    top: -11px;
    right: -2px;
    color: white;
    background: var(--v-primary-base);
    font-size: 12px;
    padding-top: 0.2em;
    min-width: 21px;
    height: 21px;
    border-radius: 50%;
  }

  &--theme-disabled {
    &::v-deep .v-toolbar__content {
      border-bottom: 2px solid #56565a;
    }
    &__item {
      &-menu {
        &-item {
          color: #56565a;
        }
      }
    }
  }
}

.account-item {
  &-icon {
    background: white;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
  }

  &-preview {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
  }
}

.logo-link {
  &,
  img {
    display: block;
  }
}

@media screen and (max-width: 1263px) {
  .toolbar-title {
    padding: 30px 10px 30px 10px;
  }
}

@media screen and (max-width: 960px) {
  .app-bar-item__logout {
    display: none;
  }
  .toolbar-title {
    padding-left: 0;
    margin-left: 30px;
  }
  .app-bar .search {
    display: none;
  }
  .app-bar__hamburger {
    margin-right: -7px !important;
  }
  .app-bar .v-toolbar__content {
    padding-left: 30px;
  }
}
</style>
