<template>
  <div>
    <div v-if="!hideHeader" class="page-title">
      {{ title || "EDIT INVOICE DETAILS" }}
    </div>
    <InvoicePersonalInfo
      v-if="!hideHeader && localValue.user"
      :user="localValue.user"
    />
    <div v-if="!hideHeader">
      *
      <span class="invoice-form__required-hint">Indicates required fields</span>
    </div>
    <slot name="invoiceItems" />
    <div class="new-gig-wrapper">
      <v-btn
        class="new-gig"
        color="#4282FF"
        elevation="0"
        rounded
        @click="$emit('addInvoiceItem', defaultItemType)"
      >
        <slot name="btn"> + New Gig/Item </slot>
      </v-btn>
      <v-tooltip right max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" size="20" class="new-gig-tooltip"
            >$question_round</v-icon
          >
        </template>
        <span>
          <slot name="tooltip"
            >Add multiple gigs and select the same gig multiple times for
            different payment items (labor, reimbursements, or expenses).</slot
          >
        </span>
      </v-tooltip>
    </div>
    <div class="form-row">
      <div class="form-label">Invoice Summary*</div>
      <TextArea
        :rules="[validation.required]"
        v-model="localValue.description"
        block
        name="description"
        @input="
          $emit('updateField', {
            field: 'description',
            value: localValue.description,
          })
        "
      />
    </div>

    <div class="form-row">
      <div class="form-label">Attachment(s)</div>
      <div class="invoice-form__attachments">
        <InvoiceAttachmentForm
          placeholder="Upload"
          v-for="(invoiceAttachment, idx) in localValue.invoice_attachments"
          :value="invoiceAttachment"
          :key="`invoiceAttachment-${idx}-${invoiceAttachment.id}`"
          @input="updateAttachment(idx, $event)"
          @delete="$emit('removeInvoiceAttachment', idx)"
        />
        <v-btn
          small
          color="#4282FF"
          elevation="0"
          rounded
          @click="$emit('addInvoiceAttachment')"
          class="new-attachment"
          v-if="
            localValue.invoice_attachments.length == 0 ||
            localValue.invoice_attachments[
              localValue.invoice_attachments.length - 1
            ].body
          "
        >
          <slot name="btn"> + Add Another Attachment </slot>
        </v-btn>
      </div>
    </div>

    <div class="form-row">
      <div class="form-label">Total Amount</div>
      <div class="form-field">
        <TextField
          placeholder="Total Amount of All Gigs"
          type="number"
          disabled
          :value="total"
          prefix="$"
        ></TextField>
      </div>
    </div>
  </div>
</template>

<script>
import InvoicePersonalInfo from "@/components/work-assignments/InvoicePersonalInfo";
import InvoiceAttachmentForm from "./InvoiceAttachmentForm";

export default {
  props: {
    form: Object,
    total: Number,
    defaultItemType: {
      type: String,
      default: "hourly",
    },
    hideHeader: Boolean,
    title: String,
  },
  components: {
    InvoicePersonalInfo,
    InvoiceAttachmentForm,
  },
  data() {
    return {
      localValue: { ...this.form },
    };
  },
  methods: {
    updateAttachment(idx, attachmentBody) {
      this.localValue.invoice_attachments[idx].body = attachmentBody;
      this.$emit("updateField", {
        field: "invoice_attachments",
        value: this.localValue.invoice_attachments,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";
</style>
