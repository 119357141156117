<template>
  <v-dialog max-width="400" v-model="dialog" persistent>
    <v-card light class="please-wait-dialog">
      <p>Please wait. This may take a moment...</p>
      <Spinner />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean
  },
}
</script>

<style lang="scss" scoped>
.please-wait-dialog {
  font-weight: bold;
  text-align: center;
  padding: 35px;
  .spinner.relative {
    min-height: 100px;
  }
}
</style>