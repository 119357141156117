<template>
  <div class="projects-table-wrapper">
    <div class="projects-table-container">
      <v-expansion-panels v-model="panel" flat class="projects-table__panels">
        <v-expansion-panel
          class="projects-table__panel"
          :class="{ 'panel-expanded': panel === 0 }"
        >
          <v-expansion-panel-header class="projects-table__panel-header">
            <slot name="label">
              <div class="projects-table__label">
                <div>
                  <span>Active Dates: {{ activeDates }} </span>
                </div>
              </div>
            </slot>
            <template v-slot:actions>
              <v-icon color="white"> $chevron_down </v-icon>
              <ActionsMenu
                class="projects-table__actions-toggle"
                v-if="currentUser.is_finance || currentUser.is_hiring_manager"
                @close="menu = false"
                @toggle="menu = !menu"
                :menu="menu"
                :themeDisabledPage="themeDisabledPage"
                color="white"
              >
                <form
                  method="post"
                  :action="`/api/v1/profile_side/invoices/download_project/${value.id}.pdf`"
                  target="_blank"
                >
                  <input
                    type="hidden"
                    name="token"
                    :value="$store.getters['auth/token']"
                  />
                  <v-btn type="submit" color="#56565A" elevation="0" dark>
                    Export All Invoices
                  </v-btn>
                </form>
              </ActionsMenu>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <table border="2" class="projects-table">
              <thead class="payments-table-head">
                <tr class="projects-table-subhead">
                  <th class="w0 center-align">Gig Name</th>
                  <th class="w0 center-align">Gig Status</th>
                  <th class="w0 center-align">Creator</th>
                  <th class="w0 center-align">Role</th>
                  <th class="w20">Hiring Manager</th>
                  <th class="w0">Start Date</th>
                  <th class="w0">End Date</th>
                  <th class="w0">View</th>
                </tr>
              </thead>
              <tbody
                v-if="value.work_assignments && value.work_assignments.length"
              >
                <ProjectMembersTableRow
                  v-for="item in value.work_assignments"
                  :key="item.id"
                  :item="item"
                />
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8" class="center-align">No creators</td>
                </tr>
              </tbody>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import ProjectMembersTableRow from "./table-row.project-members.vue";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [themeDisabledPageMixin, paymentsTableFormats],
  components: { ProjectMembersTableRow },
  props: {
    value: Object,
  },
  data() {
    return {
      panel: 0,
      menu: false,
    };
  },
  computed: {
    activeDates() {
      if (
        !this.value.work_assignments ||
        this.value.work_assignments.length == 0
      )
        return "";
      let start = this.value.work_assignments
        .filter((wa) => wa.start_date)
        .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))[0];
      start = start
        ? this.dateFormatted(start.start_date, "MM/DD/YYYY")
        : "N/A";
      let end = this.value.work_assignments
        .filter((wa) => wa.end_date)
        .sort(
          (a, b) =>
            new Date(b.extended_end_date) - new Date(a.extended_end_date)
        )[0];
      end = end
        ? this.dateFormatted(end.extended_end_date, "MM/DD/YYYY")
        : "N/A";
      return `${start}–${end}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
.projects-table-container {
  overflow-x: unset;
  .v-expansion-panel-content {
    overflow-x: auto;
  }
}
</style>
