<template>
  <div class="review-group">
    <div v-if="value && value.length">
      <div class="review-group__wrapper">
        <div class="review-group__header">
          <div class="review-group__title">{{ title }}</div>
        </div>
        <div class="review-group__body">
          <SubmissionFormForReview
            v-for="group in value"
            :key="group.id"
            :value="group"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubmissionFormForReview from "./SubmissionFormForReview.vue";

export default {
  components: {
    SubmissionFormForReview,
  },
  props: {
    value: Array,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.review-group {
  &__wrapper {
    margin-top: 50px;
    border-radius: 10px;
    background: white;
  }
  &__header {
    padding: 25px 40px;
    background: #ffbc10;
    border: 2px solid #262729;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 960px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    color: #e12d1b;
  }
}
</style>
