<template>
  <div class="hr" v-if="gig">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">
      {{ `Contract #${gig.monday_com_item_id}` }}
    </div>
    <div class="hr">
      <div class="hr__main">
        <v-row>
          <v-col cols="12">
            <FormRow label="Creator *" light>
              <div class="hr__avatar">
                <TableRowAvatar :user="gig.creator" newTab />
              </div>
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <FormRow label="Start Date" light>
              <div class="hr__txt">
                {{
                  gig.start_date
                    | moment("timezone", currentTimezone, "M/D/YYYY")
                }}
              </div>
            </FormRow>
          </v-col>
          <v-col cols="8">
            <FormRow label="End Date" light>
              <div class="hr__txt" v-if="gig.extended_end_date">
                {{
                  gig.extended_end_date
                    | moment("timezone", currentTimezone, "M/D/YYYY")
                }}
                (extended)
              </div>
              <div class="hr__txt" v-else>
                {{
                  gig.end_date | moment("timezone", currentTimezone, "M/D/YYYY")
                }}
              </div>
            </FormRow>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <FormRow label="Rate Type" light>
              <div class="hr__txt">
                {{ rateTypeLabels[gig.rate_type] }}
              </div>
            </FormRow>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col cols="6">
                <FormRow
                  :label="`${rateTypeLabels[gig.rate_type]} Rate`"
                  light
                  v-if="gig.rate_type != 'flat_fee'"
                >
                  <div class="hr__txt">
                    {{ gig.rate | currency }}
                  </div>
                </FormRow>
                <FormRow label="Amount" light v-else>
                  <div class="hr__txt">
                    {{ gig.amount | currency }}
                  </div>
                </FormRow>
              </v-col>
              <v-col cols="6">
                <FormRow
                  label="Expected Hrs."
                  light
                  v-if="gig.rate_type != 'flat_fee'"
                >
                  <div class="hr__txt">
                    {{ gig.estimated_hours }}
                  </div>
                </FormRow>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <FormRow label="Description of Services" light>
          <div class="hr__txt hr__txt--breaks">
            {{ gig.job_description }}
          </div>
        </FormRow>
      </div>
      <div class="hr__work-assignments">
        <div class="form-label">Gigs</div>
        <div class="hr__work-assignment-list">
          <div
            v-for="(wa, idx) in gig.work_assignments"
            :key="`work-assignment_${wa.id || 'new'}_${idx}`"
            class="hr__work-assignment"
          >
            <div class="hr__work-assignment__details">
              <div class="hr__work-assignment__info">
                <div class="hr__work-assignment__title">{{ wa.title }}</div>
                <div
                  class="hr__work-assignment__info__detail"
                  v-if="wa.hiring_manager"
                >
                  Hiring Manager: {{ wa.hiring_manager.display_name }}
                </div>
              </div>
              <div class="hr__work-assignment__contracts">
                <div
                  class="hr__work-assignment__status"
                  :class="`hr__work-assignment__status--${wa.agreement_status}`"
                >
                  {{ agreementStatuses[wa.agreement_status] }}
                </div>
                <router-link
                  class="hr__work-assignment__extension"
                  :class="`hr__work-assignment__extension--${extension.agreement_status}`"
                  v-for="extension in wa.gig_extensions"
                  :key="extension.id"
                  :to="`/my-gig-extension/${extension.id}`"
                >
                  {{
                    extension.extended_date
                      | moment("timezone", currentTimezone, "M/D/YYYY")
                  }}
                  {{ extensionStatuses[extension.agreement_status] }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hr__retrospective">
        <v-btn
          v-if="retrospectiveOpen"
          class="hr__work-assignment__retrospective__open"
          elevation="0"
          color="primary"
          :to="`/my-retrospective/${gig.monday_com_item_id}`"
        >
          Retrospective Form
        </v-btn>
        <span
          v-else-if="gig.retrospective_completed"
          class="hr__work-assignment__retrospective__completed"
        >
          Retrospective Completed
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import AgreementLabelsMixin from "@/mixins/agreements/agreement-labels.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";

export default {
  mixins: [InvoiceLabelsMixin, AgreementLabelsMixin],
  components: { TableRowAvatar },
  head() {
    return {
      title: "My Gig",
      titleTemplate: "%s | iU Community",
    };
  },
  data() {
    return {
      loading: true,
      gig: null,
      mondayComItemId: null,
      mondayComItem: null,
      creatorResolved: false,
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "AgreementCreatorChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    if (this.$route.params.id) {
      this.mondayComItemId = this.$route.params.id;
      this.fetchInit();
    }
  },
  methods: {
    async fetchInit() {
      await this.fetchHireRequest();
      this.loading = false;
    },
    async fetchHireRequest() {
      try {
        const response = await this.$api.HireRequest.getMy({
          monday_com_item_id: this.mondayComItemId,
        });
        this.gig = response.data;
      } catch (e) {
        if (e.message == "Request failed with status code 401")
          this.$router.push("/my-gigs");
        else this.$alert("Something went wrong", "error");
      }
    },
    async handleAgreementBroadcast(type, payload) {
      // Early return if we did it.
      if (
        payload.transaction_id &&
        payload.transaction_id == this.$store.getters["agreement/transactionId"]
      )
        return;

      // Early return if it's regarding a hire_request, but not this one
      if (
        this.gig.monday_com_item_id &&
        payload.hire_request_monday_com_item_id &&
        this.gig.monday_com_item_id != payload.hire_request_monday_com_item_id
      )
        return;

      this.fetchInit();
    },
  },
  channels: {
    AgreementCreatorChannel: {
      connected() {},
      received() {
        this.handleAgreementBroadcast();
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "AgreementCreatorChannel",
    });
  },
  computed: {
    retrospectiveOpen() {
      return (
        this.gig &&
        !this.gig.retrospective_completed &&
        new Date(this.gig.extended_date_end || this.gig.end_date) < new Date()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/hire-requests/hire-request.scss";

.hr__retrospective {
  display: flex;
  justify-content: flex-end;
  &__open {
  }
  &__completed {
  }
}
.retro-thanks-dialog {
  font-weight: bold;
  text-align: center;
  padding: 35px;
  .spinner.relative {
    min-height: 100px;
  }
}
</style>
