import sharedTableActions from "@/mixins/shared-table-actions.mixin";
import _ from "lodash";

export default {
  mixins: [sharedTableActions],
  data() {
    return {
      dateParams: [],
    };
  },
  methods: {
    getDefaultListOptions(completedPayments) {
      return {
        order_by: completedPayments ? "paid_at" : "created_at",
        order_direction: "DESC",
        search_text: null,
        user_id: null,
        internal_project_id: null,
        created_at: [null, null],
        paid_at: [null, null],
        status: [
          { name: "submitted", label: "Submitted", value: !completedPayments },
          {
            name: "processing",
            label: "Processing",
            value: !completedPayments,
          },
          { name: "paid", label: "Completed", value: !!completedPayments },
          { name: "canceled", label: "Canceled", value: false },
        ],
        skill_id: null,
      };
    },
    resetListOptions() {
      this.listOptions = this.getDefaultListOptions(this.completedPayments);
      this.searchInput = null;
      this.fetchList();
      this.reloadKey++;
    },
    resetFilter(fieldName) {
      switch (fieldName) {
        case "status":
          this.listOptions[fieldName] = this.getDefaultListOptions(
            this.completedPayments
          )[fieldName];
          break;
        case "created_at":
        case "paid_at":
          this.listOptions[fieldName] = [null, null];
          break;
        default:
          this.listOptions[fieldName] = null;
      }
      this.optionsUpdate();
    },
    activeListOption(fieldName) {
      switch (fieldName) {
        case "status": {
          const enabledStatusNames = this.listOptions.status
            .filter((type) => type.value)
            .map((type) => type.name);
          return this.completedPayments
            ? !_.isEqual(enabledStatusNames, ["paid"])
            : !_.isEqual(enabledStatusNames, ["submitted", "processing"]);
        }
        case "created_at":
        case "paid_at":
          return this.listOptions[fieldName].filter((date) => date).length > 0;
        default:
          return this.listOptions[fieldName];
      }
    },
  },
  computed: {
    listParams() {
      return {
        order_by: this.listOptions.order_by,
        order_direction: this.listOptions.order_direction,
        user_id: this.listOptions.user_id,
        internal_project_id: this.listOptions.internal_project_id,
        skill_id: this.listOptions.skill_id,
        search_text: this.listOptions.search_text,
        created_at: this.dateParam(this.listOptions.created_at),
        paid_at: this.dateParam(this.listOptions.paid_at),
        status: this.listOptions.status
          .filter((type) => type.value)
          .map((type) => type.name),
      };
    },
    anyActiveListOptions() {
      return (
        [
          "user_id",
          "internal_project_id",
          "search_text",
          "created_at",
          "paid_at",
          "status",
          "skill_id",
        ].filter((fieldName) => this.activeListOption(fieldName)).length > 0 ||
        this.listOptions.order_by !=
          (this.completedPayments ? "paid_at" : "created_at") ||
        this.listOptions.order_direction != "DESC"
      );
    },
  },
};
