<template>
  <div class="index-page-actions">
    <div class="index-page-actions__start">
      <v-btn
        v-if="btnTo"
        :to="btnTo"
        color="primary"
        elevation="0"
        rounded
        large
      >
        <slot name="btn">
          <v-icon>$plus</v-icon>
          {{ btnText }}
        </slot>
      </v-btn>
    </div>
    <div class="index-page-actions__end">
      <div class="index-page-actions__item" v-if="view">
        <v-icon
          :class="{ active: view == 'grid' }"
          class="index-page-actions__view"
          @click="$emit('update:view', 'grid')"
          >$grid</v-icon
        >
        <v-icon
          :class="{ active: view == 'table' }"
          class="index-page-actions__view"
          @click="$emit('update:view', 'table')"
          >$list</v-icon
        >
      </div>
      <div class="index-page-actions__item" v-if="sortList.length">
        <Dropdown
          :value="sortBy"
          @input="$emit('update:sortBy', $event)"
          label="Sort by"
          :items="sortList"
        ></Dropdown>
      </div>

      <!-- <div class="index-page-actions__item">
        <Dropdown label="Filter" :items="filterList"></Dropdown>
      </div> -->
      <div class="index-page-actions__item">
        <ContextSearch
          :value="searchText"
          @input="$emit('update:searchText', $event)"
          :placeholder="searchPlaceholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnText: String,
    btnTo: String,
    sortList: {
      type: Array,
      default: () => [],
    },
    filterList: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
    },
    sortBy: {
      type: String,
      default: "updated_at",
    },
    searchPlaceholder: {
      type: String,
      default: "Search",
    },
    searchText: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.index-page-actions {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  flex-wrap: wrap;
  padding: 10px 0;
  align-items: center;

  &__item {
    margin-left: 25px;
    display: flex;
  }

  &__end {
    display: flex;
    align-items: center;
  }

  &__view {
    margin: 0 2px;
    cursor: pointer;

    &.active {
      color: var(--v-primary-base);
    }
  }
}

@media screen and (max-width: 960px) {
  .index-page-actions {
    &__end {
      width: 100%;
      margin-top: 20px;
      justify-content: space-between;
    }

    &__item {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}
</style>
