export default {
  props: {
    invoice: Object,
  },
  data() {
    return {
      statuses: {
        submitted: "Submitted",
        hm_approved: "HM Approved", // DEPRECATED
        hm_rejected: "Revisions Needed", // DEPRECATED
        fi_approved: "Approved", // DEPRECATED
        fi_rejected: "Revisions Needed", // DEPRECATED
        paid: "Completed",
        canceled: "Canceled",
        processing: "Processing",
      },
      pages: 1,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const innerContent = this.$refs["innerContent"];
      if (innerContent.clientHeight > 1046) this.pages = 2;
    });
  },
};
