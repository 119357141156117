<template>
  <div v-if="form" class="gig-extension">
    <FormRow label="Creator" light class="form-row--no-input">
      {{ hr.creator.display_name }}
    </FormRow>
    <v-form ref="form">
      <FormRow label="Extended Date *" light>
        <DateTimePicker
          placeholder="Extended Date"
          v-model="form.extended_date"
          onlyDate
          light
          :rules="[validation.required]"
          style="max-width: 250px"
        ></DateTimePicker>
      </FormRow>
      <FormRow label="Rate Changes?" light>
        <RadioGroup
          :items="rateChangesOptions"
          v-model="form.rate_changes"
        ></RadioGroup>
      </FormRow>
      <div v-if="!form.rate_changes" class="gig-extension__rate-msg">
        The original rate is a(n)
        <span class="gig-extension__rate-msg__em"
          >{{ rateTypeLabels[hr.rate_type] }}
        </span>
        rate of
        <span class="gig-extension__rate-msg__em"
          >{{ (hr.rate_type == "flat_fee" ? hr.amount : hr.rate) | currency }}
        </span>
      </div>
      <v-row v-else>
        <v-col cols="6">
          <FormRow label="Rate Type" light>
            <Select v-model="form.rate_type" :items="rateTypes" light />
          </FormRow>
        </v-col>
        <v-col cols="6">
          <div v-if="form.rate_type">
            <FormRow
              :label="`${rateTypeLabels[form.rate_type]} Rate`"
              light
              v-if="form.rate_type != 'flat_fee'"
            >
              <TextField
                v-model="form.rate"
                :rules="[validation.required]"
                block
                type="number"
                name="rate"
                prefix="$"
              />
            </FormRow>
            <FormRow label="Amount" light v-else>
              <TextField
                v-model="form.amount"
                :rules="[validation.required]"
                block
                type="number"
                name="rate"
                prefix="$"
              />
            </FormRow>
          </div>
        </v-col>
      </v-row>
      <FormRow
        label="Related Gigs *"
        light
        class="gig-extension__work-assignment-selections"
      >
        <div
          v-for="item in work_assignment_selections"
          :key="`work_assignment_selection_${item.id}`"
          class="gig-extension__work-assignment-selection"
        >
          <Checkbox
            v-model="item.value"
            @input="work_assignment_selections_invalid = false"
          />
          <div class="gig-extension__work-assignment-selection__label">
            {{ item.title }}
          </div>
        </div>
        <div
          v-if="work_assignment_selections_invalid"
          class="work-assignment-selections__invalid-msg"
        >
          At least one gig is required.
        </div>
      </FormRow>
    </v-form>
    <SubmitFooter :refs="$refs" :submit="submit" inProgressText="Submitting">
      Submit & Review
    </SubmitFooter>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import GigExtensionLabelsMixin from "@/mixins/gig-extension-labels.mixin";
import GigExtensionFormMixin from "@/mixins/gig-extension-form.mixin";

export default {
  mixins: [InvoiceLabelsMixin, GigExtensionLabelsMixin, GigExtensionFormMixin],
  props: {
    hr: Object,
    suggestion: Object,
  },
  data() {
    return {
      form: {
        hire_request_id: this.hr.id,
        rate_type: this.hr.rate_type,
        rate: this.hr.rate,
        amount: this.hr.amount,
      },
      work_assignment_selections: this.hr.work_assignments.map((wa) => {
        return {
          id: wa.id,
          title: wa.title,
          value: false,
        };
      }),
    };
  },
  mounted() {
    if (this.suggestion) {
      this.form = { ...this.form, ...this.suggestion };
      this.work_assignment_selections = this.hr.work_assignments.map((wa) => {
        return {
          id: wa.id,
          title: wa.title,
          value:
            this.suggestion.work_assignments.filter((swa) => swa.id == wa.id)
              .length > 0,
        };
      });
    }
  },
  methods: {
    submit() {
      const formValid = this.$refs.form.validate();
      const gigsValid = this.validateGigSelections();
      if (formValid && gigsValid) {
        this.form.work_assignments = this.work_assignment_selections.filter(
          (was) => was.value
        );
        this.$emit("submit", this.form);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/gig-extension-form.scss";

.gig-extension {
  .submit-footer {
    border: none;
    padding: 0;
    margin-top: 1em;
  }
}
</style>
