import _ from "lodash";

export default {
  data() {
    return {
      batchSelected: [],
      batchMenu: false,
      batchMarkAsPaidAreYouSure: false,
      batchMarkAsUnpaidAreYouSure: false,
      batchCancelAreYouSure: false,
    };
  },
  methods: {
    batchSelect(item, selected) {
      if (selected) {
        if (!this.batchSelected.includes(item.id))
          this.batchSelected.push(item.id);
      } else {
        _.remove(this.batchSelected, (x) => x == item.id);
        this.batchSelected = this.batchSelected.slice(0); // force reactivity
      }
    },
    batchSelectAll() {
      if (this.allBatchSelected) {
        this.batchSelected = [];
      } else {
        this.batchSelected = this.items.map((item) => item.id);
      }
    },
    async batchMarkAsPaid() {
      this.batchMarkAsPaidAreYouSure = false;
      try {
        const res = await this.$api.Invoice.batch({
          ids: this.batchSelected,
          action: "mark_as_paid",
        });
        if (res.status == 200) {
          this.$alert("Success");
          this.$emit("refresh");
        }
      } catch (e) {
        console.error(e);
        this.$alert("Something went wrong", "error");
      }
    },
    async batchMarkAsUnpaid() {
      this.batchMarkAsUnpaidAreYouSure = false;
      try {
        const res = await this.$api.Invoice.batch({
          ids: this.batchSelected,
          action: "mark_as_unpaid",
        });
        if (res.status == 200) {
          this.$alert("Success");
          this.$emit("refresh");
        }
      } catch (e) {
        console.error(e);
        this.$alert("Something went wrong", "error");
      }
    },
    async batchMarkAsCanceled() {
      this.batchMarkAsCanceledAreYouSure = false;
      try {
        const res = await this.$api.Invoice.batch({
          ids: this.batchSelected,
          action: "mark_as_canceled",
        });
        if (res.status == 200) {
          this.$alert("Success");
          this.$emit("refresh");
        }
      } catch (e) {
        console.error(e);
        this.$alert("Something went wrong", "error");
      }
    },
  },
  computed: {
    allBatchSelected() {
      const notIncludedItem = this.items.find((item) => {
        return !this.batchSelected.includes(item.id);
      });
      if (notIncludedItem) return false;
      else return true;
    },
  },
};
