<template>
  <div class="slides">
    <div
      v-for="(slide, index) in localValue"
      :key="index"
      :class="{ deleted: slide._destroy }"
      class="slide"
    >
      <div class="slide__deleted-overlay">
        <v-btn outlined rounded large @click="restore(index)"> Restore </v-btn>
      </div>
      <div class="slide__header">
        <div class="slide__title">
          {{ title }}
        </div>
        <div class="slide__actions">
          <v-btn icon @click="moveUp(index)">
            <v-icon size="24"> $arrow_up </v-icon>
          </v-btn>
          <v-btn icon @click="moveDown(index)">
            <v-icon size="24"> $arrow_down </v-icon>
          </v-btn>
          <v-btn icon @click="destroy(index)">
            <v-icon size="24"> $delete </v-icon>
          </v-btn>
        </div>
      </div>
      <FormRow>
        <template #label> Popup Image<br />(1920x1080) </template>
        <Uploader v-model="slide.preview" :ratio="1920 / 1080" />
      </FormRow>
      <FormRow label="Link URL">
        <TextField
          v-model="slide.url"
          placeholder="Link URL"
          :rules="[validation.url]"
        />
      </FormRow>
      <FormRow label="Open URL">
        <Select
          v-model="slide.open_url"
          style="max-width: 270px"
          :items="openUrlItems"
        />
      </FormRow>
    </div>
    <v-btn color="primary" elevation="0" rounded large @click="addSlide">
      Add Another Slide
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [{ open_url: '_self' }],
    },
    title: String,
  },
  data() {
    return {
      localValue: [...this.value],
      openUrlItems: [
        { name: 'Current Tab', value: '_self' },
        { name: 'New Tab', value: '_blank' },
      ],
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit('input', [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
  methods: {
    addSlide() {
      this.localValue.push({ open_url: '_self' });
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx + 1, 0, element);
    },
    destroy(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx] = { ...this.localValue[idx], _destroy: true };
      } else {
        this.localValue.splice(idx, 1);
      }
      this.localValue = [...this.localValue];
    },
    restore(idx) {
      this.localValue[idx] = { ...this.localValue[idx], _destroy: false };
      this.localValue = [...this.localValue];
    },
  },
};
</script>

<style scoped lang="scss">
.slides {
  margin-bottom: 30px;
}
.slide {
  border: 1px solid var(--v-border-base);
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;

  &__deleted-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &.deleted &__deleted-overlay {
    display: flex;
  }

  &__header {
    border-bottom: 1px solid var(--v-border-base);
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
