<template>
  <div>
    <vue-json-pretty :data="value"> </vue-json-pretty>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  props: {
    value: Object,
  },
  components: {
    VueJsonPretty,
  },
};
</script>

<style></style>
