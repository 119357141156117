import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import _ from "lodash";

export default {
  mixins: [paymentsTableFormats],
  components: { TableRowAvatar },
  props: {
    item: Object,
    my: Boolean,
  },
  data() {
    return {
      statuses: {
        UNKNOWN: "",
        SIGNED: "Signed",
        SIGNED_OFFSITE: "Signed",
        OUT_FOR_SIGNATURE: "Sent",
        NONE: "Contract Needed",
        EXTENSION_OUT_FOR_SIGNATURE: "Extension Sent",
        CANCELLED: "Canceled",
      },
    };
  },
  computed: {
    contractStatus() {
      if (this.item.status == "canceled") return "CANCELLED";
      if (
        !this.item ||
        !this.item.work_assignments ||
        this.item.work_assignments.length == 0
      )
        return "UNKNOWN";

      const agreement_statuses = this.item.work_assignments.map(
        (wa) => wa.agreement_status
      );
      const unique_statuses = _.uniq(agreement_statuses);

      if (unique_statuses.length == 1) return unique_statuses[0];
      if (unique_statuses.includes("NONE")) return "NONE";
      if (unique_statuses.includes("OUT_FOR_SIGNATURE"))
        return "OUT_FOR_SIGNATURE";
      if (unique_statuses.includes("EXTENSION_OUT_FOR_SIGNATURE"))
        return "EXTENSION_OUT_FOR_SIGNATURE";

      console.info("Unknown agreement_statuses", agreement_statuses);
      return "UNKNOWN";
    },
    hiringManagers() {
      if (
        !this.item ||
        !this.item.work_assignments ||
        this.item.work_assignments.length == 0
      )
        return [];
      return _.uniqBy(
        this.item.work_assignments.map((wa) => wa.hiring_manager),
        "id"
      );
    },
    itemMondayComItemIdNotAPhoneNumber() {
      // For blocking some browsers' misguided phone links based on format detection
      if (!this.item || !this.item.monday_com_item_id) return "";
      return (
        this.item.monday_com_item_id.substring(0, 2) +
        "&zwj;" +
        this.item.monday_com_item_id.substring(2)
      );
    },
  },
};
