<template>
  <v-expansion-panel class="list-panel">
    <v-expansion-panel-header class="list-panel-header">
      <div class="list-panel-header__start">
        <v-icon size="32" class="list-panel-icon" v-if="!hideExpand">
          $chevron_down
        </v-icon>
        <v-icon v-if="icon" class="list-panel-icon">
          <slot name="icon">
            {{ icon }}
          </slot>
        </v-icon>
        <div class="list-panel-title">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="list-panel-label">
          <slot name="label">
            {{ label }}
          </slot>
        </div>
      </div>
      <div class="panel-header__end">
        <slot name="addAction" />
        <v-btn
          v-if="addBtnLink"
          :to="addBtnLink"
          color="primary"
          elevation="0"
          rounded
          class="list-panel__add-btn"
        >
          <slot name="btn">
            <v-icon>$plus</v-icon>
            <span class="list-panel__add-btn-text">
              {{ addBtnText }}
            </span>
          </slot>
        </v-btn>
        <Actions
          v-if="actionPath"
          :path="actionPath"
          :hide-destroy="hideDestroy"
          :hide-show="hideShow"
          :hide-edit="hideEdit"
          :hide-duplicate="hideDuplicate"
          @destroy="$emit('destroy')"
          @duplicate="$emit('duplicate')"
        />
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot v-if="$slots.default" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    title: String,
    label: String,
    icon: String,
    actionPath: String,
    hideDestroy: Boolean,
    hideShow: Boolean,
    hideEdit: Boolean,
    hideDuplicate: Boolean,
    hideExpand: Boolean,
    addBtnLink: String,
    addBtnText: String,
  },
};
</script>

<style lang="scss" scoped>
.list-panel {
  &::v-deep {
    margin-bottom: 20px;
    border: 1px solid var(--v-border-base);
    border-radius: 10px !important;
    background: #ffffff;

    &:not(.v-expansion-panel--active):hover {
      border-color: #a9a9ab;
    }

    &-header--active {
      border-bottom: 1px solid var(--v-border-base);
    }

    .v-expansion-panel-content__wrap {
      min-height: 100px;
      padding: 30px 40px !important;
    }

    .v-expansion-panel-header__icon {
      display: none;
    }
  }

  &-title {
    color: var(--v-text-base);
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  &:before {
    box-shadow: none !important;
  }
  &:after {
    display: none !important;
  }

  &__blank {
    text-align: center;
    color: var(--v-text-base);
    font-size: 18px;
  }

  &-header__icon {
    display: none;
  }

  &__add-btn {
  }

  &-header {
    justify-content: space-between;
    padding: 20px 40px 20px 20px;
  }

  &-header__start {
    display: flex;
    align-items: center;
    flex: none;
  }
  &-header__end {
    flex: none !important;
    display: flex;
    align-items: center;

    .actions {
      margin-left: 20px;
    }
  }

  &-icon {
    margin-right: 10px;
  }

  &.v-expansion-panel--active {
    .list-panel-icon {
      transform: rotate(180deg);
    }
  }

  &-label {
    color: var(--v-text-base);
    opacity: 0.5;
    margin: 0 10px;
  }
}

@media screen and (max-width: 960px) {
  .list-panel {
    &-title {
      font-size: 16px !important;
    }

    &__new-block-btn {
      padding: 0 !important;
      min-width: 36px !important;

      &-text {
        display: none;
      }
    }

    &::v-deep .v-expansion-panel-header {
      padding: 15px 25px;
    }
  }
}
</style>
