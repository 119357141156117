import sharedTableActions from "@/mixins/shared-table-actions.mixin";

export default {
  mixins: [sharedTableActions],
  data() {
    return {
      dateParams: ["created_at", "start_date", "end_date"],
      listOptions: this.getDefaultListOptions(),
    };
  },
  methods: {
    getDefaultListOptions() {
      return {
        creator_id: null,
        created_at: [null, null],
        start_date: [null, null],
        end_date: [null, null],
        search_text: null,
        order_by: "created_at",
        order_direction: "DESC",
        internal_project_id: null,
        contract_status: [
          { name: "SIGNED", label: "Signed", value: true },
          { name: "OUT_FOR_SIGNATURE", label: "Sent", value: true },
          { name: "NONE", label: "Contract Needed", value: true },
          {
            name: "EXTENSION_OUT_FOR_SIGNATURE",
            label: "Extension Sent",
            value: true,
          },
          {
            name: "CANCELLED",
            label: "Canceled",
            value: true,
          },
        ],
        skill_id: null,
        page: 1,
      };
    },
    resetListOptions() {
      this.listOptions = this.getDefaultListOptions();
      this.searchInput = null;
      this.fetchList();
      this.reloadKey++;
    },
    resetFilter(fieldName) {
      switch (fieldName) {
        case "contract_status":
          this.listOptions[fieldName] = this.getDefaultListOptions()[fieldName];
          break;
        case "start_date":
        case "end_date":
        case "created_at":
          this.listOptions[fieldName] = [null, null];
          break;
        default:
          this.listOptions[fieldName] = null;
      }
      this.optionsUpdate();
    },
    activeListOption(fieldName) {
      switch (fieldName) {
        case "contract_status":
          return (
            this.listOptions.contract_status.filter((type) => !type.value)
              .length != 0
          );
        case "start_date":
        case "end_date":
        case "created_at":
          return this.listOptions[fieldName].filter((date) => date).length > 0;
        default:
          return this.listOptions[fieldName];
      }
    },
  },
  computed: {
    listParams() {
      return {
        creator_id: this.listOptions.creator_id,
        order_by: this.listOptions.order_by,
        order_direction: this.listOptions.order_direction,
        internal_project_id: this.listOptions.internal_project_id,
        search_text: this.listOptions.search_text,
        created_at: this.dateParam(this.listOptions.created_at),
        start_date: this.dateParam(this.listOptions.start_date),
        end_date: this.dateParam(this.listOptions.end_date),
        contract_status: this.listOptions.contract_status
          .filter((type) => type.value)
          .map((type) => type.name),
        skill_id: this.listOptions.skill_id,
      };
    },
    anyActiveListOptions() {
      return (
        [
          "creator_id",
          "internal_project_id",
          "search_text",
          "contract_status",
          "start_date",
          "end_date",
          "created_at",
          "skill_id",
        ].filter((fieldName) => this.activeListOption(fieldName)).length > 0 ||
        this.listOptions.order_by != "created_at" ||
        this.listOptions.order_direction != "DESC"
      );
    },
  },
};
