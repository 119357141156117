<template>
  <v-form ref="form" class="extra-info">
    <div class="form-header" :style="headerStyles">
      <div class="form-header__avatar">
        <Uploader
          circle
          v-model="form.avatar"
          :ratio="1"
          enableCropper
          @input="$emit('input', form)"
          avatar
        >
          <Availability :value="currentUser.availability" />
        </Uploader>
      </div>
      <div class="form-header__info">
        <div class="form-header__info-title">{{ currentUser.full_name }}</div>
        <a
          class="form-header__info-subtitle"
          @click="$root.$emit('openAvatarUploader')"
          >Upload/Change Photo
          <v-icon size="16" color="#7B61FF">$encouraged</v-icon></a
        >
        <v-checkbox
          v-model="availabilityCheckBox"
          @change="setAvailability($event)"
          color="primary"
          label="Available for Work"
          class="form-header__info-availability-checkbox"
        ></v-checkbox>
      </div>
      <div class="form-header__header-image-menu">
        <div class="form-header__header-image-content">
          <v-icon
            color="white"
            size="24"
            @click="headerImageForm = !headerImageForm"
            >$pencil</v-icon
          >
        </div>
      </div>
      <div v-if="headerImageForm" class="form-header__header-image-form">
        <v-btn
          class="close"
          icon
          @click="headerImageForm = false"
          color="#262729"
        >
          <v-icon size="30" light>$close</v-icon>
        </v-btn>
        <div class="form-header__header-image-form__header">
          Uploaded content to view and crop
        </div>
        <FormRow label="Desktop" light>
          <FileField
            placeholder="Desktop Image"
            v-model="form.profile_header_image"
            enableCropper
            acceptFiletypes="image/*"
            :ratio="1500 / 245"
          ></FileField>
        </FormRow>
        <FormRow label="Mobile" light>
          <FileField
            placeholder="Mobile Image"
            v-model="form.profile_header_image_mobile"
            enableCropper
            acceptFiletypes="image/*"
            :ratio="375 / 418"
          ></FileField>
        </FormRow>
        <SubmitFooter dense :refs="$refs" v-if="btnText" :submit="submit">
          <span>
            {{ btnText }}
          </span>
        </SubmitFooter>
      </div>
    </div>
    <FormPanels light>
      <FormPanel label="About you">
        <div class="form-column-headers">
          <div class="form-toptips">
            <div class="form-toptip">
              <span class="form-toptips__icon form-toptips__required">*</span>
              Indicates required fields
            </div>
            <div class="form-toptip">
              <div class="form-toptips__icon form-toptips__encouraged">
                <v-icon size="16" color="#7B61FF">$encouraged</v-icon>
              </div>
              {{ encouragedTip }}
            </div>
          </div>
          <div class="form-column-header__public">Public View</div>
        </div>
        <FormRow required label="First Name" :public-settings="{}" light>
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name" :public-settings="{}" light>
          <template #additional>Only your initial will be displayed.</template>
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow label="Account Type" required :public-settings="{}" light>
          <Select
            placeholder="Account Type"
            v-model="form.account_type"
            :items="accountTypes"
            :rules="[validation.required]"
          />
        </FormRow>
        <FormRow
          v-show="form.account_type == 'business'"
          :required="form.account_type == 'business'"
          label="Doing Business As"
          :public-settings="{}"
          light
        >
          <template #additional
            >Your business will appear as the name on your
            <span class="nowrap">public profile.</span></template
          >
          <TextField
            placeholder="Doing Business As"
            v-model="form.doing_business_as"
            :rules="
              form.account_type == 'business' ? [validation.required] : []
            "
          ></TextField>
        </FormRow>
        <FormRow label="Bio" encouraged :public-settings="{}" light>
          <template #additional>Tell us a little about yourself.</template>
          <TextArea :rows="3" placeholder="Bio" v-model="form.bio"></TextArea>
        </FormRow>
        <FormRow
          label="Pronouns"
          encouraged
          :public-settings="{ key: 'pronouns', value: form.public_fields }"
          light
        >
          <Select
            :items="datasources.pronouns"
            v-model="form.pronouns_multi"
            multiple
            light
          ></Select>
        </FormRow>
        <FormRow
          label="Contact Email"
          encouraged
          :public-settings="{ key: 'contact_email', value: form.public_fields }"
          light
        >
          <template #additional
            >You can change your public-facing email address here <br />(NOTE:
            this will not update your account credentials).</template
          >
          <TextField
            :placeholder="'Contact Email'"
            v-model="form.contact_email"
          ></TextField>
        </FormRow>
        <FormRow
          label="Primary Specialty"
          encouraged
          :public-settings="{}"
          light
        >
          <Select
            placeholder="Choose One"
            itemText="title"
            itemValue="id"
            :items="datasources.skills"
            v-model="form.primary_role_id"
            light
          ></Select>
        </FormRow>
        <FormRow
          label="Secondary Specialty(s)"
          :public-settings="{ key: 'skill_ids', value: form.public_fields }"
          light
        >
          <TagSelector
            label="Choose up to Three"
            :items="datasources.skills"
            multiple
            itemText="title"
            itemValue="id"
            v-model="form.skill_ids"
            :maximum="3"
            light
          ></TagSelector>
        </FormRow>
        <FormRow
          label="Primary Working Location"
          encouraged
          :public-settings="{}"
          light
        >
          <LocationSelector
            source="place_name_en"
            placeholder="City, State, Country"
            type="city"
            v-model="form.primary_working_location"
            light
          />
        </FormRow>
        <FormRow
          label="Secondary Working Location(s)"
          :public-settings="{
            key: 'secondary_working_location',
            value: form.public_fields,
          }"
          light
        >
          <LocationSelector
            source="place_name_en"
            placeholder="City, State, Country"
            type="city"
            v-model="form.secondary_working_location"
            light
          />
        </FormRow>
        <FormRow
          label="School"
          :public-settings="{ key: 'school', value: form.public_fields }"
          light
        >
          <template #additional>If applicable</template>
          <SchoolSearch
            @input="updateSchool"
            :initial-value="form.school"
            light
          ></SchoolSearch>
        </FormRow>
        <FormRow
          label="Grad Year"
          :public-settings="{ key: 'grad_year', value: form.public_fields }"
          light
        >
          <Select
            placeholder="Grad Year"
            :items="years"
            v-model="form.grad_year"
            light
          ></Select>
        </FormRow>
        <FormRow
          label="Instagram"
          :public-settings="{ key: 'instagram', value: form.public_fields }"
          light
        >
          <TextField
            :rules="[validation.instagramHandle]"
            placeholder="Instagram"
            v-model="form.instagram"
          ></TextField>
        </FormRow>
        <!-- <FormRow
          label="Vimeo"
          :public-settings="{ key: 'vimeo', value: form.public_fields }"
        >
          <TextField
            placeholder="Vimeo"
            v-model="form.vimeo"
          ></TextField>
        </FormRow> -->
        <FormRow
          label="LinkedIn"
          :public-settings="{ key: 'linkedin', value: form.public_fields }"
          light
        >
          <TextField
            :rules="[validation.strictUrl]"
            placeholder="LinkedIn"
            v-model="form.linkedin"
          ></TextField>
        </FormRow>
        <FormRow
          label="Resume"
          :public-settings="{ key: 'resume', value: form.public_fields }"
          light
        >
          <FileField
            placeholder="Upload Resume"
            v-model="form.resume"
          ></FileField>
        </FormRow>
        <FormRow
          label="Website"
          :public-settings="{
            key: 'website_detailed',
            value: form.public_fields,
          }"
          light
        >
          <div class="form__website">
            <TextField
              placeholder="Website Title"
              v-model="form.website_detailed.title"
            ></TextField>
            <TextField
              :rules="[validation.url]"
              placeholder="Url"
              v-model="form.website_detailed.url"
            ></TextField>
          </div>
        </FormRow>
        <div class="form__work-examples-tip">
          At least one example below is required for verification of Silver or
          Gold creator status. (Resume, Reel, Website, etc.)
        </div>
        <FormRow label="Work Examples" encouraged :public-settings="{}" light>
          <template #additional>Maximum of 3</template>
          <WorkExamplesEditor v-model="form.work_examples" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" v-if="btnText" :submit="submit">
      <span>
        {{ btnText }}
      </span>
      <template #actions>
        <v-btn
          large
          color="#262729"
          dark
          class="private-profile-link elevation-0"
          :to="`/p/${currentUser.username || currentUser.id}`"
          target="_blank"
          >View Profile</v-btn
        >
      </template>
    </SubmitFooter>
    <v-dialog
      @click:outside="thankYouDialog = false"
      max-width="400"
      v-model="thankYouDialog"
    >
      <v-card>
        <v-btn class="dialog-close" icon @click="thankYouDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-title class="dialog-title"> Thank you! </v-card-title>
        <v-card-text>
          Welcome to the iU Community! Fill out your profile details here to get
          to know each other and have access to the most paid gigs,
          opportunities, and more. When that’s done, head over to the
          <router-link to="/opportunities">Opportunities</router-link> page to
          see what’s currently available!
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import mixin from "./_extends/form.extends.js";

export default {
  extends: mixin,
  props: {
    full: Boolean,
  },
  data() {
    return {
      unlocked: false,
      thankYouDialog: false,
      headerImageForm: false,
      encouragedTip:
        "Indicates required for verification of Silver or Gold creator status. Silver or Gold creators get priority access and consideration for iU Opportunities.",
      availabilityCheckBox: false,
      accountTypes: [
        {
          value: "individual",
          name: "Sole Proprietor (individual contractor)",
        },
        {
          value: "business",
          name: "Business (LLC, Partnership, S Corp, etc.)",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.thanks) this.thankYouDialog = true;
    this.availabilityCheckBox = this.currentUser.availability == "available";
  },
  methods: {
    publicChange(value, field) {
      this.form.public_fields[field] = value;
    },
    updateSchool(val) {
      this.form.school = val.title;
    },
    async setAvailability($event) {
      const availability = $event ? "available" : "unavailable";
      await this.$api.User.availability({ availability });
      this.$store.commit("user/set", { ...this.currentUser, availability });
    },
  },
  computed: {
    headerStyles() {
      if (!this.form.profile_header_image?.large?.url) return {};
      const img = this.form.profile_header_image.large.url;
      const mobileImg =
        this.form.profile_header_image_mobile?.large?.url || img;
      return {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
          ["sm", "xs"].includes(this.$size) ? mobileImg : img
        })`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.form-header {
  display: flex;
  align-items: center;
  background: #262729;
  background-size: cover;
  background-position: center;
  padding: 33px 40px;
  position: relative;
  z-index: 1;
  border-radius: 10px 10px 0 0;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    margin: 0 31px 36px;
  }

  &__avatar {
    ::v-deep .v-icon {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__info {
    margin-left: 35px;
    @media screen and (max-width: 960px) {
      margin-left: 0;
      text-align: center;
    }

    &-availability {
      &-checkbox {
        color: white;
        &::v-deep {
          .v-label {
            color: white !important;
            font-weight: 700;
            font-size: 14px;
          }
          .mdi-checkbox-blank-outline::before {
            content: "";
            border: 2px solid var(--v-primary-base);
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
          .mdi-checkbox-marked::before {
            content: "";
            border: 2px solid var(--v-primary-base);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--v-primary-base);
          }
          .v-input--selection-controls__ripple {
            height: 21px;
            width: 21px;
            left: -6px;
            top: calc(50% - 18px);
          }
          .v-input--selection-controls__input {
            margin-right: 2px;
          }
        }
      }
    }

    &-title {
      font-weight: 900;
      font-size: 20px;
      color: white;
    }
    &-subtitle {
      font-size: 14px;
      color: #dcdce1;
    }
  }

  &::v-deep {
    .uploader-circle {
      height: 175px;
      width: 175px;
      border-color: rgba(255, 255, 255, 0.5);

      &:hover {
        border-color: white;
      }
    }
  }

  &__header-image {
    &-menu {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
    }
    &-form {
      position: absolute;
      top: 16px;
      right: 46px;
      background: white;
      border: 1.58162px solid #262729;
      border-radius: 8px;
      min-height: 216px;
      padding: 44px 24px;
      width: 566px;
      z-index: 1;
      @media screen and (max-width: 960px) {
        right: 16px;
        max-width: calc(100% - 32px);
      }
      &__header {
        color: #e12d1b;
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 35px;
      }
    }
  }
}

.extra-info {
  .form-panels {
    margin-top: -2px !important;
    @media screen and (max-width: 960px) {
      margin: 38px 0;
      width: auto;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      margin-bottom: 0;
    }

    ::v-deep .v-expansion-panel {
      .v-expansion-panel-content {
        .v-input input,
        .v-input textarea {
          color: rgba(0, 0, 0, 0.87);
        }
        .v-input fieldset {
          border-color: #9a9a9a;
        }
        .v-input:hover fieldset {
          border-color: rgba(0, 0, 0, 0.3);
        }
        .v-autocomplete.primary--text:not(.tag-selector):not(.location-selector)
          fieldset {
          border-color: currentColor;
        }
        .v-text-field .v-input__slot {
          background: white;
        }
        .uploader {
          border: 1px solid #9a9a9a;

          &__upload {
            border-left: 1px solid #9a9a9a;

            .v-icon {
              color: rgba(0, 0, 0, 0.54);
            }
          }
        }
      }
    }
  }
  .submit-footer {
    @media screen and (max-width: 960px) {
      margin: 0;
      border-width: 0;
      border-radius: 0;
      padding: 0 40px 40px;
    }
  }
}

.view-profile-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.private-profile-link {
  position: relative;
  left: -12px;
}
.form {
  &-column-headers {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    color: #56565a;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  &-column-header__public {
    text-align: center;
    min-width: max-content;
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  &__website {
    > div {
      margin-bottom: 0.5em;
    }
  }

  &__work-examples-tip,
  &-toptips {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.36;
    font-style: italic;
    color: #56565a;
  }

  &__work-examples-tip {
    padding: 20px 0;
    border: 2px solid #dcdce1;
    border-style: solid hidden;
    margin-right: 78px;
    @media screen and (max-width: 960px) {
      margin-right: 0;
    }
  }

  &-toptips {
    margin-right: 2em;
    &__icon {
      margin-right: 0.4em;
    }
    &__required {
      color: var(--v-primary-base);
      margin-left: 3px;
      font-size: 14px;
      font-weight: 700;
    }
  }
  &-toptip {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

#app.disabled-theme .extra-info .theme--dark.v-btn {
  color: white;
}
#app.disabled-theme.page-Profile .theme--dark.v-card {
  > .v-card__title {
    justify-content: center;
  }
  > .v-card__text {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  }
}
</style>

<style lang="scss">
.form-header__header-image-form {
  .form-row {
    flex-direction: column;
  }
  .form-label {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .form-inputs {
    padding: 0 !important;
  }
}
</style>
