<template>
  <tr class="payments-row">
    <td class="center-align">
      <span class="payments-row__detail-link" @click="$emit('openDialog')">{{
        item.int_id
      }}</span>
    </td>
    <td class="center-align">
      {{ item.created_at ? dateFormatted(item.created_at, "MM/DD/YYYY") : "" }}
    </td>
    <td class="td-amount">{{ item.total_amount | currency }}</td>
    <td class="td-nowrap">
      {{ item.rate_units.length ? item.rate_units.join(" + ") : "" }}
    </td>
    <td class="center-align">{{ uniqGigTitles(item).join(", ") || "N/A" }}</td>
    <td class="center-align">
      {{ uniqProjectCodes(item).join(", ") || "N/A" }}
    </td>
    <td
      class="invoice__status center-align"
      :class="`invoice__status--${item.status}`"
    >
      {{ statuses[item.status] }}
    </td>
    <td>
      <div class="td-actions">
        <ActionsMenu
          class="payments-row__actions-toggle"
          @close="menu = false"
          @toggle="menu = !menu"
          :menu="menu"
          :themeDisabledPage="themeDisabledPage"
        >
          <v-btn
            @click="$emit('openDialog')"
            color="#262729"
            elevation="0"
            dark
          >
            View Invoice
          </v-btn>
          <v-btn @click="download(item)" color="#56565A" elevation="0" dark>
            Download PDF
          </v-btn>
        </ActionsMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [themeDisabledPageMixin, paymentsTableFormats],
  props: {
    item: Object,
  },
  data() {
    return {
      menu: false,
      statuses: {
        submitted: "Submitted",
        hm_approved: "Submitted", // DEPRECATED
        hm_rejected: "Submitted", // DEPRECATED
        fi_approved: "Submitted", // DEPRECATED
        fi_rejected: "Submitted", // DEPRECATED
        paid: "Completed",
        canceled: "Canceled",
        processing: "Processing",
      },
    };
  },
  methods: {
    download({ id }) {
      this.$api.Invoice.download({ id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.btn-multiline {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
  max-height: unset;
  height: auto !important;
  max-width: 131px;
}
</style>
