<template>
  <tr class="payments-row">
    <td>
      <router-link
        :to="`/hire-requests/${item.monday_com_item_id}`"
        v-html="itemMondayComItemIdNotAPhoneNumber"
      />
    </td>
    <td>
      <TableRowAvatar
        :user="item.creator"
        :path="`/p/${item.creator.username || item.creator.id}/hire-requests`"
      />
    </td>
    <td class="center-align">
      {{
        item.work_assignments && item.work_assignments.length
          ? item.work_assignments.map((gig) => gig.title).join(", ")
          : "N/A"
      }}
    </td>
    <td>
      <TableRowAvatar
        :user="hiringManager"
        v-for="(hiringManager, idx) in hiringManagers"
        :key="`hm-${idx}-${hiringManager ? hiringManager.id : ''}`"
      />
    </td>
    <td
      class="center-align"
      :class="[
        'payments-row__td--status',
        `payments-row__td--status--${contractStatus}`,
      ]"
    >
      {{ statuses[contractStatus] }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.start_date, "MM/DD/YYYY") }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.extended_end_date, "MM/DD/YYYY") }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.created_at, "MM/DD/YYYY") }}
    </td>
  </tr>
</template>

<script>
import hireRequestsTableRow from "@/mixins/hire-requests/hire-requests-table-row.mixin";
import peopleTeamActions from "@/mixins/agreements/people-team-actions.mixin";

export default {
  mixins: [hireRequestsTableRow, peopleTeamActions],
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
@import "@/styles/hire-requests/payments-row.scss";
</style>
