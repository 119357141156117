<template>
  <v-snackbar
    v-if="alert"
    class="alert-container"
    v-model="alert"
    top
    :timeout="4000"
  >
    <v-alert
      :icon="false"
      dismissible
      class="alert"
      dense
      :type="alert.type"
      :value="!!alert"
      @input="alert = $event"
    >
      <span v-html="alert.message"></span>
    </v-alert>
  </v-snackbar>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      alert: null,
    };
  },
  mounted() {
    this.$root.$on("alert", async (alert) => {
      this.alert = false;
      await this.$nextTick();
      this.alert = alert;
    });
  },
};
</script>

<style scoped lang="scss">
.alert::v-deep {
  white-space: pre-line;
  line-height: 1.3;
  margin: 0 !important;
  width: 100%;
  padding: 0 10px;
  font-size: 1em;
  padding: 15px;

  a {
    text-decoration: underline;
    font-weight: bold;
  }

  .v-icon {
    align-self: center;
    font-size: 20px;
  }
}

.alert-container::v-deep {
  background: transparent;
  padding-top: 54px !important;

  .v-snack__action {
    display: none;
  }

  .v-snack__content {
    height: auto;
    min-height: 0;
    padding: 0;
  }
}
</style>
