<template>
  <div v-if="loaded">
    <div v-if="status == 200" :class="`page-${page.id}`" :key="page.id">
      <div class="components">
        <div
          class="component"
          v-for="(component, idx) in page.components"
          :key="idx"
        >
          <component
            :value="component.resource_id"
            v-bind="component"
            :is="component.type"
            :page="page"
          ></component>
        </div>
      </div>
      <div v-html="styles"></div>
    </div>
    <NotAuthorized v-else-if="status == 403" />
    <NotFound v-else-if="status == 404" />
  </div>
</template>

<script>
import _ from "lodash";
import HtmlBlock from "./HtmlBlock";
import CustomComponent from "./CustomComponent";
import Faq from "./Faq";

export default {
  components: {
    HtmlBlock,
    CustomComponent,
    Faq,
  },
  data() {
    return {
      page: null,
      loaded: false,
    };
  },
  mounted(){
    this.fetchPage();
  },
  methods: {
    async fetchPage(){
      const { data } = await this.$api.Page.get({ url: this.$route.path.slice(1) });
      this.page = data;
      this.loaded = true;
    }
  },
  computed: {
    status() {
      if (!this.page) return 404;

      if (
        (this.page.authorized && !this.currentUser) ||
        (this.currentUser &&
          this.page.role_ids.length &&
          !_.intersection(this.page.role_ids, this.currentUser.role_ids).length)
      ) {
        return 403;
      } else {
        return 200;
      }
    },
    styles() {
      return `
        <style rel="stylesheet" type="text/css">
          ${this.page?.css}
        </style>
      `;
    }
  },
  watch:{
    $route (to, from){
      if(to != from) this.fetchPage();
    }
  } 
};
</script>

<style scoped lang="scss">
</style>
