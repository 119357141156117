<template>
  <v-switch
    class="switcher"
    hide-details="auto"
    :rules="rules"
    :true-value="true"
    :false-value="false"
    :input-value="value"
    :value="value"
    @change="
      $emit('input', $event);
      $emit('change', $event);
    "
    :label="label"
  ></v-switch>
</template>

<script>
export default {
  props: {
    label: String,
    value: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.switcher {
  margin: 0;
}
</style>
