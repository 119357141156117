export default {
  data() {
    const pageName = this.$route.name;

    return {
      list: null,
      searchText: '',
      pageName,
      perPage: 10,
      searchFields: ['title'],
      page: 1,
      view: this.$ls.get(`${pageName}/view`, 'table'),
      sortBy: this.$ls.get(`${pageName}/sortBy`, 'updated_at'),
      sortList: [
        { text: 'Created date', value: 'created_at' },
        { text: 'Updated date', value: 'updated_at' },
      ],
    };
  },
  computed: {
    computedList() {
      return (this.list || []).filter(t => {
        for (const name of this.searchFields) {
          const result = (t[name] || "").toLowerCase().includes(this.searchText.toLowerCase());
          if (result) {
            return true;
          }
        }
        return false;
      });
    },
  },
  watch: {
    view: {
      immediate: true,
      handler(value) {
        this.$ls.set(`${this.pageName}/view`, value);
      },
    },
    sortBy: {
      immediate: true,
      handler(value) {
        this.$ls.set(`${this.pageName}/sortBy`, value);
        this.fetchList();
      },
    },
  },
};
