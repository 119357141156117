<template>
  <div class="project-members-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">Project View - {{ project.title }}</div>
    <ProjectMembersTable :value="project" />
  </div>
</template>

<script>
import ProjectMembersTable from "./project-members/table.project-members";

export default {
  components: { ProjectMembersTable },
  head: {
    title: "Payments | Project Team",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      project: {},
    };
  },
  mounted() {
    this.setProject();
  },
  methods: {
    async setProject() {
      const { data } = await this.$api.InternalProject.members({
        id: this.$route.params.id,
      });
      this.project = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-members-page {
  .page-title {
    margin-top: 30px;
    word-wrap: break-word;
  }
}
</style>
