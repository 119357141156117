import _ from "lodash";

export default {
  methods: {
    uniqProjects(invoice) {
      return _.uniqBy(invoice.projects, "id");
    },
    uniqProjectCodes(invoice) {
      return _.uniq(invoice.projects.map((p) => p.code));
    },
    uniqProjectTitles(invoice) {
      return _.uniq(invoice.projects.map((p) => p.title));
    },
    uniqGigs(invoice) {
      return _.uniqBy(invoice.gigs, "id");
    },
    uniqGigTitles(invoice) {
      return _.uniqBy(invoice.gigs, "id").map((gig) => gig.title);
    },
    dateFormatted(date, format) {
      if (!date) return "";
      const momentDate = this.$moment.tz(date, this.currentTimezone);
      return momentDate.format(format);
    },
    signedAtFormatted(item) {
      if (item.status == "SIGNED_OFFSITE") return "Signed offsite";
      if (item.status == "SIGNED" && !item.signed_at) return "N/A";
      return item.signed_at
        ? this.dateFormatted(item.signed_at, "MMM D, YYYY")
        : "Pending";
    },
  },
};
