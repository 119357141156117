<template>
  <div>
    <v-dialog
      width="1346"
      v-model="dialog"
      @click:outside="cancel"
      content-class="send-agreement-dialog agreements-dialog"
      light
    >
      <v-card class="send-agreement__content">
        <div class="send-agreement__header">
          <div class="send-agreement__title">Send Agreement</div>
          <v-btn class="close" icon @click="cancel">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="send-agreement__form">
          <v-form @submit.prevent="submit" ref="form">
            <div>
              *
              <span class="send-agreement__required-hint"
                >Indicates required fields</span
              >
            </div>
            <div>
              <div class="form-row">
                <div class="form-label">Creator*</div>
                <div class="form-field">
                  <UsersSelector
                    v-model="form.user_id"
                    placeholder="Creator"
                    :rules="[validation.required]"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-label">Agreement Type*</div>
                <Select
                  :items="agreementTypes"
                  item-value="value"
                  item-text="name"
                  v-model="form.agreement_type"
                  :rules="[validation.required]"
                  class="form-field"
                />
              </div>
              <div
                class="form-row"
                v-if="
                  ['msa_contractor', 'msa_crew'].includes(form.agreement_type)
                "
              >
                <div class="form-label">Agreement Year*</div>
                <Select
                  :items="msaYears"
                  item-value="value"
                  item-text="value"
                  v-model="form.msa_year"
                  :rules="[validation.required]"
                  class="form-field"
                />
              </div>
            </div>
            <div class="invoice-form__footer">
              <v-btn
                type="submit"
                color="#7B61FF"
                elevation="0"
                class="btn-submit"
              >
                Submit
              </v-btn>
              <v-btn
                @click="cancel"
                elevation="0"
                v-ripple="false"
                color="#56565A"
                class="btn-cancel"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    dialog: Boolean,
    user: Object,
  },
  data() {
    const today = new Date();
    return {
      form: {
        user_id: null,
        agreement_type: null,
        work_assignment_ids: [],
      },
      agreementTypes: [
        { name: "MSA, Contractor", value: "msa_contractor" },
        { name: "MSA, Crew", value: "msa_crew" },
        { name: "W-9", value: "w9" },
        { name: "ACH Authorization", value: "ach_authorization" },
      ],
      msaYears: [
        { value: today.getFullYear() },
        { value: today.getFullYear() + 1 },
      ],
      pleaseWait: false,
    };
  },
  mounted() {
    if (this.user) this.form.user_id = this.user.id;
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.pleaseWait = true;
        try {
          this.form.transaction_id = uuidv4();
          this.$store.commit(
            "agreement/setTransactionId",
            this.form.transaction_id
          );
          const result = await this.$api.Agreement.create(this.form);
          this.pleaseWait = false;
          if (result.status == 200) {
            this.$alert("Agreement sent successfullly", "success");
            this.cancel();
            this.$emit("refresh");
          }
        } catch (e) {
          this.pleaseWait = false;
          this.$alert("Something went wrong", "error");
          this.$emit("refresh");
        }
      }
    },
    cancel() {
      this.$emit("close");
      this.form = {
        user_id: this.user ? this.user.id : null,
        agreement_type: null,
        work_assignment_ids: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/payments/payments-dialog.scss";
</style>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";

.send-agreement {
  &__header {
    padding: 35px 44px 30px;
    border-bottom: 2px solid #262729;
  }

  &__title {
    color: #e12d1b;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 900;
  }
  &-dialog {
    .form-label {
      text-align: left;
      font-weight: bold;
      color: #56565a;
      margin-bottom: 0.35em;
      margin-right: 0.35em;
    }
  }

  &__form {
    padding: 21px 64px 30px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    form {
      width: 100%;
    }
    .form-row {
      margin-top: 1em;
    }

    > div {
      margin-bottom: 1em;
    }
    .col {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .v-input::v-deep {
      label {
        top: 14px !important;
      }
      .v-text-field--enclosed .v-input__append-inner {
        margin-top: 14px !important;
      }
      .v-input__slot {
        min-height: 50px;
        padding-left: 27px !important;
      }
      &.v-input--is-disabled fieldset {
        border-color: #666 !important;
      }

      fieldset,
      .v-input__control fieldset {
        border-color: #262729;
        border-width: 2px;
      }
    }

    .v-btn.btn-cancel::before {
      display: none;
    }
    .v-btn.btn-submit {
      color: white !important;
    }

    .invoice-form__footer {
      .v-btn:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}
</style>
