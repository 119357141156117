import "@/styles/app.scss";
import 'vue-advanced-cropper/dist/style.css';

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./plugins";
import store from "./store";
import router from "./router";
import "@/components/global.components";
import "./api";
import "./data";
import "./validations/rules";
import "./filters";

Vue.config.productionTip = false;

const app = new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

export default app;
