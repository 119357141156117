<template>
  <div class="profile-view-page">
    <Profile :id="$route.params.id" />
  </div>
</template>

<script>
export default {
  head: {
    title: "Profile",
    titleTemplate: "%s | iU Community",
  },
};
</script>

<style scoped lang="scss">
.profile-view-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.update-profile-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
