<template>
  <div class="meta-tags-editor">
    <CodeEditor
      :placeholder="siteMetaTagsTooltip"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      siteMetaTagsTooltip: `<meta property="og:type" content="website">\n<meta property="og:site_name" content="My Site">\netc`,
    };
  },
};
</script>

<style scoped lang="scss">
.meta-tags-editor {
  &::v-deep {
    .CodeMirror {
      min-height: 80px;
    }
  }
}
</style>
