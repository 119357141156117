<template>
  <div class="work-example">
    <v-responsive
      class="work-example__header"
      @click="dialog = true"
      :aspect-ratio="1500 / 245"
    >
      <div class="work-example__header-inner">
        <div
          class="work-example__lede-bg"
          :style="headerStyles"
        ></div>
        <div class="work-example__header_text">
          <div class="work-example__title"> {{ item.title }}</div>
          <div class="work-example__specialty" v-if="item.skill"> {{ item.skill }}</div>
          <div class="work-example__website" v-if="item.website">
            <a :href="item.website" target="_blank" @click.stop>View Project
              <v-icon color="white">$arrow_right_short</v-icon>
            </a>
          </div>
        </div>
      </div>
    </v-responsive>
    <WorkExampleDialog
      :item="item"
      :dialog="dialog"
      @close="dialog = false"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    headerStyles() {
      let preview = this.item.preview?.large?.url;

      const original = this.item.preview?.url;

      if (original) {
        if (original.match(".gif$")) {
          preview = original;
        }
      }

      return {
        backgroundImage: `url(${preview})`
      };
    }
  },
}
</script>

<style lang="scss" scoped>
.work-example {
  &__header {
    position: relative;
    border-bottom: 2px solid black;
    cursor: pointer;
    @media screen and (max-width: 960px) {
      min-height: 229px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &::before {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &_text {
      color: white;
      position: relative;
      z-index: 1;
      padding: 57px 76px;
      @media screen and (max-width: 960px) {
        padding: 27px 38px;
      }
    }
  }
  
  &__lede-bg {
    background: gray;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

  }

  &__title {
    font-weight: 900;
    font-size: 42px;
    line-height: 1.07;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border-bottom: solid 0.04762em white;
    padding-bottom: 0.1em;
    margin-bottom: 0.2381em;
    width: max-content;
    padding-right: 2.262em;
    max-width: 100%;
    @supports (-webkit-text-stroke: 0.047619em) {
      opacity: 1.0;
      -webkit-text-stroke: 0.047619em;
      -webkit-text-fill-color: transparent;
      font-weight: 900;
    }
    @media screen and (max-width: 960px) {
      font-size: 32px;
    }
  }

  &__specialty {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 0.7em;
  }

  &__website {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.79;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}
</style>
