<template>
  <div class="submission-stage">
    <div class="submission-stage__items">
      <div
        v-for="(item, idx) in datasources.submission_form_states[submissionForm.project_type]"
        :key="idx"
        class="submission-stage__item"
        :class="{ active: activeIndex >= idx }"
      >
        <div class="submission-stage__item-space"></div>
        <div class="submission-stage__item-name">
          <div class="submission-stage__item-name__icon">
            <v-icon color="white" size="12">$check_status</v-icon>
          </div>
          <div class="submission-stage__item-name__text">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    submissionForm: Object,
  },
  computed: {
    activeIndex() {
      return this.datasources.submission_form_states[this.submissionForm.project_type].findIndex(
        (t) => t.value == this.value
      );
    },
  },
};
</script>

<style scoped lang="scss">
.submission-stage {
  margin: 10px 0;
  @media screen and (max-width: 960px) {
    margin: 10px 1em 2em;
  }
  &__items {
    display: flex;
    flex-grow: 1;
    margin-bottom: 2.5em;
    position: relative;
  }

  &__item {
    display: flex;
    align-items: center;
    max-width: 75px;
    flex-grow: 1;
    &:first-child {
      max-width: 15px;
    }

    &.active &-name__icon {
      background: var(--v-primary-base);
    }
    &.active &-name__text {
      color: var(--v-primary-base);
    }
    &.active &-space {
      background: var(--v-primary-base);
    }

    &-space {
      max-width: 60px;
      height: 2px;
      background: #56565a;
      flex-grow: 1;
    }
    &-name {
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 960px) {
        font-size: 9.36px;
      }

      &__icon {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #56565a;
      }

      &__text {
        position: absolute;
        margin-top: 24px;
        color: #56565a;
        white-space: pre-wrap;
        width: 100px;
        max-width: 21%;
      }
    }
  }

  &__items > &__item:first-child &__item-space {
    display: none;
  }
}
</style>
