<template>
  <div class="text-block__item-wrapper">
    <div
      :style="{
        backgroundImage: `url(${item.preview.large.url})`,
        color: item.color,
      }"
      :class="{ 'with-preview': item.preview }"
      class="text-block__item"
    >
      <div class="text-block__item-container">
        <div class="text-block__item-title">{{ item.title }}</div>
        <div class="text-block__item-description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style scoped lang="scss">
.text-block {
  &__item {
    color: var(--v-text-field);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    height: 100%;

    &.dark {
      color: white;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
    &-wrapper {
      padding: 10px;
    }

    &-title {
      font-weight: 900;
      font-size: 34px;
      line-height: 24px;
    }
    &-description {
      font-size: 16px;
      line-height: 16px;
      margin-top: 10px;
    }

    &-container {
      height: 100%;
      position: relative;
      padding: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
