<template>
  <TextField
    :placeholder="label"
    :value="value"
    @input="$emit('input', $event)"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
