var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PaymentsTable',{staticClass:"payments-section",class:{ 'table-loading': _vm.loadingList },attrs:{"items":_vm.list,"title":_vm.title,"listOptions":_vm.listOptions,"completedPayments":_vm.completedPayments},on:{"refresh":_vm.refresh,"orderUpdate":function($event){return _vm.orderUpdate($event)},"filterUpdate":function($event){return _vm.filterUpdate($event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"payments-table__action"},[_c('div',{staticClass:"payments-table__action__label"},[_vm._v("Filter by:")]),_c('v-overlay',{attrs:{"value":_vm.filtersMenu},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeFilters()}}}),_c('v-menu',{staticClass:"filters-menu",attrs:{"bottom":"","offset-y":"","close-on-click":false,"close-on-content-click":false,"light":"","content-class":"filters-menu__content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('TableActiveFilters',_vm._g({attrs:{"listOptions":_vm.listOptions,"fields":_vm.filterFields,"activeListOption":_vm.activeListOption},on:{"openMenu":function($event){_vm.filtersMenu = true},"resetFilter":function($event){return _vm.resetFilter($event)}}},on))]}}]),model:{value:(_vm.filtersMenu),callback:function ($$v) {_vm.filtersMenu=$$v},expression:"filtersMenu"}},[_c('v-card',{staticClass:"filters-menu__content__card"},[_c('div',{staticClass:"filters-menu__body"},[_c('v-expansion-panels',{attrs:{"multiple":"","flat":""},model:{value:(_vm.filterPanels),callback:function ($$v) {_vm.filterPanels=$$v},expression:"filterPanels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Creator ")]),_c('v-expansion-panel-content',[_c('UsersSelector',{attrs:{"placeholder":"All","includeBlocked":"","multiple":""},on:{"input":function($event){_vm.optionsUpdate();
                      _vm.closeFilters();}},model:{value:(_vm.listOptions.user_id),callback:function ($$v) {_vm.$set(_vm.listOptions, "user_id", $$v)},expression:"listOptions.user_id"}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Project ")]),_c('v-expansion-panel-content',[_c('InternalProjectsSelector',{attrs:{"emptyLabel":"All","multiple":"","chipText":"code"},on:{"input":function($event){_vm.optionsUpdate();
                      _vm.closeFilters();},"remove":function($event){_vm.removeFromFilter('internal_project_id', $event);
                      _vm.optionsUpdate();
                      _vm.closeFilters();}},model:{value:(_vm.listOptions.internal_project_id),callback:function ($$v) {_vm.$set(_vm.listOptions, "internal_project_id", $$v)},expression:"listOptions.internal_project_id"}})],1)],1),_c('TableDateFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"created_at","fieldLabel":"Date Created"},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('TableOptionsFilterPanel',{attrs:{"listOptions":_vm.listOptions,"fieldName":"status","fieldLabel":"Status","selectAll":"","reset":"","defaultListOptions":_vm.getDefaultListOptions()},on:{"filterUpdate":function($event){return _vm.filterMenuUpdate($event)}}}),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Role ")]),_c('v-expansion-panel-content',[_c('SkillsSelector',{attrs:{"placeholder":"Role","rules":[_vm.validation.required]},on:{"input":function($event){_vm.optionsUpdate();
                      _vm.closeFilters();}},model:{value:(_vm.listOptions.skill_id),callback:function ($$v) {_vm.$set(_vm.listOptions, "skill_id", $$v)},expression:"listOptions.skill_id"}})],1)],1)],1)],1)])],1)],1),_c('div',{staticClass:"payments-table__action"},[_c('div',{staticClass:"payments-table__action__label"}),_c('TextField',{attrs:{"placeholder":"Search"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('div',{staticClass:"payments-table__secondary-actions"},[(_vm.anyActiveListOptions)?_c('div',{staticClass:"payments-table__action-btn payments-table__action-btn--reset"},[_c('div',{on:{"click":function($event){return _vm.resetListOptions()}}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("$close")]),_vm._v(" Reset ")],1)]):_vm._e()])]},proxy:true},{key:"pagination",fn:function(){return [(_vm.paginationEnabled)?_c('TablePagination',{key:("pagination-" + _vm.reloadKey),attrs:{"page":_vm.page,"totalPages":_vm.totalPages},on:{"input":function($event){_vm.page = $event}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }