<template>
  <div class="fin-all-projects-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <FinanceAllProjectsTable
      :items="list"
      title="All Active Projects"
    />
  </div>
</template>

<script>
import FinanceAllProjectsTable from "./all-projects/table.all-projects";

export default {
  components: { FinanceAllProjectsTable },
  head: {
    title: "All Projects",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.InternalProject.financeList();
      this.list = data;
    },
  }
}
</script>

<style lang="scss" scoped>
.project-members-page {
  .page-title {
    margin-top: 52px;
  }
}
</style>