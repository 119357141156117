<template>
  <div class="create-opp-page">
    <div class="page-title">Create Opportunity</div>
    <v-form class="create-opp-form" ref="form" v-if="!submitted">
      <FormPanels light>
        <FormPanel
          label="Basic Info"
          additional="Everything in this section is for internal review only"
          light
        >
          <FormRow
            tooltip="Put 'NA' if this is for multiple projects of for non-project specific work"
            label="Project Code *"
            light
          >
            <ProjectCodeField
              v-model="form.project_code"
              style="max-width: 360px"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow label="Studio Department *" light>
            <Select
              placeholder="Department"
              :items="studioDepartments"
              v-model="form.studio_department"
              light
              :rules="[validation.required]"
              style="max-width: 360px"
              @input="studioDepartmentChanged($event)"
            ></Select>
          </FormRow>
          <div v-if="form.studio_department">
            <FormRow
              label="Project Status *"
              light
              tooltip="Is this for an active/sold project or a pitch?"
            >
              <Select
                placeholder="Select a status"
                :items="statuses"
                v-model="form.status"
                light
                :rules="[validation.required]"
                style="max-width: 360px"
              ></Select>
            </FormRow>
            <FormRow
              label="Hiring Managers"
              light
              tooltip="Select the person(s) who are responsible for reviewing submissions for this opportunity."
            >
              <UsersSelector
                class="create-opp-form__hms"
                placeholder="Hiring Managers"
                v-model="form.hiring_manager_ids"
                :badges="['iustaff']"
                :roles="['reviewer']"
                light
                fromProduction
                multiple
              />
            </FormRow>
            <FormRow
              label="Opportunity Title *"
              tooltip="A descriptive title for admins."
              light
            >
              <TextField
                placeholder="Opportunity Title"
                v-model="form.title"
                :rules="[validation.required]"
              ></TextField>
            </FormRow>
            <FormRow
              label="Partner Name *"
              light
              tooltip="The name of our partner on the project (i.e. PGA of America, Ideas United, etc)"
            >
              <TextField
                placeholder="Partner Name"
                v-model="form.partner_name"
                :rules="[validation.required]"
              ></TextField>
            </FormRow>
            <!--
            <FormRow label="Partner or Sponsor Logo" light>
              <Uploader v-model="form.partner_logo" :ratio="1" />
            </FormRow>
            -->
            <FormRow
              label="Community Need Reasoning *"
              light
              tooltip="Please explain why you are seeking to hire a Community creator for this role: eg: you have exhausted all internal bandwidth, seeking creator with a specific skillset."
            >
              <Select
                v-model="form.community_need_option"
                :items="communityNeedOptions"
                placeholder="Community Need Reasoning"
                light
              />
            </FormRow>
            <FormRow
              label="Community Need Explanation *"
              light
              tooltip="Please explain why you are seeking to hire a Community creator for this role: eg: you have exhausted all internal bandwidth, seeking creator with a specific skillset."
              v-if="form.community_need_option == 'other'"
            >
              <TextArea
                placeholder="Community Need Explanation"
                v-model="form.community_need"
                :rules="[validation.required]"
              />
            </FormRow>
            <FormRow label="Project Tier *" light>
              <template #additional>
                <a
                  href="https://docs.google.com/document/d/1uILO33zMGhmYfskTw4TM5gcxV8Y553hl4JUwMH3kco8/edit"
                  target="_blank"
                >
                  Project Tiers Guide
                </a>
              </template>
              <Select
                placeholder="Project Tier"
                :items="projectTiers"
                v-model="form.project_tier"
                light
                :rules="[validation.required]"
                style="max-width: 360px"
              ></Select>
            </FormRow>
            <FormRow
              label="Budget Range *"
              light
              tooltip="Show us the money! How much are we looking to pay the Community member(s) for their participation?"
            >
              <TextField
                placeholder="Budget Range"
                v-model="form.comp"
                :rules="[validation.required]"
              ></TextField>
            </FormRow>
            <FormRow
              label="Opportunity URL *"
              light
              tooltip="Put the URL that you want to show after the main part of the web address. Example: https://community.ideasunited.com/lehighdesigner"
            >
              <div class="create-opp__opportunity-url-wrapper">
                <div class="create-opp__opportunity-url__prefix">
                  https://community.ideasunited.com/
                </div>
                <TextField
                  placeholder="Opportunity URL"
                  v-model="form.production_root_path"
                  :rules="[validation.required]"
                ></TextField>
              </div>
            </FormRow>

            <FormRow
              label="Opportunity Requirements *"
              light
              tooltip="If there is a submission component of the opp, tell us the requirements (i.e. .mov files must be smaller than 500 mb; questions from partner that must be answered)."
            >
              <TextArea
                placeholder="Opportunity Requirements"
                v-model="form.requirements"
              ></TextArea>
            </FormRow>
            <FormRow label="Submissions Open" light>
              <DateTimePicker
                placeholder="Select Date/Time"
                v-model="form.opening_date"
              />
            </FormRow>
            <FormRow label="Submissions Close" light>
              <DateTimePicker
                placeholder="Select Date/Time"
                v-model="form.closing_date"
              />
            </FormRow>
          </div>
        </FormPanel>
        <FormPanel
          label="Opportunity Description / Gig Info"
          additional="Everything after this section is for public view"
          light
        >
          <div v-if="form.studio_department">
            <FormRow
              label="Project Brief"
              light
              tooltip="Add a short description (2-4 sentences) that summarizes the gig at a high-level. Try to use plain, colloquial language and add a bit of personality."
            >
              <TextArea
                placeholder="Project Brief"
                v-model="form.description"
                :rules="[validation.required]"
              ></TextArea>
            </FormRow>
            <FormRow
              label="Project Deliverable/Scope"
              light
              tooltip="What is the “finished product” of this role? #of videos edited, hours of footage recorded, footage style, design, multi-slide pitchdeck, etc. "
            >
              <TextArea
                placeholder="Project Deliverable/Scope"
                v-model="form.deliverable"
                :rules="[validation.required]"
              ></TextArea>
            </FormRow>
            <FormRow
              label="Project Timeline"
              light
              tooltip="Describe the projected start and end date of the actual gig."
            >
              <TextArea
                placeholder="Project Timeline"
                v-model="form.timeline"
                :rules="[validation.required]"
              ></TextArea>
            </FormRow>
            <FormRow
              label="Technical Requirements"
              light
              tooltip="Does this role require any specific gear, software,additional personnel, etc.?"
            >
              <TextArea
                placeholder="Technical Requirements"
                v-model="form.technical_requirements"
                :rules="[validation.required]"
              ></TextArea>
            </FormRow>
            <FormRow
              label="Reference Examples"
              light
              tooltip="If applicable, please provide any references. (Media file and/or link upload.)"
            >
              <ReferenceExamplesEditor v-model="form.reference_examples" />
            </FormRow>
          </div>
          <div v-else class="create-opp__continue-tip">
            Select a Studio Department to continue.
          </div>
        </FormPanel>
      </FormPanels>
      <SubmitFooter :refs="$refs" :submit="submit">Create</SubmitFooter>
    </v-form>
    <div v-else>
      <div class="submitted">
        Thanks for submitting an opportunity! We will review it and reach out to
        you soon!
      </div>
      <v-btn color="primary" @click="reset" elevation="0">Submit Another</v-btn>
    </div>
    <GDriveImageLibraryDialog @selectImage="selectImage" />
  </div>
</template>

<script>
import GDriveImageLibraryDialog from "@/components/GDriveImageLibraryDialog.vue";
import ReferenceExamplesEditor from "./create-opportunity/ReferenceExamplesEditor.vue";
import _ from "lodash";

export default {
  components: {
    GDriveImageLibraryDialog,
    ReferenceExamplesEditor,
  },
  head: {
    title: "Create Opportunity",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      submitted: false,
      form: {
        reference_examples: [],
      },
      statuses: [
        { name: "Active/Sold", value: "active" },
        { name: "Pitch", value: "pitch" },
      ],
      studioDepartments: [
        { name: "Production", value: "production" },
        { name: "Post-production", value: "post_production" },
        { name: "Design", value: "design" },
        { name: "Writing", value: "writing" },
        { name: "Events", value: "events" },
      ],
      gDriveImageTarget: "",
      projectTiers: [
        { name: "Tier 1", value: "no_creative" },
        {
          name: "Tier 2",
          value: "creative_thought_starters",
        },
        { name: "Tier 3", value: "creative_component" },
        { name: "Tier 4", value: "high" },
      ],
      communityNeedOptions: [
        { name: "Internal Bandwidth Exhausted", value: "bandwidth" },
        { name: "Seeking Specific Skillset", value: "skillset" },
        { name: "Local Support Needed", value: "location" },
        { name: "Other", value: "other" },
      ],
      templates: {},
    };
  },
  created() {
    if (!this.currentUser.roles.find((t) => t.name == "opportunity_creator")) {
      return this.$router.push("/");
    }
  },
  mounted() {
    this.fetchTemplates();
  },
  methods: {
    async fetchTemplates() {
      const { data } = await this.$api.Opportunity.templates();
      this.templates = data;
    },
    async submit() {
      await this.$api.Opportunity.create(this.form);
      this.submitted = true;
    },
    reset() {
      this.form = {
        reference_examples: [],
      };
      this.submitted = false;
    },
    openGDriveFor(fieldName) {
      this.gDriveImageTarget = fieldName;
      this.$root.$emit("GDriveImageLibraryDialog");
    },
    async selectImage(value) {
      this.form[this.gDriveImageTarget] = value;
      this.gDriveImageTarget = "";
    },
    studioDepartmentChanged($event) {
      if (!$event) return;
      const template = this.templates[$event];
      if (!template) return;
      _.assign(this.form, template);
    },
  },
};
</script>

<style scoped lang="scss">
.submitted {
  padding: 20px 0;
  font-size: 20px;
}
.create-opp {
  &__continue-tip {
    text-align: center;
    font-size: 13px;
  }
  &__opportunity-url {
    &-wrapper {
      display: flex;
      font-size: 13px;
      align-items: baseline;
    }
    &__prefix {
      margin-right: 0.5em;
    }
  }
  &-form__hms::v-deep input::placeholder {
    font-weight: normal !important;
  }
}
</style>
