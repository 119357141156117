<template>
  <v-select
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    outlined
    :placeholder="placeholder"
    class="select"
    :hideDetails="hideDetails"
    :multiple="multiple"
    :rules="rules"
    :disabled="disabled"
    :light="light"
    :menu-props="{ light }"
  >
    <template v-slot:append>
      <v-icon>$chevron_down</v-icon>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: [String, Number, Boolean, Array],
    items: Array,
    multiple: Boolean,
    disabled: Boolean,
    hideDetails: {
      type: [Boolean, String],
      default: "auto",
    },
    placeholder: String,
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    light: Boolean,
  },
};
</script>

<style scoped lang="scss">
.select {
  &::v-deep {
    .v-input__append-inner {
      margin-top: 12px !important;
    }

    .v-input__slot {
      min-height: 50px;
    }

    fieldset {
      border-color: var(--v-border-base);
    }

    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    .v-icon__svg {
      position: relative;
      top: 3px;
    }
  }
}
</style>
