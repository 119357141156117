<template>
  <td :class="[`review-cell--type-${field.type}`]">
    <span v-if="['TextField', 'TextArea'].includes(field.type)">
      {{ response.value }}</span
    >
    <span v-else-if="field.type == 'MediaField'">
      <span v-if="!(response && response.value)">N/A</span>
      <a
        v-else-if="response.value.type == 'image'"
        :href="response.value.url"
        target="_blank"
      >
        <img
          class="review-cell__thumb"
          :src="response.value.preview.thumb.url"
        />
      </a>
      <a
        v-else-if="response.value.type == 'file'"
        :href="response.value.url"
        target="_blank"
      >
        <v-icon>$link</v-icon>
      </a>
      <a v-else :href="response.value.url" target="_blank">
        <v-icon>$link</v-icon>
      </a>
    </span>
    <span v-else-if="field.type == 'Radio'">
      {{ field.options.find((o) => o.title == response.value).title }}
    </span>
    <span v-else-if="field.type == 'LinkField'">
      <a
        v-for="(link, idx) in splitLinks(response)"
        :key="`link--${idx}`"
        :href="link"
        target="_blank"
      >
        {{ link }}
      </a>
    </span>
    <span v-else></span>
  </td>
</template>

<script>
export default {
  props: {
    field: Object,
    response: Object,
  },
  methods: {
    splitLinks(linkResponse) {
      if (!linkResponse) return [];
      return linkResponse.value.split(",").map((link) => link.trim());
    },
  },
};
</script>

<style scoped lang="scss">
.review-cell {
  &--type {
    &-TextArea {
      width: 45%;
    }
    &-MediaField,
    &-Radio {
      text-align: center;
    }
  }
  &__thumb {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
}
</style>
