<template>
  <v-navigation-drawer
    class="sidebar"
    floating
    left
    width="290"
    clipped
    :value="value"
    app
    :permanent="$size == 'xl' || $size == 'lg'"
    @input="$emit('input', $event)"
    touchless
    light
  >
    <div class="logo">
      <img src="@/assets/logo-red.png" alt="Logo" />
    </div>
    <v-list dense shaped>
      <a
        v-if="currentUser && currentUser.avatar.url"
        class="sidebar-avatar"
        :style="{
          backgroundImage: `url(${currentUser.avatar.thumb.url})`,
        }"
        :href="`/p/${currentUser.username || currentUser.id}`"
      >
        <Availability :value="currentUser.availability" />
      </a>
      <div class="sidebar-group-header">
        <img src="@/assets/USER_PORTAL.svg" alt="User Portal" />
      </div>
      <div v-for="(item, idx) in items" :key="idx">
        <v-list-group
          v-if="item.children"
          :group="item.to"
          class="sidebar-group"
        >
          <template v-slot:appendIcon>
            <v-icon class="sidebar-group__toggle-icon"> $chevron_down </v-icon>
          </template>
          <template v-slot:activator>
            <v-tooltip
              open-delay="500"
              :disabled="!item.tooltip"
              max-width="300"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" class="sidebar-item" v-on="on">
                  <v-list-item-title> {{ item.text }} </v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </template>

          <div>
            <v-tooltip
              v-for="(subItem, sidx) in item.children"
              :key="sidx"
              open-delay="500"
              :disabled="!subItem.tooltip"
              max-width="300"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  :to="subItem.to"
                  class="sidebar-subitem"
                  active-class="active"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ subItem.tooltip }}</span>
            </v-tooltip>
          </div>
        </v-list-group>
        <v-tooltip
          v-else
          open-delay="500"
          :disabled="!item.tooltip"
          max-width="300"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :to="item.to"
              class="sidebar-item"
              active-class="active"
              v-bind="attrs"
              v-on="on"
              @click="logout(item)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "MainMenu",
  props: {
    value: Boolean,
  },
  data() {
    return {
      activeGroup: 0,
    };
  },
  methods: {
    async logout(item) {
      if (item.logout) await this.$store.dispatch("auth/logout");
    },
  },
  computed: {
    items() {
      let items = [
        {
          text: "Dashboard",
          to: "/",
        },
      ];

      if (this.currentUser?.is_reviewer) {
        items.push({
          text: "For Review",
          to: "/for-review",
        });
      }

      items = items.concat([
        {
          text: "Creator Profile",
          to: "/profile",
        },
        {
          text: "Submissions",
          to: "/submissions",
        },
      ]);

      if (this.currentUser && this.currentUser.badges.find((t) => t.slug == "referrer")) {
        items.push({
          text: "Referrals",
          to: "/referral",
        });
      }

      if (!this.isProduction()) {
        if (this.currentUser &&
            !this.currentUser.roles.find((t) => t.name == "hiring_manager") &&
            !this.currentUser.roles.find((t) => t.name == "finance") &&
            !this.currentUser.roles.find((t) => t.name == "opportunity_creator")
        ) {
          items.push({
            text: "My Payments",
            to: "/my-payments",
          });
        }
        if (this.currentUser && this.currentUser.roles.find((t) => t.name == "hiring_manager")) {
          items.push({
            text: "Payments",
            to: "/payments",
          });
        }
        if (this.currentUser &&
            ( this.currentUser.roles.find((t) => t.name == "finance") ||
              this.currentUser.roles.find((t) => t.name == "people")
            ) ) {
          items.push({
            text: "Payments Dashboard",
            to: "/payments-dashboard",
          });
        }
        if (this.currentUser && 
            ( this.currentUser.roles.find((t) => t.name == "people_team") ||
              this.currentUser.roles.find((t) => t.name == "hiring_manager") ||
              this.currentUser.roles.find((t) => t.name == "finance")
            ) ) {
          items.push({
            text: "Agreements",
            to: "/agreements",
          });
        }
      }

      if (this.currentUser && this.currentUser.roles.find((t) => t.name == "opportunity_creator")) {
        items.push({
          text: "Create Opportunity",
          to: "/create-opportunity",
        });
      }

      return items;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  border-right: 2px solid #262729;
  max-height: unset !important;
  padding-bottom: 78px;

  &::v-deep .v-navigation-drawer__content {
    padding: 70px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-avatar {
    width: 120px;
    height: 120px;
    margin: -40px auto 40px auto;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    display: block;
    position: relative;

    &::v-deep {
      .availability {
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 9px);
      }
    }
  }

  &-group-header {
    > img {
      display: block;
      width: 148.5px;
      height: 27px;
      margin-bottom: 21px;
    }
    margin-left: 74px;
    border-bottom: 2px solid #dcdce1;
    width: max-content;
    margin-bottom: 17px;
  }

  &-subitem {
    padding-left: 88px;
  }

  &-item {
    padding-left: 74px;

    &::v-deep .v-list-item__title {
      color: #56565a;
      font-weight: bold !important;
    }

    &::v-deep .v-list-item__icon {
      margin-right: 18px;
    }
  }

  &-item,
  &-subitem {
    &.active {
      &,
      &::v-deep .v-list-item__title {
        color: var(--v-primary-base) !important;
      }

      &:before {
        opacity: 0;
      }
    }
  }
  &-group {
    &::v-deep .v-list-group__header {
      padding: 0 !important;
    }

    &__toggle-icon {
      position: relative;
      left: -8px;
    }
  }
}

.logo {
  display: none;
}

@media screen and (max-width: 680px) {
  .sidebar {
    z-index: 8;
    height: auto !important;
    bottom: 0;
  }
}
@media screen and (max-width: 1263px) {
  .logo {
    // display: block;
    // padding-left: 55px;
    // margin-bottom: 20px;
  }

  .sidebar {
    &::v-deep .v-navigation-drawer__content {
      // padding-top: 20px;
    }
  }
}
</style>
