<template>
  <svg class="time-chart"></svg>
</template>

<script>
import * as d3 from "d3";
export default {
  props: {
    data: Object,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const dataReady = new Date();
      const { timeOverviewData, timeByProjectData, timeByTeamData } = this.data;

      const leftPadding = 135;
      const rightPadding = 100;
      const bottomPadding = 35;
      const yGap = 10;
      const ySubhead = 20;
      const width = 900;
      const yLegendHeight = 30;
      const yOverview = yLegendHeight + yGap;
      const yOverviewHeight = 70;
      const yDetailHeight = 10;
      const yTeamsHeight = yDetailHeight * timeByTeamData.length;
      const yTeams = yOverview + yOverviewHeight + yGap + ySubhead;
      const yProjectsHeight = yDetailHeight * timeByProjectData.length;
      const yProjects = yTeams + yTeamsHeight + yGap + ySubhead;
      const yTotal = yProjects + yProjectsHeight + yGap;
      const height = yTotal + bottomPadding;
      const innerPadding = 0.1;
      const svg = d3
        .select(".time-chart")
        .attr("viewBox", `0 0 ${width} ${height}`);
      const g = svg.append("g");

      const extentArray = timeOverviewData.concat(
        timeByProjectData.concat(timeByTeamData)
      );
      const xExtent = d3.extent(extentArray, (d) => d.fullValue || d.value);
      xExtent[0] = 0;
      const xAxis = d3
        .scaleLinear()
        .domain(xExtent)
        .rangeRound([leftPadding, width - rightPadding]);

      const legendData = [
        {
          group: "Legend (# invoices)",
          phase: "processing",
          value: xExtent[1] * 0.45,
        },
        {
          group: "Legend (# invoices)",
          phase: "paid",
          value: xExtent[1] * 0.1,
        },
      ];

      const yAxisLegend = d3
        .scaleBand()
        .rangeRound([0, yLegendHeight])
        .domain(legendData.map((d) => d.group))
        .paddingInner(innerPadding);
      const yAxisOverview = d3
        .scaleBand()
        .rangeRound([0, yOverviewHeight])
        .domain(timeOverviewData.map((d) => d.group))
        .paddingInner(innerPadding);
      const yAxis = d3
        .scaleBand()
        .rangeRound([0, yTeamsHeight])
        .domain(timeByTeamData.map((d) => d.group))
        .paddingInner(innerPadding);
      const yAxisProjects = d3
        .scaleBand()
        .rangeRound([0, yProjectsHeight])
        .domain(timeByProjectData.map((d) => d.group))
        .paddingInner(innerPadding);

      const legendStackedData = d3
        .stack()
        .keys(d3.union(legendData.map((d) => d.phase)))
        .value(([, group], key) => group.get(key).value)(
        d3.index(
          legendData,
          (d) => d.group,
          (d) => d.phase
        )
      );
      const overviewStackedData = d3
        .stack()
        .keys(d3.union(timeOverviewData.map((d) => d.phase)))
        .value(([, group], key) => group.get(key).value)(
        d3.index(
          timeOverviewData,
          (d) => d.group,
          (d) => d.phase
        )
      );
      const byTeamStackedData = d3
        .stack()
        .keys(d3.union(timeByTeamData.map((d) => d.phase)))
        .value(([, group], key) => group.get(key).value)(
        d3.index(
          timeByTeamData,
          (d) => d.group,
          (d) => d.phase
        )
      );
      const byProjectStackedData = d3
        .stack()
        .keys(d3.union(timeByProjectData.map((d) => d.phase)))
        .value(([, group], key) => group.get(key).value)(
        d3.index(
          timeByProjectData,
          (d) => d.group,
          (d) => d.phase
        )
      );

      const daysLabel = function (d) {
        return `${d[1].toFixed(1)} days`;
      };

      var stackColor = d3
        .scaleOrdinal()
        .domain(["processing", "paid"])
        .range(["#e8852b", "#4282ff"]);

      this.drawBars(svg, legendStackedData, 0, xAxis, yAxisLegend, stackColor);
      this.drawLegendValues(svg, legendStackedData, 0, xAxis, yAxisLegend);
      this.drawBars(
        svg,
        overviewStackedData,
        yOverview,
        xAxis,
        yAxisOverview,
        stackColor
      );
      this.drawValues(
        svg,
        overviewStackedData,
        leftPadding,
        yOverview,
        xAxis,
        yAxisOverview,
        daysLabel
      );
      this.drawBars(svg, byTeamStackedData, yTeams, xAxis, yAxis, stackColor);
      this.drawValues(
        svg,
        byTeamStackedData,
        leftPadding,
        yTeams,
        xAxis,
        yAxis,
        daysLabel
      );
      this.drawBars(
        svg,
        byProjectStackedData,
        yProjects,
        xAxis,
        yAxisProjects,
        stackColor
      );
      this.drawValues(
        svg,
        byProjectStackedData,
        leftPadding,
        yProjects,
        xAxis,
        yAxisProjects,
        daysLabel
      );

      // Subheads
      g.append("text")
        .attr("class", "time-chart__subhead")
        .attr("x", 10)
        .attr("y", yTeams - 10)
        .text("By Team/Department");
      g.append("text")
        .attr("class", "time-chart__subhead")
        .attr("x", 10)
        .attr("y", yProjects - 10)
        .text("By Project");

      // X Axis
      g.append("g")
        .attr("transform", `translate(0, ${height - bottomPadding})`)
        .call(d3.axisBottom(xAxis));
      g.append("text")
        .attr("class", "payments-reports-chart__axis-label")
        .attr("text-anchor", "middle")
        .attr("x", leftPadding + (width - leftPadding - rightPadding) / 2)
        .attr("y", height - 6)
        .text("Days");

      // Y Axis
      g.append("g")
        .attr("transform", `translate(${leftPadding - 0.5}, 0)`)
        .call(d3.axisLeft(yAxisLegend));
      g.append("g")
        .attr("transform", `translate(${leftPadding - 0.5}, ${yTeams})`)
        .call(d3.axisLeft(yAxis));
      g.append("g")
        .attr("transform", `translate(${leftPadding - 0.5}, ${yOverview})`)
        .call(d3.axisLeft(yAxisOverview));
      g.append("g")
        .attr("transform", `translate(${leftPadding - 0.5}, ${yProjects})`)
        .call(d3.axisLeft(yAxisProjects));

      const chartRendered = new Date();
      console.info("TimeChart chartRendered time:", chartRendered - dataReady);
      console.info("TimeChart byTeamStackedData", byTeamStackedData);
      console.info("TimeChart byProjectStackedData", byProjectStackedData);
    },
    drawBars(svg, data, yOffset, xAxis, yAxis, stackColor) {
      svg
        .append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("fill", function (d) {
          return stackColor(d.key);
        })
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("x", (d) => xAxis(d[0]))
        .attr("y", (d) => yOffset + yAxis(d.data[0]))
        .attr("width", function (d) {
          return xAxis(d[1]) - xAxis(d[0]);
        })
        .attr("height", yAxis.bandwidth());
    },
    drawValues(svg, data, xOffset, yOffset, xAxis, yAxis, textF) {
      svg
        .append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("fill", (d) => (d.key == "paid" ? "#4282ff" : "white"))
        .attr("class", (d) => {
          return d.key == "paid"
            ? "payments-reports-chart__value-labels payments-reports-chart__value-labels--paid"
            : "payments-reports-chart__value-labels payments-reports-chart__value-labels--processing";
        })
        .selectAll("text")
        .data(function (d) {
          return d;
        })
        .enter()
        .append("text")
        .text((d) => textF(d))
        .attr("x", (d) => xAxis(d[1]))
        .attr(
          "y",
          (d) => yOffset + yAxis(d.data[0]) + yAxis.bandwidth() / 2 + 3
        )
        .attr("class", (d) => {
          return xAxis(d[1]) < 50 + xOffset
            ? "payments-reports-chart__value-label--flipped"
            : "";
        });
    },
    drawLegendValues(svg, data, yOffset, xAxis, yAxis) {
      svg
        .append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("fill", (d) => (d.key == "paid" ? "#4282ff" : "white"))
        .attr("class", (d) =>
          d.key == "paid"
            ? "payments-reports-chart__value-labels  payments-reports-chart__value-labels--paid"
            : "payments-reports-chart__value-labels  payments-reports-chart__value-labels--processing"
        )
        .append("text")
        .data(data)
        .text((d) =>
          d.key == "paid" ? "← Time until Paid" : "Time until Processing →"
        )
        .attr("x", (d) => xAxis(d[0][1]))
        .attr("y", yOffset + yAxis.bandwidth() / 2 + 5);
    },
  },
};
</script>

<style lang="scss">
.time-chart {
  width: 100%;
  max-width: 900px;
  height: auto;
  &__subhead {
    font-size: 12px;
    font-weight: bold;
    fill: #56565a;
  }
}
</style>
