var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"creator-item"},[_c('div',{staticClass:"creator-item__body"},[_c('a',{staticClass:"creator-item__preview",style:({ backgroundImage: _vm.backgroundImage }),attrs:{"href":("/p/" + (_vm.value.username || _vm.value.id)),"target":"_blank"}},[_c('Availability',{attrs:{"value":_vm.value.availability}}),_c('div',{staticClass:"creator-item__badges"},_vm._l((_vm.value.badges),function(badge,idx){return _c('v-tooltip',{key:idx,attrs:{"open-delay":"500","max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('router-link',_vm._g(_vm._b({staticClass:"creator-item__badge",style:({
                  left: -idx * 15 + 'px',
                  backgroundImage: ("url(" + (badge.preview) + ")"),
                }),attrs:{"to":"/badge-info","target":"_blank"}},'router-link',attrs,false),on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(badge.title))])])}),1)],1),_c('div',{staticClass:"creator-item__name"},[_vm._v(" "+_vm._s(_vm.value.display_name)+" ")]),_c('div',{staticClass:"creator-item__role"},[_vm._v(" "+_vm._s(_vm.value.primary_role)+" ")]),_c('div',{staticClass:"creator-item__location"},[_vm._v(" "+_vm._s(_vm.value.location)+" ")])]),_c('CreatorOnboardingAlerts',{staticClass:"creator-item__alerts",attrs:{"onboardingDetails":_vm.value.onboarding_details,"value":_vm.value}}),_c('div',{staticClass:"creator-item__actions"},[(_vm.hmActions)?_c('v-btn',{staticClass:"creator-item__action creator-item__action--red",attrs:{"to":("/p/" + (_vm.value.username || _vm.value.id) + "/payments"),"elevation":"0"}},[_vm._v("View Details")]):_c('a',{staticClass:"creator-item__action creator-item__action--red",attrs:{"href":("/p/" + (_vm.value.username || _vm.value.id)),"target":"_blank"}},[_vm._v("View")]),(_vm.removeReviewRequest)?_c('div',{staticClass:"creator-item__action",on:{"click":function($event){_vm.removeReviewRequestDialog = true}}},[_vm._v(" Remove ")]):_vm._e()],1),(
      _vm.currentUser.is_reviewer ||
      _vm.currentUser.is_hiring_manager ||
      _vm.currentUser.is_finance ||
      _vm.currentUser.is_people_team
    )?_c('CreatorActionsMenu',{staticClass:"creator-item__admin-actions-toggle",attrs:{"value":_vm.value,"menu":_vm.creatorActionsMenu},on:{"close":function($event){_vm.creatorActionsMenu = false;
      _vm.removeReviewRequestDialog = false;},"toggle":function($event){_vm.creatorActionsMenu = !_vm.creatorActionsMenu},"reviewProfile":function($event){return _vm.reviewProfile($event)},"refresh":function($event){_vm.creatorActionsMenu = false;
      _vm.removeReviewRequestDialog = false;
      _vm.$emit('refresh');}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.removeReviewRequestDialog),callback:function ($$v) {_vm.removeReviewRequestDialog=$$v},expression:"removeReviewRequestDialog"}},[_c('v-card',{staticClass:"remove-review-request-dialog"},[_c('v-btn',{staticClass:"close",attrs:{"icon":""},on:{"click":function($event){_vm.removeReviewRequestDialog = false}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("$close")])],1),_c('div',{staticClass:"remove-review-request-dialog__header"},[_vm._v(" Remove Request to Review Creator ")]),_c('div',{staticClass:"remove-review-request-dialog__content"},[_c('p',[_vm._v("Are you sure?")]),_c('v-btn',{on:{"click":function($event){return _vm.removeReviewRequestConfirm(_vm.value.id)}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }