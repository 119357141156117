<template>
  <tr class="payments-row" v-if="item">
    <td>{{ `${item.code} - ${item.title}` }}</td>
    <td>
      <div class="td-action">
        <v-btn
          icon
          :to="`/project/${item.id}/members`"
        >
          <v-icon size="35">$arrow_right</v-icon>
        </v-btn>
        
      </div>
    </td>
</tr>
</template>

<script>
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  props: {
    item: Object,
  },
}

</script>

<style lang="scss" scoped>
@import '@/styles/payments/payments-row.scss';
</style>
