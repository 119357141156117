import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      cancelConfirm: false,
      sendReminderConfirm: false,
      reuploadDialog: false,
      pleaseWait: false,
    };
  },
  methods: {
    async cancelAgreement(item) {
      this.pleaseWait = true;
      const transaction_id = uuidv4();
      const params = {
        transaction_id,
        id: item.id,
      };
      this.$store.commit("agreement/setTransactionId", transaction_id);
      const result = await this.$api.Agreement.cancel(params);
      this.pleaseWait = false;
      if (result.status == 200) {
        this.$alert("Agreement canceled successfullly", "success");
        this.close();
        this.$emit("refresh");
      } else {
        this.$alert("Something went wrong", "error");
      }
    },
    async downloadPdf(item) {
      this.pleaseWait = true;
      const result = await this.$api.Agreement.documentUrl({ id: item.id });
      console.info("downloadPdf result", result);
      this.pleaseWait = false;
      if (result.status == 200) {
        window.open(result.data.url, "_blank");
      } else {
        this.$alert(result.data.alert || "Something went wrong", "error");
      }
    },
    async sendReminder(item) {
      this.pleaseWait = true;
      const transaction_id = uuidv4();
      const params = {
        transaction_id,
        id: item.id,
      };
      this.$store.commit("agreement/setTransactionId", transaction_id);
      try {
        const result = await this.$api.Agreement.sendReminder(params);
        this.pleaseWait = false;
        this.sendReminderConfirm = false;
        if (result.status == 200) {
          this.$alert("Reminder email sent", "success");
          this.close();
          this.$emit("refresh");
        }
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
  },
};
