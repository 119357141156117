<template>
  <TagSelector
    multiple
    :items="items"
    :value="value"
    itemText="title"
    itemValue="uuid"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    items: [String, Number, Array],
  },
};
</script>