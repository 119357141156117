<template>
  <div>
    <div v-if="loaded">
      <DefaultLayout />
    </div>
    <div v-else>
      <Spinner :relative="false" />
    </div>
  </div>
</template>

<script>
import DefaultLayout from "./layouts/default.layout";
import { mapState } from "vuex";
export default {
  name: "App",
  components: { DefaultLayout },
  created() {
    this.$moment.locale("en");
    this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch("application/init");
      setInterval(
        () => this.$store.dispatch("auth/refreshToken"),
        1000 * 60 * 60
      );
    },
  },
  computed: {
    ...mapState({
      loaded: (state) => state.application.loaded,
    }),
  },
};
</script>
