import store from "@/store";

export const ifAuthenticated = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser || !currentUser.confirmed || currentUser.blocked) {
    unauthenticatedRedirect(to, from, next);
  }

  /*
  const rules = [
    {
      condition: !currentUser.onboarding_finished,
      allowedPages: ["onboarding"],
      redirectTo: "onboarding", //redirect to admin?
    },
  ];

  for (const rule of rules) {
    if (rule.condition && !rule.allowedPages.includes(to.name)) {
      return next({ name: rule.redirectTo });
    }
  }
  */

  next();
};

export const ifReviewer = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (!currentUser.is_reviewer) {
    window.location.href = window.location.origin;
  }

  next();
};

export const ifHiringManager = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (!currentUser.is_hiring_manager) {
    window.location.href = window.location.origin;
  }

  next();
};

export const ifFinanceOrHiringManager = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (!currentUser.is_finance && !currentUser.is_hiring_manager) {
    window.location.href = window.location.origin;
  }

  next();
};

export const ifPeopleTeam = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (!currentUser.is_people_team) {
    window.location.href = window.location.origin;
  }

  next();
};

export const ifFinanceOrHiringManagerOrPeopleTeam = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (
    !currentUser.is_finance &&
    !currentUser.is_hiring_manager &&
    !currentUser.is_people_team
  ) {
    window.location.href = window.location.origin;
  }

  next();
};

export const ifFinanceOrPeopleTeam = async (to, from, next) => {
  const currentUser = await fetchUser();

  if (!currentUser) {
    unauthenticatedRedirect(to, from, next);
  }

  if (!currentUser.is_finance && !currentUser.is_people_team) {
    window.location.href = window.location.origin;
  }

  next();
};

const fetchUser = async () => {
  return await store.dispatch("user/fetch");
};

const unauthenticatedRedirect = async (to, from, next) => {
  return next({ name: "login", params: { to } });
};

export const ifNotAuthenticated = async (to, from, next) => {
  const currentUser = await fetchUser();
  if (!currentUser) {
    next();
    return;
  }
  next("/");
};
