var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-title"},[_vm._v("Email + Password Registration Settings")]),_c('div',{staticClass:"checkbox-wrapper"},[_c('Checkbox',{attrs:{"label":"Enable Email Validation"},on:{"input":_vm.checkDependentOptions},model:{value:(_vm.localValue.data.enable_email_validation),callback:function ($$v) {_vm.$set(_vm.localValue.data, "enable_email_validation", $$v)},expression:"localValue.data.enable_email_validation"}}),_c('v-tooltip',{attrs:{"open-delay":"500","max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"form-question",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("$question_round")])]}}])},[_c('span',[_vm._t("tooltip",[_vm._v("When enabled, this will provide feedback to a registering user if there appears to be a typo in their email.")])],2)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.localValue.data.enable_email_validation),expression:"localValue.data.enable_email_validation"}],staticClass:"suboptions"},[_c('div',{staticClass:"checkbox-wrapper"},[_c('Checkbox',{attrs:{"label":"Show an “Are you sure?” prompt","disabled":!this.localValue.data.enable_email_validation},on:{"input":_vm.checkDependentOptions},model:{value:(_vm.localValue.data.are_you_sure_prompt),callback:function ($$v) {_vm.$set(_vm.localValue.data, "are_you_sure_prompt", $$v)},expression:"localValue.data.are_you_sure_prompt"}}),_c('v-tooltip',{attrs:{"open-delay":"500","max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"form-question",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("$question_round")])]}}])},[_c('span',[_vm._t("tooltip",[_vm._v("When enabled, if the user's email seems to have a type, this will open a modal that asks the user if they are sure their email is correct before submitting their registration. If this is disabled, the user will be allowed to register with no warning, but will be marked as “Email Validation Failed” in admin.")])],2)])],1),_c('div',{staticClass:"checkbox-wrapper"},[_c('Checkbox',{attrs:{"label":"Show suggestions","disabled":!this.localValue.data.are_you_sure_prompt},model:{value:(_vm.localValue.data.show_suggestions),callback:function ($$v) {_vm.$set(_vm.localValue.data, "show_suggestions", $$v)},expression:"localValue.data.show_suggestions"}}),_c('v-tooltip',{attrs:{"open-delay":"500","max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"form-question",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("$question_round")])]}}])},[_c('span',[_vm._t("tooltip",[_vm._v("When enabled, this will offer suggestions to the user, if any are available. For example, if they submit “example@gmil.com”, it will suggest “example@gmail.com”.")])],2)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }