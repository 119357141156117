<template>
  <MyPaymentsTable
    :items="list"
    :title="title"
    @refresh="refresh"
    class="payments-section"
    :class="{ 'table-loading': loadingList }"
    :listOptions="listOptions"
    @orderUpdate="orderUpdate($event)"
    @filterUpdate="filterUpdate($event)"
    :completedPayments="completedPayments"
  >
    <template #actions>
      <div class="payments-table__action">
        <div class="payments-table__action__label">Filter by:</div>
        <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
        <v-menu
          bottom
          offset-y
          :close-on-click="false"
          :close-on-content-click="false"
          v-model="filtersMenu"
          light
          class="filters-menu"
          content-class="filters-menu__content"
        >
          <template v-slot:activator="{ on }">
            <TableActiveFilters
              v-on="on"
              @openMenu="filtersMenu = true"
              :listOptions="listOptions"
              :fields="filterFields"
              @resetFilter="resetFilter($event)"
              :activeListOption="activeListOption"
            />
          </template>
          <v-card class="filters-menu__content__card">
            <div class="filters-menu__body">
              <v-expansion-panels v-model="filterPanels" multiple flat>
                <v-expansion-panel>
                  <v-expansion-panel-header> Project </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <InternalProjectsSelector
                      v-model="listOptions.internal_project_id"
                      emptyLabel="All"
                      @input="
                        optionsUpdate();
                        closeFilters();
                      "
                      multiple
                      chipText="code"
                      @remove="
                        removeFromFilter('internal_project_id', $event);
                        optionsUpdate();
                        closeFilters();
                      "
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <TableDateFilterPanel
                  :listOptions="listOptions"
                  fieldName="created_at"
                  @filterUpdate="filterMenuUpdate($event)"
                  fieldLabel="Date Created"
                />
                <TableOptionsFilterPanel
                  :listOptions="listOptions"
                  fieldName="status"
                  @filterUpdate="filterMenuUpdate($event)"
                  fieldLabel="Status"
                  selectAll
                  reset
                  :defaultListOptions="getDefaultListOptions()"
                />
                <v-expansion-panel>
                  <v-expansion-panel-header> Role </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <SkillsSelector
                      v-model="listOptions.skill_id"
                      placeholder="Role"
                      :rules="[validation.required]"
                      @input="
                        optionsUpdate();
                        closeFilters();
                      "
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-menu>
      </div>
      <div class="payments-table__action">
        <div class="payments-table__action__label"></div>
        <TextField v-model="searchInput" placeholder="Search" />
      </div>
      <div class="payments-table__secondary-actions">
        <div
          class="payments-table__action-btn payments-table__action-btn--reset"
          v-if="anyActiveListOptions"
        >
          <div @click="resetListOptions()">
            <v-icon size="14">$close</v-icon> Reset
          </div>
        </div>
      </div>
    </template>
    <template #pagination>
      <TablePagination
        v-if="completedPayments"
        :page="page"
        :totalPages="totalPages"
        @input="page = $event"
        :key="`pagination-${reloadKey}`"
      />
    </template>
  </MyPaymentsTable>
</template>

<script>
import MyPaymentsTable from "./table.my-payments";
import invoicesTableActions from "@/mixins/invoices/invoices-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  mixins: [invoicesTableActions, paginatedTable],
  components: {
    MyPaymentsTable,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
  },
  props: {
    title: String,
    completedPayments: Boolean,
  },
  data() {
    return {
      list: [],
      loadingList: false,
      listOptions: this.getDefaultListOptions(this.completedPayments),
      reloadKey: 1,
      filterFields: [
        { name: "internal_project_id", label: "Project" },
        { name: "created_at", label: "Date" },
        { name: "status", label: "Status" },
        { name: "skill_id", label: "Role" },
      ],
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    getParams() {
      const params = { ...this.listParams };
      params.user_id = this.creatorId;
      if (this.completedPayments) {
        params.page = this.page;
        params.per = 5;
      }
      return params;
    },
    async fetchList() {
      if (this.completedPayments) {
        this.list = [];
      }
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Invoice.my(params);
      this.list = data.invoices;
      if (this.completedPayments) {
        this.totalPages = data.total_pages;
      }
      this.loadingList = false;
    },
    async refresh() {
      await this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-section.scss";
@import "@/styles/table-filters-menu.scss";
</style>
