<template>
  <div :style="bannerStyles" class="banner">
    <div class="banner-container">
      <div class="banner-content">
        <div class="banner-title">
          <slot name="title"></slot>
        </div>
        <div class="banner-description">
          <slot name="description"></slot>
        </div>
      </div>
      <div class="banner-image">
        <slot name="image"></slot>
      </div>
    </div>

    <svg
      class="banner-line"
      viewBox="0 0 1024 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M650 0.781578C388.4 9.85112 107.667 49.9083 0 68.8031V101H1168C1104.33 63.8149 911.6 -8.28796 650 0.781578Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="642.4"
          y1="-15.8981"
          x2="642.893"
          y2="163.201"
          gradientUnits="userSpaceOnUse"
        >
          <stop :stop-color="colorSchema[2] || colorSchema[0]" />
          <stop
            offset="0.609375"
            :stop-color="colorSchema[2] || colorSchema[1]"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
    },
    theme: {
      type: String,
      default: "orange",
    },
    textColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      themes: {
        purple: ["#C7A4E0", "#EB3F33"],
        orange: ["#FDAD7A", "#EB3F33"],
        green: ["#C0E0AC", "#EB3F33"],
        pink: ["#F5A0BE", "#EB3F33"],
        yellow: ["#FFE78F", "#FFE78F", "#fff2c0"],
      },
    };
  },
  computed: {
    colorSchema() {
      return this.colors || this.themes[this.theme];
    },
    bannerStyles() {
      return {
        background: `linear-gradient(180deg, ${this.colorSchema[0]} 0%, ${this.colorSchema[1]} 100%)`,
        color: this.textColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  border-radius: 10px;
  min-height: 215px;
  width: 100%;
  position: relative;
  overflow: hidden;

  &-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 85px;
    z-index: 1;
  }

  &-content {
    padding: 40px 0 0 0;
    max-width: 50%;
  }
  &-title {
    font-weight: 900;
    font-size: 36px;
  }
  &-description {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.2;
  }
  &-image {
    width: 215px;
    height: 180px;
    align-self: center;
    display: flex;

    img {
      max-width: 100%;
    }
  }

  &-line {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 960px) {
  .banner {
    &-container {
      padding: 0 35px;
    }
  }
}
</style>
