<template>
  <PaymentsTable
    :items="list"
    :title="title"
    @refresh="refresh"
    :class="{ 'table-loading': loadingList }"
    :listOptions="listOptions"
    @orderUpdate="orderUpdate($event)"
    @filterUpdate="filterUpdate($event)"
  >
    <template #actions>
      <div class="payments-table__action">
        <div class="payments-table__action__label">Filter by:</div>
        <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
        <v-menu
          bottom
          offset-y
          :close-on-click="false"
          :close-on-content-click="false"
          v-model="filtersMenu"
          light
          class="filters-menu"
          content-class="filters-menu__content"
        >
          <template v-slot:activator="{ on }">
            <TableActiveFilters
              v-on="on"
              @openMenu="filtersMenu = true"
              :listOptions="listOptions"
              :fields="filterFields"
              @resetFilter="resetFilter($event)"
              :activeListOption="activeListOption"
            />
          </template>
          <v-card class="filters-menu__content__card">
            <div class="filters-menu__body">
              <v-expansion-panels v-model="filterPanels" multiple flat>
                <v-expansion-panel>
                  <v-expansion-panel-header> Creator </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <UsersSelector
                      v-model="listOptions.user_id"
                      placeholder="All"
                      @input="
                        optionsUpdate();
                        closeFilters();
                      "
                      includeBlocked
                      multiple
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header> Project </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <InternalProjectsSelector
                      v-model="listOptions.internal_project_id"
                      emptyLabel="All"
                      @input="
                        optionsUpdate();
                        closeFilters();
                      "
                      multiple
                      chipText="code"
                      @remove="
                        removeFromFilter('internal_project_id', $event);
                        optionsUpdate();
                        closeFilters();
                      "
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <TableDateFilterPanel
                  :listOptions="listOptions"
                  fieldName="created_at"
                  @filterUpdate="filterMenuUpdate($event)"
                  fieldLabel="Date Created"
                />
                <TableOptionsFilterPanel
                  :listOptions="listOptions"
                  fieldName="status"
                  @filterUpdate="filterMenuUpdate($event)"
                  fieldLabel="Status"
                  selectAll
                  reset
                  :defaultListOptions="getDefaultListOptions()"
                />
                <v-expansion-panel>
                  <v-expansion-panel-header> Role </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <SkillsSelector
                      v-model="listOptions.skill_id"
                      placeholder="Role"
                      :rules="[validation.required]"
                      @input="
                        optionsUpdate();
                        closeFilters();
                      "
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-menu>
      </div>
      <div class="payments-table__action">
        <div class="payments-table__action__label"></div>
        <TextField v-model="searchInput" placeholder="Search" />
      </div>
      <div class="payments-table__secondary-actions">
        <div
          class="
            payments-table__action
            payments-table__action--compact
            payments-table__action--download
          "
        >
          <ActionsMenu
            @close="downloadMenu = false"
            @toggle="downloadMenu = !downloadMenu"
            :menu="downloadMenu"
            themeDisabledPage
            icon="$download"
            :iconSize="24"
          >
            <form
              method="post"
              action="/api/v1/profile_side/invoices/csv"
              target="_blank"
              style="margin-bottom: 21px"
            >
              <input
                type="hidden"
                name="token"
                :value="$store.getters['auth/token']"
              />
              <input
                type="hidden"
                v-for="item in Object.entries(listParams)"
                :key="`listParams-${item[0]}`"
                :name="item[0]"
                :value="item[1]"
              />
              <v-btn
                type="submit"
                color="#56565A"
                elevation="0"
                style="color: white !important"
                >Export All Payments</v-btn
              >
            </form>
            <form
              method="post"
              action="/api/v1/profile_side/invoices/csv_itemized"
              target="_blank"
              class="form-button"
            >
              <input
                type="hidden"
                name="token"
                :value="$store.getters['auth/token']"
              />
              <input
                type="hidden"
                v-for="item in Object.entries(listParams)"
                :key="`listParams-${item[0]}`"
                :name="item[0]"
                :value="item[1]"
              />
              <v-btn
                type="submit"
                color="#56565A"
                elevation="0"
                style="color: white !important"
                >Itemized Export</v-btn
              >
            </form>
          </ActionsMenu>
        </div>
        <div
          class="payments-table__action-btn payments-table__action-btn--reset"
          v-if="anyActiveListOptions"
        >
          <div @click="resetListOptions()">
            <v-icon size="14">$close</v-icon> Reset
          </div>
        </div>
      </div>
    </template>
    <template #pagination>
      <TablePagination
        :page="page"
        :totalPages="totalPages"
        @input="page = $event"
        :key="`pagination-${reloadKey}`"
      />
    </template>
  </PaymentsTable>
</template>

<script>
import PaymentsTable from "./completed-table.payments-dashboard";
import invoicesTableActions from "@/mixins/invoices/invoices-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  mixins: [invoicesTableActions, paginatedTable],
  components: {
    PaymentsTable,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
  },
  props: {
    title: String,
  },
  data() {
    return {
      list: [],
      loadingList: false,
      completedPayments: true,
      listOptions: this.getDefaultListOptions(true),
      downloadMenu: false,
      reloadKey: 1,
      filterFields: [
        { name: "user_id", label: "Creator" },
        { name: "internal_project_id", label: "Project" },
        { name: "created_at", label: "Date" },
        { name: "status", label: "Status" },
        { name: "skill_id", label: "Role" },
      ],
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = 5;
      return params;
    },
    async fetchList() {
      this.list = [];
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Invoice.payments(params);
      this.list = data.invoices;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
    async refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-section.scss";
@import "@/styles/table-filters-menu.scss";
</style>
