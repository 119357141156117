<template>
  <div>
    <div class="page-title">CAS Settings</div>
    <TextField
      label="CAS Server Url (without /login)"
      v-model="localValue.data.endpoint"
    />

    <TextField
      placeholder="Example: Sign In with CAS account"
      label="Auth Button Text"
      v-model="localValue.data.btn_text"
    />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style></style>
