<template>
	<div class="badge-info-page">
    <div class="page-title">Badge Info</div>
    <div class="badge-info-section">
      <div class="badge-info__header">Basic Info</div>
      <div class="badge-info__content">
        <p>You have the opportunity to earn badges in the iU Community which give you access to exclusive opportunities, priority consideration for certain gigs, the ability to have your work or profile featured to the entire community, and more. Here are the current iU Badges:</p>
        <div
          class="badge-info__badge"
          v-for="(badge, idx) in badges"
          :key="`badge_${idx}`"
        >
          <div class="badge__header">
            <span class="badge__icon" :style="{backgroundImage: `url(${badge.icon})`}"></span>
            <span class="badge__title" :style="{color: badge.color}">{{ badge.title }}</span>
          </div>
          <p class="badge__content">{{ badge.description }}</p>
        </div>
        <div
          class="badge-info__rating-badge"
          v-for="(badge, idx) in ratingBadges"
          :key="`ratingBadge${idx}`"
        >
          <div class="rating-badge__header">
            <span class="rating-badge__icon" :style="{backgroundImage: `url(${badge.icon})`}"></span>
          </div>
          <p class="rating-badge__content">{{ badge.description }}</p>
        </div>
        <p>If you have any questions or think you may be entitled to any of the badges on this list then let us know and we'll do whatever we can to make it right. <a @click="clickSupport()">Contact Support</a></p>
      </div>
    </div>
    <div><Button large to="/faq" class="back-to-faq-btn">Back to FAQ</Button></div>
  </div>
</template>

<script>
export default {
  name: "BadgeInfo",
  data () {
    return {
      badges: [
        {
          title: "Silver Creators",
          color: "#A7B7C0",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/2ae7efe8-d3a2-456d-85a0-5507789725a6/silver.svg",
          description: "Anyone who takes the time to fill out all the fields with a purple checkmark in the 'My Profile' and 'Account Details' sections of the user portal will be eligible for a Silver creator badge.",
        },
        {
          title: "Gold Creators",
          color: "#FFBC10",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/77d18335-4e40-4b97-b84c-fafcfc801f34/gold.svg",
          description: "Creators who are winners/finalists for any iU opportunities, or users who have completed at least 1 paid gig will be eligible for a Gold creator badge.",
        },
        {
          title: "Diamond Creators",
          color: "#2EBFED",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/7704d8ac-18f0-4a76-98d6-aa17286339cd/diamond.svg",
          description: "These are our hand selected, elite tier of creators that we have had an ongoing relationship with. These are available by invitation only.",
        },
        {
          title: "Campus Creators",
          color: "#7B61FF",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/1ac0a941-3fed-47df-8e80-62e55619d490/campus.svg",
          description: "For currently enrolled College and University students. Must have their school and graduation date filled out in their profile.",
        },
        {
          title: "Legacy Creators",
          color: "#0051EF",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/7aeb186d-d1e5-444d-9ff5-0100acb86cdb/legacy.svg",
          description: "Creators that we did work with before the iU Community launch",
        },
        {
          title: "Team Member",
          color: "#e12d1b",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/ca103556-e75f-4a24-a331-d75be1b9b035/team.svg",
          description: "Full-time iU team members",
        },
      ],
      ratingBadges: [
        
        {
          description: "Participation Badges: Any time you submit to an opportunity you get +1 participation badge.",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/41a3d2ff-e3af-4882-bc72-2a61c70d2ce4/participation.svg",
        },
        {
          description: "Quality Badges: Users who are finalists or winners of any iU Opportunity will get +1 quality badges.",
          icon: "https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/13db1b04-bb08-4fcb-8f83-02eb494fdf58/quality.svg",
        }
      ],
    }
  },
  methods: {
    clickSupport() {
      this.$root.$emit("openSupportDialog");
    },
  }
}
</script>

<style scoped>
.badge-info-section {
  margin-top: 40px;
  margin-bottom: 78px;
}
.page-title {
  position: relative;
  z-index: 1;
}
.page-title::before {
  content: '';
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-position: bottom left;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjk1IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMjk1IDE2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBvcGFjaXR5PSIwLjg4IiBkPSJNOC4yNDM3IDEyLjg0NDRDNDUuMDQ3MSAxMC42MTQ4IDgxLjg4ODYgOS4zOTYxMSAxMTguNzY4IDkuMTg4NDlDMTU1LjY0NyA4Ljk4MDg2IDE5Mi41MzggOS43ODM4MyAyMjkuMzg2IDExLjYyMzFDMjUwLjA1NCAxMi42MzAzIDI3MC42ODkgMTMuOTgyNSAyOTEuMzIgMTUuNjI3M0MyOTMuNjY5IDE1LjgyMjcgMjk1LjM1NCAxMy45MzQgMjk0LjU2MSAxMS42MzUxQzI5My43MTcgOS4xNzU5OCAyOTEuMTg0IDcuMDEwMDkgMjg4LjU0MiA2LjgxMDE4QzI1MS42MDQgMy44NTI3MyAyMTQuNTcxIDEuOTA0MjIgMTc3LjUyMSAwLjk5MjQ4QzE0MC40NzIgMC4wODA3MzYyIDEwMy4zODEgMC4xNTIxODMgNjYuMzI3MSAxLjI2MTJDNDUuMjg3MiAxLjg5Njk2IDI0LjI0MjkgMi44MjUxMyAzLjIxOTk4IDQuMDk5MzFDMC42MjU3MTggNC4yNDU4IDAuNjAyNDcxIDcuNTE2IDEuNjE4NzkgOS4yMDY2OUMzLjAyNjUxIDExLjUxNDkgNS40ODg3MyAxMy4wMTUgOC4yNDM3IDEyLjg0NDRaIiBmaWxsPSIjQ0FGMTJDIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-size: 7em;
  z-index: -1;
  left: -0.438em;
  bottom: -0.03em;
}
.badge-info__header {
  display: block;
  border: 2px solid var(--v-dark-base);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 24px 40px;
  color: white;
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  background: linear-gradient(98.33deg, #7B61FF 6.5%, #FF7CF2 93.73%);
}
.badge-info__content {
  background: var(--v-background-base);
  border: 2px solid var(--v-dark-base);
  padding: 40px;
  margin-top: -2px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.badge-info__badge,
.badge-info__rating-badge {
  margin-bottom: 2em;
}


.badge__header {
  margin-bottom: 0.25em;
}
.badge__content {
	color: var(--v-text-base);
}
.badge__icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 37px;
  height: 37px;
  margin-bottom: -0.5em;
  margin-right: 0.5em;
}
.badge__title {
  font-size: 29px;
}

.rating-badge__icon {
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 76px;
  height: 76px;
}

.back-to-faq-btn {
  min-width: 188px !important;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .badge-info-page {
    padding: 38px 31px 41px;
  }
  .badge-info__header,
  .badge-info__content {
    padding: 24px 12px;
  }
}

</style>
