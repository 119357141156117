<template>
  <div class="pagination">
    <Dropdown
      :value="perPage"
      @input="$emit('update:perPage', $event)"
      label="Items per page: "
      :items="perPageItems"
    />
    <div class="pagination__navigate">
      <v-btn :disabled="page <= 1" @click="prevPage" icon>
        <v-icon color="primary">$chevron_left</v-icon>
      </v-btn>
      <div class="pagination__page">{{ from }}-{{ to }} of {{ count }}</div>
      <v-btn :disabled="page >= pagesCount" @click="nextPage" icon>
        <v-icon color="primary">$chevron_right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
const perPageItems = [
  { text: 10, value: 10 },
  { text: 20, value: 20 },
  { text: 33, value: 33 },
  { text: 50, value: 50 },
  { text: 100, value: 10 },
];

export default {
  props: {
    perPage: {
      type: Number,
      default: perPageItems[0].value,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      perPageItems,
      start: 1,
      end: 10,
      count: 100,
    };
  },
  methods: {
    prevPage() {
      if (this.page > 1) {
        this.$emit("update:page", this.page - 1);
      }
    },
    nextPage() {
      if (this.page < this.pagesCount) {
        this.$emit("update:page", this.page + 1);
      }
    },
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.count / this.perPage);
    },
    from() {
      return (this.page - 1) * this.perPage + 1;
    },
    to() {
      const result = this.page * this.perPage;
      return result > this.count ? this.count : result;
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;
  margin-top: 30px;

  &::v-deep .dropdown-selected {
    font-weight: normal;
    font-size: 14px;
    color: black;
  }

  &__navigate {
    margin: 0 0 0 25px;
    display: flex;
    align-items: center;
  }
}
</style>
