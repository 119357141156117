<template>
  <div class="custom-sfc">
    <component
      :key="key"
      v-if="loaded"
      :is="customComponent.sfc_name"
    ></component>
  </div>
</template>

<script>
import Vue from "vue";
import Spinner from "./Spinner";
//import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    value: String,
    page: Object,
  },
  data() {
    return {
      loaded: false,
      key: 1,
    };
  },
  created() {
    Vue.component("Spinner", Spinner);
  },
  computed: {
    customComponent() {
      return (this.page || this.currentPage).custom_components[this.value];
    },
  },
  methods: {
    compile(component, callback) {
      return new Promise((resolve) => {
        console.info("CustomComponent resolve", resolve);
        const { sfc_name } = component;

        const { loadModule } = require("vue3-sfc-loader/dist/vue2-sfc-loader");
        const options = {
          moduleCache: {
            vue: Vue,
          },
          getFile: () => {
            return component.code;
          },
          addStyle: (textContent) => {
            const styleExist = document.querySelector(`[sfc=${sfc_name}]`);

            const style = Object.assign(document.createElement("style"), {
              textContent,
            });
            style.setAttribute("sfc", sfc_name);

            const ref = document.head.getElementsByTagName("style")[0] || null;
            document.head.insertBefore(style, ref);

            if (styleExist) styleExist.remove();
          },
        };

        loadModule(
          sfc_name + "_" + component.updated_at + ".vue",
          options
        ).then((compiledComponent) => {
          console.log(sfc_name, compiledComponent);
          Vue.component(sfc_name, compiledComponent);

          if (callback) callback();
          this.key = component.updated_at;
        });
      });
    },
  },
  watch: {
    customComponent: {
      deep: true,
      immediate: true,
      handler(val) {
        for (let descendent of val.descendents) {
          this.compile(descendent);
        }
        this.compile(val, () => (this.loaded = true));
      },
    },
  },
};
</script>

<style></style>
