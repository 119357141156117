<template>
  <div class="auth-register-form">
    <div v-if="registrationForm">
      <div v-if="claimPending">
        <div>
          <div class="register__title">Hi there!</div>
          <p> We've been waiting for you! You should have an email in your inbox inviting you to claim your account. If not, you can resend the email:</p>
        </div>
        <v-btn
          class="signup-btn"
          elevation="0"
          @click="resendClaimEmail()"
          :disabled="resendingClaimEmail"
          style="margin-bottom: 20px;"
          >Resend Claim Account Email</v-btn
        >
        <div v-if="claimResent">
          <div class="register__title">Thank you!</div>
          <div>Check your email for the invitation to claim your account! If you're still having trouble, contact us at <a href="mailto:community@ideasunited.com">community@ideasunited.com</a></div>
        </div>
      </div>
      <div v-else-if="!currentUser">
        <div v-show="registrationForm.login_form && !chooseSignUp" class="auth-register-form__login">
          <AuthForm
            :login-form-request="loginFormRequest"
            @loginFormResponse="loginFormResponse"
          />
          <div class="auth-register-form__not-registered">
            <p class="auth-register-form__not-registered-cta">
              {{ registrationForm.not_registered_cta || "Not a member yet? It’s easy to join!" }}
            </p>
            <v-btn
              class="signup-btn"
              elevation="0"
              @click="clickRegister()"
              >{{ registrationForm.sign_up_btn_text || "Register" }}</v-btn
            >
          </div>
        </div>
        <div v-if="!registrationForm.login_form || chooseSignUp" class="register">
          <div
            v-if="registrationForm.title"
            class="register__title"
            >{{ registrationForm.title }}</div
          >
          <div
            v-if="registrationForm.description"
            class="register__description"
          >
            {{ registrationForm.description }}
          </div>
          <DynamicForm
            :submit="submit"
            :fields="registrationForm.registration_form"
            v-model="form"
            :mark-required="registrationForm.mark_required"
            show-label
            :btn-text="registrationForm.btn_text"
            :login-form="registrationForm.login_form"
            @backToLogin="backToLogin()"
            :show-back-to-login="true"
            :claim-account-data="claimAccountData"
          />
        </div>
      </div> <!-- v-if="!currentUser" -->
      <div v-else-if="claimAccountData" class="register__thank-you-claimed">
        <div class="register__thank-you_title">
          Thank you!
        </div>
        <!-- <div>
          <p class="register__thank-you_message-claimed" v-if="registrationForm.claim_success_msg" v-html="registrationForm.claim_success_msg">
          </p>
          <p class="register__thank-you_message-claimed" v-else>
            Welcome to the iU Community! Let's get to know each other, fill out your <a href="/profile">profile</a> and when it looks good, head over to the <a href="/opportunities">opportunities</a> section to show us what you got!
          </p>
        </div> -->
      </div>
      <div v-else class="register__thank-you">
        <div class="register__thank-you_title">
          Thank you!
        </div>
        <div v-if="!currentUser.confirmed">
          <p class="register__thank-you_message">
            Please check your email and click the verification link,
            which will direct you to your first log in.
          </p>
          <div class="register__buttons">
            <Button
              :refs="$refs"
              :disabled="resending"
              @click="resendConfirmationEmail()"
            >
              Resend Confirmation Email
            </Button>
          </div>
        </div> <!-- v-if="!currentUser.confirmed" -->
      </div> <!-- v-if="currentUser" -->
    </div> <!-- v-if="registrationForm" -->
  </div>
</template>

<script>
import registrationMixin from "../registration.mixin";
import DynamicForm from "../DynamicFields/DynamicForm";

export default {
  extends: registrationMixin,
  components: { DynamicForm },
  props: {
    dialogState: Object,
    claimAccountData: Object,
    claimToken: String,
  },
  data() {
    return {
      form: {
        registration_form_id: null,
        register_url: null,
        data: {}
      },
      areYouSureDialog: false,
      emailValidationSuggestions: [],
      chooseSignUp: false,
      registrationForm: null,
      claimPending: false,
      claimResent: false,
      resendingClaimEmail: false,
      loginFormRequest: false,
    };
  },
  mounted() {
    this.fetchForm();
    if (this.dialogState) {
      this.chooseSignUp = this.dialogState.chooseSignUp;
    }
    if (this.claimToken) {
      this.form.claim_token = this.claimToken;
    }
  },
  methods: {
    async fetchForm() {
      const { data } = await this.$api.RegistrationForm.root();
      this.registrationForm = data;
      this.form.registration_form_id = data.id;
      this.form.register_url = window.location.href;
    },
    async submit() {
      try {
        const { data } = await this.$api.RegistrationForm.submit(this.form);
        if(data.claim_pending) {
          this.claimPending = true;
        } else {
          this.emailValidationSuggestions = [];
          this.$store.commit("auth/setToken", data.token);
          await this.$store.dispatch("user/fetch");
          if(data.alert == "Account successfully claimed")
            this.$router.push("/profile?thanks=1");
          if (this.registrationForm.destination_page_id)
            this.$router.push("/" + this.registrationForm.destination_url);
        }
      } catch (e) {
        if (!e.response.data) return;
        const data = e.response.data;
        if (data.email_validation_response == "are_you_sure") {
          if (data.email_suggestions)
            this.emailValidationSuggestions = data.email_suggestions;
          else this.emailValidationSuggestions = [];
          this.areYouSureDialog = true;
        }
      }
    },
    backToLogin() {
      this.chooseSignUp = false;
      this.updateDialogState();
    },
    clickRegister() {
      this.loginFormRequest = true;
      this.chooseSignUp = true;
      this.updateDialogState();
    },
    loginFormResponse(res) {
      if(res) {
        const emailUuid = this.registrationForm.registration_form.find((field) => field.short_name == "email").uuid;
        this.form.data[emailUuid] = res.email;
        if(res.password) {
          const passwordUuid = this.registrationForm.registration_form.find((field) => field.short_name == "password").uuid;
          this.form.data[passwordUuid] = res.password;
        }
      }
      this.loginFormRequest = false;
    },
    updateDialogState() {
      if(this.dialogState) this.dialogState.chooseSignUp = this.chooseSignUp;
    },
    emailFromForm() {
      const uuid = this.registrationForm.registration_form.find((field) => field.short_name == "email").uuid;
      return this.form.data[uuid];
    },
    async resendClaimEmail() {
      try {
        this.claimResent = true;
        this.resendingClaimEmail = true;
        const { data } = await this.$api.Auth.resendClaimEmail({ email: this.emailFromForm() });
        if(data.sent_recently) {
          this.$alert(data.errors.join("\n"), "error");
          setTimeout(() => {
            this.resendingClaimEmail = false;
          }, data.seconds * 1000);
        } else {
          setTimeout(() => {
            this.resendingClaimEmail = false;
          }, 120000);
        }
      } catch (e) {
        this.resendingClaimEmail = false;
      }
    }
  },
  watch: {
    dialogState: {
      deep: true,
      handler() {
        if (this.dialogState) {
          this.chooseSignUp = this.dialogState.chooseSignUp;
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.auth-register-form {
  &__login {
    max-width: 300px;
  }
  &__not-registered {
    text-align: center;
  }
}

.register {
  &__title {
    color: #262729;
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__thank-you {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    max-width: 274px;
    margin: auto;
    color: #262729;
    &-claimed {
      text-align: center;
      max-width: 500px;
      margin: auto;
      color: #262729;
    }
    &_title {
      font-weight: bold;
      font-size: 28px !important;
      margin-bottom: 0.5em;
    }
    &_message {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }
    &_message-claimed {
      margin-left: auto;
      margin-right: auto;
      font-weight: normal;
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.register {
  .v-form.dynamic-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 100%;
    }
    > div:nth-child(1),
    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5),
    > div:nth-child(6),
    > div:nth-child(7),
    > div:nth-child(8) {
      @media screen and (min-width: 945px) {
        width: calc(50% - 12px);
      }
    }
  }
  .dynamic-form__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    .v-btn {
      margin-bottom: 10px;
    }
    .btn_back-to-login {
      margin-right: 1em;
    }
  }
  .datetime-picker {
    margin-bottom: 22px;
  }
}
</style>