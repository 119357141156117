<template>
  <RadioGroup
    :label="title"
    itemValue="uuid"
    :items="options"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    title: String,
    value: [String, Object, Number, Boolean],
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
