import Vue from "vue";
import VueGtag from "vue-gtag";

const isProduction =
  window.location.hostname == "profile.community.ideasunited.com";

if (isProduction) {
  Vue.use(VueGtag, {
    config: {
      id: "G-3H7VEBMHL7",
      params: {
        anonymize_ip: true,
      },
    },
  });
}
