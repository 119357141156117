<template>
  <div class="downloading">{{ status }}</div>
</template>

<script>
export default {
  data() {
    return {
      status: "requesting",
      data: null,
    };
  },
  created() {
    this.requestFile();
  },
  methods: {
    async requestFile() {
      try {
        const params = {
          path: this.$route.query.path,
        };
        if (this.$route.query.invoice_id)
          params.invoice_id = this.$route.query.invoice_id;
        const { data } = await this.$api.Download.download(params);
        window.location.replace(data.url);
      } catch (e) {
        this.status = "You don't have access to view this file";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.downloading {
  font-size: 40px;
  text-align: center;
  margin-top: 20px;
}
</style>
