<template>
  <div class="profile-view-wrapper">
    <div class="profile-view" v-if="user">
      <div class="profile-view__header" :style="headerStyles">
        <div
          class="profile-view__avatar"
          v-if="user.avatar.url"
          :style="{ backgroundImage: `url(${user.avatar.thumb.url || ''})` }"
        >
          <Availability :value="user.availability" />
          <div class="profile-view__mini-badges-mobile">
            <v-tooltip
              open-delay="500"
              max-width="300"
              top
              v-for="badge in miniBadges"
              :key="badge.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="mini-badge">
                  <MiniBadge :badge="badge" :link="false" />
                </div>
              </template>
              <span>{{ badge.title }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="profile-view__header-info">
          <div class="profile-view__name">
            <span>
              {{ user.display_name }}
            </span>
            <div class="profile-view__mini-badges">
              <v-tooltip
                open-delay="500"
                max-width="300"
                top
                v-for="badge in miniBadges"
                :key="badge.id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="mini-badge">
                    <MiniBadge :badge="badge" />
                  </div>
                </template>
                <span>{{ badge.title }}</span>
              </v-tooltip>
              <CreatorActionsMenu
                class="creator-item__admin-actions-toggle"
                v-if="
                  currentUser &&
                  (currentUser.is_reviewer ||
                    currentUser.is_hiring_manager ||
                    currentUser.is_finance ||
                    currentUser.is_people_team)
                "
                :value="user"
                @close="
                  creatorActionsMenu = false;
                  removeReviewRequestDialog = false;
                "
                @toggle="creatorActionsMenu = !creatorActionsMenu"
                :menu="creatorActionsMenu"
                @reviewProfile="reviewDialog = true"
                :themeDisabledPage="true"
                @refresh="
                  creatorActionsMenu = false;
                  removeReviewRequestDialog = false;
                  setUser();
                "
              >
                <CreatorOnboardingAlerts
                  :onboardingDetails="user.onboarding_details"
                  :value="user"
                  class="profile-view__alerts"
                />
              </CreatorActionsMenu>
            </div>
          </div>
          <div class="profile-view__header-info__subname">
            <div class="profile-view__header-info__text">
              <div class="profile-view__pronouns" v-if="user.pronouns_multi">
                {{ user.pronouns_multi }}
              </div>
              <div class="profile-view__short-bio">
                {{ user.primary_working_location }}
              </div>
              <div
                class="profile-view__socials"
                v-if="user.instagram || user.linkedin"
              >
                <div class="profile-view__socials__item" v-if="user.instagram">
                  <a :href="instagram(user.instagram)" target="_blank">
                    <v-icon color="white" size="16">$instagram_profile</v-icon>
                  </a>
                </div>
                <div class="profile-view__socials__item" v-if="user.linkedin">
                  <a :href="user.linkedin" target="_blank">
                    <v-icon color="white" size="16">$linkedin_profile</v-icon>
                  </a>
                </div>
                <!-- <div class="profile-view__socials__item" v-if="user.vimeo">
                  <a :href="user.vimeo" target="_blank">
                    <v-icon color="white" size="16">$vimeo_profile</v-icon>
                  </a>
                </div> -->
              </div>
            </div>
            <div class="profile-view__header-badges-mobile">
              <CountableBadge
                v-for="badge in countableBadges"
                :key="badge.id"
                :badge="badge"
                class="countable-badge"
              />
            </div>
          </div>
        </div>
        <div class="profile-view__header-badges">
          <CountableBadge
            v-for="badge in countableBadges"
            :key="badge.id"
            :badge="badge"
            class="countable-badge"
          />
        </div>
      </div>
      <div class="profile-view__info">
        <div class="profile-view__info-section">
          <div v-if="user.primary_role">
            <div class="profile-view__title">Primary Specialty</div>
            <div class="profile-view__info__content">
              <div>
                {{ user.primary_role.title }}
              </div>
            </div>
          </div>
          <div v-if="user.secondary_roles && user.secondary_roles.length > 0">
            <div class="profile-view__title">
              Secondary Specialt{{
                user.secondary_roles.length > 1 ? "ies" : "y"
              }}
            </div>
            <div class="profile-view__info__content">
              <div v-for="item in user.secondary_roles" :key="item.id">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div v-if="user.resume && user.resume.url">
            <div class="profile-view__title">Resume</div>
            <div class="profile-view__info__content">
              <a :href="user.resume.url" download target="_blank"> Download </a>
            </div>
          </div>
          <div v-if="user.website_detailed && user.website_detailed.url">
            <div class="profile-view__title">Website</div>
            <div class="profile-view__info__content">
              <a :href="user.website_detailed.url" target="_blank">
                {{ user.website_detailed.title || user.website_detailed.url }}
              </a>
            </div>
          </div>

          <div v-if="user.school">
            <div class="profile-view__title">School</div>
            <div class="profile-view__info__content">
              <div>
                {{ user.school
                }}{{ user.grad_year ? ", " + user.grad_year : "" }}
              </div>
            </div>
          </div>
          <div v-if="user.contact">
            <div class="profile-view__title">Contact</div>
            <div class="profile-view__info__content">
              <div v-if="user.contact.name">{{ user.contact.name }}</div>
              <div v-if="user.contact.email">
                <a :href="`mailto:${user.contact.email}`">{{
                  user.contact.email
                }}</a>
              </div>
              <div v-if="user.contact.phone">
                <a :href="`tel:${user.contact.phone}`">
                  {{ parsePhoneNumber(user.contact.phone) }}
                </a>
              </div>
              <div v-if="user.contact_address && user.contact_address.street">
                <span>{{ user.contact_address.street }}</span
                ><br />
                <span v-if="user.contact_address.location">{{
                  user.contact_address.location
                }}</span
                ><span v-if="user.contact_address.zip"
                  >, {{ user.contact_address.zip }}</span
                ><br />
              </div>
            </div>
          </div>
          <div v-else-if="user.contact_email">
            <div class="profile-view__title">Contact</div>
            <div class="profile-view__info__content">
              <div>
                <a :href="`mailto:${user.contact_email}`">{{
                  user.contact_email
                }}</a>
              </div>
            </div>
          </div>
          <div v-if="user.secondary_working_location">
            <div class="profile-view__title">
              {{ user.primary_working_location ? "Secondary " : "" }}Location
            </div>
            <div class="profile-view__info__content">
              <div>
                <div>
                  {{ user.secondary_working_location }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="work-examples">
        <div class="work-examples__bio" v-if="user.bio">
          <div class="work-examples__bio_header">Bio</div>
          <div class="work-examples__bio_content">
            {{ user.bio }}
          </div>
        </div>
        <div class="work-example-wrapper" v-if="user.work_examples.length">
          <WorkExample :item="user.work_examples[0]" />
        </div>
        <div v-if="user.work_examples.length > 1">
          <div
            class="work-example-wrapper"
            v-for="item in workExamplesBottom"
            :key="item.id"
          >
            <WorkExample :item="item" />
          </div>
        </div>
      </div>
      <ProfileInternalSection :id="id" />
    </div>
    <Spinner v-else />
    <ProfileReviewsDialog
      v-if="user && currentUser && currentUser.roles.includes('reviewer')"
      :creator="user"
      :dialog="reviewDialog"
      :value="user.id"
      light
      @close="reviewDialog = false"
      @reviewSubmitted="reviewSubmitted()"
    />
  </div>
</template>

<script>
import _ from "lodash";
import CreatorOnboardingAlerts from "@/components/work-assignments/CreatorOnboardingAlerts";
import ProfileReviewsDialog from "@/components/profile-review/ProfileReviewsDialog.vue";
import ProfileInternalSection from "@/components/ProfileInternalSection.vue";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

export default {
  head() {
    let meta = [...this.currentProject.meta_tags];

    if (this.user) {
      meta.unshift(
        {
          hid: "og-title",
          property: "og:title",
          content: (this.user?.display_name || "Profile") + " | iU Community",
        },
        {
          hid: "twitter-title",
          property: "twitter:title",
          content: (this.user?.display_name || "Profile") + " | iU Community",
        }
      );
    }

    meta = _.uniqBy(meta, function (tag) {
      return (tag.name || "") + "_" + (tag.property || "");
    });

    return {
      title: this.user?.display_name || "Profile",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        {
          rel: "icon",
          href:
            this.currentProject?.favicon.url ||
            this.activeTheme.favicon.url ||
            "/favicon.ico",
        },
      ],
    };
  },
  components: {
    CreatorOnboardingAlerts,
    ProfileReviewsDialog,
    ProfileInternalSection,
  },
  props: {
    id: String,
  },
  data() {
    return {
      user: null,
      creatorActionsMenu: false,
      reviewDialog: false,
    };
  },
  created() {
    this.setUser();
  },
  computed: {
    countableBadges() {
      if (!this.user) return [];
      return this.user.badges.filter((t) =>
        ["participation", "quality"].includes(t.slug)
      );
    },
    miniBadges() {
      if (!this.user) return [];
      const slugs = ["gold", "silver", "campus", "iustaff", "diamond"];
      return this.user.badges
        .filter((t) => slugs.includes(t.slug))
        .sort((a, b) => slugs.indexOf(a.slug) - slugs.indexOf(b.slug));
    },
    workExamplesBottom() {
      if (this.user.work_examples.length <= 1) return [];
      else return this.user.work_examples.slice(1);
    },
    headerStyles() {
      if (!this.user.profile_header_image) return {};
      const img = this.user.profile_header_image.large.url;
      const mobileImg =
        this.user.profile_header_image_mobile?.large?.url || img;
      return {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
          ["sm", "xs"].includes(this.$size) ? mobileImg : img
        })`,
      };
    },
  },
  methods: {
    async setUser() {
      const { data } = await this.$api.User.profile({
        id: this.id,
      });
      this.user = data;
    },
    instagram(v) {
      /* eslint-disable no-useless-escape */
      const urlRegexp =
        /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

      /* eslint-disable no-useless-escape */
      const instagramHandleRegexp =
        /^([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/;

      /* eslint-disable no-useless-escape */
      const atInstagramHandleRegexp =
        /^@([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/;

      if (urlRegexp.test(v)) return v;
      if (instagramHandleRegexp.test(v))
        return `https://www.instagram.com/${v}`;
      if (atInstagramHandleRegexp.test(v))
        return `https://www.instagram.com/${v.substr(1)}`;

      return v;
    },
    reviewSubmitted() {
      this.reviewDialog = false;
      this.creatorActionsMenu = false;
      this.$alert("Thank you for your review");
    },
    parsePhoneNumber(number) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedNumber = phoneUtil.parse(number, "US");

        // Check if the number is from the US
        if (parsedNumber.getCountryCode() === 1) {
          const nationalNumber = phoneUtil.format(
            parsedNumber,
            PhoneNumberFormat.NATIONAL
          );
          if (number.startsWith("+1")) {
            return ` +1 ${nationalNumber}`;
          }
          return nationalNumber;
        }

        // For non-US numbers, reutrn the international format
        return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      } catch (error) {
        console.info(error);
        return number;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.profile-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__header {
    padding: 40px;
    background: #262729;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    @media screen and (max-width: 960px) {
      flex-direction: column;
      justify-content: center;
    }

    &-info {
      color: white;
      width: 100%;
      position: relative;
      &__subname {
        display: flex;
        justify-content: space-between;
      }
    }

    &-badges {
      display: flex;
      @media screen and (max-width: 960px) {
        display: none;
      }

      .countable-badge {
        margin-left: 20px;
      }
    }
    &-badges-mobile {
      display: none;
      @media screen and (max-width: 960px) {
        display: flex;
        .countable-badge {
          margin-left: 20px;
        }
      }
    }
  }
  &__title {
    color: #e12d1b;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  &__info {
    &__content {
      font-weight: 700;
      font-size: 14px;
      color: #262729;
      a {
        color: #262729;
      }
    }
  }
  &__avatar {
    width: 175px;
    min-width: 175px;
    height: 175px;
    background-size: cover;
    background-position: center;
    margin-right: 50px;
    border-radius: 50%;
    position: relative;
    @media screen and (max-width: 960px) {
      width: 230px;
      min-width: 230px;
      height: 230px;
      margin-right: 0;
      margin-bottom: 27px;
    }

    &::v-deep {
      .availability {
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 10px);
      }
    }
  }
  &__name {
    font-weight: 900;
    font-size: 42px;
    line-height: 1.2;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 960px) {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  &__mini-badges {
    display: flex;
    margin-left: 20px;
    margin-top: 12px;
    align-items: center;

    .mini-badge {
      margin: 0 10px;
    }

    @media screen and (max-width: 960px) {
      display: none;
    }
    &-mobile {
      display: none;
      @media screen and (max-width: 960px) {
        display: flex;
        position: absolute;
        right: 12px;
        top: 7px;
        .mini-badge {
          margin-left: 12px;
          width: 38px;
          height: 38px;
          cursor: default;
        }
      }
    }
  }
  &__pronouns {
    font-weight: 900;
    font-size: 20px;
  }
  &__short-bio {
    font-weight: bold;
    font-size: 14px;
    font-weight: 700;
  }
  &__socials {
    display: flex;
    &__item {
      &:not(:last-child) {
        margin-right: 2em;
      }
      a {
        text-decoration: none;
      }
    }
  }

  &__verified {
    position: relative;
    top: 2px;
    margin-left: 5px;
  }

  &__info {
    border: 1px solid #262729;
    display: flex;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      border-right: none;
      border-left: none;
    }
    &-section {
      flex-basis: calc(100%);
      display: flex;
      padding: 58px 70px 40px 70px;
      flex-wrap: wrap;

      @media screen and (max-width: 960px) {
        padding: 34px 31px 24px 31px;
        border-right: none;
        border-left: none;
      }

      &:first-child {
        @media screen and (min-width: 1000px) {
          padding-right: 8px;
        }
      }
      &:last-child {
        @media screen and (min-width: 1000px) {
          padding-right: 54px;
        }
      }
      &.empty {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      > div {
        margin-bottom: 1.5em;
        padding-right: 65px;
        @media screen and (max-width: 1100px) {
          padding-right: 45px;
          min-width: 250px;
        }
        @media screen and (max-width: 680px) {
          padding-right: 0;
          width: 100%;
        }
      }
    }
  }

  &__alerts {
    top: -20px;
    right: 15px;
    z-index: 1;
    -webkit-text-stroke-width: 0;
  }
}

.work-examples {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__bio {
    padding: 74px 76px;
    border-bottom: 2px solid #262729;
    flex-grow: 1;
    background-color: #262729;
    @media screen and (max-width: 960px) {
      padding: 47px 31px;
    }
    &_header {
      color: white;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 0.4em;
      transform-style: preserve-3d;

      &::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCA1MyAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggb3BhY2l0eT0iMC44OCIgZD0iTTIuMDU2MTggMTMuNjIwMUM5LjU1MjY0IDEwLjU4MiAxNS4zOSAxMC4zNzgzIDIyLjk5NzEgOS4yODc2NUMyNC44MzU2IDkuMDU0MzggMzEuMzU2NCA4LjU4MjQxIDM5LjA1MzIgOS40NjU4OUM0My4zNjkxIDkuOTM3NzggNDcuNjk0NSAxMC43NDMyIDUyLjAzMjggMTEuODMwN0M1Mi41MjcgMTEuOTYyMyA1Mi43ODY0IDEwLjEwMDggNTIuNTE0NiA3LjkwMzc2QzUyLjIyNDcgNS41NTMzNiA1MS42IDMuNTI2NDYgNTEuMDQ0OCAzLjM5NzUzQzQzLjI3NyAxLjQzODI2IDM1LjU5NDIgMC43NjE3MjUgMjcuODk5NCAwLjc3NzMzN0MyMC4yMDQ2IDAuNzkyOTQ4IDE3LjY1NjIgMS4yMjc4MiAxMi45OTg0IDIuMjA0NkM4LjkxNDkxIDIuOTIxOTQgNC44ODk5NCAzLjU3NDYyIDAuNjA3ODM1IDUuMzEwNjJDMC4wNzg5MDk5IDUuNTE0NDkgMC4yMjc3MTcgOC42Njc3MiAwLjUxNzAzOCAxMC4yNzMxQzAuOTE2MjAzIDEyLjQ2NDQgMS40OTUyIDEzLjg1MTEgMi4wNTYxOCAxMy42MjAxWiIgZmlsbD0iI0ZGQkMxMCIvPgo8L3N2Zz4K");
        width: 52.23px;
        height: 12.28px;

        position: absolute;
        top: 7px;
        left: -12px;
        transform: translateZ(-1px);
      }
    }

    &_content {
      font-size: 14px;
      line-height: 1.29;
      white-space: break-spaces;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 960px) {
  .profile-view__mini-badges-mobile {
    &::v-deep .mini-badge {
      margin-left: -25px;
    }
  }
}

@supports (-webkit-text-stroke-width: 1px) {
  .profile-view__name {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
  }
}
</style>
