<template>
  <div class="claim-account-page">
    <div v-if="data">
      <AuthRegisterForm
        :dialog-state="dialogState"
        :claim-account-data="data"
        :claim-token="token"
      />
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "Claim Your Account",
    titleTemplate: '%s | iU Community'
  },
  data() {
    return {
      token: this.$route.query.token,
      data: null,
      dialogState: {
        chooseSignUp: true,
      },
      alreadyClaimed: false,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Auth.claimAccountData({
        token: this.token,
      });

      if (!data) {
        this.$alert("Token not found", "error");
      } else if(data.link_expired){
        if(this.currentUser) this.$router.push('/account');
        else this.$router.push('/opportunities');
      } else {
        if(this.currentUser && this.currentUser.id != data.id) {
          await this.$store.dispatch("auth/logout");
        }
        this.data = data;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.claim-account-page {
  margin: auto;
  max-width: 800px;
}
</style>
