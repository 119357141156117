<template>
  <div class="uploader-wrapper" :style="{ maxWidth: ratio * 300 + 'px' }">
    <v-btn
      @click="remove"
      v-if="src"
      fab
      small
      color="primary"
      class="uploader__delete"
    >
      <v-icon>$delete</v-icon>
    </v-btn>
    <label
      :style="{
        backgroundImage: `url(${src})`,
        backgroundPosition: position,
        borderRadius: circle ? '50%' : '20px',
        backgroundSize: size,
      }"
      class="uploader"
      :class="{ 'uploader-circle': circle }"
      @drop.prevent="drop"
      @dragover.prevent
    >
      <v-responsive class="uploader__placeholder" :aspect-ratio="ratio">
        <div v-if="value && value.vimeo_id && !value.preview.url">
          Processing video...
        </div><v-btn
          tag="div"
          color="default"
          fab
          class="elevation-0 uploader__upload-btn"
          v-if="!src"
        >
          <v-icon>$plus</v-icon>
        </v-btn>
        <slot></slot>
      </v-responsive>
      <input
        ref="fileUploader"
        accept="image/*,video/*"
        @change="handleChange"
        class="uploader__input"
        type="file"
      />
    </label>
    <v-text-field
      ref="validation"
      class="uploader__validation"
      :value="value"
      type="hidden"
      hide-details="auto"
      :rules="rules"
    />
    <ImageCropper
      :open="dialog"
      :srcFile="uploadedFile"
      @cancel="onCancel"
      @confirm="onConfirm"
      :width="500"
      :height="500"
      :ratio="ratio"
      :stencil-size="{
        width: 280,
        height: 280,
      }"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "Uploader",
  props: {
    value: [File, Object],
    placeholder: String,
    ratio: {
      type: Number,
      default: 580 / 320,
    },
    circle: Boolean,
    position: {
      type: String,
      default: "center",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "cover",
    },
    enableCropper: {
      type: Boolean,
      default: false,
    },
    avatar: Boolean,
  },
  data: () => ({
    dialog: false,
    uploadedFile: null,
  }),
  mounted() {
    if(this.avatar) {
      this.$root.$on('openAvatarUploader', () => {
        this.$refs.fileUploader.click();
      })
    }
  },
  methods: {
    handleChange(event) {
      if (event.target.files.length == 0) return;
      const file = event.target.files[0];
      file.uuid = uuidv4();
      if (file) {
        if (this.enableCropper) {
          this.openCropper(file);
        } else {
          this.$emit("input", file);
        }
      }
    },
    drop(event) {
      const file = event.dataTransfer.files[0];
      if (this.enableCropper) {
        this.openCropper(file);
      } else {
        this.$emit("input", file);
      }
    },
    openCropper(file) {
      console.info('openCropper file', file);
      this.uploadedFile = file;
      this.dialog = true;
    },
    remove() {
      this.$emit("input", null);
      this.$refs.fileUploader.value = null;
    },
    onCancel() {
      this.dialog = false;
      this.$refs.fileUploader.value = null;
    },
    onConfirm(file) {
      this.dialog = false;
      file.uuid = uuidv4();
      this.$emit("input", file);
      this.$refs.fileUploader.value = null;
    },
  },
  computed: {
    src() {
      if (this.value instanceof File) {
        return URL.createObjectURL(this.value);
      }
      if (this.value instanceof Object) {
        return this.value.preview?.thumb?.url || this.value.url;
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.uploader {
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid var(--v-border-base);
  background-size: cover;
  background-position: center;
  display: block;
  position: relative;

  &::v-deep {
    .availability {
      position: absolute;
      bottom: -9px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 18px);
    }
  }

  &-wrapper {
    position: relative;
  }

  &__delete {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }

  &__validation {
    &:not(.v-input--has-state) {
      display: none !important;
    }
    padding: 0 !important;
    &::v-deep {
      .v-input__slot {
        display: none !important;
      }
    }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: visible;
  }

  &__upload-btn {
    background: #f5f5f5e0 !important;
  }

  &__input {
    display: none;
  }
}

.uploader-wrapper::v-deep .cropper {
  height: 400px;
}
</style>
