<template>
  <div>
    <WorkAssignmentSelector
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :list="listComputed"
      :rules="rules"
    />
  </div>
</template>

<script>
import WorkAssignmentSelector from "@/components/selects/WorkAssignmentSelector";

export default {
  components: { WorkAssignmentSelector },
  props: {
    value: [String, Number, Array],
    rules: Array,
    userId: String,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.setWorkAssignments();
  },
  methods: {
    async setWorkAssignments() {
      const params = {};
      if (this.userId) params.user_id = this.userId;
      const { data } = await this.$api.WorkAssignment.hmLite(params);
      this.list = [{ label: "N/A", id: null }, ...data.map((t) => ({ ...t }))];
      if (this.list.length == 2) this.$emit("input", this.list[1].id);
    },
  },
  computed: {
    listComputed() {
      return this.list.map((item) => {
        item.disabled = item.agreement_status != "SIGNED";
        return item;
      });
    },
  },
};
</script>
