<template>
  <div>
    <iframe 
      :src="`https://player.vimeo.com/video/${vimeoId}?h=${vimeoH}&autoplay=1`"
      frameborder="0"
      allow="autoplay; fullscreen"
      class="vimeo-player"
      allowfullscreen
      ref="vimeo"
    >
    </iframe>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  name: "VimeoPlayer",
  props: {
    vimeoId: [String, Number],
    vimeoH: [String, Number],
  },
  mounted() {
    new Player(this.$refs.vimeo);
  },
};
</script>

<style scoped lang="scss">
.vimeo-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
