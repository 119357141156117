<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title" v-if="title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0 center-align">
              <div>Type</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="agreement_type"
                fieldLabel="Type"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w45">
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">
              <div>Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="status"
                fieldLabel="Status"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div style="white-space: nowrap">Date Signed</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="signed_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Requested"
                isDate
              />
            </th>
            <th>Requested By</th>
            <th>
              <div>Date Requested</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Requested"
                isDate
              />
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <AgreementsTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            @refresh="$emit('refresh')"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="8" class="center-align">No agreements</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
import AgreementsTableRow from "./table-row.creator-agreements.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";

export default {
  components: {
    AgreementsTableRow,
    TableHeaderActions,
  },
  props: {
    title: String,
    items: Array,
    listOptions: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
.payments-table {
  &-wrapper {
    margin-top: 30px;
  }

  &-head {
    color: white;
    background: #4282ff;
  }
}
</style>
