<template>
  <v-expansion-panel>
    <v-expansion-panel-header>{{ fieldLabel }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="table-date-filter">
        <div v-if="quickOptions">
          <v-list dense>
            <v-list-item
              class="table-date-filter__quick-option"
              :class="{
                'table-date-filter__quick-option--selected':
                  item.value == quickOption,
              }"
              v-for="item in quickOptionItems"
              :key="`quick-option--${item.value}`"
              @click="selectQuickOption(item.value)"
            >
              {{ item.title }}
            </v-list-item>
          </v-list>
        </div>
        <div
          class="table-date-filter__options"
          v-show="!quickOptions || quickOption == 'custom'"
        >
          <div class="table-date-filter__actions">
            <div
              class="table-date-filter__action"
              :class="{ 'table-date-filter__action--selected': !dateRange }"
              @click="setToSingleDate()"
            >
              Single Date
            </div>
            <div
              class="table-date-filter__action"
              :class="{ 'table-date-filter__action--selected': dateRange }"
              @click="dateRange = true"
            >
              Date Range
            </div>
            <div v-if="anyValues" class="table-date-filter__action-group">
              <div class="table-date-filter__separator">•</div>
              <div class="table-date-filter__action" @click="clear()">
                Reset
              </div>
            </div>
          </div>
          <DateTimePicker
            v-model="localListOptions[fieldName][0]"
            :startYear="2023"
            :endYear="new Date().getFullYear()"
            :rounded="false"
            onlyDate
            light
            @input="onDateUpdate(0)"
          />
          <DateTimePicker
            v-if="dateRange"
            v-model="localListOptions[fieldName][1]"
            :startYear="2023"
            :endYear="new Date().getFullYear()"
            :rounded="false"
            onlyDate
            light
            @input="onDateUpdate(1)"
          />
          <v-btn @click="submitDate" elevation="0" color="primary"
            >Submit</v-btn
          >
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    listOptions: Object,
    fieldName: String,
    fieldLabel: String,
    quickOptions: Boolean,
  },
  data() {
    const isDateRange =
      this.listOptions[this.fieldName].filter((item) => item).length == 2;

    return {
      localListOptions: { ...this.listOptions },
      dateRange: isDateRange,
      quickOption: this.initialQuickOption(this.listOptions),
      quickOptionItems: [
        {
          value: "month_to_date",
          title: "Month to date",
        },
        {
          value: "last_month",
          title: "Last Month",
        },
        {
          value: "year_to_date",
          title: "Year to Date",
        },
        {
          value: "custom",
          title: "Custom",
        },
      ],
    };
  },
  methods: {
    monthToDate() {
      const today = new Date();
      const first = new Date(today.getFullYear(), today.getMonth(), 1);
      return [first, today];
    },
    lastMonth() {
      const today = new Date();
      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      const firstDayOfLastMonth = new Date(
        lastDayOfLastMonth.getFullYear(),
        lastDayOfLastMonth.getMonth(),
        1
      );
      return [firstDayOfLastMonth, lastDayOfLastMonth];
    },
    yearToDate() {
      const today = new Date();
      const firstOfThisYear = new Date(today.getFullYear(), 1, 1);
      return [firstOfThisYear, today];
    },
    simpleDate(date) {
      return {
        day: date.getDay(),
        month: date.getMonth(),
        year: date.getYear(),
      };
    },
    simpleDatesMatch(rangeA, rangeB) {
      return (
        rangeA[0].day == rangeB[0].day &&
        rangeA[0].month == rangeB[0].month &&
        rangeA[0].year == rangeB[0].year &&
        rangeA[1].day == rangeB[1].day &&
        rangeA[1].month == rangeB[1].month &&
        rangeA[1].year == rangeB[1].year
      );
    },
    initialQuickOption(listOptions) {
      if (listOptions[this.fieldName].length != 2) return "custom";
      if (listOptions[this.fieldName].filter((d) => d).length == 0)
        return "custom";
      const simpleDates = listOptions[this.fieldName].map((d) =>
        this.simpleDate(d)
      );

      const simpleMonthToDate = this.monthToDate().map((d) =>
        this.simpleDate(d)
      );
      if (this.simpleDatesMatch(simpleDates, simpleMonthToDate))
        return "month_to_date";

      const simpleLastMonth = this.lastMonth().map((d) => this.simpleDate(d));
      if (this.simpleDatesMatch(simpleDates, simpleLastMonth))
        return "last_month";

      const simpleYearToDate = this.yearToDate().map((d) => this.simpleDate(d));
      if (this.simpleDatesMatch(simpleDates, simpleYearToDate))
        return "year_to_date";

      return "custom";
    },
    selectQuickOption(value) {
      switch (value) {
        case "month_to_date": {
          this.localListOptions[this.fieldName] = this.monthToDate();
          this.submitDate();
          break;
        }
        case "last_month": {
          this.dateRange = true;
          this.localListOptions[this.fieldName] = this.lastMonth();
          this.submitDate();
          break;
        }
        case "year_to_date":
          this.dateRange = true;
          this.localListOptions[this.fieldName] = this.yearToDate();
          this.submitDate();
          break;
        case "custom":
          break;
      }
      this.quickOption = value;
    },
    setToSingleDate() {
      this.dateRange = false;
      this.localListOptions[this.fieldName][1] = null;
    },
    onDateUpdate(indexChanged) {
      if (
        !this.localListOptions[this.fieldName][0] ||
        !this.localListOptions[this.fieldName][1]
      )
        return;
      if (indexChanged == 0) {
        if (
          this.localListOptions[this.fieldName][0] >
          this.localListOptions[this.fieldName][1]
        )
          this.localListOptions[this.fieldName][1] =
            this.localListOptions[this.fieldName][0];
      }
      if (indexChanged == 1) {
        if (
          this.localListOptions[this.fieldName][1] <
          this.localListOptions[this.fieldName][0]
        )
          this.localListOptions[this.fieldName][0] =
            this.localListOptions[this.fieldName][1];
      }
    },
    submitDate() {
      this.$emit("filterUpdate", {
        fieldName: this.fieldName,
        option: this.localListOptions[this.fieldName],
      });
    },
    clear() {
      this.localListOptions[this.fieldName] = [null, null];
    },
  },
  computed: {
    anyValues() {
      return (
        this.localListOptions[this.fieldName].filter((item) => item).length > 0
      );
    },
  },
  watch: {
    listOptions: {
      deep: true,
      handler() {
        if (
          JSON.stringify(this.listOptions[this.fieldName]) !=
          JSON.stringify(this.localListOptions[this.fieldName])
        ) {
          this.localListOptions = { ...this.listOptions };
          this.dateRange =
            this.listOptions[this.fieldName].filter((item) => item).length == 2;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.table-date-filter {
  .date-picker {
    flex-direction: column;
    margin: 0 1em;
    &::v-deep > div {
      width: 100% !important;
      margin-bottom: 0.5em;
    }
  }
  &__options {
    > div {
      margin-bottom: 0.5em;
    }
  }

  &__actions {
    display: flex;
    color: #e12d1b;
    font-size: 12px;
    div {
      margin-right: 0.5em;
    }
  }
  &__action-group {
    display: contents;
  }
  &__action {
    cursor: pointer;
    &--selected {
      font-weight: bold;
    }
  }
  &__separator {
    display: inline-block;
  }
  &__range-toggle {
    color: white !important;
  }

  &__quick-option {
    cursor: pointer;
    text-decoration: underline;
    &--selected {
      font-weight: bold;
      background: #f8f8f9;
    }
  }
}
</style>
