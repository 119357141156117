<template>
  <div class="pfr" v-if="item">
    <div class="pfr__column">
      <div class="pfr__avatar">
        <div class="pfr__preview" :style="{ backgroundImage: avatarImage }">
          <Availability :value="item.availability" />
        </div>
      </div>
      <div class="pfr__name">
        <div class="pfr__label">Name</div>
        <div class="pfr__value">{{ item.display_name }}</div>
      </div>
      <div class="pfr__pronouns" v-if="item.pronouns">
        <div class="pfr__label">Pronouns</div>
        <div class="pfr__value">{{ item.pronouns }}</div>
      </div>
    </div>
    <div class="pfr__column">
      <div class="pfr__primary-location" v-if="item.primary_working_location">
        <div class="pfr__label">Location</div>
        <div class="pfr__value">{{ item.primary_working_location }}</div>
      </div>
      <div class="pfr__primary-specialty" v-if="item.primary_role">
        <div class="pfr__label">Primary Specialty</div>
        <div class="pfr__value">{{ item.primary_role }}</div>
      </div>
      <div
        class="pfr__secondary-specialty"
        v-if="item.secondary_specialties && item.secondary_specialties.length"
      >
        <div class="pfr__label">Secondary Specialty(s)</div>
        <div class="pfr__value">
          {{ item.secondary_specialties.join(", ") }}
        </div>
      </div>
      <div
        class="pfr__website"
        v-if="item.website_detailed && item.website_detailed.url"
      >
        <div class="pfr__label">Website / Portfolio</div>
        <div class="pfr__value">
          <a :href="item.website_detailed.url" target="_blank">{{
            item.website_detailed.title
          }}</a>
        </div>
      </div>
    </div>
    <div class="pfr__column">
      <div
        class="pfr__secondary-location"
        v-if="item.secondary_working_location"
      >
        <div class="pfr__label">Secondary Location</div>
        <div class="pfr__value">{{ item.secondary_working_location }}</div>
      </div>
      <div class="pfr__resume" v-if="item.resume && item.resume.url">
        <div class="pfr__label">Resume</div>
        <div class="pfr__value">
          <a :href="item.resume.url" target="_blank">View</a>
        </div>
      </div>
      <div class="pfr__school" v-if="item.school">
        <div class="pfr__label">School</div>
        <div class="pfr__value">{{ item.school }}</div>
      </div>
      <div class="pfr__grad-year" v-if="item.grad_year">
        <div class="pfr__label">Grad Year</div>
        <div class="pfr__value">{{ item.grad_year }}</div>
      </div>
    </div>
    <div class="pfr__column">
      <div class="pfr__contact" v-if="item.contact_email">
        <div class="pfr__label">Contact</div>
        <div class="pfr__value">
          <a :href="`mailto:${item.contact_email}`" target="_blank">{{
            item.contact_email
          }}</a>
        </div>
      </div>
      <div class="pfr__average-rating">
        <div class="pfr__label">Average Rating</div>
        <div class="pfr__value">
          <ProfileRating
            :value="item.average_profile_rating"
            :count="item.profile_reviews_count"
          />
        </div>
      </div>
      <div class="pfr__instagram" v-if="item.instagram">
        <div class="pfr__label">Instagram</div>
        <div class="pfr__value">
          <a :href="instagram(item.instagram)" target="_blank">
            {{ instagram(item.instagram) }}
          </a>
        </div>
      </div>
      <div class="pfr__linkedin" v-if="item.linkedin">
        <div class="pfr__label">LinkedIn</div>
        <div class="pfr__value">
          <a :href="item.linkedin" target="_blank">{{ linkedInFormatted }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileRating from "@/components/profile-review/ProfileRating.vue";

export default {
  name: "ProfileForReview",
  components: { ProfileRating },
  props: {
    value: Object,
  },
  data() {
    return {
      item: null,
      avatarImage: null,
    };
  },
  mounted() {
    this.fetchItem(this.value.id);
  },
  methods: {
    async fetchItem(id) {
      const { data } = await this.$api.Creator.showForReview({ id });
      this.item = data;
      this.avatarImage = this.value.avatar.url
        ? `url(${this.value.avatar.thumb.url})`
        : `url("data:image/svg+xml,%3Csvg width='158' height='158' viewBox='0 0 158 158' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M157.214 79.2449C157.214 122.572 122.09 157.696 78.7629 157.696C35.4357 157.696 0.312012 122.572 0.312012 79.2449C0.312012 35.9176 35.4357 0.793945 78.7629 0.793945C122.09 0.793945 157.214 35.9176 157.214 79.2449Z' fill='%23DCDCE1'/%3E%3Cpath opacity='0.3' d='M99 44C99 55.0457 90.0457 64 79 64C67.9543 64 59 55.0457 59 44C59 32.9543 67.9543 24 79 24C90.0457 24 99 32.9543 99 44Z' fill='%23262729'/%3E%3Cpath opacity='0.3' fill-rule='evenodd' clip-rule='evenodd' d='M28.9999 79C28.9999 106.614 51.3857 129 78.9999 129C106.614 129 129 106.614 129 79L28.9999 79Z' fill='%23262729'/%3E%3C/svg%3E")`;
    },
    instagram(v) {
      /* eslint-disable no-useless-escape */
      const urlRegexp =
        /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

      /* eslint-disable no-useless-escape */
      const instagramHandleRegexp =
        /^([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/;

      /* eslint-disable no-useless-escape */
      const atInstagramHandleRegexp =
        /^@([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/;

      if (urlRegexp.test(v)) return v;
      if (instagramHandleRegexp.test(v))
        return `https://www.instagram.com/${v}`;
      if (atInstagramHandleRegexp.test(v))
        return `https://www.instagram.com/${v.substr(1)}`;

      return v;
    },
  },
  computed: {
    linkedInFormatted() {
      const url = this.item.linkedin;
      let splitted = url.split("/");
      if (splitted.length && splitted[splitted.length - 1] == "")
        splitted = splitted.slice(0, -1);
      return splitted.length ? "/" + splitted.pop() : this.item.linkedin;
    },
  },
};
</script>

<style lang="scss" scoped>
.pfr {
  display: flex;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  a {
    color: var(--v-dark-base);
  }

  &__column {
    flex-basis: 25%;
    &:not(:last-child) {
      padding-right: 0.5em;
    }
    @media screen and (max-width: 960px) {
      flex-basis: unset;
      &:not(:last-child) {
        padding-right: 0;
      }
    }
    > div:not(:last-child):not(.pfr__avatar) {
      margin-bottom: 50px;
    }
  }

  &__preview {
    width: 165px;
    height: 165px;
    border-radius: 100%;
    background-position: center;
    background-size: cover;
    position: relative;

    &::v-deep {
      .availability {
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 10px);
      }
    }
  }

  &__label {
    font-weight: 700;
  }
  &__value {
    font-weight: 300;
    font-size: 20px;
  }
}

.disabled-theme .pfr {
  a {
    color: var(--v-light-base);
  }
}
</style>
