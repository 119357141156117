<template>
  <div v-if="creator" class="agreements-page">
    <div class="agreements-page__header">
      <a class="back-to-agreements" @click="$router.back()">
        <v-icon size="50">$arrow_left</v-icon>
      </a>
      <div class="agreements-page__header__top">
        <div class="page-title">
          {{ `${creator.display_name}’s Agreements` }}
        </div>
        <CreatorHeader :value="creator" v-if="creator" @refresh="refreshAll" />
      </div>
      <div class="agreements-page__actions" v-if="currentUser.is_people_team">
        <v-btn
          color="primary"
          @click="sendAgreementDialog = true"
          elevation="0"
          class="agreements-page__action-btn agreements-page__sa-btn"
        >
          Send Agreement
        </v-btn>
        <v-btn
          color="primary"
          @click="uploadAgreementDialog = true"
          elevation="0"
          class="agreements-page__action-btn agreements-page__sa-btn"
        >
          Upload Signed Agreement
        </v-btn>
      </div>
    </div>
    <CreatorAgreementsTable
      :items="list"
      @refresh="refreshAll"
      :class="{ 'table-loading': loadingList }"
      :listOptions="listOptions"
      @orderUpdate="orderUpdate($event)"
      @filterUpdate="filterUpdate($event)"
    >
      <template #actions>
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Project
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InternalProjectsSelector
                        v-model="listOptions.internal_project_id"
                        emptyLabel="All"
                        :params="{
                          agreements: true,
                          user_id: $route.params.id,
                        }"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        multiple
                        chipText="code"
                        @remove="
                          removeFromFilter('internal_project_id', $event);
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="agreement_type"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Type"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="status"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Status"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Signed"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Requested"
                  />
                  <v-expansion-panel>
                    <v-expansion-panel-header> Role </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <SkillsSelector
                        v-model="listOptions.skill_id"
                        placeholder="Role"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="payments-table__action">
          <div class="payments-table__action__label"></div>
          <TextField
            v-model="searchInput"
            emptyLabel="All"
            :params="{ agreements: true }"
            placeholder="Search"
          />
        </div>
        <div class="payments-table__secondary-actions">
          <div
            class="payments-table__action-btn payments-table__action-btn--reset"
            v-if="anyActiveListOptions"
          >
            <div @click="resetListOptions()">
              <v-icon size="14">$close</v-icon> Reset
            </div>
          </div>
        </div>
      </template>
      <template #pagination>
        <TablePagination
          :page="page"
          :totalPages="totalPages"
          @input="page = $event"
          :key="`pagination-${reloadKey}`"
        />
      </template>
    </CreatorAgreementsTable>
    <SendAgreementDialog
      :dialog="sendAgreementDialog"
      @refresh="refreshAll"
      @close="sendAgreementDialog = false"
      :key="`SendAgreementDialog-${reloadKey}`"
      :user="creator"
    />
    <UploadAgreementDialog
      :dialog="uploadAgreementDialog"
      @refresh="refreshAll"
      @close="uploadAgreementDialog = false"
      :key="`UploadAgreementDialog-${reloadKey}`"
      :user="creator"
    />
  </div>
</template>

<script>
import CreatorAgreementsTable from "./creator-agreements/table.creator-agreements";
import agreementsTableActions from "@/mixins/agreements/agreements-table-actions.mixin";
import SendAgreementDialog from "./people-team/SendAgreementDialog";
import UploadAgreementDialog from "./people-team/UploadAgreementDialog";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";
import CreatorHeader from "@/components/CreatorHeader";

export default {
  mixins: [agreementsTableActions, paginatedTable],
  components: {
    CreatorAgreementsTable,
    SendAgreementDialog,
    UploadAgreementDialog,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
    CreatorHeader,
  },
  data() {
    return {
      creator: null,
      list: [],
      loadingList: false,
      reloadKey: 1,
      sendAgreementDialog: false,
      uploadAgreementDialog: false,
      filterFields: [
        { name: "internal_project_id", label: "Project" },
        { name: "agreement_type", label: "Type" },
        { name: "status", label: "Status" },
        { name: "signed_at", label: "Date Signed" },
        { name: "created_at", label: "Date Requested" },
        { name: "skill_id", label: "Role" },
      ],
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "AgreementPeopleTeamChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
    this.setContent();
  },
  methods: {
    async setContent() {
      this.fetchList();
      const { data } = await this.$api.Creator.showForPayments({
        id: this.$route.params.id,
      });
      this.creator = data;
    },
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = ["sm", "xs"].includes(this.$size) ? 5 : 10;
      params.user_id = this.$route.params.id;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Agreement.list(params);
      console.info("data.agreements", data.agreements);
      this.list = data.agreements;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
    async refreshAll() {
      this.fetchList();
      this.reloadKey++;
    },
    async handleAgreementBroadcast(type, payload) {
      if (
        (!payload.transaction_id ||
          payload.transaction_id !=
            this.$store.getters["agreement/transactionId"]) &&
        (payload.creator_id == this.creator.id ||
          (payload.creator_ids &&
            payload.creator_ids.includes(this.creator.id)))
      ) {
        this.fetchList();
        this.reloadKey++;
      }
    },
  },
  channels: {
    AgreementPeopleTeamChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleAgreementBroadcast(type, payload);
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "AgreementPeopleTeamChannel",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/agreements/agreements-page.scss";
@import "@/styles/table-filters-menu.scss";

.back-to-agreements {
  display: inline-block;
  margin-bottom: 40px;
}

.agreements-page__header__top {
  width: 100%;
  margin-bottom: 50px;
  .page-title {
    margin-bottom: 57px;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 31px;
  }
}
</style>
