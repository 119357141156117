<template>
  <TagSelector
    :multiple="multiple"
    :items="items"
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    itemText="label"
    itemValue="uuid"
    :rules="rules"
    class="tag-selector"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    items: Array,
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.tag-selector {
  &::v-deep {
    fieldset {
      background: white;
    }
  }
}
</style>
