import Vue from "vue";

[
  "TextField",
  "FileField",
  "TextArea",
  "RadioGroup",
  "Checkbox",
  "ColorPicker",
  "DateTimePicker",
  "DatePicker",
  "FormBuilder",
  "Banner",
  "Uploader",
  "Spinner",
  "Search",
  "ContextSearch",
  "Dropdown",
  "SubmitFooter",
  "IndexPageActions",
  "Pagination",
  "Actions",
  "Slides",
  "Alert",
  "ConfirmDialog",
  "RecaptchaText",
  "Avatar",
  "Switcher",
  "CodeEditor",
  "SubmissionLayoutBuilder",
  "MetaTagsEditor",
  "JsonViewer",
  "SubmissionStage",
  "SubmissionFormStage",
  "Profile",
  "Footer",
  "RightBar",
  "MainMenu",
  "AppBar",
  "CreatorsSections",
  "CreatorsSection",
  "CreatorsSearch",
  "SupportForm",
  "Button",
  "Eligibles",
  "SchoolSearch",
  "ImageCropper",
  "Availability",
  "ThemeSwitch",
  "BadgeInfo",
  "ActionsMenu",
  "CreatorActionsMenu",
  "ProjectCodeField",
  "PleaseWaitDialog",
  "AreYouSureDialog",
].forEach((e) => {
  Vue.component(e, require(`./${e}.vue`).default);
});

[
  "TextBlock",
  "Countdown",
  "ContentSection",
  "Event",
  "List",
  "Schedule",
  "SupportForm",
  "SubmissionForm",
  "HtmlBlock",
  "SubmissionContent",
  "Presentation",
  "CustomComponent",
].forEach((e) => {
  Vue.component(`Page${e}`, require(`./page-components/${e}.vue`).default);
});

["FormRow", "FormPanels", "FormPanel"].forEach((e) => {
  Vue.component(e, require(`./form/${e}.vue`).default);
});

["ListPanels", "ListPanel"].forEach((e) => {
  Vue.component(e, require(`./list/${e}.vue`).default);
});

["OpportunityGroup", "Opportunity"].forEach((e) => {
  Vue.component(e, require(`./opportunities/${e}.vue`).default);
});

["MiniBadge", "CountableBadge"].forEach((e) => {
  Vue.component(e, require(`./badges/${e}.vue`).default);
});

[
  "InternalProjectsSelector",
  "RadiusSelector",
  "TimezoneSelector",
  "ThemesSelector",
  "SchedulesSelector",
  "TagSelector",
  "ListsSelector",
  "NotificationTypeSelector",
  "TextBlockLayoutSelector",
  "FontSelector",
  "Select",
  "EventsSelector",
  "PagesSelector",
  "RolesSelector",
  "ContentSectionsSelector",
  "UsersSelector",
  "SubmissionFormSelector",
  "SubmissionContentLayoutSelector",
  "CustomComponentsSelector",
  "SkillsSelector",
  "LocationSelector",
].forEach((e) => {
  Vue.component(e, require(`./selects/${e}.vue`).default);
});

[
  "PresentationImage",
  "PresentationRotator",
  "PresentationVimeo",
  "PresentationYoutube",
].forEach((e) => {
  Vue.component(e, require(`./presentation/${e}.vue`).default);
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "Checkbox",
  "Radio",
  "HtmlBlock",
  "PasswordField",
].forEach((e) => {
  Vue.component(
    `FormBuilder${e}`,
    require(`./form-builder/${e}.form-builder.vue`).default
  );
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "Checkbox",
  "Radio",
  "HtmlBlock",
].forEach((e) => {
  Vue.component(
    `Dynamic${e}`,
    require(`./dynamic-inputs/${e}.dynamic.vue`).default
  );
});

["VimeoPlayer", "YoutubePlayer"].forEach((e) => {
  Vue.component(e, require(`./Players/${e}.vue`).default);
});

["AuthForm", "AuthModal", "AuthRegisterForm"].forEach((e) => {
  Vue.component(e, require(`./Auth/${e}.vue`).default);
});

[
  "WorkExamplesEditor",
  "WorkExampleEditorSlides",
  "WorkExample",
  "WorkExampleDialog",
  "WorkExampleSlides",
].forEach((e) => {
  Vue.component(e, require(`./work-examples/${e}.vue`).default);
});

["NotFound", "NotAuthorized"].forEach((e) => {
  Vue.component(e, require(`./Pages/${e}.vue`).default);
});

["ProfileNotificationCenter", "ProfileNotificationDialog"].forEach((e) => {
  Vue.component(e, require(`./profile-notifications/${e}.vue`).default);
});
