<template>
  <div v-if="loaded" class="date-picker">
    <v-btn
      v-if="month || day || year"
      icon
      @click="clearAll"
      class="date-picker__clear-btn"
    >
      <v-icon>$close</v-icon>
    </v-btn>
    <div>
      <Select
        placeholder="Month"
        :items="months"
        :value="month"
        @input="updateMonth($event)"
        :light="light"
      />
    </div>
    <div>
      <Select
        placeholder="Day"
        :items="days"
        :value="day"
        @input="updateDay($event)"
        :disabled="month == null"
        :light="light"
      />
    </div>
    <div>
      <Select
        placeholder="Year"
        :items="years"
        :value="year"
        @input="updateYear($event)"
        :light="light"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    placeholder: String,
    label: String,
    value: [String, Date],
    rounded: {
      type: Boolean,
      default: true,
    },
    startYear: { type: Number, default: null },
    endYear: { type: Number, default: null },
    light: Boolean
  },
  mounted() {
    if(this.value) {
      const parts = this.value.split('-');
      this.year = parseInt(parts[0]);
      this.month = parseInt(parts[1]);
      this.updateDaysInMonth();
      this.day = parseInt(parts[2]);
    }
    this.updateYears();
    this.loaded = true;
  },
  data() {
    const months = this.$moment.months().map((m, i) => { return { value: i+1, name: m } });
    return {
      months,
      days: [],
      years: [2000, 2001],
      month: null,
      day: null,
      year: null,
      loaded: false,
    }
  },
  methods: {
    updateMonth(m) {
      this.month = m;
      this.updateDaysInMonth();
      this.emitUpdate();
    },
    updateDay(d) {
      this.day = d;
      this.emitUpdate();
    },
    updateYear(y) {
      this.year = y;
      this.updateDaysInMonth();
      this.emitUpdate();
    },
    updateDaysInMonth() {
      if(this.month) {
        const year = this.year || 2020;
        const count = this.$moment(year + "-" + this.month, "YYYY-MM").daysInMonth();
        const days = [];
        for(let i = 1; i <= count; i++) days.push(i);
        this.days = days;
      }
    },
    updateYears() {
      let max = new Date().getFullYear() - 10;
      if(this.endYear) max = this.endYear;
      let min = 1901;
      if(this.startYear) min = this.startYear;
      if (this.year && this.year < min) min = this.year;
      const years = [];
      for(let i = max; i >= min; i--) years.push(i);
      this.years = years;
    },
    emitUpdate() {
      if(this.month && this.year && this.day) {
        this.$emit('input', this.year + "-" + (this.month < 10 ? "0" : '') + this.month + "-" + (this.day < 10 ? "0" : '') + this.day);
      }
    },
    clearAll() {
      this.month = null;
      this.day = null;
      this.year = null;
      this.$emit('input', null);
    },
  }
}
</script>

<style lang="scss" scoped>
.date-picker {
  display: flex;
  position: relative;
  justify-content: space-between;
  > div {
    width: calc(33% - 10px);
  }

  &__clear-btn {
    position: absolute;
    right: calc(100% - 4px);
    top: 5px;
  }

  &::v-deep {
    .v-select__selection {
      font-size: 14px !important;
    }
  }
}
</style>