<template>
  <div
    class="opportunity-group"
    :class="[
      {
        'opportunity-group__light': light,
        'opportunity-group__swiper-wrapper': swiper,
        'opportunity-group__load-more-available': loadMoreAvailable,
      },
      'opportunity-group__' + type,
    ]"
    v-if="list && list.length"
  >
    <router-link
      v-if="titleLink"
      to="/opportunities"
      class="opportunity-group__header"
      >{{ title }}</router-link
    >
    <div v-else class="opportunity-group__header">
      {{ title }}
      <div class="opportunity-group__subheader">
        {{ subheader }}
      </div>
    </div>
    <div class="swiper-button-prev" v-if="enableSwiper" @click="swipePrev()">
      <v-icon :color="caretColor" size="50">
        {{
          ["xs", "sm"].includes($size) ? "$chevron_left" : "$chevron_left_large"
        }}
      </v-icon>
    </div>
    <div class="swiper-button-next" v-if="enableSwiper" @click="swipeNext()">
      <v-icon :color="caretColor" size="50">
        {{
          ["xs", "sm"].includes($size)
            ? "$chevron_right"
            : "$chevron_right_large"
        }}
      </v-icon>
    </div>
    <component
      :is="enableSwiper ? 'swiper' : 'div'"
      :class="{ 'opportunity-group__swiper': enableSwiper }"
      ref="swiper"
      :options="swiperOption"
      @slideChange="slideChanged"
    >
      <Opportunity
        class="opportunity-group__opportunity"
        v-for="opportunity in list"
        :key="opportunity.id"
        :value="opportunity"
        :swiper="enableSwiper"
        :type="type"
      />
    </component>
    <div
      v-if="enableSwiper && swiperPositionEnabled"
      class="opportunity-group__fraction"
    >
      <span>{{ swiperPosition }} / {{ swiperCount }}</span>
    </div>
    <div class="opportunity-group__loadmore" v-if="loadMoreAvailable">
      <v-btn @click="loadMore" color="grey" class="elevation-0"
        >Load More</v-btn
      >
    </div>
  </div>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";

export default {
  mixins: [themeDisabledPageMixin],
  props: {
    type: String,
    title: String,
    subheader: String,
    titleLink: Boolean,
    previous: Boolean,
    light: Boolean,
    swiper: Boolean,
    swiperPositionEnabled: Boolean,
  },
  data() {
    return {
      list: null,
      page: 1,
      loadMoreAvailable: true,
      swiperOption: {
        keyboard: {
          enabled: true,
        },
        slidesPerView: 1,
        autoplay: {
          delay: 8000,
        },
        loop: true,
      },
      swiperPosition: 1,
      swiperCount: null,
    };
  },
  created() {
    this.setList();
  },
  methods: {
    async setList() {
      const { data } = await this.$api.SubmissionForm.get({
        type: this.type,
        page: this.page,
        previous: this.previous,
      });
      if (data.length < 5) this.loadMoreAvailable = false;
      this.list = [...(this.list || []), ...data];
      this.swiperCount = this.list.length;
      this.$emit("loaded");
    },
    loadMore() {
      this.page += 1;
      this.setList();
    },
    swipePrev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    swipeNext() {
      console.info(
        "this.$refs.swiper.swiperInstance",
        this.$refs.swiper.swiperInstance
      );
      this.$refs.swiper.swiperInstance.slideNext();
    },
    slideChanged() {
      this.swiperPosition = this.$refs.swiper.swiperInstance.realIndex + 1;
    },
  },
  computed: {
    enableSwiper() {
      return this.swiper && this.list?.length > 1;
    },
    caretColor() {
      if (
        (this.$vuetify.theme.dark && !this.themeDisabledPage) ||
        ["sm", "xs"].includes(this.$size)
      )
        return "white";
      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.opportunity-group {
  position: relative;
  background: var(--v-background-base);
  margin-bottom: 66px;
  border-radius: 10px;
  @media screen and (max-width: 960px) {
    margin-bottom: 48px;
  }

  &__header {
    display: block;
    border: 2px solid var(--v-dark-base);
    background: var(--v-background-base);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px 40px;
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: 900;
    text-decoration: none;
    @media screen and (max-width: 960px) {
      padding: 24px 12px;
    }
  }
  &__featured &__header,
  &__undefined &__header {
    background: linear-gradient(90deg, #7a74a0 0%, #d882ad 100%);
    color: white;
  }
  &__gigs &__header {
    background: linear-gradient(90deg, #d882ad 0%, #d1a799 100%);
    color: white;
  }
  &__ideation_short &__header {
    background: linear-gradient(90deg, #d1a799 0%, #e8842b 99.99%);
    color: white;
  }

  &__evp &__header {
    background: linear-gradient(90deg, #7b61ff 0%, #ff7cf2 99.99%);
    color: white;
  }
  &__subheader {
    font-size: 16px;
    font-weight: normal;
  }
  &__loadmore {
    padding: 40px;
    background: var(--v-background-lighten1);
    border: 2px solid var(--v-dark-base);
    margin-top: -2px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__light {
    .opportunity-group__loadmore {
      background: white;
    }
  }

  &__swiper {
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: var(--v-dark-base);
    background: var(--v-background-lighten1);
    border-radius: 0 0 10px 10px;
    .opportunity {
      border: none;
    }
  }

  &__fraction {
    position: relative;
    top: -35px;
    z-index: 1;
    text-align: center;

    span {
      text-align: center;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: calc(50% + 31px);
    z-index: 1;

    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: -67.5px;
  }
  .swiper-button-next {
    right: -67.5px;
  }

  &__load-more-available .opportunity:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: 960px) {
  .opportunity-group {
    &__fraction {
      top: 3px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 34.5px;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
}

.disabled-theme .opportunity-group {
  background: white;

  &__header {
    border: 2px solid #262729;
  }

  &__opportunity {
    background: white;
    border: 2px solid #262729;
  }

  &__loadmore {
    background: white;
    border: 2px solid #262729;
  }

  &__swiper {
    border-color: #262729;

    .opportunity {
      border: none;
    }
  }
}
</style>
