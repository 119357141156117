<template>
  <div
    class="presentation-img"
    :style="{
      backgroundImage: `url(${slide.preview ? slide.preview.thumb.url : ''})`,
    }"
  ></div>
</template>

<script>
export default {
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss" scoped>
.presentation-img {
  width: 100%;
  height: calc(100% - 40px);
  background-position: center center;
  background-size: contain;
}
</style>
