<template>
  <TagSelector
    :multiple="multiple"
    :items="events"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  created() {
    this.setEvents();
  },
  methods: {
    async setEvents() {
      const { data } = await this.$api.Event.lite();
      this.events = data;
    },
  },
};
</script>

<style></style>
