<template>
  <div>
    <v-expansion-panels
      v-if="loaded"
      class="list-panels"
      accordion
      v-model="panel"
      multiple
    >
      <slot></slot>
    </v-expansion-panels>
    <Spinner relative v-else />
  </div>
</template>

<script>
export default {
  props: {
    loaded: Boolean,
  },
  data() {
    return {
      panel: [],
    };
  },
  created() {},
};
</script>

<style lang="scss">
.list-panels > div {
  width: 100%;
}
</style>
