<template>
  <div
    class="video-player"
    :class="{
      show_event: item.event_ids.length,
      with_events: item.event_ids.length > 1,
      with_chat: item.chat_ids.length,
    }"
  >
    <div class="video-player__main">
      <div class="video-player__video" v-if="item.event_ids.length">
        <div class="video-player__title">
          Video Player
        </div>
        <div class="video-player__video-container">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="#F8F8F9" />
            <path d="M70 50L40 67.3205L40 32.6795L70 50Z" fill="#DCDCE1" />
          </svg>
        </div>
      </div>
      <div class="video-player__chat" v-if="item.chat_ids.length">
        <div class="video-player__title">Chat Box</div>

        <div class="video-player__chat-item" v-for="i in 6" :key="i">
          <div class="video-player__chat-item-logo"></div>
          <div class="video-player__chat-item-text">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="video-player__timeline" v-if="item.event_ids.length > 1">
      <div class="video-player__timeline-item">
        <div class="video-player__title">Events Timeline</div>
      </div>
      <div class="video-player__timeline-item" v-for="i in 6" :key="i"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    widgets: Object,
  },
};
</script>

<style scoped lang="scss">
.video-player {
  border: 1px solid var(--v-border-base);
  border-radius: 10px;
  color: var(--v-text-base);
  height: 355px;

  &.show_event {
    height: 480px;
  }

  &.with_events &__main {
    height: calc(100% - 100px);
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
    padding: 24px 40px;
  }

  &__main {
    display: flex;
    height: 100%;
  }

  &.with_chat &__video {
    border-right: 1px solid var(--v-border-base);
  }

  &__video {
    width: 100%;
    position: relative;

    &-container {
      text-align: center;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.show_event &__chat {
    width: auto;
  }
  &__chat {
    min-width: 240px;
    width: 100%;

    &-item {
      display: flex;
      margin-bottom: 10px;
      padding: 0 40px;

      &-logo {
        height: 32px;
        width: 32px;
        min-width: 32px;
        background: var(--v-background-base);
        border-radius: 32px;
        margin-right: 9px;
      }
      &-text {
        width: 100%;
        margin: -4px;
        & > div {
          height: 9px;
          width: 100%;
          background: var(--v-background-base);
          margin: 4px;
        }
      }
    }
  }
  &__timeline {
    border-top: 1px solid var(--v-border-base);
    height: 100px;
    display: flex;
    padding: 10px;

    &-item {
      border-radius: 10px;
      border: 1px solid var(--v-border-base);
      width: 135px;
      height: 62px;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .video-player__title {
        padding: 0;
      }
    }
  }
}
</style>
