/* eslint-disable */
import Vue from "vue";

const store = {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    set(state, user) {
      state.current = user;
    },
  },
  actions: {
    async fetch({ commit }) {
      const token = $cookies.get("authToken");
      if (token) {
        const { data } = await Vue.prototype.$api.User.current();
        commit("set", data);
        const cookieDomain =
          process.env.NODE_ENV === "development"
            ? "localhost"
            : window.location.hostname.replace("profile", "");
        $cookies.set("authToken", token, null, null, cookieDomain);
        return data;
      }
    },
    async update({ commit }, params) {
      const { data } = await Vue.prototype.$api.User.update(params);
      commit("set", data);
      return data;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
};

export default store;
