<template>
  <div class="monday-on">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title" v-if="!loading">
      {{
        form.id
          ? `Hire Request #${form.monday_com_item_id}`
          : "New Hire Request"
      }}
    </div>

    <div v-if="loading">
      <Spinner />
    </div>
    <div v-else>
      <HireRequest v-if="form.id" v-model="form" @refresh="fetchHireRequest" />
      <div
        class="monday-on__issue-msg--emphasis"
        v-else-if="!form.id && mondayComItemId && !mondayComItem"
      >
        Monday.com task not found.
      </div>
      <HireRequestForm
        v-else-if="creatorResolved"
        v-model="form"
        @creatorChange="creatorChange()"
        @addNewGig="addNewGig"
        @removeGig="removeGig($event)"
        @submit="submit($event)"
        @input="form = $event"
      />
      <CreatorResolver
        v-else
        v-model="form.creator_id"
        :mondayComItem="mondayComItem"
        @input="setCreator"
      />
    </div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import HireRequest from "../hire-requests/HireRequest";
import HireRequestForm from "./monday-onboarding/HireRequestForm";
import CreatorResolver from "./monday-onboarding/CreatorResolver";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  head: {
    title: "Hire Request",
    titleTemplate: "%s | iU Community",
  },
  components: {
    HireRequest,
    HireRequestForm,
    CreatorResolver,
  },
  mixins: [InvoiceLabelsMixin],
  data() {
    return {
      loading: true,
      form: {
        monday_com_item_id: null,
        is_flat_fee: false,
        work_assignments: [
          {
            internal_project_id: null,
            start_date: null,
            end_date: null,
          },
        ],
      },
      mondayComItemId: null,
      mondayComItem: null,
      creatorResolved: false,
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "AgreementPeopleTeamChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    if (this.$route.params.id) {
      this.mondayComItemId = this.$route.params.id;
      this.fetchInit();
    }
  },
  methods: {
    async fetchInit() {
      await this.fetchHireRequest();
      if (this.form.id) {
        this.creatorResolved = true;
      } else {
        await this.fetchMondayItem();
      }
      this.loading = false;
    },
    async fetchHireRequest() {
      try {
        const response = await this.$api.HireRequest.get({
          monday_com_item_id: this.mondayComItemId,
        });
        this.form = response.data;
      } catch (e) {
        if (e.response.status == 404) {
          this.form.monday_com_item_id = this.mondayComItemId;
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    async fetchMondayItem() {
      try {
        const { data } = await this.$api.HireRequest.getMondayComItem({
          id: this.mondayComItemId,
        });
        this.mondayComItem = data.item;
        if (!this.form.id && data.suggestion) {
          _.assign(this.form, data.suggestion);
        }
        console.info("fetchMondayItem this.mondayComItem", this.mondayComItem);
        console.info("fetchMondayItem this.form", this.form);
        console.info("fetchMondayItem data.suggestion", data.suggestion);
      } catch (e) {
        if (e.response.status == 404) {
          this.$alert(
            "Monday.com Item #" + this.mondayComItemId + " not found.",
            "error"
          );
          this.loading = false;
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    setCreator(payload) {
      this.form.creator_id = payload.id;
      this.creatorResolved = true;
    },
    creatorChange() {
      this.creatorResolved = false;
    },
    addNewGig() {
      this.form.work_assignments.push({});
      this.form.work_assignments = [...this.form.work_assignments];
    },
    removeGig(idx) {
      this.form.work_assignments.splice(idx, 1);
      this.form.work_assignments = [...this.form.work_assignments];
    },
    async submit(form) {
      const transaction_id = uuidv4();
      this.$store.commit("agreement/setTransactionId", transaction_id);
      const params = { ...form };
      params.transaction_id = transaction_id;
      params.work_assignments.forEach((wa, idx) => (wa.position = idx));
      const response = await this.$api.HireRequest.create(params);
      if (response.status == 200) {
        this.form = response.data;
        window.scrollTo(0, 0);
      } else {
        this.$alert("Something went wrong", "error");
      }
    },
    async handleAgreementBroadcast(type, payload) {
      // Early return if we did it.
      if (
        payload.transaction_id &&
        payload.transaction_id == this.$store.getters["agreement/transactionId"]
      )
        return;

      // Early return if it's regarding a hire_request, but not this one
      if (
        this.form.monday_com_item_id &&
        payload.hire_request_monday_com_item_id &&
        this.form.monday_com_item_id != payload.hire_request_monday_com_item_id
      )
        return;

      this.fetchInit();
    },
  },
  channels: {
    AgreementPeopleTeamChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleAgreementBroadcast(type, payload);
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "AgreementPeopleTeamChannel",
    });
  },
};
</script>

<style lang="scss">
.monday-on {
  margin: 0 auto;
  max-width: 1400px;

  .form-label {
    text-align: left;
    font-weight: bold;
    color: #56565a;
  }

  &__issue-msg {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    &--emphasis {
      color: #e12d1b;
    }
  }
  .submit-footer {
    border: none !important;
    background: none !important;
    padding: 4em 0 0 !important;
  }
}
</style>
