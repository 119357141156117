<template>
  <v-expansion-panel>
    <v-expansion-panel-header>{{ fieldLabel }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <div>
        <div class="table-filter__actions">
          <div
            v-if="selectAll"
            class="table-filter__action"
            @click="selectAllOptions()"
          >
            Select All
          </div>
          <div
            v-if="reset && resettable"
            class="table-filter__action"
            @click="resetOptions()"
          >
            Reset
          </div>
        </div>
        <div class="table-filter__options">
          <div
            v-for="option in localListOptions[fieldName]"
            :key="`filter-${option.name}`"
          >
            <Checkbox :label="option.label" v-model="option.value" />
          </div>
        </div>
        <v-btn elevation="0" @click="submit()" color="primary">Submit</v-btn>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    listOptions: Object,
    fieldName: String,
    fieldLabel: String,
    selectAll: Boolean,
    reset: Boolean,
    defaultListOptions: Object,
  },
  data() {
    return {
      localListOptions: { ...this.listOptions },
    };
  },
  methods: {
    submit() {
      this.$emit("filterUpdate", {
        fieldName: this.fieldName,
        option: this.localListOptions[this.fieldName],
      });
    },
    selectAllOptions() {
      this.localListOptions[this.fieldName].forEach((option) => {
        option.value = true;
      });
    },
    resetOptions() {
      if (this.defaultListOptions)
        this.localListOptions[this.fieldName] = [
          ...this.defaultListOptions[this.fieldName],
        ];
    },
  },
  computed: {
    resettable() {
      return (
        this.reset &&
        JSON.stringify(this.localListOptions[this.fieldName]) !=
          JSON.stringify(this.defaultListOptions[this.fieldName])
      );
    },
  },
  watch: {
    listOptions: {
      deep: true,
      handler() {
        if (
          JSON.stringify(this.listOptions[this.fieldName]) !=
          JSON.stringify(this.localListOptions[this.fieldName])
        ) {
          this.localListOptions = { ...this.listOptions };
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.table-filter {
  &__actions {
    display: flex;
    color: #e12d1b;
    font-size: 12px;
    div {
      margin-right: 1em;
    }
  }
  &__action {
    cursor: pointer;
  }
}
</style>
