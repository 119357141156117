<template>
  <div class="payments-table__pagination" v-if="totalPages && totalPages > 1">
    <v-btn
      icon
      aria-label="First page"
      class="v-pagination__navigation payments-table__pagination__first-last"
      :class="{ 'v-pagination__navigation--disabled': page == 1 }"
      @click="localPage = 1"
    >
      <v-icon light size="24">$chevron_double_left</v-icon>
    </v-btn>
    <v-pagination
      v-model="localPage"
      :length="totalPages"
      :total-visible="['sm', 'xs'].includes($size) ? 3 : 5"
      show-first-last-page
      light
      elevation="0"
      @input="localPage = $event"
    ></v-pagination>
    <v-btn
      icon
      aria-label="Last page"
      class="v-pagination__navigation payments-table__pagination__first-last"
      :class="{ 'v-pagination__navigation--disabled': page == totalPages }"
      @click="localPage = totalPages"
    >
      <v-icon light size="24">$chevron_double_right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    totalPages: Number,
  },
  data() {
    return {
      localPage: this.page || 1,
    };
  },
  mounted() {
    this.localPage = this.page;
  },
  watch: {
    localPage(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-table__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  &::v-deep {
    .v-pagination__item,
    .v-pagination__navigation {
      border-radius: 20px;
      box-shadow: none;
      border: 1px solid #dcdce1;
      font-family: "Open Sans", sans-serif;
      font-weight: 600 !important;
      font-size: 13px !important;
    }
  }

  &__first-last {
    height: 32px !important;
    width: 32px !important;
    margin-left: 4px;
    margin-right: 4px;
  }
}
</style>
