<template>
  <!-- <TextField :label="label" :value="value" @input="$emit('input', $event)" /> -->
  <Uploader :rules="rules" :value="value" @input="$emit('input', $event)" />
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, File, Object],
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
