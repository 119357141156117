<template>
  <v-expansion-panel class="form-panel" :class="{'form-panel--light': light}">
    <v-expansion-panel-header>
      <div>
        <slot name="label">{{ label }}</slot>
        <slot name="additional" v-if="additional">
          <div class="form-panel__additional">{{ additional }}</div>
        </slot>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    label: String,
    closed: Boolean,
    additional: String,
    light: Boolean
  },
};
</script>

<style scoped lang="scss">
.form-panel {
  &.v-expansion-panel:before {
    box-shadow: none !important;
  }

  &.v-expansion-panel:after {
    display: none !important;
  }

  &::v-deep {
    .v-expansion-panel-header {
      color: var(--v-primary-base);
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      border-bottom: 2px solid #262729;
      padding: 26px 40px;
      @media screen and (max-width: 960px) {
        padding: 26px 33px;
      }
      .v-icon__svg {
        height: 34px;
        width: 34px;
      }
    }
    .v-expansion-panel-content {
      border-bottom: 2px solid #262729;
    }
    .v-expansion-panel-content__wrap {
      padding: 30px 40px;
      @media screen and (max-width: 960px) {
        padding: 30px 33px;
      }
    }
  }

  &.v-expansion-panel:not(.v-expansion-panel--active):last-child
    .v-expansion-panel-header {
    margin-bottom: 5px;
  }

  &__additional {
    font-size: 13px;
    line-height: 1.2;
    color: var(--v-text-base);
    font-weight: normal;
    margin-top: .5em;
    &:not(:empty) {
      margin-bottom: .25em
    }
  }
  &--light {
    .form-panel__additional {
      color: #56565A;
    }
  }
}
</style>
