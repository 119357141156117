<template>
  <TagSelector
    :multiple="multiple"
    :items="pages"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    condition: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      pages: [],
    };
  },
  created() {
    this.setPages();
  },
  methods: {
    async setPages() {
      const { data } = await this.$api.Page.lite();
      this.pages = data.filter(page => this.condition(page));
    },
  },
};
</script>

<style></style>
