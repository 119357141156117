var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"presentation-slides"},[_c('v-btn',{staticClass:"presentation-slides__prev",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("$arrow_left")])],1),_c('swiper',{attrs:{"options":_vm.swiperMiniOptions}},_vm._l((_vm.slides),function(slide){return _c('swiper-slide',{key:slide.id,staticClass:"presentation-slide",class:{
        active:
          slide.id == _vm.presentation.active_slide_id &&
          _vm.presentation.broadcasting,
      },style:({
        backgroundImage: ("url(" + (slide.preview ? slide.preview.thumb.url : '') + ")"),
      })},[_c('div',{staticClass:"presentation-slide__title",on:{"click":function($event){_vm.$emit('input', slide);
          _vm.$emit('setSlide', slide);}}},[_c('div',{staticClass:"presentation-slide__slide-type"},[(slide.slide_type == 'youtube')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("$youtube")]):(slide.slide_type == 'vimeo')?_c('v-icon',{attrs:{"color":"#1bb6ea"}},[_vm._v("$vimeo")]):_vm._e()],1),_vm._v(" "+_vm._s(slide.title)+" ")])])}),1),_c('v-btn',{staticClass:"presentation-slides__next",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("$arrow_right")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }