<template>
  <div class="payments-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="payments-page__my-creators-header">
      <div class="page-title">My Creator Payments</div>
      <div class="payments-page__actions">
        <v-btn
          color="primary"
          @click="payDirectlyDialogOpen = true"
          elevation="0"
          class="payments-page__pd-btn"
        >
          Pay Directly
        </v-btn>
      </div>
    </div>
    <CreatorsSection
      v-if="myActiveCreators"
      :value="myActiveCreators"
      light
      hmActions
    />
    <div :key="`payments-sections-wrapper-${reloadKey}`">
      <PaymentsSection
        title="Pending Payments"
        :key="`pending-section-${reloadKey}`"
      />
      <PaymentsSection
        title="Completed Payments"
        :key="`completed-section-${reloadKey}`"
        completedPayments
      />
    </div>
    <div class="payments__go-to-all-gigs">
      <div class="page-title">Go to All Projects</div>
      <router-link to="/payments/all-gigs">
        <v-icon color="white" size="58">$arrow_right</v-icon>
      </router-link>
    </div>
    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="close()">
          <v-icon>$close</v-icon>
        </v-btn>
        <InternalInvoice
          v-if="urlParamInvoice"
          :value="urlParamInvoice.id"
          @refresh="urlParamDialogRefresh"
        />
      </v-card>
    </v-dialog>

    <HmPayDirectlyDialog
      :dialog="payDirectlyDialogOpen"
      @refresh="refresh"
      @close="payDirectlyDialogOpen = false"
    />
  </div>
</template>

<script>
import PaymentsSection from "./payments/section.payments";
import InternalInvoice from "@/components/work-assignments/InternalInvoice";
import HmPayDirectlyDialog from "./HmPayDirectlyDialog";

export default {
  name: "PaymentsPage",
  components: {
    PaymentsSection,
    InternalInvoice,
    HmPayDirectlyDialog,
  },
  head: {
    title: "Payments",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      myActiveCreators: null,
      dialog: false,
      urlParamInvoice: null,
      reloadKey: 1,
      payDirectlyDialogOpen: false,
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "InvoiceHmChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  mounted() {
    this.fetchMyActiveCreators();
    if (this.$route.query.invoice) {
      this.fetchUrlParamInvoice(this.$route.query.invoice);
    }
  },
  methods: {
    refresh() {
      this.close();
      this.reloadKey++;
    },
    async fetchMyActiveCreators() {
      const { data } = await this.$api.Creator.sections({
        section_name: "my_active",
      });
      this.myActiveCreators = data[0];
    },
    async fetchUrlParamInvoice(id) {
      const { data } = await this.$api.Invoice.get({ id });
      this.urlParamInvoice = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$router.replace({ query: null });
    },
    urlParamDialogRefresh() {
      this.close();
      this.refresh();
    },
    async handleInvoiceBroadcast(type, payload) {
      if (
        !payload.transaction_id ||
        payload.transaction_id != this.$store.getters["invoice/transactionId"]
      ) {
        this.refresh();
        switch (type) {
          case "submit":
            this.$alert("A new invoice has arrived! 🎉");
            break;
          default:
            this.$alert("Updating invoices.");
        }
      }
    },
  },
  computed: {
    loading() {
      return this.loadingList || this.loadingCompletedList;
    },
  },
  channels: {
    InvoiceHmChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "InvoiceHmChannel",
    });
  },
};
</script>

<style scoped lang="scss">
.payments {
  margin-top: 50px;
  border-radius: 10px;

  &-page {
    &__my-creators-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 38px;
      align-items: center;
    }
    &__pd-btn {
      margin-left: 30px;
    }
    @media screen and (max-width: 960px) {
      &__my-creators-header {
        flex-direction: column;
        align-items: flex-start;
      }
      &__pd-btn {
        margin-left: 0;
      }
    }
  }

  &__header {
    padding: 25px 40px;
    background: white;
    border: 2px solid #262729;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 960px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    color: #e12d1b;
  }
  &__load-more {
    display: flex;
    justify-content: center;
    padding-top: 45px;
  }

  &__go-to-all-gigs {
    background: white;
    border: 2px solid #262729;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    .page-title {
      margin: 15px 15px 15px 35px;
    }
    a {
      display: flex;
      background: #2ebfed;
      border-radius: 0 8px 8px 0;
      flex-basis: 258px;
      align-items: center;
      justify-content: center;
    }
  }
}

.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
}

.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
