<template>
  <div class="pfrf">
    <v-form>
      <div class="pfrf__title">For Review</div>
      <div class="pfrf__top">
        <div class="pfrf__project-code">
          <div class="pfrf__label">Project Code</div>
          <v-text-field class="pfrf__textfield" v-model="form.project_code"></v-text-field>
        </div>
        <div class="pfrf__rating">
          <div class="pfrf__label">Rating</div>
          <ProfileRating
            :value="form.rating"
            clickable
            @input="form.rating = $event"
            class="pfrf__rating-select"
          />
        </div>
      </div>
      <div class="pfrf__bottom">
        <div class="pfrf__label">Reviewer Notes</div>
        <div class="pfrf__tip">How was your experience with this creator? Did they meet the desired outcome? Examples: Technical expertise, communication, reliability, etc.</div>
        <v-textarea class="pfrf__textarea" v-model="form.notes"></v-textarea>
      </div>
    </v-form>
  </div>
</template>
<script>
import ProfileRating from "@/components/profile-review/ProfileRating.vue";
export default {
  name: "ProfileForReviewForm",
  components: { ProfileRating },
  props: {
    value: Object,
    form: Object
  },
}
</script>

<style lang="scss" scoped>
.pfrf {
  color: var(--v-dark-base);

  &__title {
    color: var(--v-primary-base);
    font-weight: 900;
    font-size: 20px;
    line-height: 1.0;
    margin-bottom: 33px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    font-weight: 700;
  }

  &__project-code {
    min-width: 168px;
    margin-right: 40px;
  }

  &__textarea::v-deep, &__textfield::v-deep {
    .v-input__slot {
      border: 1px solid #DCDCE1;
      border-radius: 10px;
      padding: 16px;
    }
    &.v-text-field > .v-input__control > .v-input__slot:before {
      border: none;
    }
  }
  &__textfield::v-deep {
    margin-top: 0;
    .v-input__slot {
      padding: 5px 16px;
    }
    &.v-input input {
      font-weight: 300;
      font-size: 20px !important;
    }
  }
  &__rating-select {
    margin-top: 8px;
  }
}
</style>