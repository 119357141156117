import { render, staticRenderFns } from "./WorkExample.vue?vue&type=template&id=da530898&scoped=true&"
import script from "./WorkExample.vue?vue&type=script&lang=js&"
export * from "./WorkExample.vue?vue&type=script&lang=js&"
import style0 from "./WorkExample.vue?vue&type=style&index=0&id=da530898&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da530898",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VIcon,VResponsive})
