<template>
  <div class="all-gigs-page">
    <router-link class="back-to-payments" to="/payments">
      <v-icon size="50">$arrow_left</v-icon>
    </router-link>

    <PaymentsAllGigsTable
      :items="activeList"
      title="My Creators’ Active Projects"
    />
    <PaymentsAllGigsTable
      :items="list"
      title="Completed Gigs"
      :showCreators="false"
    />
    <TablePagination
      :page="page"
      :totalPages="totalPages"
      @input="page = $event"
    />
  </div>
</template>

<script>
import PaymentsAllGigsTable from "./all-gigs/table.all-gigs";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";

export default {
  name: "PaymentsAllGigsPage",
  mixins: [paginatedTable],
  components: {
    PaymentsAllGigsTable,
    TablePagination,
  },
  head: {
    title: "Payments | All Gigs",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      activeList: [],
      list: [],
      loadingCompletedList: false,
      completedListDone: false,
    };
  },
  mounted() {
    this.fetchActive();
    this.fetchCompleted();
  },
  methods: {
    async fetchActive() {
      const { data } = await this.$api.WorkAssignment.hmGigs({ active: true });
      this.activeList = data.projects;
    },
    async fetchCompleted() {
      this.loadingCompletedList = true;
      const { data } = await this.$api.WorkAssignment.hmGigs({
        completed: true,
        page: this.page,
      });
      this.list = data.projects;
      this.loadingCompletedList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.all-gigs-page {
  &__load-more {
    display: flex;
    justify-content: center;
    padding-top: 45px;
  }
}
</style>
