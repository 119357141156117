<template>
  <div>
    <v-dialog
      width="1346"
      v-model="dialog"
      @click:outside="cancel"
      content-class="pay-directly-dialog payments-dialog"
      light
    >
      <v-card class="pay-directly__content">
        <div class="pay-directly__header">
          <div class="pay-directly__title">Pay Directly</div>
          <v-btn class="close" icon @click="cancel">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="pay-directly__form">
          <v-form
            @submit.prevent="submitPR"
            ref="form"
            class="invoice-form-form"
          >
            <div>
              *
              <span class="invoice-form__required-hint"
                >Indicates required fields</span
              >
            </div>
            <div>
              <div class="form-row form-row-wide">
                <div class="form-label">Creator*</div>
                <div class="form-field">
                  <UsersSelector
                    v-model="form.user_id"
                    placeholder="Creator"
                    :rules="[validation.required]"
                  />
                </div>
              </div>
              <div class="form-row form-row-wide"></div>
            </div>

            <EditInvoiceForm
              :form="form"
              :total="total"
              @addInvoiceItem="addInvoiceItem($event)"
              @updateField="updateInvoiceField($event)"
              :key="`pay-directly-${form.user_id}`"
              :class="{ 'pay-directly-dialog--disabled': !form.user_id }"
              hideHeader
              @addInvoiceAttachment="addInvoiceAttachment($event)"
              @removeInvoiceAttachment="removeInvoiceAttachment($event)"
            >
              <template v-slot:invoiceItems>
                <div class="invoice-form__items">
                  <EditInvoiceItem
                    v-for="(item, idx) in form.invoice_items"
                    :key="`invoice-item_${idx}_${item.work_assignment_id}_${item._destroy}`"
                    class="invoice-form__item"
                    :item="item"
                    :idx="idx"
                    :deleteDisabled="form.invoice_items.length == 1"
                    @input="updateInvoiceItem($event, idx)"
                    @removeInvoiceItem="removeInvoiceItem($event)"
                  >
                    <template v-slot:workAssignmentSelector>
                      <FiWorkAssignmentSelector
                        v-model="item.work_assignment_id"
                        :userId="form.user_id"
                        :rules="[validation.required]"
                        @input="updateItemWorkAssignment($event, idx)"
                        :key="`work_assignments-${idx}-${form.user_id}`"
                      />
                    </template>
                  </EditInvoiceItem>
                </div>
              </template>
            </EditInvoiceForm>

            <div class="invoice-form__footer">
              <v-btn
                type="submit"
                color="#7B61FF"
                elevation="0"
                class="btn-submit"
              >
                Submit
              </v-btn>
              <v-btn
                @click="cancel"
                elevation="0"
                color="white"
                v-ripple="false"
                class="btn-cancel"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InvoiceFormMixin from "@/mixins/invoices/invoice-form.mixin";
import EditInvoiceForm from "@/components/work-assignments/EditInvoiceForm";
import EditInvoiceItem from "@/components/work-assignments/EditInvoiceItem";
import FiWorkAssignmentSelector from "./FiWorkAssignmentSelector";
import { v4 as uuidv4 } from "uuid";

export default {
  mixins: [InvoiceFormMixin],
  components: {
    EditInvoiceForm,
    EditInvoiceItem,
    FiWorkAssignmentSelector,
  },
  props: {
    dialog: Boolean,
    value: String,
    user: Object,
  },
  data() {
    return {
      form: {
        invoice_items: [],
        invoice_attachments: [{ body: null }],
      },
      dueDateMenu: false,
    };
  },
  mounted() {
    if (this.value) this.fetchInvoice();
    else this.form = this.getInitForm();
  },
  methods: {
    async fetchInvoice() {
      const { data } = await this.$api.Invoice.get({ id: this.value });
      this.invoice = data;
      if (this.invoice && this.invoice.user.id) {
        this.form.user_id = this.invoice.user.id;
        this.form.invoice_items[0].work_assignment_id = this.invoice.gigs[0].id;
      }
    },
    async submitPR() {
      if (this.$refs.form.validate()) {
        this.form.transaction_id = uuidv4();
        this.$store.commit(
          "invoice/setTransactionId",
          this.form.transaction_id
        );
        this.form.invoice_items.forEach((item, idx) => (item.position = idx));
        this.form.invoice_attachments = this.form.invoice_attachments.filter(
          (att) => att.body
        );
        this.form.invoice_attachments.forEach(
          (item, idx) => (item.position = idx)
        );
        const result = await this.$api.Invoice.create(this.form);
        console.info("result", result);
        if (result.status == 200) {
          this.$alert("Payment request submitted successfullly", "success");
          this.cancel();
          this.$emit("refresh");
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    getInitForm() {
      const form = {
        user_id: null,
        role: "finance", // people_team acts as finance
        invoice_items: [
          {
            rate_type: "flat_fee",
          },
        ],
        invoice_attachments: [{ body: null }],
      };
      if (!this.value && this.user) form.user_id = this.user.id;
      return form;
    },
    cancel() {
      this.$emit("close");
      this.form = this.getInitForm();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/payments/payments-dialog.scss";
</style>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";
@import "@/styles/payments/pay-directly-dialog.scss";
</style>
