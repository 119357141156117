<template>
  <div class="my-payments-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">My Payments</div>
    <MyPaymentsHeader :earned="earned" />
    <div
      class="page-subtitle"
      v-if="!currentUser.onboarded"
      style="margin-top: 2em"
    >
      Please check your
      <router-link to="/my-agreements">My Agreements</router-link> page to
      ensure you have completed all standard onboarding paperwork (MSA, W-9, ACH
      Authorization) and then return to this page to submit invoices.
      <br />
    </div>

    <MyInvoices
      @earned="earned = $event"
      v-if="currentUser.onboarded"
      :key="`my-invoices-${reloadKey}`"
      @checkUrlParamInvoice="checkUrlParamInvoice($event)"
    />

    <!-- <MyGigsTable v-if="currentUser.onboarded" /> -->

    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="close()">
          <v-icon>$close</v-icon>
        </v-btn>
        <MyInvoice
          v-if="urlParamInvoice"
          :value="urlParamInvoice.id"
          @refresh="refresh"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyInvoices from "./my-payments/my-invoices.my-payments";
import MyPaymentsHeader from "./my-payments/header.my-payments";
import MyInvoice from "./my-payments/MyInvoice";
//import MyGigsTable from "./my-payments/my-gigs-table.my-payments";

export default {
  name: "MyPaymentsPage",
  components: {
    MyInvoices,
    MyPaymentsHeader,
    MyInvoice,
    //MyGigsTable,
  },
  head: {
    title: "My Payments",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      earned: null,
      dialog: false,
      urlParamInvoice: null,
      reloadKey: 1,
    };
  },
  mounted() {
    if (this.$route.query.invoice) {
      this.fetchUrlParamInvoice(this.$route.query.invoice);
    }
  },
  methods: {
    async fetchUrlParamInvoice(id) {
      const { data } = await this.$api.Invoice.get({ id });
      this.urlParamInvoice = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$router.replace({ query: null });
    },
    refresh() {
      this.close();
      this.reloadKey++;
    },
    checkUrlParamInvoice(data) {
      if (
        data.payload.invoice_id &&
        data.payload.invoice_id == this.urlParamInvoice?.id
      ) {
        this.fetchUrlParamInvoice(data.payload.invoice_id);
        this.$alert("Invoice updated");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
