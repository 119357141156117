<template>
  <div class="request-form">
    <v-btn
      class="request-form__btn"
      @click="dialog = true"
      elevation="0"
      :disabled="disabled"
    >
      Request More Info
    </v-btn>
    <v-dialog
      v-model="dialog"
      width="400"
      content-class="request-dialog"
      @click:outside="close"
    >
      <v-card light>
        <v-btn class="dialog-close" icon @click="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text>
          <div v-if="!submitted">
            <div class="request-form__title">Request More Info</div>
            <div
              class="request-form__previous-notices"
              v-if="previousNotices.length"
            >
              <div
                v-for="notice in previousNotices"
                :key="notice"
                v-html="notice"
              ></div>
            </div>
            <v-form
              @submit.prevent="submit"
              class="request-form__form"
              :key="`request-form--${reloadKey}`"
              ref="form"
            >
              <div class="request-form__field">
                <div class="request-form__field__label">Contact Option:</div>
                <Select
                  :items="selectOptions"
                  v-model="form.selectValue"
                  :rules="[validation.required]"
                />
              </div>
              <div class="request-form__field request-form__field--notes">
                <div class="request-form__field__label">Any Notes?</div>
                <TextArea v-model="form.notes" :rows="5" />
              </div>
              <input
                type="hidden"
                name="submission_id"
                :value="form.submission_id"
              />
              <v-btn elevation="0" type="submit" color="primary">Submit</v-btn>
            </v-form>
          </div>
          <div v-else class="request-form__submitted">
            Thank you! Your request has been submitted.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    submissionId: String,
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      form: {
        submission_id: this.submissionId,
        phone_screening: false,
        send_email: false,
        selectValue: null,
        notes: null,
      },
      previousRequests: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      submitted: false,
      selectOptions: [
        {
          name: "Schedule a phone screen",
          value: "phone_screening",
        },
        {
          name: "Send an email",
          value: "send_email",
        },
      ],
      reloadKey: 0,
    };
  },
  mounted() {
    this.fetchRequests();
  },
  methods: {
    async fetchRequests() {
      const { data } = await this.$api.SubmissionRequest.getMy({
        submission_id: this.submissionId,
      });
      if (data != "none") this.previousRequests = [...data];
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const { status } = await this.$api.SubmissionRequest.create(this.form);
        if (status == 200) {
          this.submitted = true;
          this.fetchRequests();
        }
      }
    },
    timeSpan(time) {
      return `<span class="request-form__date-time-span">${this.$moment
        .tz(time, this.timezone)
        .format("h:mm A")}</span>`;
    },
    dateSpan(time) {
      return `<span class="request-form__date-time-span">${this.$moment
        .tz(time, this.timezone)
        .format("MMM. D, YYYY")}</span>`;
    },
    close() {
      this.dialog = false;
      this.form = {
        ...{
          submission_id: this.submissionId,
          phone_screening: false,
          send_email: false,
          selectValue: null,
          notes: null,
        },
      };
      setTimeout(() => {
        this.submitted = false;
        this.reloadKey++;
      }, 500);
    },
  },
  computed: {
    previousNotices() {
      if (this.previousRequests.length == 0) return [];

      const lastRequest = this.previousRequests[0];
      const lastPhoneRequest = this.previousRequests.find(
        (r) => r.phone_screening
      );
      const lastEmailRequest = this.previousRequests.find((r) => r.send_email);
      const lastDate = this.dateSpan(lastRequest.created_at);
      const lastTime = this.timeSpan(lastRequest.created_at);

      const messages = [
        `You last requested more info on ${lastDate} at ${lastTime}`,
      ];
      if (lastRequest.send_email && lastRequest.phone_screening) {
        messages[0] = `${messages[0]}, including a phone screening and an email.`;
      } else if (lastRequest.send_email) {
        messages[0] = `${messages[0]}, including that an email be sent.`;
      } else if (lastRequest.phone_screening) {
        messages[0] = `${messages[0]}, including a phone screening.`;
      } else {
        messages[0] = `${messages[0]}.`;
      }

      if (!lastRequest.phone_screening && lastPhoneRequest) {
        const lastPhoneDate = this.dateSpan(lastPhoneRequest.created_at);
        const lastPhoneTime = this.timeSpan(lastPhoneRequest.created_at);
        if (lastPhoneRequest == lastEmailRequest) {
          messages.push(
            `You also requested a phone screening and that an email be sent on ${lastPhoneDate} at ${lastPhoneTime}.`
          );
        } else {
          messages.push(
            `You also requested a phone screening on ${lastPhoneDate} at ${lastPhoneTime}.`
          );
        }
      }

      if (
        !lastRequest.send_email &&
        !lastPhoneRequest.send_email &&
        lastEmailRequest &&
        lastEmailRequest != lastRequest &&
        lastEmailRequest != lastPhoneRequest
      ) {
        const lastEmailDate = this.dateSpan(lastEmailRequest.created_at);
        const lastEmailTime = this.timeSpan(lastEmailRequest.created_at);
        messages.push(
          `You also requested that an email be sent on ${lastEmailDate} at ${lastEmailTime}.`
        );
      }
      return messages;
    },
    watchedSelectValue() {
      return this.form.selectValue;
    },
  },
  watch: {
    watchedSelectValue(newValue) {
      switch (newValue) {
        case "phone_screening":
          this.form.phone_screening = true;
          this.form.send_email = false;
          break;
        case "send_email":
          this.form.phone_screening = false;
          this.form.send_email = true;
          break;
        default:
          this.form.phone_screening = false;
          this.form.send_email = true;
      }
    },
  },
};
</script>

<style lang="scss">
.request-dialog {
  border: 2px solid #262729;
  border-radius: 10px;
}
.request-form__date-time-span {
  white-space: nowrap;
}
</style>

<style lang="scss" scoped>
.request-form {
  color: #262729;
  &__btn {
    width: 100%;
    margin-bottom: 21px;
    font-size: 14px;
    color: white;
    background: #4282ff !important;
    border: solid 2px black;
    border-radius: 10px;
    height: 50px !important;
  }
  &__title {
    color: #262729;
    font-size: 20px;
    font-weight: 900;
    padding: 30px 0 8px !important;
  }
  &__field {
    margin: 0 !important;
    &:first-child {
      padding-top: 0 !important;
    }
    &--notes {
      margin: 1em 0 !important;
    }
    &__label,
    .v-label {
      font-weight: 900;
      font-size: 16px;
      color: #262729;
      margin-bottom: 0.25em;
    }
  }
  &__previous-notices {
    margin-bottom: 1.5em;
    padding: 1em;
    background: rgb(255, 255, 146);
    border-radius: 10px;
  }
  &__submitted {
    color: #262729;
    font-size: 24px;
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    padding: 30px 0 8px !important;
  }
}
</style>
