<template>
  <div class="payments-page">
    <a class="back-to-payments" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>

    <div class="creator-profile">
      <div class="creator-profile__header">
        <div class="page-title">Creator Payments Details</div>
        <CreatorPaymentsHeader
          :value="creator"
          v-if="creator"
          @refresh="refresh"
        />
      </div>
      <CreatorPaymentsSection
        :creatorId="$route.params.id"
        title="Pending Payments"
        :key="`pending-section-${reloadKey}`"
      />
      <CreatorPaymentsSection
        :creatorId="$route.params.id"
        title="Completed Payments"
        completedPayments
        :key="`completed-section-${reloadKey}`"
      />
      <!--
      <ProjectTables
        v-if="userId"
        title="Active Projects"
        :userId="userId"
        @refresh="refresh"
      />
      <ProjectTables
        v-if="userId"
        title="Completed Projects"
        completed
        :userId="userId"
        loadMore
        @refresh="refresh"
      />-->
    </div>
  </div>
</template>

<script>
import CreatorPaymentsHeader from "./creator-payments/header.creator-payments";
import CreatorPaymentsSection from "./creator-payments/section.creator-payments";
//import ProjectTables from "./creator-payments/project-tables.creator-payments.vue";
import _ from "lodash";

export default {
  head() {
    let meta = [...this.currentProject.meta_tags];

    meta = _.uniqBy(meta, function (tag) {
      return (tag.name || "") + "_" + (tag.property || "");
    });

    return {
      title: "Creator Payments Details",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        {
          rel: "icon",
          href:
            this.currentProject?.favicon.url ||
            this.activeTheme.favicon.url ||
            "/favicon.ico",
        },
      ],
    };
  },
  components: {
    CreatorPaymentsHeader,
    CreatorPaymentsSection,
    //ProjectTables,
  },
  data() {
    return {
      creator: null,
      reloadKey: 0,
    };
  },
  created() {
    this.setCreator();
    this.$cable.subscribe({
      channel: "InvoiceHmChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  methods: {
    async setCreator() {
      const { data } = await this.$api.Creator.showForPayments({
        id: this.$route.params.id,
      });
      this.creator = data;
    },
    refresh() {
      this.reloadKey++;
    },
    async handleInvoiceBroadcast(type, payload) {
      if (
        !payload.transaction_id ||
        payload.transaction_id != this.$store.getters["invoice/transactionId"]
      ) {
        if (
          payload.creator_id == this.creator.id ||
          (payload.creator_ids && payload.creator_ids.includes(this.creator.id))
        ) {
          this.refresh();
          switch (type) {
            default:
              this.$alert("Updating invoices.");
          }
        }
      }
    },
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
  },
  channels: {
    InvoiceHmChannel: {
      connected() {},
      received({ type, payload }) {
        this.handleInvoiceBroadcast(type, payload);
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "InvoiceHmChannel",
    });
  },
};
</script>

<style scoped lang="scss">
.back-to-payments {
  display: inline-block;
  margin-bottom: 40px;
}
.creator-profile {
  &__header {
    margin-bottom: 100px;
    .page-title {
      margin-bottom: 57px;
    }
    @media screen and (max-width: 960px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 31px;
    }
  }
}

#app.disabled-theme .creator-profile .theme--dark.v-btn {
  color: white;
}
</style>
