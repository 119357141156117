<template>
  <div class="datetime-picker">
    <v-text-field
      :value="displayValue"
      @input="$emit('input', $event)"
      hide-details="auto"
      :rounded="rounded"
      outlined
      :placeholder="placeholder"
      :rules="rules"
      :light="light"
    >
      <template v-slot:append>
        <!-- <v-btn
          v-if="displayValue && displayValue.length"
          icon
          @click="$emit('input', null)"
        >
          <v-icon>$close</v-icon>
        </v-btn> -->
        <v-menu
          v-model="opened"
          :content-class="`datetime-picker__menu ${
            openedDt ? 'datetime-picker__menu--visible' : ''
          }`"
        >
          <template v-slot:activator="{ on }">
            <v-btn class="datetime-picker__picker" icon v-on="on">
              <v-icon>$calendar</v-icon>
            </v-btn>
          </template>
          <VueCtkDateTimePicker
            v-if="openedDt"
            inline
            :value="inputValue"
            locale="en"
            @input="setDate"
            @close="close"
            color="#E12D1B"
            button-color="#E12D1B"
            :timezone="timezone"
            :only-date="onlyDate"
            :start-year="startYear"
            :end-year="endYear"
          >
          </VueCtkDateTimePicker>
        </v-menu>
      </template>
      <template v-slot:prepend-inner>
        <div :style="{ background: value }" class="datetime-picker__box"></div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import VueCtkDateTimePicker from "./VueCtkDateTimePicker/index";

export default {
  props: {
    placeholder: String,
    label: String,
    value: [String, Date],
    rounded: {
      type: Boolean,
      default: true,
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    startYear: { type: Number, default: null },
    endYear: { type: Number, default: null },
    light: Boolean
  },
  data() {
    return {
      displayValue: "",
      inputValue: "",
      opened: false,
      openedDt: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },
  methods: {
    setDate($event) {
      const momentDate = this.$moment(
        $event,
        "YYYY-MM-DD h:mm A",
        this.timezone
      );
      this.$emit("input", momentDate.toDate());
      this.displayValue = $event;
      this.inputValue = $event;
    },
    close() {
      this.opened = false;
    },
  },
  components: { VueCtkDateTimePicker },
  watch: {
    value: {
      handler() {
        if (this.value) {
          let momentDate = this.$moment.tz(this.value, this.timezone);
          if (this.onlyDate) momentDate = momentDate.startOf('day');

          this.displayValue = momentDate.format(
            this.onlyDate ? "LL" : "YYYY-MM-DD h:mm A"
          );
          this.inputValue = momentDate.format("YYYY-MM-DD h:mm A");
        } else {
          this.displayValue = "";
          this.inputValue = "";
        }
      },
      immediate: true,
    },
    opened() {
      setTimeout(() => {
        this.openedDt = this.opened;
        window.dispatchEvent(new Event('resize')); // fix position when opened from a dialog
      }, 50);
    },
  },
};
</script>

<style scoped lang="scss">
.datetime-picker {
  &__menu {
    width: 420px;
    visibility: hidden;

    &--visible {
      visibility: visible;
    }
  }

  &::v-deep {

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    .v-input__append-inner {
      margin-top: 11px;
    }
    .v-input__slot {
      padding-right: 10px;
      padding-left: 10px !important;
    }

    .time-picker {
      max-width: 160px;
    }
    
    .v-input__append-inner {
      position: absolute;
      right: 0;
      width: 100%;
      justify-content: flex-end;
      padding-right: 0.75em;
    }
    .datetime-picker__picker {
      width: 100%;
      justify-content: flex-end;
      position: relative;
      &:before {
        right: -5px;
        left: unset;
      }
      .v-ripple__container {
        width: 36px;
        right: -5px;
        left: unset;
      }
    }
  }
}
</style>
