<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="items"
    :chips="multiple"
    outlined
    min-height="40"
    :label="label"
    :multiple="multiple"
    class="tag-selector"
    :search-input.sync="localSearch"
    :class="{
      multiple,
      'hidden-input': multiple && maximum && value && value.length >= maximum,
    }"
    :item-text="itemText"
    :item-value="itemValue"
    :deletable-chips="multiple"
    :clearable="!multiple"
    hide-details="auto"
    :rules="rules"
    :menu-props="{
      closeOnContentClick:
        multiple && maximum && value && value.length >= maximum,
      light,
      contentClass: menuClass,
    }"
    dense
    :append-icon="
      !appendIcon && appendIcon !== '' ? '$chevron_down' : appendIcon
    "
    :placeholder="placeholder"
    elevation="0"
    :light="light"
    :disabled="disabled"
  >
    <template v-if="chipText" v-slot:selection="data">
      <v-chip v-bind="data.attrs" close @click:close="remove(data.item)">{{
        data.item[chipText]
      }}</v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Number, Array],
    multiple: Boolean,
    maximum: Number,
    returnObject: Boolean,
    disabled: Boolean,
    search: String,
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: [Number, String],
      default: "id",
    },
    items: {
      type: Array,
      default: () => [],
    },
    appendIcon: String,
    placeholder: String,
    light: Boolean,
    chipText: String,
    menuClass: String,
  },
  data() {
    return {
      localSearch: "",
    };
  },
  methods: {
    remove(item) {
      this.$emit("remove", item[this.itemValue]);
    },
  },
  watch: {
    localSearch(val) {
      this.$emit("update:search", val);
    },
  },
};
</script>

<style scoped lang="scss">
.tag-selector {
  width: 100%;

  &.multiple {
    max-width: 100%;
  }

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    &.hidden-input input {
      display: none;
    }
    &.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 40px;
      padding: 10px 0 6px;
      .v-chip {
        margin: 0 4px 4px 4px;
      }
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 50px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
      margin-top: 14px !important;
    }

    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }

    label {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.38);
    }
    &.v-text-field--outlined.v-input--dense {
      .v-label {
        top: 14px;
      }
      .v-label--active {
        transform: translateY(-21px) scale(0.75);
      }
    }
    input::placeholder {
      color: #262729;
      font-weight: 900;
    }
  }
}
</style>
