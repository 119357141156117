<template>
  <div>
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div v-if="gig" class="show-gig">
      <div class="show-gig__header">
        <div class="page-title">Gig Details</div>

        <div class="show-gig__header-actions">
          <div class="show-gig__status">Status: {{ gig.status }}</div>
        </div>
      </div>
      <div class="edit-gig">
        <v-container class="pa-0">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader"
                    >Creator:</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <router-link :to="`/p/${gig.username || gig.user_id}`">
                    {{ gig.user_name }}
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader">Gig:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.title }}</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader"
                    >Hiring Manager:</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.hiring_manager.display_name }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader">
                    Project Code:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <router-link
                    :to="`/project/${gig.internal_project_id}/members`"
                  >
                    {{ gig.project_code }}
                  </router-link>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader">Role:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.role }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader">
                    Start Date:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.start_date }}</div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader"
                    >End Date:</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.end_date }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader">
                    Billing Category:
                  </v-subheader>
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.qb_expense_category_name }}</div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6">
              <v-row>
                <v-col cols="3">
                  <v-subheader class="show-gig__subheader"
                    >Team/Department:</v-subheader
                  >
                </v-col>
                <v-col cols="9">
                  <div>{{ gig.team }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="d-flex align-center">
            <v-col cols="4">
              <v-row>
                <v-col cols="6" class="d-flex align-center">
                  <v-subheader class="show-gig__subheader">
                    Rate Type:
                  </v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-row class="d-flex align-center">
                    <v-col cols="5" class="d-flex align-center">
                      <div>{{ rateTypeLabels[gig.rate_type] }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col cols="6">
                  <v-row v-if="gig.rate_type == 'flat_fee'">
                    <v-col
                      cols="6"
                      :class="{ 'show-gig__empty-field': !gig.amount }"
                    >
                      <v-subheader class="show-gig__subheader"
                        >Payment Amount (USD):</v-subheader
                      >
                    </v-col>
                    <v-col
                      cols="6"
                      :class="{ 'show-gig__empty-field': !gig.amount }"
                    >
                      <div v-if="gig.amount">
                        {{ gig.amount | currency }}
                      </div>
                      <div v-else>"Not set"</div>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col
                      cols="6"
                      :class="{ 'show-gig__empty-field': !gig.rate }"
                    >
                      <v-subheader class="show-gig__subheader"
                        >{{ rateTypeLabels[gig.rate_type] }} Rate</v-subheader
                      >
                    </v-col>
                    <v-col cols="6">
                      <div>{{ gig.rate | currency }}</div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row v-if="gig.rate_type != 'flat_fee'">
                    <v-col
                      cols="6"
                      :class="{ 'show-gig__empty-field': !gig.rate }"
                    >
                      <v-subheader class="show-gig__subheader">
                        Estimated Total
                        {{ rateUnitsLabels[gig.rate_type] }}:</v-subheader
                      >
                    </v-col>
                    <v-col cols="6">
                      <div>{{ gig.rate_units }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <div class="show-gig__row" v-if="gig.company_name">
          <div class="show-gig__subheader">Company Name:</div>
          <div class="show-gig__value">{{ gig.company_name }}</div>
        </div>
        <div class="show-gig__row" v-if="gig.description">
          <div class="show-gig__subheader">Notes/Description:</div>
          <div class="show-gig__value">{{ gig.description }}</div>
        </div>
      </div>
      <div class="gig-actions">
        <v-btn
          @click="cancelGig()"
          color="#E12D1B"
          elevation="0"
          light
          :disabled="gig.is_cancelled"
          >Cancel Gig</v-btn
        >
      </div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";

export default {
  mixins: [InvoiceLabelsMixin],
  head: {
    title: "Gig Details",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      gig: null,
    };
  },
  created() {
    this.setGig();
  },
  methods: {
    async setGig() {
      const { data } = await this.$api.WorkAssignment.get({
        id: this.$route.params.id,
      });
      this.gig = data;
    },
    async cancelGig() {
      const res = await this.$api.WorkAssignment.cancel({
        id: this.$route.params.id,
      });
      if (res.status == 200) {
        this.$alert("Gig canceled successfully.");
        this.setGig();
      } else {
        this.$alert("Something went wrong", "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show-gig {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__header-actions {
    display: flex;
  }
  &__status {
    font-weight: bold;
    margin-right: 1.5em;
  }
  &__subheader {
    color: var(--black, #262729);
    font-feature-settings: "clig" off, "liga" off;
    /* H3 Bold */
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
    padding: 0;
    height: auto;
    padding-top: 0.3em;
  }
  &__empty-field {
    opacity: 0.5;
  }
  .col-3,
  .col-6,
  .col-9 {
    padding: 6px 12px;
  }
}

.gig-actions {
  margin-top: 3em;
  .v-btn {
    &:not(:last-child) {
      margin-right: 1.5em;
    }
    &:not(.v-btn--disabled) {
      color: white;
    }
  }
}
</style>
