<template>
  <v-dialog max-width="400" v-model="dialog" persistent>
    <v-card light class="are-you-sure-dialog">
      <slot></slot>
      <div class="are-you-sure-dialog__actions">
        <v-btn
          @click="$emit('yes')"
          color="#262729"
          dark
          elevation="0"
          :disabled="yesDisabled"
        >
          <slot name="yes">Yes</slot>
        </v-btn>
        <v-btn @click="$emit('no')" color="#262729" dark elevation="0">
          <slot name="no">No</slot>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    yesDisabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.are-you-sure-dialog {
  font-weight: bold;
  text-align: center;
  padding: 35px;
  .spinner.relative {
    min-height: 100px;
  }
  &__actions > *:not(:last-child) {
    margin-right: 1em;
  }
  ::v-deep
    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #272727 !important;
  }
}
</style>
