<template>
  <div class="vimeo-player">
    <iframe
      :src="`https://player.vimeo.com/video/${slide.vimeo_id}`"
      frameborder="0"
      allow="fullscreen"
      allowfullscreen
      ref="vimeo"
    ></iframe>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  props: {
    slide: Object,
    started: Boolean,
    value: Object,
  },
  mounted() {
    var player = new Player(this.$refs.vimeo);

    this.$emit("input", player);

    player.on("play", async () => {
      if (!this.started) {
        this.$emit("setStarted", true);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.vimeo-player {
  height: calc(100% - 55px);
  background: black;

  &,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  iframe {
    height: 100%;
  }
}
</style>
