<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="dialog"
    class="pn-dialog"
    width="800"
  >
    <v-card class="pn-dialog__card">
      <v-btn class="dialog-close" icon @click="$emit('close')">
        <v-icon>$close</v-icon>
      </v-btn>
      <div
        v-if="item && item.preview"
        class="pn-dialog__side pn-dialog__preview"
      >
        <img :src="item.preview.large.url" width="100%" alt="Notification image"/>
      </div>
      <div
        v-if="item && item.preview"
        class="pn-dialog__side pn-dialog__content"
      >
        <v-card-title class="pn-dialog__title">
          {{ item.title }}
        </v-card-title>

        <v-card-text
          v-html="item.description"
        ></v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileNotificationDialog",
  props: {
    dialog: Boolean,
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.pn-dialog {
  &__title {
    padding-top: 25px !important;
  }
  &__card {
    display: flex;
    align-items: center;
    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }
  &__side {
    width: 50%;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 75vh;
    }
  }
}

</style>
