<template>
  <div class="th-actions" :class="{ 'th-actions--menu-active': filterMenu }">
    <div class="th-actions__orders" v-if="orders">
      <v-btn
        icon
        @click="
          $emit('orderUpdate', {
            fieldName,
            direction: 'ASC',
          })
        "
        class="th-actions__order--up"
        :class="{
          active:
            fieldName == listOptions.order_by &&
            listOptions.order_direction == 'ASC',
        }"
      >
        <v-icon size="20">$menu_up</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="
          $emit('orderUpdate', {
            fieldName,
            direction: 'DESC',
          })
        "
        class="th-actions__order--down"
        :class="{
          active:
            fieldName == listOptions.order_by &&
            listOptions.order_direction == 'DESC',
        }"
      >
        <v-icon size="20">$menu_down</v-icon>
      </v-btn>
    </div>
    <div class="th-actions__filters" v-if="filters">
      <ActionsMenu
        @close="filterMenu = false"
        @toggle="filterMenu = !filterMenu"
        :menu="filterMenu"
        :themeDisabledPage="themeDisabledPage"
        icon="$filter"
        :iconSize="12"
        :title="fieldLabel ? `Filter by ${fieldLabel}` : null"
      >
        <div class="th-actions__filters__menu">
          <div v-if="isDate" class="th-actions__date-options">
            <DateTimePicker
              v-model="localListOptions[fieldName][0]"
              :startYear="2023"
              :endYear="new Date().getFullYear()"
              :rounded="false"
              onlyDate
              light
              @input="onDateUpdate(0)"
            />
            <DateTimePicker
              v-if="dateRange"
              v-model="localListOptions[fieldName][1]"
              :startYear="2023"
              :endYear="new Date().getFullYear()"
              :rounded="false"
              onlyDate
              light
              @input="onDateUpdate(1)"
            />
            <div>
              <div
                @click="clear()"
                class="th-actions__clear"
                v-if="
                  localListOptions[fieldName].filter((item) => item).length > 0
                "
              >
                Clear
              </div>
            </div>
            <div>
              <v-btn
                @click="toggleDateRange()"
                elevation="0"
                color="#262729"
                class="th-actions__date-range-toggle"
                >{{ dateRange ? "Single Date" : "Date Range" }}</v-btn
              >
            </div>
            <v-btn @click="submitDate" elevation="0" color="primary"
              >Submit</v-btn
            >
          </div>
          <div v-else>
            <div
              v-for="option in localListOptions[fieldName]"
              :key="`filter-${option.name}`"
              class="th-actions__filter"
            >
              <Checkbox :label="option.label" v-model="option.value" />
            </div>
            <v-btn
              elevation="0"
              @click="
                $emit('filterUpdate', {
                  fieldName,
                  option: localListOptions[fieldName],
                });
                filterMenu = false;
              "
              color="primary"
              >Submit</v-btn
            >
          </div>
        </div>
      </ActionsMenu>
    </div>
  </div>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
export default {
  mixins: [themeDisabledPageMixin],
  props: {
    orders: Boolean,
    fieldName: String,
    fieldLabel: String,
    filters: Boolean,
    listOptions: Object,
    isDate: Boolean,
  },
  data() {
    return {
      filterMenu: false,
      localListOptions: { ...this.listOptions },
      dateRange: false,
    };
  },
  methods: {
    toggleDateRange() {
      this.dateRange = !this.dateRange;
      if (!this.dateRange) this.localListOptions[this.fieldName][1] = null;
    },
    onDateUpdate(indexChanged) {
      if (
        !this.localListOptions[this.fieldName][0] ||
        !this.localListOptions[this.fieldName][1]
      )
        return;
      if (indexChanged == 0) {
        if (
          this.localListOptions[this.fieldName][0] >
          this.localListOptions[this.fieldName][1]
        )
          this.localListOptions[this.fieldName][1] =
            this.localListOptions[this.fieldName][0];
      }
      if (indexChanged == 1) {
        if (
          this.localListOptions[this.fieldName][1] <
          this.localListOptions[this.fieldName][0]
        )
          this.localListOptions[this.fieldName][0] =
            this.localListOptions[this.fieldName][1];
      }
    },
    submitDate() {
      this.$emit("filterUpdate", {
        fieldName: this.fieldName,
        option: this.localListOptions[this.fieldName],
      });
      this.filterMenu = false;
    },
    clear() {
      this.localListOptions[this.fieldName] = [null, null];
    },
  },
};
</script>

<style lang="scss" scoped>
.th-actions {
  display: flex;
  align-items: center;
  min-height: 30px;

  &::v-deep .v-icon {
    color: white !important;
    height: 15px;
    width: 15px;
  }

  &__orders {
    display: flex;
    flex-direction: column;
    margin-right: 4px;

    .v-btn {
      width: 15px;
      height: 15px;

      .v-icon,
      &.active {
        pointer-events: none;
        .v-icon,
        &::v-deep .v-icon {
          color: #e12d1b !important;
        }
      }

      &:hover::v-deep .v-icon {
        color: #e2e2e2 !important;
      }
    }
  }
  &__order--up {
    margin-bottom: -3.5px;
  }
  &__order--down {
    margin-top: -3.5px;
  }

  &--menu-active {
    visibility: visible !important;
  }

  &__filters {
    height: 30px;
    display: flex;
    align-items: center;
  }

  &__filters__menu {
    padding: 0 1em;

    .date-picker {
      flex-direction: column;
      margin: 0 1em;
      &::v-deep > div {
        width: 100% !important;
        margin-bottom: 0.5em;
      }
    }
  }
  &__date-options {
    > div {
      margin-bottom: 0.5em;
    }
  }
  &__date-range-toggle {
    color: white !important;
  }
  &__clear {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 0.75em;
    > div {
      cursor: pointer;
    }
  }
}
</style>
