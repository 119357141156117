<template>
  <div class="payments-table-wrapper">
    <div class="page-title">{{ title }}</div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w45">Project Code</th>
            <th class="w0 center-align">View</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <FinanceAllProjectsTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="center-align">No projects</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FinanceAllProjectsTableRow from "./table-row.all-projects.vue";

export default {
  components: { FinanceAllProjectsTableRow },
  props: {
    items: Array,
    title: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-wrapper {
    margin-top: 52px;
  }

  &-head {
    color: white;
    background: #7b61ff;
  }
}
</style>
