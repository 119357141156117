<template>
  <div class="my-submissions-page">
    <div class="page-title">Submissions</div>
    <div class="page-subtitle" v-if="list && list.length">
      Keep track of your active Submissions or explore other iU opportunities.
    </div>
    <div class="page-subtitle" v-else>
      Looks like you haven’t submitted to any of our opportunities yet. Head on over to the opportunities page and submit there.
      <br/>
      <br/>
      <v-btn color="primary" to="/opportunities">Go to Opportunities Page</v-btn>
    </div>
    <div v-if="list">
      <div v-if="list.length">
        <div class="my-submissions">
          <div class="my-submissions__header">
            <div class="my-submissions__title">My Submissions</div>
          </div>
          <div class="my-submissions__body">
            <div
              class="my-submission"
              v-for="submission in list"
              :key="submission.id"
            >
              <div class="my-submission__main">
                <a class="my-submission__title" :href="submission.project_url">
                  {{ submission.submission_form.title }}
                </a>
                <div class="my-submission__links">
                  <a @click="showSubmission(submission)">View Submission</a>
                  <a
                    @click="retract(submission)"
                    v-if="
                      submission.submission_form.state == 'submitted' ||
                      submission.submission_form.state == 'in_progress'
                    "
                    >Retract Submission</a
                  >
                  <span v-else>Submission locked</span>
                </div>
                <div class="my-submission__date">
                  {{
                    submission.created_at
                      | moment(
                        "timezone",
                        currentTimezone,
                        "MMM D, YYYY hh:mm a"
                      )
                  }}
                </div>
              </div>
              <div class="my-submission__status">
                <SubmissionStage
                  :submission="submission"
                  :value="submission.submission_form.state"
                />
              </div>
            </div>
          </div>
          <div class="my-submissions__footer">
            <v-btn
              to="/opportunities"
              large
              class="elevation-0"
              color="primary"
            >
              See All Opportunities
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else />

    <v-dialog
      max-width="800"
      v-if="submissionDialog"
      v-model="submissionDialog"
    >
      <v-card>
        <v-card-text class="submission-dialog">

          <v-btn class="close" icon @click="submissionDialog = false">
            <v-icon size="30">$close</v-icon>
          </v-btn>
          <div class="submission-dialog__title">
            {{ submissionDialogContent.submission_form.title }}
          </div>
          <div class="submission-dialog__date">
            Submitted
            {{
              submissionDialogContent.created_at
                | moment("timezone", currentTimezone, "MMM D, YYYY hh:mm a")
            }}
          </div>
          <div class="submission-dialog__right-title">
            Download uploaded content to view
          </div>
          <div class="submission-fields">
            <component
              class="submission-field"
              :key="idx"
              v-for="(field, idx) in formattedData"
              :class="'submission-field--' + field.type"
              :href="field.value.url || field.value"
              target="_blank"
              :is="
                ['MediaField', 'LinkField'].includes(field.type) ? 'a' : 'div'
              "
            >
              <div>
                <div class="submission-field__title">
                  {{ field.short_name || field.label }}
                </div>
                <div
                  class="submission-field__value"
                  v-if="field.type != 'MediaField'"
                  v-text="field.value"
                ></div>
              </div>
              <div class="submission-field__icon">
                <v-icon v-if="field.type == 'MediaField'">$paperclip</v-icon>
                <v-icon v-if="field.type == 'LinkField'">$link</v-icon>
              </div>
            </component>
          </div>
          <!-- {{ submissionDialogContent.formatted_data }} -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IndexPage from "./index-page";

export default {
  name: "MySubmissionsPage",
  extends: IndexPage,
  head: {
    title: "Submissions",
    titleTemplate: '%s | iU Community'
  },
  data() {
    return {
      submissionDialog: false,
      submissionDialogContent: null,
    };
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.Submission.get();

      this.list = data;
    },
    async retract(submission) {
      const confirmed = await this.$confirm({ title: "Are you sure?", description: "This will permanently delete your submission." });
      if (!confirmed) return;
      await this.$api.Submission.destroy({ id: submission.id });
      this.fetchList();
    },
    showSubmission(submission) {
      this.submissionDialogContent = submission;
      this.submissionDialog = true;
    }
  },
  computed: {
    formattedData() {
      return this.submissionDialogContent.formatted_data.filter(
        (t) => t.type != "Checkbox"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.my-submissions {
  margin-top: 50px;
  border-radius: 10px;
  background: white;

  &__header {
    padding: 25px 40px;
    background: white;
    border: 2px solid #262729;
    border-radius: 10px 10px 0 0;
    @media screen and (max-width: 960px) {
      padding: 25px 12px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.0;
    color: #E12D1B;
  }
  &__body {
  }
  &__footer {
    padding: 25px 40px;
    background: white;
    border: 2px solid #262729;
    border-radius: 0 0 10px 10px;
    margin-top: -2px;
  }
}

.my-submission {
  padding: 40px;
  background: white;
  border: 2px solid #262729;
  margin-top: -2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    padding: 25px 12px 16px;
  }

  &__main {
  }

  &__date {
    font-style: italic;
    font-size: 12px;
    margin-top: 15px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
  }

  &__links {
    font-weight: 600;
    font-size: 9px;
    text-transform: uppercase;

    a {
      text-decoration: none;
      margin-right: 20px;
    }
  }

  a.my-submission__title {
    font-weight: bold;
    font-size: 14px;
    color: var(--v-dark-base);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__status {
    flex-basis: 260px;
    @media screen and (max-width: 960px) {
      flex-basis: 100%;
    }
  }
}

.submission-dialog {
  padding: 30px !important;

  &__title {
    color: #E12D1B;
    font-weight: 900;
    font-size: 35px;
    line-height: 1.0;
  }

  &__date {
    color: #262729;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
  }

  &__left {
    width: 50%;
  }
  &__right {
    width: 50%;
    &-title {
      color: #26272977;
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      width: 100%;
    }
  }
}

.submission-fields {
  display: flex;
  flex-wrap: wrap;
}
.submission-field {
  text-decoration: none;
  background: white;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  color: var(--v-dark-base);
  text-decoration: none;
  padding: 8px 13px 8px 20px;
  width: calc(100% - 10px);
  margin: 5px 0;

  &--TextArea {
    height: auto;
  }
  &--TextArea &__value {
    white-space: pre-wrap;
  }

  .v-icon {
    color: var(--v-dark-base);
  }

  &__title {
    font-weight: bold;
  }
  &__value {
    position: relative;
    top: -4px;
  }
}
</style>
