<template>
  <div class="radio-group">
    <div class="radio-group__label">
      {{ title }}<span v-if="required && markRequired" class="radio-group__required">*</span>
    </div>
    <v-radio-group
      class="radio-group__control"
      row
      :value="value"
      hide-details="auto"
      @change="$emit('input', $event)"
      :rules="rules"
      light
    >
      <v-radio
        v-for="item in options"
        :key="item.uuid"
        :label="item.title"
        :value="item.uuid"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: [String, Object, Number, Boolean],
    options: {
      type: Array,
      default: () => []
    },
    rules: Array,
    required: Boolean,
    markRequired: Boolean
  }
};
</script>

<style scoped lang="scss">
.radio-group {
  margin-bottom: 30px;
  &__required {
    color: red;
    margin-left: 3px;
  }
  &__control {
    margin-top: 0;
  }
  &__label {
    font-weight: bold;
  }
}
</style>
