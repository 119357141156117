<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    hidden
    class="dynamic-hidden-field"
  />
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style>
.dynamic-hidden-field {
  visibility: hidden;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
}
</style>
