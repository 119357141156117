<template>
  <div>
    <div class="actions-menu-wrapper">
      <v-overlay :value="menu" @click.native.stop="$emit('close')" />
      <v-menu
        content-class="actions-menu"
        bottom
        :offset-y="false"
        :close-on-click="false"
        :close-on-content-click="false"
        v-model="menu"
        :light="themeDisabledPage"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :size="iconSize"
            @click="$emit('toggle')"
            v-bind="attrs"
            v-on="on"
            :color="color || 'currentColor'"
            class="actions-menu__toggle-icon"
            :disabled="disabled"
            >{{ icon }}</v-icon
          >
        </template>
        <v-card class="actions-menu__content">
          <v-btn class="dialog-close" icon @click="$emit('close')">
            <v-icon>$close</v-icon>
          </v-btn>
          <div class="actions-menu__header">{{ title }}</div>
          <div class="actions-menu__body">
            <div class="actions-menu__main">
              <slot></slot>
            </div>
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: Boolean,
    themeDisabledPage: Boolean,
    color: String,
    icon: {
      type: String,
      default: "$ellipsis_v",
    },
    iconSize: {
      type: Number,
      default: 17,
    },
    light: Boolean,
    title: String,
    disabled: Boolean,
  },
};
</script>

<style scoped lang="scss">
.actions-menu-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  > .v-icon {
    display: block;
  }
}

.actions-menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  width: 245px;
  border-radius: 10px;
  text-align: center;
  box-shadow: none;
  &__content {
    position: relative;
    background: #dcdce1;
  }
  .dialog-close {
    position: absolute;
    top: 0;
    right: 0;
  }
  .v-btn {
    font-weight: bold;
  }
  &__header {
    width: 100%;
    background: white;
    min-height: 36px;
    border-radius: 10px 10px 0 0;
    font-size: 16px;
    font-weight: 900;
    padding: 12px;
  }
  &__main {
    .v-btn:not(.v-btn--icon) {
      min-width: 131px;
      padding: 0 10px;
    }
  }
  &__main {
    padding-top: 27px;
    padding-bottom: 29px;
    :slotted(.v-btn:not(.v-btn--icon):not(:last-child)) {
      margin-bottom: 21px;
    }
  }
  &__toggle-icon {
    transform: none !important;
    display: block;
  }
}
</style>
