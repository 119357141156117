<template>
  <div class="invoice-attachment-form">
    <div class="invoice-attachment-form__body">
      <FileField
        placeholder="Upload"
        :value="value.body"
        @input="$emit('input', $event)"
      />
    </div>
    <div class="invoice-attachment-form__actions">
      <v-btn icon @click="$emit('delete')">
        <v-icon size="24"> $delete </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      openUrlItems: [
        { name: "New Tab", value: "_blank" },
        { name: "Current Tab", value: "_self" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.invoice-attachment-form {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    flex-grow: 1;
    margin-right: 1em;
  }
}
</style>
