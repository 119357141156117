export default {
  data() {
    return {
      work_assignment_selections_invalid: false,
    };
  },
  methods: {
    validateGigSelections() {
      if (
        this.work_assignment_selections.filter((was) => was.value).length > 0
      ) {
        return true;
      } else {
        this.work_assignment_selections_invalid = true;
        return false;
      }
    },
  },
};
