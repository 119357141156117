<template>
  <div class="payments-table-wrapper">
    <div class="page-title">{{ title }}</div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="center-align" :class="showCreators ? 'w20' : 'w45'">
              Project
            </th>
            <th class="w45 center-align" v-if="showCreators">
              Active Creators
            </th>
            <th class="w0 center-align">View</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <PaymentsAllGigsTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            :showCreators="showCreators"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="showCreators ? 3 : 2" class="center-align">
              No Gigs
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PaymentsAllGigsTableRow from "./table-row.all-gigs.vue";

export default {
  components: { PaymentsAllGigsTableRow },
  props: {
    title: String,
    items: Array,
    showCreators: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-head {
    color: white;
    background: #4282ff;
  }
}
</style>
