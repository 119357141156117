<template>
  <div class="offer-form">
    <v-tooltip left v-if="formState == 'submitted'" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            class="offer-form__btn"
            @click="dialog = true"
            elevation="0"
            disabled
            v-bind="attrs"
            v-on="on"
          >
            Make an Offer
          </v-btn>
        </div>
      </template>
      <span
        >This opportunity is still accepting submissions. An offer can't be made
        until the opportunity enters the Review phase.</span
      >
    </v-tooltip>
    <div v-else>
      <v-btn
        class="offer-form__btn"
        @click="dialog = true"
        elevation="0"
        :disabled="disabled || formState != 'review'"
      >
        Make an Offer
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      width="400"
      content-class="offer-dialog"
      @click:outside="close"
    >
      <v-card light>
        <v-btn class="dialog-close" icon @click="close">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text>
          <div v-if="!submitted">
            <div class="offer-form__title">Make an Offer</div>
            <div
              class="offer-form__previous-notice"
              v-if="previousNotice"
              v-html="previousNotice"
            ></div>
            <v-form @submit.prevent="submit" class="offer-form__form">
              <div class="offer-form__field">
                <div class="offer-form__field__label">
                  Is there anything you want the Community Lead to know?
                </div>
                <TextArea v-model="form.notes" :rows="5" />
              </div>
              <input
                type="hidden"
                name="submission_id"
                :value="form.submission_id"
              />
              <v-btn elevation="0" type="submit" color="primary">Submit</v-btn>
            </v-form>
          </div>
          <div v-else class="offer-form__submitted">
            Thank you! Your request has been submitted.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    submissionId: String,
    formState: String,
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      form: {
        submission_id: this.submissionId,
        notes: null,
      },
      previousOffer: null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      submitted: false,
    };
  },
  mounted() {
    this.fetchOffer();
  },
  methods: {
    async fetchOffer() {
      const { data } = await this.$api.SubmissionOffer.getMy({
        submission_id: this.submissionId,
      });
      if (data != "none") this.previousOffer = data;
    },
    async submit() {
      const { status } = await this.$api.SubmissionOffer.create(this.form);
      if (status == 200) {
        this.submitted = true;
        this.fetchOffer();
      }
    },
    timeSpan(time) {
      return `<span class="offer-form__date-time-span">${this.$moment
        .tz(time, this.timezone)
        .format("h:mm A")}</span>`;
    },
    dateSpan(time) {
      return `<span class="offer-form__date-time-span">${this.$moment
        .tz(time, this.timezone)
        .format("MMM. D, YYYY")}</span>`;
    },
    close() {
      this.dialog = false;
      this.form = {
        ...{
          submission_id: this.submissionId,
          notes: null,
        },
      };
      if (this.submitted) this.$emit("refresh");
      setTimeout(() => (this.submitted = false), 500);
    },
  },
  computed: {
    previousNotice() {
      if (!this.previousOffer) return null;
      const lastDate = this.dateSpan(this.previousOffer.created_at);
      const lastTime = this.timeSpan(this.previousOffer.created_at);
      return `You last requested that we make an offer on ${lastDate} at ${lastTime}.`;
    },
  },
};
</script>

<style lang="scss">
.offer-dialog {
  border: 2px solid #262729;
  border-radius: 10px;
}
.offer-form__date-time-span {
  white-space: nowrap;
}
</style>

<style lang="scss" scoped>
.offer-form {
  color: #262729;
  &__btn {
    width: 100%;
    font-size: 14px;
    color: white;
    background: #7b61ff !important;
    border: solid 2px black;
    border-radius: 10px;
    height: 50px !important;
  }
  &__title {
    color: #262729;
    font-size: 20px;
    font-weight: 900;
    padding: 30px 0 8px !important;
  }
  &__field {
    margin-bottom: 1em !important;
    &:first-child {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    &__label,
    .v-label {
      font-weight: 900;
      font-size: 16px;
      color: #262729;
    }
  }
  &__previous-notice {
    margin-bottom: 1.5em;
    padding: 1em;
    background: rgb(255, 255, 146);
    border-radius: 10px;
  }
  &__submitted {
    color: #262729;
    font-size: 24px;
    line-height: 1.4;
    font-weight: bold;
    text-align: center;
    padding: 30px 0 8px !important;
  }
}
</style>
