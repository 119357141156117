export default {
  data() {
    return {
      page: 1,
      totalPages: null,
    };
  },
  watch: {
    page() {
      this.fetchList();
    },
  },
};
