<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="currentUser.allowed_projects"
    :multiple="multiple"
    :class="{ multiple }"
    dense
    chips
    item-text="title"
    item-value="id"
    :deletable-chips="multiple"
    :clearable="!multiple"
    hide-details="auto"
    class="projects-selector"
    :disabled="disabled"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
};
</script>

<style scoped lang="scss">
.projects-selector {
  &::v-deep {
    .v-chip {
      height: 24px;
      margin: 1px;
    }

    .v-select__selections {
      min-height: 26px !important;
    }
  }
}
</style>
