<template>
  <v-dialog @click:outside="cancel" max-width="1000" v-model="dialog">
    <v-card>
      <v-btn class="dialog-close" icon @click="cancel">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">Image Library</v-card-title>
      <v-card-text>
        <div v-if="data.length">
          <v-tabs ref="tabs" v-model="tab">
            <v-tab
              @click="tab = top_level_idx"
              v-for="(top_level_item, top_level_idx) in data"
              :key="top_level_idx"
            >
              {{ top_level_item.title }}
            </v-tab>
          </v-tabs>
          <div
            v-show="tab == top_level_idx"
            v-for="(top_level_item, top_level_idx) in data"
            :key="top_level_idx"
            class="image-library"
          >
            <div
              v-for="(file, idx) in top_level_item.files"
              :key="idx"
              class="image-library__file"
              @click="selectImage(file)"
            >
              <div class="image-library__file__preview">
                <img :src="file.thumbnail_url" referrerpolicy="no-referrer" />
              </div>
              <div class="image-library__file__title">
                {{ file.title.replace(/\.[^/.]+$/, "") }}
              </div>
            </div>
          </div>
        </div>

        <Spinner v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "GDriveImageLibraryDialog",
  props: {
    value: [File, Object],
  },
  data() {
    return {
      data: [],
      tab: 0,
      dialog: null,
    };
  },
  mounted() {
    this.$root.$on("GDriveImageLibraryDialog", async () => {
      this.dialog = true;
    });
  },
  methods: {
    async getData() {
      const { data } = await this.$api.GDriveImageLibrary.list();
      this.data = data;
    },
    cancel() {
      this.dialog = false;
    },
    async selectImage(file) {
      const { data } = await this.$api.GDriveImageLibrary.getImage({
        id: file.id,
        name: file.title,
      });

      const blob = new Blob([data], { type: file.mime_type });
      const fileObj = new File([blob], file.title, {
        type: file.mime_type,
        lastModified: new Date().getTime(),
      });
      fileObj.uuid = uuidv4();

      this.$emit("selectImage", fileObj);
      this.dialog = false;
    },
  },
  watch: {
    dialog(value) {
      if (value && this.data.length == 0) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
.image-library {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  &__file {
    flex-basis: 150px;
    width: 150px;
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    &__preview {
      text-align: center;
      width: 130px;
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        max-width: 130px;
        max-height: 130px;
      }
    }
    &__title {
      text-align: center;
    }
  }
}
</style>
