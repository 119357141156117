<template>
  <TagSelector
    :multiple="multiple"
    :items="qbClasses"
    :value="value"
    @input="$emit('input', $event)"
    :placeholder="placeholder"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    placeholder: String,
    rules: {
      type: Array,
      default: () => [],
    },
    selectedSkill: String,
  },
  data() {
    return {
      qbClasses: [],
    };
  },
  created() {
    this.setQbClasses();
  },
  methods: {
    async setQbClasses() {
      const { data } = await this.$api.QbClass.lite();
      this.qbClasses = data;
    },
  },
  watch: {
    selectedSkill(newValue) {
      if(!newValue) return;
      const newQbClass = this.qbClasses.find((qbc) => qbc.skill_ids.includes(newValue))
      if(newQbClass) this.$emit('input', newQbClass.id)
    }
  }
};
</script>

<style></style>
