<template>
  <v-app
    :class="[
      `page-${$route.name}`,
      { fullpage },
      { 'disabled-theme': themeDisabledPage },
    ]"
  >
    <RightBar v-model="sidebar" />
    <!-- <MainMenu v-if="currentUser && !fullpage" v-model="mainMenu" /> -->
    <AppBar
      @toggleSidebar="sidebar = !sidebar"
      @toggleMenu="mainMenu = !mainMenu"
      :disable-theme="themeDisabledPage"
    />

    <v-main>
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </v-main>

    <Footer :is-static="fullpage" />

    <!-- <v-btn fab fixed bottom right>
      <v-icon>$question</v-icon>
    </v-btn> -->
    <ConfirmDialog />
    <Alert />
    <AuthModal />
  </v-app>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";

export default {
  mixins: [themeDisabledPageMixin],
  data() {
    return {
      mainMenu: false,
      sidebar: false,
    };
  },
  computed: {
    fullpage() {
      return [
        "Opportunities",
        "ProfileView",
        "ClaimAccount",
        "Creators",
        "BadgeInfo",
        "WildcardPage",
        "CreatorsName",
      ].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  background: var(--v-background-base);
  font-family: "Source Sans Pro", sans-serif;
  color: var(--v-text-base);

  &.disabled-theme {
    background: #f8f8f9;
    color: #56565a;

    .background {
      background-color: #f8f8f9 !important;
      border-color: #f8f8f9 !important;
    }
    &::v-deep {
      .my-submission__title,
      .submission-field {
        color: #262729 !important;
      }
    }
    &::v-deep .theme--dark {
      &.v-icon {
        color: #56565a;
      }
      &.v-list {
        background: white;
      }
      &.v-list-item {
        color: rgba(0, 0, 0, 0.87) !important;
      }
      &.v-card {
        background: #ffffff;
        color: rgba(0, 0, 0, 0.87);
      }
      &.v-btn.primary {
        color: white;
      }
    }
  }
}

.v-main::v-deep {
  padding-bottom: 0 !important;
  .v-main__wrap {
    padding: 56px 85px;
  }
}
.page-ProfileView::v-deep .v-main__wrap {
  padding: 0;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 960px) {
  .v-main::v-deep {
    padding-bottom: 0 !important;
    .v-main__wrap {
      padding: 38px 30px;
    }
  }

  .page-Profile::v-deep .v-main__wrap {
    padding: 38px 0 0;
  }

  .page-ProfileView .v-main::v-deep .v-main__wrap {
    padding: 0;
  }
}
</style>
