<template>
  <TagSelector
    :multiple="multiple"
    :items="schedules"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      schedules: [],
    };
  },
  created() {
    this.setSchedules();
  },
  methods: {
    async setSchedules() {
      const { data } = await this.$api.Schedule.lite();
      this.schedules = data;
    },
  },
};
</script>

<style></style>
