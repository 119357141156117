<template>
  <v-dialog
    v-model="dialog"
    max-width="1023"
    content-class="review-dialog"
    ref="reviewProfileDialog"
    :light="light"
  >
    <v-card class="review-dialog-container">
      <v-btn class="review-dialog-close" icon @click="$emit('close')">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-text class="review-dialog__content">
        <div class="review-dialog__header">
          <div class="review-dialog__title">
            Creator{{ creators ? "s" : "" }} for Review
          </div>
          <div class="review-dialog__nav" v-if="creators">
            <span> Profile {{ reviewIdx + 1 }} of {{ creators.length }} </span>
            <div class="review-dialog__nav__btn" @click="prevNext('prev')">
              <v-icon>$chevron_left_filled</v-icon>
            </div>
            <div class="review-dialog__nav__btn" @click="prevNext('next')">
              <v-icon>$chevron_right_filled</v-icon>
            </div>
            <v-fade-transition>
              <div class="please-save__tip" width="200" v-if="pleaseSaveDialog">
                <div
                  class="please-save__tip_click-outside"
                  @click="pleaseSaveDialog = false"
                ></div>
                <v-card class="please-save__tip_content">
                  <div>
                    Please save your work below, or changes will be lost.
                  </div>
                  <v-btn
                    class="please-save__btn"
                    color="primary"
                    @click="advanceAnyway"
                    elevation="0"
                    >Advance Anyway</v-btn
                  >
                </v-card>
              </div>
            </v-fade-transition>
          </div>
        </div>
        <div class="review-dialog__current" v-if="activeCreator">
          <ProfileForReview :value="activeCreator" :key="activeCreator.id" />
        </div>
        <div class="review-dialog__review-form" v-if="activeCreator">
          <ProfileForReviewForm
            :value="activeCreator"
            :form="form"
            @input="submitReview"
          />
        </div>
        <div class="review-dialog__reviews" v-if="reviews">
          <ProfileReviews
            :reviews="reviews"
            :key="activeCreator.id + '_' + reviews.length"
            :light="light"
          />
        </div>
        <div
          class="review-dialog__next"
          @click="submitReview()"
          :class="{ disabled: !form.rating }"
        >
          {{ creators ? "Save and Go To Next Submission" : "Submit Review" }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileForReview from "@/components/profile-review/ProfileForReview.vue";
import ProfileForReviewForm from "@/components/profile-review/ProfileForReviewForm.vue";
import ProfileReviews from "@/components/profile-review/ProfileReviews.vue";

export default {
  props: {
    dialog: Boolean,
    light: Boolean,
    creators: Array,
    creator: Object,
    value: String,
  },
  components: {
    ProfileForReview,
    ProfileForReviewForm,
    ProfileReviews,
  },
  data() {
    return {
      reviewIdx: null,
      activeCreator: null,
      pleaseSaveDialog: false,
      pleaseSaveDirection: "next",
      reviews: null,
      form: {
        rating: null,
      },
    };
  },
  mounted() {
    if (this.creators) {
      const idx = this.creators.findIndex(
        (creator) => creator.id == this.value
      );
      this.setCreatorByIdx(idx);
    } else if (this.creator) {
      this.activeCreator = this.creator;
      this.loadReviews(this.activeCreator.id);
    }
  },
  methods: {
    setCreatorByIdx(idx) {
      this.reviewIdx = idx;
      this.activeCreator = this.creators[this.reviewIdx];
      this.loadReviews(this.activeCreator.id);
    },
    async loadReviews(id) {
      const { data } = await this.$api.Creator.profileReviews({ id });
      this.reviews = data;
    },
    prevNext(dir) {
      if (this.pleaseSaveDialog) return;
      if (this.form.rating || this.form.project_code || this.form.notes) {
        // check if this reviewer has ever reviewed this user
        this.pleaseSaveDialog = true;
        this.pleaseSaveDirection = dir;
        return;
      }
      this[dir]();
    },
    prev() {
      const len = this.creators.length;
      const idx = (this.reviewIdx + len - 1) % len;
      this.setCreatorByIdx(idx);
    },
    next() {
      const idx = (this.reviewIdx + 1) % this.creators.length;
      this.setCreatorByIdx(idx);
    },
    advanceAnyway() {
      this.resetForm();
      this.pleaseSaveDialog = false;
      if (this.pleaseSaveDirection == "prev") this.prev();
      else this.next();
    },
    async submitReview() {
      if (!this.form.rating) {
        this.$alert("A rating is required to submit a review", "error");
        return;
      }
      await this.$api.Creator.submitReview({
        id: this.activeCreator.id,
        form: this.form,
      });
      this.resetForm();
      this.$emit("reviewSubmitted", this.reviewIdx);
    },
    resetForm() {
      this.form.project_code = null;
      this.form.rating = null;
      this.form.notes = null;
    },
  },
};
</script>

<style scoped lang="scss">
.review-dialog {
  &-container {
    border: 2px solid var(--v-dark-base);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    > .v-card__text {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &-close {
    position: absolute;
    top: 35px;
    right: 33px;
  }

  &__nav {
    display: flex;
    position: relative;
    width: max-content;
    margin-top: 10px;
    align-items: center;
    color: var(--v-text-base);
    font-size: 14px;
    &__btn:hover {
      cursor: pointer;
    }
  }

  &__header,
  &__current,
  &__review-form,
  &__reviews {
    border-bottom: 2px solid var(--v-dark-base);
  }
  &__header,
  &__current,
  &__review-form,
  &__reviews,
  &__next {
    padding: 30px !important;
    cursor: pointer;
  }

  &__title {
    color: var(--v-primary-base);
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
  }

  &__next {
    color: var(--v-primary-base);
    font-weight: 900;
    font-size: 20px;
    text-align: center;

    &.disabled {
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    }
  }

  > .theme--light {
    .review-dialog__nav {
      color: #56565a;
    }
    .review-dialog__header,
    .review-dialog__current,
    .review-dialog__review-form,
    .review-dialog__reviews {
      border-bottom: 2px solid #262729;
    }

    .pfrf {
      color: #262729;
    }
  }
}

.please-save {
  &__tip {
    position: absolute;
    top: calc(100% + 0.25em);
    width: 200px;
    right: calc(24px - 100px);
    z-index: 1;

    &_click-outside {
      content: "";
      display: block;
      background: rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
    }

    &_content {
      text-align: center;
      border: 2px solid var(--v-text-base);
      border-radius: 10px;
      padding: 23px 9px 27px;
      z-index: 2;
      > div {
        margin-bottom: 1em;
      }
    }
  }
  &__btn {
    font-weight: 700;
  }
}
</style>
