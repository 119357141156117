<template>
  <v-menu
    class="updatable-hm__menu"
    v-model="menu"
    :close-on-click="false"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <span class="updatable-hm__label" v-bind="attrs" v-on="on">{{
        gig.hiring_manager.display_name
      }}</span>
    </template>

    <v-card class="updatable-hm__content">
      <v-btn class="dialog-close" icon @click="close">
        <v-icon>$close</v-icon>
      </v-btn>
      <div class="updatable-hm__form">
        <UsersSelector
          v-model="localValue"
          placeholder="Hiring Manager"
          :roles="['hiring_manager']"
          :rules="[validation.required]"
          @input="input($event)"
        />
      </div>
      <v-btn
        :disabled="
          localValue == gig.hiring_manager_id || localValue === undefined
        "
        @click="submit"
        elevation="0"
        color="primary"
        >Update</v-btn
      >
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    gig: Object,
  },
  data() {
    return {
      menu: false,
      localValue: this.gig.hiring_manager_id,
    };
  },
  methods: {
    input(event) {
      console.info("$event", event);
      this.localValue = event;
    },
    async submit() {
      try {
        const res = await this.$api.WorkAssignment.update({
          id: this.gig.id,
          hiring_manager_id: this.localValue,
        });
        this.menu = false;
        this.$emit("refresh");
        if (res.status == 200) {
          this.$alert("Hiring Manger updated successfully.");
        }
      } catch (e) {
        console.info("error", e);
        this.$alert("Something went wrong", "error");
      }
    },
    close() {
      this.menu = false;
      this.localValue = this.gig.qb_class_id;
    },
  },
};
</script>

<style scoped lang="scss">
#app.disabled-theme .updatable-hm {
  display: inline-block;
  &__label {
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  &__content {
    padding: 3em 2em 2em 1.5em;
    .v-btn {
      color: white !important;
      &.v-btn--disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
  &__form {
    margin-bottom: 1em;
  }
}

.dialog-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: #56565a !important;
}
</style>
