<template>
  <div class="presentation-slides">
    <v-btn icon class="presentation-slides__prev">
      <v-icon size="36">$arrow_left</v-icon>
    </v-btn>
    <swiper :options="swiperMiniOptions">
      <swiper-slide
        class="presentation-slide"
        :class="{
          active:
            slide.id == presentation.active_slide_id &&
            presentation.broadcasting,
        }"
        v-for="slide in slides"
        :key="slide.id"
        :style="{
          backgroundImage: `url(${
            slide.preview ? slide.preview.thumb.url : ''
          })`,
        }"
      >
        <div
          @click="
            $emit('input', slide);
            $emit('setSlide', slide);
          "
          class="presentation-slide__title"
        >
          <div class="presentation-slide__slide-type">
            <v-icon color="red" v-if="slide.slide_type == 'youtube'"
              >$youtube</v-icon
            >
            <v-icon color="#1bb6ea" v-else-if="slide.slide_type == 'vimeo'"
              >$vimeo</v-icon
            >
          </div>
          {{ slide.title }}
        </div>
      </swiper-slide>
    </swiper>
    <v-btn icon class="presentation-slides__next">
      <v-icon size="36">$arrow_right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    slides: Array,
    presentation: Object,
  },
  data() {
    return {
      swiperMiniOptions: {
        speed: 400,
        keyboard: {
          enabled: true,
        },
        slidesPerView: "auto",
        spaceBetween: 20,
      },
    };
  },
};
</script>

<style scoped lang="scss">
.presentation-slides {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  background: #e7e7e7;
  padding: 0 60px;
  display: flex;
  align-items: center;

  &::v-deep {
    .swiper-container {
      width: 100%;
    }
  }

  &__prev {
    position: relative;
    left: -20px;
  }

  &__next {
    position: relative;
    right: -20px;
  }
}
.presentation-slide {
  height: 100px;
  width: 180px;
  background: white;
  margin: 20px 10px;
  cursor: pointer;
  border-radius: 5px;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &__slide-type {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  &.active {
    box-shadow: 0 0 0 5px rgb(255, 217, 0);
  }

  &__title {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    width: 100%;
    padding: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
