<template>
  <div class="subs-review-page" v-if="currentUser">
    <router-link class="back-btn subs-review-page__back-btn" to="/for-review">
      <v-icon size="50">$arrow_left</v-icon>
    </router-link>
    <ReviewMyToggle
      class="subs-review-page__my-toggle"
      :value="my"
      @input="toggleMy($event)"
    />
    <div v-if="loaded && !loadingSubmissions">
      <div v-if="gigGroups && gigGroups.length">
        <SubmissionsReviewSection
          class="subs-review-page__section"
          v-for="item in gigGroups"
          :key="`gig--${item.id}`"
          :formId="item.id"
        />
      </div>
      <div v-if="otherGroups && otherGroups.length">
        <SubmissionsReviewSection
          class="subs-review-page__section"
          v-for="item in otherGroups"
          :key="`other--${item.id}`"
          :formId="item.id"
        />
      </div>
    </div>
    <div class="no-entries" v-else-if="noSubmissions && !loadingSubmissions">
      No Submissions
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import IndexPage from "../index-page";
import SubmissionsReviewSection from "./submissions-review/section.submissions-review";
import ReviewMyToggle from "./shared/ReviewMyToggle.vue";

export default {
  name: "SubmissionsReviewPage",
  extends: IndexPage,
  components: { SubmissionsReviewSection, ReviewMyToggle },
  head: {
    title: "Submissions For Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      gigGroups: null,
      otherGroups: null,
      my: true,
      loadingSubmissions: false,
    };
  },
  methods: {
    async fetchList() {
      this.loadingSubmissions = true;
      const params = {};
      if (this.my) params.my = true;
      const { data } = await this.$api.SubmissionForm.forReview(params);
      this.gigGroups = data.gig_groups;
      this.otherGroups = data.other_groups;
      this.loadingSubmissions = false;
    },
    toggleMy(value) {
      this.my = value;
      this.fetchList();
    },
  },
  computed: {
    loaded() {
      return (
        (this.gigGroups && this.gigGroups.length > 0) ||
        (this.otherGroups && this.otherGroups.length > 0)
      );
    },
    noSubmissions() {
      return (
        this.gigGroups &&
        this.gigGroups.length == 0 &&
        this.otherGroups &&
        this.otherGroups.length == 0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.subs-review-page {
  &__back-btn {
    margin-bottom: 24px;
  }
  &__my-toggle {
    margin-bottom: 30px;
  }

  &__section {
    margin-bottom: 80px;
  }
}
</style>
