const store = {
  namespaced: true,
  state: {
    transactionId: null
  },
  mutations: {
    setTransactionId(state, tid) {
      state.transactionId = tid;
    }
  },
  getters: {
    transactionId: state => state.transactionId
  },
};

export default store;
