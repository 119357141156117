<template>
  <div class="finance-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="page-title">Payments Reports</div>
    <Spinner v-if="loadingList" />
    <div v-if="!loadingList">
      <div class="payments-table__actions">
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Submitted"
                    quickOptions
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="paid_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Paid"
                    quickOptions
                  />
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div v-if="summarizedData && report.length == 0">
        <p style="text-align: center">No Results</p>
      </div>
      <div v-if="summarizedData && report.length > 0">
        <div>
          <h2>Totals</h2>
          <v-tabs v-model="tab">
            <v-tab>By Month</v-tab>
            <v-tab>By Project</v-tab>
            <v-tab>By Creator</v-tab>
            <v-tab>By Role</v-tab>
            <v-tab>By Team</v-tab>
          </v-tabs>
        </div>
        <v-tabs-items class="fi__tabs-items" v-model="tab" light>
          <v-tab-item class="fi__report">
            <h2>Totals by Month</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.byMonthData.total"
            >
              Total: {{ summarizedData.byMonthData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.byMonthData.data"
              chartClass="by-month-totals-chart"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Project</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.totalByProjectData.total"
            >
              Total: {{ summarizedData.totalByProjectData.total | currency }}
            </div>
            <TotalsChart
              :data="
                summarizedData.totalByProjectData.data.filter(
                  (d) => d.phase == 'paid'
                )
              "
              chartClass="project-totals-chart"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Creator</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.byCreatorData.total"
            >
              Total: {{ summarizedData.byCreatorData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.byCreatorData.data"
              chartClass="creator-totals-chart"
              :leftPadding="150"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Role</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.bySkillData.total"
            >
              Total: {{ summarizedData.bySkillData.total | currency }}
            </div>
            <TotalsChart
              :data="summarizedData.bySkillData.data"
              chartClass="role-totals-chart"
              :leftPadding="180"
            />
          </v-tab-item>
          <v-tab-item class="fi__report">
            <h2>Totals by Team</h2>
            <div
              class="fi__report__subhead"
              v-if="summarizedData.totalByTeamData.total"
            >
              Total: {{ summarizedData.totalByTeamData.total | currency }}
            </div>
            <TotalsChart
              :data="
                summarizedData.totalByTeamData.data.filter(
                  (d) => d.phase == 'paid'
                )
              "
              chartClass="team-totals-chart"
              :leftPadding="135"
            />
          </v-tab-item>
        </v-tabs-items>
        <div class="fi__report">
          <h2>Time to Processing/Paid</h2>
          <TimeChart :data="summarizedData" />
        </div>
      </div>
    </div>
    <Spinner v-if="!loadingList && !summarizedData" />
  </div>
</template>

<script>
import _ from "lodash";
import TimeChart from "./payments-reports/TimeChart.payments-reports";
import TotalsChart from "./payments-reports/TotalsChart.payments-reports";
import invoicesTableActions from "@/mixins/invoices/invoices-table-actions.mixin";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  name: "PaymentsReports",
  head() {
    return {
      title: "Payments Reports",
      titleTemplate: "%s | iU Community",
    };
  },
  mixins: [invoicesTableActions],
  components: {
    TimeChart,
    TotalsChart,
    TableDateFilterPanel,
    TableActiveFilters,
  },
  data() {
    return {
      report: null,
      summarizedData: null,
      tab: null,
      loadingList: false,
      listOptions: this.getDefaultListOptions(true),
      filterFields: [
        { name: "created_at", label: "Date Submitted" },
        { name: "paid_at", label: "Date Paid" },
      ],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    daysPerS(secs) {
      return secs / 60 / 60 / 24;
    },
    getAverageAndSumData(invoiceGroup, groupName) {
      const timesToProcessing = invoiceGroup.map((invoice) => {
        return invoice.days_to_processing;
      });

      const avgTimeToProcessing = _.mean(timesToProcessing);

      const timesToPaid = invoiceGroup.map((invoice) => {
        return invoice.days_to_paid - invoice.days_to_processing;
      });
      const avgTimeToPaid = _.mean(timesToPaid);

      const fullTimesToPaid = invoiceGroup.map((invoice) => {
        return invoice.days_to_paid;
      });
      const avgFullTimesToPaid = _.mean(fullTimesToPaid);

      const paidAmounts = invoiceGroup.map((invoice) => invoice.total_amount);
      const paidSum = _.sum(paidAmounts);

      return [
        { group: groupName, phase: "processing", value: avgTimeToProcessing },
        {
          group: groupName,
          phase: "paid",
          value: avgTimeToPaid,
          fullValue: avgFullTimesToPaid,
          sum: paidSum,
        },
      ];
    },
    getSumData(itemGroup, groupName) {
      const paidAmounts = itemGroup.map((item) => item.total_amount);
      const paidSum = _.sum(paidAmounts);

      return [
        {
          group: groupName,
          phase: "paid",
          sum: paidSum,
        },
      ];
    },
    async fetchList() {
      this.loadingList = true;
      const initStart = new Date();
      await this.fetchReport();

      const fetchDone = new Date();

      const processedAndPaidInvoices = this.report.filter((invoice) => {
        return invoice.days_to_processing && invoice.days_to_paid;
      });
      const completedInvoices = this.report.filter((invoice) => {
        return invoice.days_to_paid;
      });

      const timeOverviewData = this.getAverageAndSumData(
        processedAndPaidInvoices,
        `Average (${processedAndPaidInvoices.length})`
      );

      const timeTeams = _.sortBy(
        _.uniq(
          _.flatten(processedAndPaidInvoices.map((invoice) => invoice.teams))
        ),
        (team) => team.toLowerCase()
      );
      let timeByTeamData = [];
      timeTeams.forEach((team) => {
        const results = processedAndPaidInvoices.filter(
          (invoice) => invoice.teams.filter((rc) => rc == team).length > 0
        );
        const averageData = this.getAverageAndSumData(
          results,
          `${team} (${results.length})`
        );
        timeByTeamData = timeByTeamData.concat(averageData);
      });
      const noTeamResults = processedAndPaidInvoices.filter(
        (invoice) => invoice.teams.length == 0
      );
      if (noTeamResults.length > 0) {
        const noTeamAverageData = this.getAverageAndSumData(
          noTeamResults,
          `N/A (${noTeamResults.length})`
        );
        timeByTeamData = noTeamAverageData.concat(timeByTeamData);
      }

      const totalTeams = _.sortBy(
        _.uniq(_.flatten(completedInvoices.map((invoice) => invoice.teams))),
        (team) => team.toLowerCase()
      );
      let totalByTeamData = {
        data: [],
        total: 0.0,
      };
      totalTeams.forEach((team) => {
        const invoices = completedInvoices.filter(
          (invoice) => invoice.teams.filter((rc) => rc == team).length > 0
        );
        const results = _.flatten(
          invoices.map((invoice) => {
            if (invoice.invoice_items) {
              return invoice.invoice_items.filter(
                (invoiceItem) => invoiceItem.team == team
              );
            } else {
              return [invoice];
            }
          })
        );
        const sumData = this.getSumData(results, `${team} (${results.length})`);
        totalByTeamData.data = totalByTeamData.data.concat(sumData);
        totalByTeamData.total += sumData[0].sum;
      });
      const totalNoTeamResults = completedInvoices.filter(
        (invoice) => invoice.teams.length == 0
      );
      if (totalNoTeamResults.length > 0) {
        const noTeamSumData = this.getSumData(
          totalNoTeamResults,
          `N/A (${totalNoTeamResults.length})`
        );
        totalByTeamData.data = noTeamSumData.concat(totalByTeamData.data);
        totalByTeamData.total += noTeamSumData[0].sum;
      }

      const timeProjects = _.sortBy(
        _.uniqBy(
          _.flatten(
            processedAndPaidInvoices.map((invoice) => invoice.projects)
          ),
          "id"
        ),
        (project) => project.code.toLowerCase()
      );
      let timeByProjectData = [];
      timeProjects.forEach((project) => {
        const results = processedAndPaidInvoices.filter(
          (invoice) =>
            invoice.projects.filter((p) => p.id == project.id).length > 0
        );
        const averageData = this.getAverageAndSumData(
          results,
          `${project.code} (${results.length})`
        );
        timeByProjectData = timeByProjectData.concat(averageData);
      });

      const totalProjects = _.sortBy(
        _.uniqBy(
          _.flatten(completedInvoices.map((invoice) => invoice.projects)),
          "id"
        ),
        (project) => project.code.toLowerCase()
      );
      let totalByProjectData = {
        data: [],
        total: 0.0,
      };
      totalProjects.forEach((project) => {
        const invoices = completedInvoices.filter(
          (invoice) =>
            invoice.projects.filter((p) => p.id == project.id).length > 0
        );
        const results = _.flatten(
          invoices.map((invoice) => {
            if (invoice.invoice_items) {
              return invoice.invoice_items.filter(
                (invoiceItem) => invoiceItem.project.id == project.id
              );
            } else {
              return [invoice];
            }
          })
        );
        const sumData = this.getSumData(
          results,
          `${project.code} (${results.length})`
        );
        totalByProjectData.data = totalByProjectData.data.concat(sumData);
        totalByProjectData.total += sumData[0].sum;
      });

      const totalNoProjectResults = completedInvoices.filter(
        (invoice) => invoice.projects.length == 0
      );
      if (totalNoProjectResults.length > 0) {
        const noProjectSumData = this.getSumData(
          totalNoProjectResults,
          `N/A (${totalNoProjectResults.length})`
        );
        totalByProjectData.data =
          totalByProjectData.data.concat(noProjectSumData);
        totalByProjectData.total += noProjectSumData[0].sum;
      }

      const today = new Date();
      const month = today.getMonth();
      const year = today.getYear() - 100;
      const years = Array.from(
        { length: year - 24 + 1 },
        (value, index) => 24 + index
      );
      let byMonthData = {
        data: [],
        total: 0.0,
      };
      years.forEach((y, iYear) => {
        const lastMonthOfYear = iYear < years.length - 1 ? 11 : month;
        for (let iMonth = 0; iMonth <= lastMonthOfYear; iMonth++) {
          const results = completedInvoices.filter((invoice) => {
            const paidAt = new Date(invoice.paid_at);
            return paidAt.getMonth() == iMonth && paidAt.getYear() - 100 == y;
          });
          const sumData = this.getSumData(
            results,
            `${iMonth + 1}/${y} (${results.length})`
          );
          byMonthData.data = sumData.concat(byMonthData.data);
          byMonthData.total += sumData[0].sum;
        }
      });

      const skills = _.sortBy(
        _.uniqBy(
          _.flatten(completedInvoices.map((invoice) => invoice.skills)),
          "id"
        ),
        (skill) => skill.title.toLowerCase()
      );
      let bySkillData = {
        data: [],
        total: 0.0,
      };
      skills.forEach((skill) => {
        const invoices = completedInvoices.filter(
          (invoice) => invoice.skills.filter((s) => s.id == skill.id).length > 0
        );
        const results = _.flatten(
          invoices.map((invoice) => {
            if (invoice.invoice_items) {
              return invoice.invoice_items.filter(
                (invoiceItem) => invoiceItem.skill.id == skill.id
              );
            } else {
              return [invoice];
            }
          })
        );
        const sumData = this.getSumData(
          results,
          `${skill.title} (${results.length})`
        );
        bySkillData.data = bySkillData.data.concat(sumData);
        bySkillData.total += sumData[0].sum;
      });

      const creators = _.sortBy(
        _.uniqBy(
          completedInvoices.map((invoice) => invoice.user),
          "id"
        ),
        (creator) => {
          if (!creator.display_name)
            console.info("creators creator with no display_name", creator);
          creator.display_name.toLowerCase();
        }
      );

      let byCreatorData = {
        data: [],
        total: 0.0,
      };
      creators.forEach((creator) => {
        const results = completedInvoices.filter(
          (invoice) => invoice.user.id == creator.id
        );
        const sumData = this.getSumData(
          results,
          `${creator.display_name} (${results.length})`
        );
        byCreatorData.data = byCreatorData.data.concat(sumData);
        byCreatorData.total += sumData[0].sum;
      });

      this.summarizedData = {
        timeOverviewData,
        timeByProjectData,
        totalByProjectData,
        timeByTeamData,
        totalByTeamData,
        byMonthData,
        byCreatorData,
        bySkillData,
      };

      const dataReady = new Date();

      console.info("fetchDone time:", fetchDone - initStart);
      console.info("dataReady time:", dataReady - fetchDone);
      this.loadingList = false;
    },
    getParams() {
      const params = { ...this.listParams };
      return params;
    },
    async fetchInvoices(params) {
      const { data } = await this.$api.Invoice.reports(params);
      return data;
    },
    async fetchHistoricalInvoices(params) {
      const { data } = await this.$api.HistoricalInvoice.reports(params);
      return data;
    },
    async fetchReport() {
      const params = this.getParams();
      const [invoicesData, historicalInvoicesData] = await Promise.all([
        this.fetchInvoices(params),
        this.fetchHistoricalInvoices(params),
      ]);
      invoicesData.push(...historicalInvoicesData);
      this.report = invoicesData;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/table-filters-menu.scss";
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &__action {
    .v-input fieldset {
      border-color: #262729 !important;
      border-width: 2px;
    }
  }
}

.fi__report {
  &__subhead {
    font-weight: bold;
    font-size: 0.8em;
    color: #4282ff;
    margin-bottom: 1.5em;
  }
}

.fi__tabs-items {
  background: #f8f8f9 !important;
}
.fi__tabs-items .fi__report {
  padding-top: 2em;
}
.payments-reports-chart {
  width: 100%;
  max-width: 900px;
  height: auto;
  &__axis-label {
    font-size: 12px;
  }
  &__value-labels {
    font-size: 12px;
    font-weight: bold;
    &--processing {
      text:not(.payments-reports-chart__value-label--flipped) {
        text-anchor: end;
        transform: translateX(-0.5em);
      }
      text.payments-reports-chart__value-label--flipped {
        text-anchor: start;
        transform: translateX(0.5em);
      }
    }
    &--paid {
      text {
        text-anchor: start;
        transform: translateX(0.5em);
      }
    }
  }
}
</style>
