<template>
  <div class="hire-request-page">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div v-if="form">
      <span class="page-title">{{
        `Hire Request #${form.monday_com_item_id}`
      }}</span>
      <span v-if="form.status == 'canceled'" class="hire-request-page__canceled"
        >Canceled</span
      >
    </div>

    <div v-if="!form">
      <Spinner />
    </div>
    <div v-else>
      <HireRequest v-if="form.id" v-model="form" @refresh="fetchHireRequest" />
    </div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import HireRequest from "../hire-requests/HireRequest";

export default {
  head: {
    title: "Hire Request",
    titleTemplate: "%s | iU Community",
  },
  components: {
    HireRequest,
  },
  mixins: [InvoiceLabelsMixin],
  data() {
    return {
      loading: true,
      form: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchHireRequest();
    }
  },
  methods: {
    async fetchHireRequest() {
      try {
        const response = await this.$api.HireRequest.get({
          monday_com_item_id: this.$route.params.id,
        });
        this.form = response.data;
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.hire-request-page {
  &__canceled {
    display: inline-block;
    background: #e12d1b;
    color: white;
    font-weight: bold;
    padding: 0.2em 0.5em;
    margin-left: 1.5em;
    transform: translateY(-0.5em);
  }
}
</style>
