<template>
  <TagSelector
    :multiple="multiple"
    :items="submissionForms"
    :value="value"
    @input="
      $emit('input', $event);
      $emit('changed', $event);
    "
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      submissionForms: [],
    };
  },
  created() {
    this.setSubmissionForms();
  },
  methods: {
    async setSubmissionForms() {
      const { data } = await this.$api.SubmissionForm.lite();
      this.submissionForms = data;
    },
  },
};
</script>

<style></style>
