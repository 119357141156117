<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('close')"
      width="700"
      content-class="send-extension"
    >
      <v-card class="send-extension__content" v-if="hr" light>
        <div class="send-extension__header">
          <div class="page-title">Extension Request</div>
          <v-btn class="close" icon @click="$emit('close')">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="send-extension__body">
          <GigExtensionForm
            v-if="!review"
            :hr="hr"
            :suggestion="localSuggestion"
            @submit="submitForReview($event)"
          />
          <GigExtensionReview
            v-else
            :hr="hr"
            :form="form"
            @back="backFromReview"
            @submit="submit"
            @signedOffsite="submitSignedOffsite"
          />
        </div>
      </v-card>
    </v-dialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
import GigExtensionForm from "./GigExtensionForm.vue";
import GigExtensionReview from "./GigExtensionReview.vue";

export default {
  components: { GigExtensionForm, GigExtensionReview },
  props: {
    dialog: Boolean,
    hr: Object,
    suggestion: Object,
  },
  data() {
    return {
      review: false,
      form: {},
      localSuggestion: null,
      pleaseWait: false,
    };
  },
  mounted() {
    if (this.suggestion) this.localSuggestion = { ...this.suggestion };
  },
  methods: {
    submitForReview(form) {
      this.form = { ...form };
      this.review = true;
    },
    backFromReview() {
      this.localSuggestion = { ...this.form };
      this.review = false;
    },
    async submit() {
      this.pleaseWait = true;
      try {
        const response = await this.$api.GigExtension.create(this.form);
        if (response.status == 200) {
          this.form = response.data;
          this.$emit("refresh");
          this.$alert(
            this.form.signed_offsite
              ? "Extension created, marked as signed offsite."
              : "Extension agreement sent"
          );
          this.$emit("close");
        }
      } catch (e) {
        if (e.response.status == 422) {
          this.$alert(e.response.data.msg, "error");
        } else {
          this.$alert("Something went wrong", "error");
        }
      }
      this.pleaseWait = false;
    },
    async submitSignedOffsite() {
      this.form.signed_offsite = true;
      await this.submit();
      this.$router.replace({ query: null });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-extension {
  &__content {
    padding: 17px 30px 30px;

    ::v-deep {
      .v-input,
      .v-input:not(.v-input--has-state) {
        fieldset {
          border-color: #262729;
          border-width: 2px;
        }
      }

      .form-label {
        min-width: 120px;
        max-width: 120px;
      }

      .form-row--no-input .form-label {
        margin-top: 5px;
      }
    }
  }
}
</style>
