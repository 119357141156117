<template>
  <div class="font-selector">
    <v-layout style="flex-wrap: wrap">
      <v-flex xs12 sm6 class="pr-3 pl-3">
        <v-layout style="position: relative">
          <Select
            class="font-selector__font"
            placeholder="Font Family"
            :items="currentUser.fonts"
            v-model="form.font_id"
            item-value="id"
            item-text="title"
          />
          <v-btn icon class="font-selector__font-settings">
            <v-icon>$cog</v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm1 class="pl-3 pr-3 font-selector__size">
        <TextField
          v-model="form.size"
          type="number"
          min="8"
          max="120"
          label="Size"
          suffix="px"
        />
      </v-flex>
      <v-flex xs12 sm1 class="pl-3 pr-3 font-selector__size">
        <TextField
          v-model="form.letter_spacing"
          type="number"
          min="0"
          max="100"
          label="Spacing"
          suffix="px"
        />
      </v-flex>
      <v-flex xs12 sm4 class="pr-3 pl-3">
        <Select
          placeholder="Weight"
          :items="datasources.font_weights"
          v-model="form.weight"
        />
      </v-flex>
      <v-flex xs12 sm4 class="pr-3 pl-3">
        <Select
          placeholder="Text Case"
          :items="datasources.font_cases"
          v-model="form.case"
        />
      </v-flex>
      <v-flex xs12 sm4 class="pr-3 pl-3">
        <Select
          placeholder="Text Style"
          :items="datasources.font_styles"
          v-model="form.style"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.form = { ...this.value };
      },
    },
    form: {
      deep: true,
      handler() {
        if (JSON.stringify(this.form) != JSON.stringify(this.value)) {
          this.$emit("input", { ...this.form });
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.font-selector {
  margin-left: -10px;

  .flex {
    min-width: 170px;
  }
  &__size {
    min-width: 150px;
  }

  &__font-settings {
    position: absolute;
    top: 7px;
    right: 10px;
    display: none;
  }

  &::v-deep {
    .v-label,
    .v-input {
      font-size: 14px;
    }
    .v-label--active {
      transform: translateY(-24px) scale(1);
      background: white;
      padding-right: 3px;
    }
  }

  &__font {
    &::v-deep {
      .v-input__append-inner {
        position: relative;
        // left: -40px;
        left: 0;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .font-selector__size {
    margin-bottom: 30px;
  }
}
</style>
