/* eslint-disable */
import vuetify from "../../plugins/vuetify";

const store = {
  namespaced: true,
  state: {
    current: $cookies.get("theme") || 'dark'
  },
  mutations: {
    toggleTheme(state) {
      state.current = state.current === 'dark' ? 'light' : 'dark';
    }
  },
  getters: {
    current: state => state.current
  },
  actions: {
    toggleTheme({ commit }) {
      commit('toggleTheme');
      const theme = $cookies.get("theme");
      const newTheme = theme === 'light' ? 'dark' : 'light';
      const cookieDomain =
          process.env.NODE_ENV === "development"
            ? "localhost"
            : window.location.hostname.replace("profile", "");
      $cookies.set("theme", newTheme, null, null, cookieDomain);
      vuetify.framework.theme.dark = newTheme === 'dark';
    }
  },
};

export default store;
