<template>
  <div class="cp-header">
    <InvoicePersonalInfo :user="value">
      <template v-slot:actions>
        <div class="cp-header__actions">
          <v-btn
            color="#262729"
            dark
            class="elevation-0"
            :to="`/p/${value.username || value.id}`"
            >View Public Profile</v-btn
          >
        </div>
      </template>
    </InvoicePersonalInfo>
  </div>
</template>

<script>
import InvoicePersonalInfo from "@/components/work-assignments/InvoicePersonalInfo";

export default {
  props: {
    value: Object,
  },
  components: {
    InvoicePersonalInfo,
  },
};
</script>

<style scoped lang="scss">
.cp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__actions {
    margin-top: 15px;
    .v-btn {
      margin-right: 17px;
      margin-bottom: 8px;
    }
  }
}
</style>
