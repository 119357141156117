<template>
  <div class="review-slide">
    <div
      class="review-slide__image"
      v-if="slide.value && slide.value.preview"
      :style="{ backgroundImage: `url(${slide.value.preview.large.url})` }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    slide: Object,
  },
  mounted() {
    console.info("slide slide", this.slide);
  },
};
</script>

<style lang="scss" scoped>
.review-slide {
  height: 100%;
  padding-left: 92px;
  padding-right: 92px;
  &__image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
  }
}
</style>
