<template>
  <div class="creators-sections">
    <CreatorsSection
      :search="search"
      :preview="!sectionName"
      :key="index"
      v-for="(section, index) in sections"
      :value="section"
      :featured="section.name === 'featured'"
      :disable-swiper="section.disable_swiper"
      :removeReviewRequest="section.remove_review_request"
    />
  </div>
</template>

<script>
export default {
  props: {
    search: Object,
  },
  data() {
    return {
      sections: null,
      sectionName: this.$route.params.name,
    };
  },
  created() {
    this.setSections();
  },
  methods: {
    async setSections() {
      const { data } = await this.$api.Creator.sections({
        section_name: this.sectionName,
      });
      this.sections = data;
    },
  },
};
</script>

<style lang="scss">
.creators-sections {
  border: 2px solid var(--v-dark-base);
  border-radius: 10px;
  overflow: hidden;

  .creators-section {
    margin-bottom: 0 !important;

    .creators-section__list {
      border-width: 0 0 2px;
      &-inner {
        &::after {
          display: none;
        }
      }
      &--all {
        border-width: 0;
      }
      &:last-child {
        border-radius: 0;
      }
    }

    .creators-section__header {
      padding: 9px 0;
      color: white;
      background-color: var(--v-encouraged-base);
      font-size: 36px;
      line-height: 1;
      text-align: center;
      border: 0;
      border-radius: 0;

      &--featured {
        background: linear-gradient(98.33deg, #ee3624 6.5%, #4282ff 93.73%);
      }

      @media screen and (max-width: 960px) {
        font-size: 20px;
      }
    }
    .creators-section__loadmore,
    .no-entries {
      padding: 18px 0;
      border: 0;
      margin-top: 0;
      border-radius: 0;
    }
  }
}

.dashboard-page .creators-section__header {
  background: #4282ff !important;
  color: white !important;
}
.payments-page .creators-section__header {
  background: linear-gradient(
    98.33deg,
    #ee3624 6.5%,
    #4282ff 93.73%
  ) !important;
  color: white !important;
}
</style>
