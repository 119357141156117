<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    content-class="submit-invoice-dialog"
    light
  >
    <v-card class="submit-invoice__content" light>
      <v-btn class="close" icon @click="closeDialog">
        <v-icon size="30">$close</v-icon>
      </v-btn>
      <v-card-text>
        <div class="submit-invoice__form">
          <v-form
            class="invoice-form-form"
            @submit.prevent="submitInvoice"
            ref="form"
          >
            <EditInvoiceForm
              :form="form"
              :total="total"
              @addInvoiceItem="addInvoiceItem($event)"
              @updateField="updateInvoiceField($event)"
              title="Submit New Invoice"
              @addInvoiceAttachment="addInvoiceAttachment($event)"
              @removeInvoiceAttachment="removeInvoiceAttachment($event)"
            >
              <template v-slot:invoiceItems>
                <div class="invoice-form__items">
                  <EditInvoiceItem
                    v-for="(item, idx) in form.invoice_items"
                    :key="`invoice-item_${idx}_${item.work_assignment_id}`"
                    class="invoice-form__item"
                    :item="item"
                    :idx="idx"
                    :deleteDisabled="form.invoice_items.length == 1"
                    @input="updateInvoiceItem($event, idx)"
                    @removeInvoiceItem="removeInvoiceItem($event)"
                  >
                    <template v-slot:workAssignmentSelector>
                      <MyWorkAssignmentSelector
                        v-model="item.work_assignment_id"
                        :rules="[validation.required]"
                        @input="updateItemWorkAssignment($event, idx)"
                      />
                    </template>
                  </EditInvoiceItem>
                </div>
              </template>
            </EditInvoiceForm>

            <div class="invoice-form__footer">
              <v-btn
                class="elevation-0"
                color="#7B61FF"
                :refs="$refs"
                type="submit"
                >Submit Invoice</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InvoiceFormMixin from "@/mixins/invoices/invoice-form.mixin";
import EditInvoiceForm from "@/components/work-assignments/EditInvoiceForm";
import EditInvoiceItem from "@/components/work-assignments/EditInvoiceItem";
import MyWorkAssignmentSelector from "./MyWorkAssignmentSelector.vue";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  mixins: [InvoiceFormMixin],
  props: {
    dialog: Boolean,
    invoiceTemplate: Object,
  },
  components: {
    EditInvoiceForm,
    EditInvoiceItem,
    MyWorkAssignmentSelector,
  },
  data() {
    return {
      form: {
        invoice_items: [
          {
            work_assignment_id: null,
            rate_type: "hourly",
            rate_units: null,
            amount: null,
            rate: null,
          },
        ],
        invoice_attachments: [{ body: null }],
      },
    };
  },
  mounted() {
    if (this.invoiceTemplate) {
      this.loadInvoiceTemplate(this.invoiceTemplate.id);
    }
  },
  methods: {
    resetForm() {
      this.form = {
        invoice_items: [
          {
            work_assignment_id: null,
            rate_type: "flat_fee",
            hours: null,
            amount: null,
            rate: null,
          },
        ],
        invoice_attachments: [{ body: null }],
      };
    },
    closeDialog() {
      this.resetForm();
      this.$emit("close");
    },
    async submitInvoice() {
      this.form.user_id = this.currentUser.id;
      if (this.$refs.form.validate()) {
        this.form.transaction_id = uuidv4();
        this.$store.commit(
          "invoice/setTransactionId",
          this.form.transaction_id
        );
        this.form.invoice_items.forEach((item, idx) => (item.position = idx));
        this.form.invoice_attachments = this.form.invoice_attachments.filter(
          (att) => att.body
        );
        this.form.invoice_attachments.forEach(
          (item, idx) => (item.position = idx)
        );
        try {
          const response = await this.$api.Invoice.create(this.form);
          if (response.data.hire_requests_needing_retro)
            this.$emit(
              "suggestRetros",
              response.data.hire_requests_needing_retro
            );
          this.resetForm();
          this.$emit("submit");
        } catch (e) {
          this.$alert("Something went wrong", "error");
        }
      }
    },
    async loadInvoiceTemplate(id) {
      const { data } = await this.$api.Invoice.get({ id });
      this.form.invoice_items = data.invoice_items.map((invoiceItem) => {
        return _.pick(invoiceItem, [
          "work_assignment_id",
          "rate_type",
          "rate_units",
          "rate",
          "amount",
        ]);
      });
      this.form.description = data.description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";
</style>

<style lang="scss">
.submit-invoice-dialog {
  border: 2px solid #262729;
  border-radius: 10px;
  .invoice-form-form {
    padding: 45px;
  }
}
</style>
