<template>
  <Invoice v-if="invoice" :invoice="invoice" @refresh="$emit('refresh')" concur>
    <template v-slot:actions>
      <div class="invoice-actions"></div>
    </template>

    <template v-slot:dialogs> </template>
  </Invoice>
</template>

<script>
import Invoice from "@/components/work-assignments/Invoice";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  components: {
    Invoice,
  },
  mixins: [paymentsTableFormats],
  props: {
    value: String,
  },
  data() {
    return {
      invoice: null,
    };
  },
  mounted() {
    this.fetchInvoice();
  },
  methods: {
    async fetchInvoice() {
      const { data } = await this.$api.Invoice.get({ id: this.value });
      this.invoice = data;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/invoice.scss";
</style>
