<template>
  <div class="page-content-block">
    <div class="page-content-block__start">
      <div class="page-content-block__title">
        {{ widgets[item.resource_id].title }}
      </div>
      <div class="page-content-block__subtitle">{{ item.type }}</div>
    </div>
    <div class="page-content-block__actions">
      <div class="page-content-block__actions__btns">
        <v-btn icon @click="$emit('up')">
          <v-icon>$arrow_up</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('down')">
          <v-icon>$arrow_down</v-icon>
        </v-btn>
      </div>
      <div class="page-content-block__actions-more">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>$dots_horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="`/widgets/submission-forms/${item.resource_id}/edit`"
              >Edit</v-list-item
            >
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    widgets: Object,
  },
};
</script>

<style scoped lang="scss"></style>
