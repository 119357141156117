<template>
  <TagSelector
    :label="label"
    :items="skills"
    :value="value"
    :multiple="multiple"
    @input="$emit('input', $event)"
    itemText="title"
    itemValue="id"
    :maximum="maximum"
    :placeholder="placeholder"
    :rules="rules"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    label: String,
    maximum: Number,
    placeholder: String,
    rules: {
      type: Array,
      default: () => [],
    },
    payments: Boolean,
  },
  computed: {
    skills() {
      return this.payments ?
        this.datasources.skills.filter((skill) => !skill.unavailable_for_payments ) :
        this.datasources.skills
    }
  }
};
</script>

<style></style>
