<template>
  <div class="community-opportunities-page">
    <div class="community-opportunities-page__header">
      <div class="page-title">
        <h1>Community Opportunities</h1>
        <img :src="underlineImg" alt="" />
      </div>
      <ThemeSwitch />
    </div>
    <p>Let's work together – browse and apply below.</p>
    <div class="community-opportunities" ref="opportunities">
      <OpportunityGroup
        v-for="(group, idx) in groups"
        :key="idx"
        :title="group.title"
        :subheader="group.subheader"
        :type="group.type"
        :swiper="group.swiper"
        :previous="group.previous"
        :swiperPositionEnabled="group.swiperPositionEnabled"
        @loaded="oppGroupLoaded(idx)"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CommunityProjectsPage",
  head() {
    let meta = [...this.currentProject.meta_tags];

    meta = _.uniqBy(meta, function (tag) {
      return (tag.name || "") + "_" + (tag.property || "");
    });

    return {
      title: "Opportunities",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        {
          rel: "icon",
          href:
            this.currentProject?.favicon.url ||
            this.activeTheme.favicon.url ||
            "/favicon.ico",
        },
      ],
    };
  },
  data() {
    const groups = [
      {
        title: "Featured Opportunities",
        type: "featured",
        swiper: true,
        swiperPositionEnabled: true,
      },
      {
        title: "Emerging Voices Program",
        type: "evp",
        subheader: "Bring your ideas to life.",
      },
      {
        title: "Gigs",
        type: "gigs",
        subheader: "Contracted and full-time work with iU and our partners.",
      },
      {
        title: "Ideation Shorts",
        type: "ideation_short",
        subheader:
          "Short, bite-size projects. Stuff you can do within an hour.",
      },
      //{
      //  title: "Referral Program",
      //  type: "referral",
      //},
      {
        title: "Previous Opportunities",
        previous: true,
        swiper: true,
        swiperPositionEnabled: false,
      },
    ];
    groups.forEach((g) => (g.loaded = false));
    return {
      underlineImg: require("@/assets/opportunities-underline.svg"),
      groups,
    };
  },
  mounted() {
    this.$root.$on("authModal/close", () => {
      this.oppListVisit();
    });
  },
  methods: {
    oppGroupLoaded(idx) {
      this.groups[idx].loaded = true;
      if (this.currentUser && this.groups.filter((g) => !g.loaded).length == 0)
        this.oppListVisit();
    },
    async oppListVisit() {
      if (this.currentUser) {
        await this.$api.ProfileNotification.oppListVisit({});
        this.$store.dispatch("opportunity/fetchNewCount", null, { root: true });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.community-opportunities-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .page-title {
      position: relative;

      h1 {
        font-size: inherit;
        position: relative;
        z-index: 1;
      }

      img {
        position: absolute;
        left: -20px;
        bottom: 0px;
        max-width: 100%;
      }
    }
  }

  .community-opportunities {
    margin-top: 40px;

    &__group {
      background: white;
      margin-bottom: 66px;
      @media screen and (max-width: 960px) {
        margin-bottom: 48px;
      }

      &_header {
        border: 2px solid #262729;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 24px 40px;
        color: var(--v-primary-base);
        font-weight: 900;
      }
    }
  }

  .community-opportunity {
    background: white;
    border: 2px solid #262729;
    padding: 40px;
    margin-top: -2px;

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &__header {
      width: 100%;
      padding-right: 240px;
    }

    &__title {
      font-weight: 900;
      font-size: 20px;
      color: var(--v-dark-base);
      margin-bottom: 0.5em;
    }

    &__dates {
      display: flex;
      font-size: 14px;
      white-space: nowrap;
    }
    &__date {
      font-weight: bold;
      margin-right: 30px;

      span {
        padding: 0 3px 0 0;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    &__description {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      padding-right: 50px;
    }

    &__preview {
      width: 140px;
      min-width: 140px;
      margin-top: -36px;
      height: 140px;
      background-size: cover;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='140' height='140' viewBox='0 0 140 140' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect x='0.5' y='0.5' width='139' height='139' rx='9.5' fill='url(%23pattern0)' stroke='%23DCDCE1'/%3E%3Cdefs%3E%3Cpattern id='pattern0' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23image0' transform='scale(0.015625)'/%3E%3C/pattern%3E%3Cimage id='image0' width='64' height='64' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3C/svg%3E%0A");
      border-radius: 10px;
      border: 1px solid #dcdce1;
      display: flex;
      justify-content: center;
      align-items: center;

      &::v-deep {
        .v-btn {
          border-radius: 10px;
        }
      }

      &-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-btn {
        margin-top: 5px;
      }
    }

    &__loadmore {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
