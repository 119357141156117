<template>
  <div class="notification-type-select">
    <div
      class="notification-type-select__item"
      @click="$emit('input', item.value)"
      v-for="item in items"
      :key="item.value"
    >
      <div
        class="notification-type-select__item-img"
        :style="{ backgroundImage: `url(${item.image})` }"
      ></div>
      <v-icon
        :color="item.value == value ? 'primary' : 'text'"
        class="notification-type-select__radio"
        >{{
          item.value == value ? "$circle_checkbox_on" : "$circle_checkbox_off"
        }}</v-icon
      >
      <div class="notification-type-select__item-title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
  },
  data() {
    return {
      items: [
        {
          value: "banner",
          image: require("@/assets/notifications/banner.png"),
          title: "Banner",
        },
        {
          value: "popup",
          image: require("@/assets/notifications/popup.png"),
          title: "Popup",
        },
        {
          value: "sidebar",
          image: require("@/assets/notifications/sidebar.png"),
          title: "Sidebar",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.notification-type-select {
  display: flex;
  flex-wrap: wrap;

  &__item {
    position: relative;
    height: 200px;
    width: 200px;
    margin-right: 20px;
    border: 1px solid var(--v-border-base);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.02);
    }

    &-img {
      width: 150px;
      height: 95px;
      background-position: center;
      background-size: cover;
    }

    &-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      position: absolute;
      bottom: 20px;
    }
  }
  &__radio {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
</style>
