<template>
  <div class="subs-section" v-if="submissionForm">
    <div :id="`table-${formId}`" class="subs-section__anchor" />
    <div class="subs-section__header">
      <router-link :to="`/review/${formId}`">
        <div class="page-title">
          {{ submissionForm.project_code || submissionForm.title }}
          Submissions
        </div>
        <v-icon size="50" color="primary">$arrow_right</v-icon>
      </router-link>
    </div>
    <component
      :is="
        submissionForm.project_type == 'gig'
          ? 'GigSubmissionsReviewTable'
          : 'SubmissionsReviewTable'
      "
      :submissionForm="submissionForm"
      :items="list"
      :listOptions="listOptions"
      @orderUpdate="orderUpdate($event)"
      :reloadKey="reloadKey"
      @refresh="refresh()"
      :class="{ 'table-loading': loadingList }"
    >
      <template #pagination>
        <TablePagination
          :page="page"
          :totalPages="totalPages"
          @input="page = $event"
          :key="`pagination-${reloadKey}`"
        />
      </template>
    </component>
  </div>
</template>

<script>
import GigSubmissionsReviewTable from "../shared/GigSubmissionsReviewTable";
import SubmissionsReviewTable from "../shared/SubmissionsReviewTable";
import submissionsReviewTableActions from "@/mixins/submissions/submissions-review-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";

export default {
  components: {
    SubmissionsReviewTable,
    GigSubmissionsReviewTable,
    TablePagination,
  },
  mixins: [paginatedTable, submissionsReviewTableActions],
  props: {
    formId: String,
  },
  data() {
    return {
      submissionForm: null,
      list: null,
      loadingList: false,
    };
  },
  mounted() {
    this.fetchForm();
    this.fetchList();
  },
  methods: {
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.review({
        id: this.formId,
      });
      this.submissionForm = data;
    },
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = 6;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      params["id"] = this.formId;
      const { data } = await this.$api.SubmissionForm.reviewSubmissions(params);
      this.list = data.submissions;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
    async refresh() {
      this.fetchForm();
      await this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss">
.subs-section {
  &__header {
    .page-title {
      margin-right: 0.5em;
      margin-bottom: 0;
      display: inline-block;
    }
    a {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 22px;
      align-items: center;
      text-decoration: none;
    }
  }
}
.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
@media screen and (max-width: 960px) {
  .subs-section {
    &__header {
      flex-direction: column;
      .page-title {
        margin-right: 0;
      }
    }
  }
}
</style>
