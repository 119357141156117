<template>
  <div class="table-active-filters" @click="$emit('openMenu')">
    <v-chip-group column light>
      <v-chip
        v-for="field in fields"
        :key="`filter-${field.name}`"
        v-show="activeListOption(field.name)"
        close
        @click:close="$emit('resetFilter', field.name)"
        close-icon="$close"
        >{{ field.label }}
      </v-chip>
    </v-chip-group>
    <v-btn
      icon
      @click="$emit('openMenu')"
      :ripple="false"
      class="table-active-filters__caret"
    >
      <v-icon>$chevron_down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    listOptions: Object,
    fields: Array,
    activeListOption: Function,
  },
};
</script>

<style scoped lang="scss">
.table-active-filters {
  border: 2px solid #262729;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  cursor: pointer;
  .v-chip-group {
    flex-grow: 1;
    &::v-deep .v-slide-group__content {
      padding: 2px 0.5em;
    }
  }

  &__caret:focus:before,
  &__caret:hover:before {
    display: none;
  }
}
</style>
