<template>
  <div class="availability">
    <svg
      width="100%"
      v-if="value == 'available'"
      preserveAspectRatio="none"
      viewBox="0 0 115 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M109.175 6.49611C105.914 17.6697 99.1164 27.484 89.8032 34.466C80.49 41.4481 69.1634 45.2211 57.5236 45.2189C45.8838 45.2167 34.5587 41.4394 25.2481 34.4538C15.9375 27.4682 9.14372 17.6513 5.88648 6.47656"
        stroke="#BECEE0"
        stroke-width="11.1727"
        stroke-miterlimit="1"
        stroke-linecap="round"
      />
      <path
        d="M9.23261 15.7673L8.84259 15.861L9.31835 16.7848L9.62114 16.5217C9.83063 16.3359 10.0606 16.1395 10.3112 15.9325C10.5617 15.7256 10.7969 15.5265 11.0166 15.3355L11.0007 15.3047C10.7228 15.3829 10.4269 15.4638 10.1129 15.5476C9.79897 15.6314 9.50552 15.7046 9.23261 15.7673ZM5.80892 15.0956L11.5975 14.0428L12.4222 15.6439L8.20357 19.7452L7.482 18.3441L8.41744 17.5507L7.69587 16.1497L6.5067 16.4505L5.80892 15.0956Z"
        fill="black"
      />
      <path
        d="M10.3258 23.0549L14.0018 18.4801L14.9003 19.7748L13.2157 21.6606C13.0152 21.8981 12.8104 22.1245 12.6015 22.3398C12.4006 22.5565 12.1958 22.7829 11.9872 23.019L12.007 23.0475C12.3044 22.9394 12.5882 22.8268 12.8582 22.7097C13.1363 22.594 13.42 22.4814 13.7094 22.372L16.0555 21.4394L16.9243 22.6914L11.3526 24.5345L10.3258 23.0549Z"
        fill="black"
      />
      <path
        d="M17.1732 27.4561L16.821 27.6467L17.5172 28.4156L17.7417 28.084C17.8961 27.851 18.0675 27.6025 18.256 27.3385C18.4446 27.0745 18.6203 26.8221 18.7832 26.5814L18.76 26.5557C18.512 26.7025 18.2473 26.8567 17.966 27.0181C17.6846 27.1796 17.4203 27.3256 17.1732 27.4561ZM13.698 27.6874L19.0116 25.1852L20.2183 26.518L17.2021 31.5576L16.1462 30.3914L16.8448 29.3858L15.789 28.2196L14.7191 28.8152L13.698 27.6874Z"
        fill="black"
      />
      <path
        d="M19.4495 33.5939L23.1694 29.3701L24.287 30.3544L20.5672 34.5782L19.4495 33.5939Z"
        fill="black"
      />
      <path
        d="M23.4565 36.8744L26.7209 32.2592L27.9422 33.123L25.401 36.7157L27.1476 37.9512L26.4244 38.9736L23.4565 36.8744Z"
        fill="black"
      />
      <path
        d="M31.9226 39.0737L31.6564 39.3738L32.5752 39.8591L32.6729 39.47C32.7384 39.1978 32.8146 38.9051 32.9016 38.592C32.9886 38.2789 33.0676 37.9811 33.1384 37.6987L33.1078 37.6825C32.9246 37.9056 32.7283 38.1414 32.5188 38.3898C32.3093 38.6382 32.1105 38.8662 31.9226 39.0737ZM28.7306 40.4823L32.875 36.3062L34.4676 37.1473L33.3552 42.9247L31.9617 42.1888L32.2747 41.0027L30.8812 40.2668L30.0782 41.194L28.7306 40.4823Z"
        fill="black"
      />
      <path
        d="M36.3009 44.1871L38.2145 38.8965L40.1191 39.5854C40.4881 39.7189 40.8108 39.8785 41.0872 40.0644C41.3636 40.2503 41.5569 40.4706 41.6673 40.7253C41.7777 40.98 41.7701 41.2809 41.6444 41.6282C41.562 41.8561 41.4199 42.0624 41.2181 42.2471C41.0182 42.4264 40.8005 42.5195 40.5649 42.5263L40.5531 42.5589C40.7826 42.7216 40.9479 42.9348 41.0489 43.1984C41.1553 43.4639 41.1448 43.7731 41.0172 44.1258C40.8857 44.4893 40.6828 44.7534 40.4085 44.9181C40.1361 45.0773 39.823 45.1542 39.469 45.1489C39.115 45.1436 38.7481 45.0722 38.3683 44.9349L36.3009 44.1871ZM38.8667 41.4703L39.3388 41.641C39.5667 41.7235 39.7525 41.7355 39.8964 41.677C40.0403 41.6186 40.1416 41.508 40.2005 41.3452C40.2594 41.1825 40.2473 41.0462 40.1643 40.9364C40.0813 40.8266 39.9258 40.7305 39.6979 40.648L39.2259 40.4773L38.8667 41.4703ZM38.0895 43.6191L38.6592 43.8251C39.2018 44.0214 39.5438 43.9242 39.6851 43.5335C39.7558 43.3382 39.738 43.1753 39.6318 43.0448C39.531 42.9163 39.3422 42.802 39.0655 42.7019L38.4957 42.4958L38.0895 43.6191Z"
        fill="black"
      />
      <path
        d="M43.8496 46.6094L45.038 41.108L46.4938 41.4225L45.5686 45.7051L47.6507 46.1549L47.3874 47.3736L43.8496 46.6094Z"
        fill="black"
      />
      <path
        d="M50.9184 47.8916L51.3923 42.2834L55.0161 42.5896L54.9111 43.832L52.7714 43.6512L52.697 44.5313L54.5261 44.6858L54.4212 45.9283L52.592 45.7737L52.5074 46.7746L54.7335 46.9627L54.6285 48.2051L50.9184 47.8916Z"
        fill="black"
      />
      <path
        d="M62.3214 48.0733L61.6862 42.481L65.3341 42.0667L65.4748 43.3056L63.3068 43.5518L63.4279 44.6187L65.2863 44.4076L65.427 45.6465L63.5686 45.8576L63.8012 47.9052L62.3214 48.0733Z"
        fill="black"
      />
      <path
        d="M71.7898 46.548C71.2867 46.6778 70.8163 46.6799 70.3785 46.5544C69.9393 46.4234 69.5587 46.1728 69.2367 45.8028C68.9147 45.4328 68.6765 44.9488 68.5221 44.3507C68.3678 43.7527 68.3435 43.2194 68.4491 42.751C68.5533 42.277 68.7674 41.8819 69.0912 41.5659C69.415 41.2498 69.8285 41.0268 70.3315 40.897C70.8346 40.7672 71.3043 40.7622 71.7406 40.8821C72.1769 41.0021 72.5554 41.2442 72.876 41.6086C73.1951 41.9674 73.4318 42.4458 73.5862 43.0439C73.7405 43.642 73.7663 44.1808 73.6635 44.6604C73.5607 45.1401 73.3489 45.5435 73.0279 45.8707C72.7056 46.1924 72.2928 46.4181 71.7898 46.548ZM71.4696 45.3071C71.7993 45.222 72.019 45.0103 72.1284 44.6721C72.2434 44.3324 72.2375 43.9166 72.1106 43.4247C71.9822 42.9273 71.7884 42.5689 71.5291 42.3496C71.274 42.1234 70.9815 42.0528 70.6517 42.1379C70.3275 42.2215 70.1058 42.4248 69.9864 42.7478C69.8656 43.0651 69.8694 43.4725 69.9977 43.9699C70.1247 44.4618 70.3207 44.8286 70.5857 45.0702C70.8508 45.3118 71.1454 45.3908 71.4696 45.3071Z"
        fill="black"
      />
      <path
        d="M77.8301 44.5597L75.6351 39.3817L77.5948 38.551C77.9665 38.3934 78.3305 38.2986 78.6866 38.2667C79.0404 38.2295 79.3647 38.2925 79.6595 38.4557C79.9542 38.619 80.1928 38.9157 80.3751 39.3458C80.5327 39.7176 80.5863 40.0583 80.5359 40.3678C80.4854 40.6774 80.3646 40.9542 80.1736 41.1981L82.1478 42.7294L80.6183 43.3777L78.9899 42.0193L78.4641 42.2422L79.2003 43.9788L77.8301 44.5597ZM78.0049 41.1588L78.4988 40.9495C79.0511 40.7153 79.2304 40.3699 79.0368 39.9132C78.9423 39.6901 78.8053 39.5665 78.6259 39.5423C78.4518 39.5159 78.2293 39.5601 77.9585 39.6749L77.4646 39.8842L78.0049 41.1588Z"
        fill="black"
      />
      <path
        d="M89.1345 38.7161L85.0056 34.766L86.2335 33.8634L87.9411 35.6601C88.1388 35.8802 88.3387 36.0986 88.541 36.3153C88.7433 36.532 88.9432 36.7504 89.1409 36.9705L89.1688 36.95C89.0363 36.682 88.9037 36.414 88.7712 36.146C88.6353 35.8734 88.5028 35.6054 88.3737 35.3421L87.3497 33.0428L88.3544 32.3043L90.2434 33.9677C90.4528 34.1648 90.6673 34.3689 90.887 34.58C91.1032 34.7864 91.3212 34.9952 91.5408 35.2063L91.5687 35.1857C91.4142 34.9268 91.2637 34.6685 91.1172 34.4107C90.9673 34.1484 90.8203 33.8947 90.676 33.6497L89.4706 31.4837L90.6148 30.6426L93.1949 35.7313L91.674 36.8493L90.002 35.3058C89.8395 35.153 89.6776 34.9961 89.5164 34.8353C89.3551 34.6744 89.2013 34.5188 89.055 34.3684L89.0271 34.3889C89.1269 34.5735 89.2296 34.7667 89.335 34.9686C89.4451 35.1671 89.5465 35.3684 89.6392 35.5725L90.6275 37.6186L89.1345 38.7161Z"
        fill="black"
      />
      <path
        d="M98.2788 31.1132C97.9151 31.4872 97.5128 31.7348 97.0717 31.8558C96.6266 31.9727 96.1693 31.9566 95.6998 31.8074C95.2304 31.6583 94.7733 31.3675 94.3286 30.9351C93.8839 30.5028 93.5845 30.0581 93.4305 29.6012C93.2723 29.1402 93.2496 28.6896 93.3623 28.2493C93.475 27.8091 93.7131 27.402 94.0768 27.0279C94.4405 26.6539 94.8408 26.4043 95.2776 26.2793C95.7145 26.1543 96.1656 26.1643 96.6308 26.3094C97.0919 26.4505 97.5449 26.7372 97.9896 27.1696C98.4343 27.602 98.7378 28.0507 98.9001 28.5157C99.0625 28.9807 99.0914 29.4374 98.9871 29.8857C98.8785 30.33 98.6425 30.7391 98.2788 31.1132ZM97.3561 30.2161C97.5945 29.9709 97.6723 29.6747 97.5895 29.3274C97.5108 28.9759 97.2886 28.6224 96.9228 28.2668C96.5529 27.9072 96.1995 27.7011 95.8626 27.6484C95.5256 27.5876 95.2379 27.6798 94.9995 27.925C94.7651 28.1661 94.6811 28.4562 94.7474 28.7955C94.8095 29.1308 95.0255 29.4783 95.3954 29.8379C95.7611 30.1935 96.1208 30.4057 96.4743 30.4745C96.8278 30.5433 97.1217 30.4572 97.3561 30.2161Z"
        fill="black"
      />
      <path
        d="M102.444 26.3402L97.8856 23.0758L99.121 21.3504C99.3554 21.0231 99.6157 20.7533 99.9021 20.5411C100.184 20.3255 100.492 20.2105 100.828 20.1962C101.164 20.1819 101.521 20.3103 101.9 20.5815C102.227 20.8159 102.45 21.0779 102.568 21.3675C102.686 21.6571 102.727 21.9553 102.691 22.2621L105.166 22.5388L104.202 23.8854L102.111 23.5761L101.779 24.039L103.308 25.1338L102.444 26.3402ZM100.825 23.356L101.137 22.9211C101.485 22.4349 101.458 22.0477 101.056 21.7598C100.86 21.6192 100.679 21.5852 100.514 21.6579C100.352 21.7259 100.185 21.8791 100.015 22.1176L99.7032 22.5524L100.825 23.356Z"
        fill="black"
      />
      <path
        d="M106.935 19.5394L101.926 16.9504L102.611 15.6249L104.476 16.5888L104.492 16.558L103.281 14.3303L104.029 12.8815L105.291 15.428L109.19 15.1776L108.441 16.6264L105.941 16.7404L106.372 17.5687L107.621 18.2139L106.935 19.5394Z"
        fill="black"
      />
    </svg>

    <svg
      v-else-if="value == 'unavailable1'"
      width="100%"
      viewBox="0 0 144 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_9405_10289)">
        <path
          d="M136.643 7.3906C129.375 17.6735 119.743 26.0605 108.559 31.8459C97.374 37.6312 84.9635 40.6458 72.3712 40.636C59.7678 40.6446 47.3469 37.6241 36.1549 31.8291C24.9628 26.034 15.3273 17.6341 8.06006 7.33691"
          stroke="#E12D1B"
          stroke-width="14.6739"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M16.8286 13.8223L17.659 14.7134L15.4543 16.7571C15.1491 16.9922 14.9241 17.316 14.8101 17.684C14.7894 17.8138 14.8005 17.9467 14.8422 18.0713C14.884 18.1959 14.9552 18.3086 15.0499 18.3998C15.1362 18.5037 15.2464 18.5853 15.371 18.6377C15.4956 18.69 15.631 18.7115 15.7657 18.7004C16.1429 18.6178 16.4847 18.4188 16.7427 18.1314L18.9152 16.1057L19.7133 16.9646L17.6017 18.9223C16.9396 19.5379 16.3383 19.8493 15.7907 19.8672C15.4982 19.8647 15.2099 19.7976 14.9464 19.6708C14.6828 19.544 14.4505 19.3605 14.2661 19.1335C14.051 18.9293 13.8829 18.6808 13.7735 18.4052C13.6641 18.1295 13.616 17.8334 13.6326 17.5373C13.6827 16.9897 14.0406 16.4063 14.7063 15.7943L16.8286 13.8223Z"
          fill="black"
        />
        <path
          d="M21.6567 18.8506L22.5837 19.6845L21.8679 22.605L21.4742 23.8397L21.4992 23.8612L22.0647 23.0881C22.2598 22.8151 22.4689 22.5523 22.6911 22.3007L23.9509 20.8906L24.8134 21.6565L21.2344 25.6614L20.3074 24.8347L21.0447 21.9071L21.4312 20.683L21.4062 20.6616C21.2272 20.9014 21.0483 21.159 20.8407 21.4382C20.6331 21.7174 20.4077 21.9643 20.2072 22.1898L18.9438 23.6214L18.0813 22.8555L21.6567 18.8506Z"
          fill="black"
        />
        <path
          d="M26.5099 28.1562L25.1964 27.1791L24.1692 28.0059L23.1814 27.2686L27.7303 23.9795L28.9006 24.8456L27.0431 30.1425L26.016 29.3766L26.5099 28.1562ZM26.8678 27.2471L27.061 26.7783C27.1612 26.517 27.2722 26.245 27.3939 25.9587C27.5156 25.6724 27.6337 25.3968 27.7518 25.1319L27.7232 25.1105L27.0396 25.6938C26.8069 25.8943 26.5779 26.0768 26.356 26.2486L25.9551 26.5671L26.8678 27.2471Z"
          fill="black"
        />
        <path
          d="M31.2665 26.5273L32.3402 27.2074L31.5743 29.6697C31.4884 29.9632 31.3989 30.2459 31.3058 30.5144C31.2128 30.7828 31.1197 31.0655 31.0338 31.359H31.0625L31.6995 30.7792C31.9 30.5788 32.1147 30.3712 32.3366 30.1636L34.1977 28.3741L35.2463 29.0291L31.0374 32.7083L29.7991 31.9388L31.2665 26.5273Z"
          fill="black"
        />
        <path
          d="M37.2934 34.7163L35.8367 33.9648L34.9598 34.9454L33.8611 34.3942L37.798 30.3965L39.0936 31.0622L38.1273 36.6025L36.9856 36.0155L37.2934 34.7163ZM37.5045 33.7607L37.6226 33.2847C37.6763 33.0127 37.7407 32.7228 37.8159 32.4222C37.891 32.1216 37.9626 31.8317 38.027 31.5489H37.9984L37.4186 32.211C37.2182 32.4437 37.0249 32.6656 36.8317 32.8696L36.4738 33.2454L37.5045 33.7607Z"
          fill="black"
        />
        <path
          d="M42.5223 32.6689L43.6317 33.1485L41.5237 38.0768L40.4106 37.6008L42.5223 32.6689Z"
          fill="black"
        />
        <path
          d="M46.2087 34.1865L47.3504 34.591L45.9188 38.6889L47.923 39.4047L47.5651 40.3675L44.4192 39.258L46.2087 34.1865Z"
          fill="black"
        />
        <path
          d="M53.0197 40.5891L51.4342 40.1703L50.79 41.3227L49.5981 41.0078L52.5902 36.2441L53.9967 36.602L54.2544 42.2211L53.0125 41.8918L53.0197 40.5891ZM53.0197 39.612V39.1038C53.0197 38.821 53.0197 38.5275 53.0197 38.2162C53.0197 37.9048 53.0197 37.6077 53.0197 37.3178H52.991C52.8515 37.5755 52.7155 37.844 52.5723 38.116C52.4291 38.388 52.286 38.6421 52.1428 38.8819L51.8887 39.3257L53.0197 39.612Z"
          fill="black"
        />
        <path
          d="M57.6293 37.4609L59.4403 37.7723C59.6972 37.8142 59.9499 37.8789 60.1954 37.9656C60.4075 38.0348 60.6044 38.1442 60.7752 38.2877C60.934 38.4124 61.0574 38.5765 61.1331 38.7637C61.2067 38.9753 61.2216 39.2029 61.1761 39.4222C61.1546 39.5462 61.1161 39.6667 61.0615 39.7801C61.0058 39.8928 60.9321 39.9955 60.8432 40.0843C60.7517 40.1808 60.6423 40.2586 60.5211 40.3134C60.3862 40.377 60.2405 40.4147 60.0916 40.4244V40.4422C60.404 40.5397 60.6781 40.7324 60.8754 40.9934C60.9566 41.1246 61.0111 41.2706 61.0356 41.4229C61.0602 41.5752 61.0544 41.7309 61.0186 41.881C60.9845 42.1237 60.8897 42.3539 60.743 42.5503C60.6032 42.7253 60.4242 42.865 60.2205 42.9583C59.9935 43.0563 59.7514 43.1144 59.5047 43.1301C59.2306 43.1478 58.9554 43.1322 58.6851 43.0836L56.7202 42.7257L57.6293 37.4609ZM58.7424 42.1459C59.3973 42.2604 59.7659 42.0743 59.8483 41.5947C59.874 41.4974 59.8732 41.3951 59.8462 41.2982C59.8191 41.2013 59.7667 41.1134 59.6944 41.0435C59.487 40.8834 59.2391 40.7843 58.9786 40.7572L58.3057 40.6427L58.0659 42.0277L58.7424 42.1459ZM59.0144 39.8947C59.2368 39.9531 59.4729 39.9303 59.68 39.8302C59.757 39.7825 59.8222 39.7182 59.8712 39.6421C59.9201 39.5659 59.9514 39.4798 59.9628 39.39C59.9851 39.308 59.985 39.2216 59.9623 39.1397C59.9396 39.0578 59.8953 38.9836 59.8339 38.9248C59.6586 38.7931 59.4508 38.7115 59.2327 38.6885L58.6636 38.5919L58.456 39.798L59.0144 39.8947Z"
          fill="black"
        />
        <path
          d="M64.0357 38.4316L65.2419 38.539L64.8625 42.866L66.9777 43.0521L66.8882 44.0686L63.5669 43.7787L64.0357 38.4316Z"
          fill="black"
        />
        <path
          d="M69.7981 38.8184L73.1552 38.872L73.1373 39.8956L70.9899 39.8599L70.972 40.9336L72.8008 40.9622L72.783 41.975L70.9577 41.9464L70.9362 43.1812L73.1659 43.217V44.237L69.7229 44.1833L69.7981 38.8184Z"
          fill="black"
        />
        <path
          d="M82.2852 38.156L85.6244 37.6191L85.7854 38.6284L83.638 38.9863L83.8349 40.2139L85.6709 39.9169L85.832 40.9261L83.9959 41.2196L84.3252 43.2775L83.1298 43.4708L82.2852 38.156Z"
          fill="black"
        />
        <path
          d="M91.3579 41.9313C91.0289 42.0196 90.6851 42.0378 90.3486 41.985C90.0308 41.9335 89.7287 41.8112 89.4646 41.6271C89.1882 41.4355 88.9513 41.1925 88.7667 40.9113C88.5543 40.5729 88.3995 40.2016 88.3086 39.8126C88.2045 39.4281 88.1657 39.0288 88.194 38.6315C88.216 38.2962 88.3087 37.9693 88.466 37.6723C88.6149 37.3905 88.8253 37.1459 89.0816 36.9565C89.3561 36.7566 89.6675 36.6131 89.9978 36.5342C90.326 36.449 90.6675 36.4284 91.0035 36.4734C91.3182 36.5171 91.6191 36.6309 91.884 36.8062C92.1605 36.9965 92.3965 37.2398 92.5783 37.522C92.7875 37.8527 92.941 38.2154 93.0328 38.5957C93.1364 38.9815 93.1763 39.3817 93.151 39.7804C93.1257 40.1204 93.0346 40.4522 92.8825 40.7574C92.7331 41.0378 92.5242 41.2821 92.2705 41.4732C92.001 41.687 91.6903 41.8429 91.3579 41.9313ZM91.1002 40.9185C91.2674 40.8775 91.4211 40.7941 91.5465 40.6762C91.672 40.5583 91.7648 40.41 91.816 40.2456C91.9513 39.8134 91.9513 39.3501 91.816 38.9178C91.7334 38.4829 91.5184 38.0841 91.2004 37.7761C91.0702 37.6658 90.9155 37.5882 90.7493 37.5496C90.5831 37.511 90.4101 37.5126 90.2446 37.5542C90.0791 37.5959 89.9259 37.6764 89.7977 37.789C89.6695 37.9017 89.5701 38.0432 89.5075 38.202C89.3717 38.6244 89.3717 39.0788 89.5075 39.5012C89.5941 39.9453 89.8124 40.3531 90.1339 40.6715C90.2591 40.7938 90.4144 40.881 90.584 40.9243C90.7536 40.9676 90.9316 40.9656 91.1002 40.9185Z"
          fill="black"
        />
        <path
          d="M99.2853 39.3685L97.6711 37.8868L97.0412 38.1087L97.6854 39.934L96.5473 40.3384L94.7578 35.2777L96.5831 34.6334C96.8361 34.5399 97.0991 34.4763 97.3669 34.4438C97.6058 34.4103 97.8489 34.4237 98.0827 34.4831C98.3042 34.5452 98.5069 34.6607 98.6732 34.8196C98.8698 35.0205 99.0147 35.2661 99.0956 35.5354C99.2219 35.8552 99.2493 36.2055 99.1743 36.5411C99.0985 36.838 98.9382 37.1066 98.7126 37.3141L100.577 38.9318L99.2853 39.3685ZM96.7191 37.2211L97.3096 37.0099C97.5596 36.9408 97.7782 36.7879 97.9288 36.5768C97.9848 36.4798 98.0172 36.3709 98.0234 36.259C98.0296 36.1471 98.0095 36.0353 97.9646 35.9326C97.9407 35.8289 97.8891 35.7335 97.8154 35.6566C97.7417 35.5797 97.6485 35.5242 97.5459 35.496C97.2947 35.4595 97.0384 35.493 96.805 35.5926L96.2181 35.8002L96.7191 37.2211Z"
          fill="black"
        />
        <path
          d="M106.304 30.4503L107.377 29.8204L108.966 31.7995L109.485 32.4724C109.664 32.6979 109.843 32.9233 110.008 33.1524H110.037L109.747 32.3364C109.646 32.068 109.55 31.7995 109.457 31.5275L108.684 29.0723L109.571 28.5498L111.354 30.4216C111.54 30.6292 111.712 30.8439 111.916 31.0623L112.488 31.7208H112.517C112.402 31.4488 112.291 31.184 112.18 30.9263L111.851 30.146L110.899 27.7911L111.901 27.2041L113.791 32.3185L112.477 33.088L110.792 31.252C110.649 31.0945 110.509 30.9334 110.369 30.7688C110.23 30.6042 110.101 30.4431 109.979 30.2892H109.951C110.022 30.4789 110.094 30.6722 110.173 30.8726C110.251 31.073 110.319 31.2734 110.387 31.4739L111.185 33.8324L109.883 34.5733L106.304 30.4503Z"
          fill="black"
        />
        <path
          d="M118.691 29.0398C118.42 29.2449 118.109 29.3911 117.778 29.4693C117.466 29.5408 117.142 29.5408 116.83 29.4693C116.497 29.3944 116.183 29.2532 115.906 29.0541C115.581 28.821 115.297 28.5356 115.065 28.2095C114.824 27.8934 114.637 27.5389 114.514 27.1608C114.408 26.8417 114.371 26.504 114.403 26.1694C114.436 25.8544 114.54 25.551 114.707 25.2819C114.885 24.9931 115.119 24.7425 115.394 24.5446C115.665 24.3395 115.974 24.191 116.304 24.1079C116.612 24.0293 116.934 24.0219 117.245 24.0865C117.575 24.1563 117.887 24.294 118.161 24.4909C118.483 24.7204 118.763 25.0023 118.991 25.3248C119.234 25.6457 119.422 26.005 119.546 26.3878C119.654 26.7115 119.695 27.0535 119.668 27.3935C119.638 27.7122 119.538 28.0204 119.374 28.2954C119.201 28.5885 118.968 28.8421 118.691 29.0398ZM118.072 28.1952C118.214 28.0955 118.329 27.9601 118.403 27.8024C118.477 27.6448 118.508 27.4704 118.494 27.2968C118.452 26.8465 118.276 26.4191 117.989 26.0692C117.748 25.6981 117.398 25.4106 116.987 25.2461C116.825 25.1931 116.652 25.1797 116.484 25.2068C116.315 25.2338 116.156 25.3007 116.018 25.4018C115.881 25.503 115.769 25.6354 115.693 25.7882C115.617 25.941 115.578 26.1097 115.581 26.2804C115.614 26.7222 115.784 27.1426 116.067 27.4829C116.314 27.862 116.67 28.1577 117.087 28.3312C117.249 28.3964 117.425 28.4181 117.598 28.3943C117.77 28.3704 117.934 28.3018 118.072 28.1952Z"
          fill="black"
        />
        <path
          d="M125.072 23.6893L123.018 22.9269L122.52 23.3707L123.805 24.8023L122.9 25.604L119.321 21.592L120.752 20.3071C120.952 20.1271 121.172 19.9699 121.407 19.8383C121.614 19.7177 121.843 19.6398 122.08 19.6092C122.308 19.5802 122.54 19.6111 122.753 19.6987C123.033 19.8278 123.279 20.0206 123.472 20.2617C123.664 20.5028 123.798 20.7855 123.862 21.0873C123.902 21.3905 123.853 21.6986 123.723 21.9749L126.06 22.773L125.072 23.6893ZM121.88 22.6514L122.348 22.2362C122.556 22.0777 122.704 21.853 122.767 21.5991C122.783 21.4877 122.771 21.3742 122.735 21.2679C122.698 21.1616 122.637 21.0654 122.556 20.9871C122.495 20.9004 122.411 20.8318 122.314 20.7884C122.217 20.7449 122.11 20.7283 122.005 20.7402C121.752 20.8022 121.52 20.9321 121.336 21.116L120.87 21.5311L121.88 22.6514Z"
          fill="black"
        />
        <path
          d="M124.145 17.1795L124.976 16.2991L126.575 17.8058L126.6 17.7808L126.096 15.1037L127.008 14.1338L127.456 16.7823L131.117 17.5983L130.208 18.5646L127.663 17.9633L127.835 18.9833L128.884 19.9711L128.054 20.8516L124.145 17.1795Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_9405_10289">
          <rect
            width="143.257"
            height="47.973"
            fill="white"
            transform="translate(0.723145)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, String],
  },
};
</script>

<style scoped lang="scss">
.availability {
  pointer-events: none;

  svg {
    display: block;
  }
}
</style>
