<template>
  <router-link
    class="countable-badge"
    :style="{ backgroundImage: `url(${badge.preview.url})` }"
    to="/badge-info"
    target="_blank"
  >
    <div class="countable-badge__count">{{ badge.count }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    badge: Object,
  },
};
</script>

<style scoped lang="scss">
.countable-badge {
  position: relative;
  background-position: center;
  background-size: cover;
  width: 130px;
  min-width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media screen and (max-width: 960px) {
    width: 77px;
    min-width: 77px;
    height: 77px;
  }

  &__count {
    color: #262729;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
