export default {
  data() {
    return {
      onboardLinkVendorDialog: false,
      onboardLinkVendorURL: "",
      onboardLinkVendorURLValid: false,
      onboardLinkVendorId: "",
    };
  },
  methods: {
    async onboard(type, status) {
      try {
        await this.$api.User.onboard({
          id: this.value.id,
          type,
          status,
        });
        this.onboardW9Dialog = false;
        this.onboardACHDialog = false;
        this.onboardLinkVendorDialog = false;
        this.$emit("refresh");
      } catch (e) {
        console.info("e", e);
      }
    },
  },
  watch: {
    onboardLinkVendorURL: function () {
      // Regex to validate the URL and extract the nameId parameter
      const regex =
        /^https:\/\/(app\.)?qbo\.intuit\.com\/app\/vendordetail\?nameId=(\d+)$/;

      // Test the URL against the regex
      const match = this.onboardLinkVendorURL.match(regex);

      // Check if the URL matches and extract the nameId
      if (match) {
        this.onboardLinkVendorURLValid = true;
        this.onboardLinkVendorId = match[2];
      } else {
        this.onboardLinkVendorURLValid = false;
        this.onboardLinkVendorId = "";
      }
    },
  },
};
