/* eslint-disable */
import Vue from "vue";

const store = {
  namespaced: true,
  state: () => ({ current: null }),
  mutations: {
    set(state, current) {
      state.current = current;
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await Vue.prototype.$api.Project.current();

      commit("set", data);

      return data;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
};

export default store;
