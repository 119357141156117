<template>
  <div>
    <div class="page-title">Custom Twitter Settings</div>
    <TextField
      disabled
      label="Callback URL"
      class="callback-url"
      :value="url"
    />
    <TextField label="Client ID" v-model="localValue.data.client_id" />
    <TextField label="Client Secret" v-model="localValue.data.client_secret" />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
  data() {
    return {
      url: `https://oauth.${process.env.VUE_APP_ROOT_DOMAIN}/api/v1/public_side/auth/twitter/callback`,
    };
  },
};
</script>

<style></style>
