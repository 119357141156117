<template>
  <div class="youtube-player">
    <div id="youtube-player"></div>
  </div>
</template>

<script>
import YouTubePlayer from "youtube-player";
export default {
  props: {
    slide: Object,
    started: Boolean,
    value: Object,
  },
  mounted() {
    const player = new YouTubePlayer("youtube-player", {
      width: "100%",
      height: "100%",
      videoId: this.slide.youtube_id,
      playerVars: { rel: 0 },
    });

    this.$emit("input", player);

    player.on("stateChange", async (event) => {
      if (!this.started && event.data == 1) {
        this.$emit("setStarted", true);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 55px);
}
</style>
