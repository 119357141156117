<template>
  <TagSelector
    :multiple="multiple"
    :items="themes"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themes: [],
    };
  },
  created() {
    this.setThemes();
  },
  methods: {
    async setThemes() {
      const { data } = await this.$api.Theme.lite();
      this.themes = data;
    },
  },
};
</script>

<style></style>
