<template>
  <div class="review-item">
    <div class="review-item__content" v-if="submissionForm.layout">
      <div class="review-item__user">
        <div class="review-item__user-name">
          {{ item.user.display_name }}
        </div>
        <div class="review-item__user-email">
          <a :href="`mailto:${item.user.email}`" target="_blank">{{
            item.user.email
          }}</a>
        </div>
      </div>
      <div
        class="review-item__field"
        :class="'s-' + section.class_name"
        v-for="(section, idx) in content"
        :key="idx"
      >
        <div class="review-item__field_label">
          {{ section.label }}
        </div>
        <div class="review-item__field_value">
          {{ section.value }}
        </div>
      </div>
    </div>
    <div class="review-item__content" v-else>
      <div class="review-item__user">
        <div
          class="review-item__user__avatar"
          v-if="item.user.avatar.thumb.url"
          :style="{
            backgroundImage: `url(${item.user.avatar.thumb.url})`,
          }"
        ></div>
        <div class="review-item__user__info">
          <div class="review-item__user__name">
            {{ item.user.display_name }}
          </div>
          <div class="review-item__user__uploadedat">
            {{ uploadedAtMsg }}
          </div>
        </div>
      </div>
      <div class="review-item__fields">
        <div class="review-item__body">
          <div
            class="review-item__gallery"
            :class="`review-item__gallery--${thumbData.length}`"
            v-if="thumbData.length"
          >
            <v-responsive
              class="review-item__thumb"
              v-for="(field, idx) in thumbData"
              :key="`thumb--${idx}`"
              :class="[
                `review-item__field--${field.type}`,
                `review-item__thumb--${
                  field.value && field.value.preview ? 'image' : 'file'
                }`,
              ]"
              :href="field.value.url || field.value"
              target="_blank"
              :aspect-ratio="thumbData.length == 1 ? 2 : 1"
              :style="thumbStyles(field)"
              @click="openSlide(idx)"
              ><div
                class="review-item__thumb__more"
                v-if="idx == 4 && galleryData.length > 5"
              >
                <div>{{ `+${galleryData.length - 5}` }}</div>
              </div></v-responsive
            >
          </div>
          <div
            class="review-item__field"
            :key="idx"
            v-for="(field, idx) in bodyData"
            :class="'review-item__field--' + field.type"
            :href="field.value.url || field.value"
            target="_blank"
          >
            <div class="review-item__field__label">
              {{ field.short_label || field.label }}
            </div>
            <div
              class="review-item__field__value"
              v-if="field.type == 'Radio'"
              v-text="getRadio(field)"
            ></div>
            <div
              class="review-item__field__value"
              v-else-if="field.type == 'LinkField'"
            >
              <a :href="field.value" target="_blank">
                {{ field.value }}
              </a>
            </div>
            <div class="review-item__field__value" v-else>
              {{ field.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="galleryDialog" content-class="gallery-dialog">
      <v-card light class="gallery-dialog-container">
        <v-btn class="dialog-close" icon @click="galleryDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-text class="gallery-dialog__content">
          <div class="gallery-dialog__user">
            <div
              class="gallery-dialog__user__avatar"
              v-if="item.user.avatar.thumb.url"
              :style="{
                backgroundImage: `url(${item.user.avatar.thumb.url})`,
              }"
            ></div>
          </div>
          <div
            class="gallery-dialog__control gallery-dialog__control-prev"
            v-if="galleryData.length > 1"
          >
            <v-btn
              icon
              @click="slidePrev()"
              aria-label="Previous"
              class="gallery-dialog__control-prev__btn"
            >
            </v-btn>
          </div>
          <swiper
            ref="swiper"
            class="gallery-dialog__swiper"
            :options="gallerySwiperOption"
          >
            <swiper-slide
              class="gallery-dialog__swiper-slide"
              v-for="(slide, index) in galleryData"
              :key="`slide--${index}`"
            >
              <SubmissionReviewItemSlide :slide="slide" />
            </swiper-slide>
          </swiper>
          <div
            class="gallery-dialog__control gallery-dialog__control-next"
            v-if="galleryData.length > 1"
          >
            <v-btn
              icon
              @click="slideNext()"
              aria-label="Next"
              class="gallery-dialog__control-next__btn"
            >
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SubmissionReviewItemSlide from "./SubmissionReviewItemSlide";

export default {
  components: { SubmissionReviewItemSlide },
  props: {
    item: Object,
    submissionForm: Object,
  },
  data() {
    return {
      galleryDialog: false,
      galleryIdx: 0,

      gallerySwiperOption: {
        speed: 400,
        keyboard: {
          enabled: true,
        },
        slidesPerView: 1,
        loop: true,
        observer: true,
        observeParents: true,
        allowTouchMove: false,
      },
    };
  },
  methods: {
    getRadio(field) {
      const formField = this.submissionForm.submission_form.find(
        (f) => f.uuid == field.uuid
      );
      if (!formField) return "[Field not found]";
      const option = formField.options.find((o) => o.title == field.value);
      return option ? option.title : "[Value not found]";
    },
    thumbStyles(field) {
      return field.value && field.value.type == "image" && field.value.preview
        ? { backgroundImage: `url(${field.value.preview.large.url})` }
        : {};
    },
    openSlide(idx) {
      this.galleryIdx = idx;
      this.galleryDialog = true;
    },
    slidePrev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    slideNext() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
  },
  computed: {
    content() {
      if (!this.submissionForm.layout) return [];
      return this.submissionForm.layout.content
        .map((t) => ({
          ...this.formattedData[t.field_id],
          class_name: t.uuid,
        }))
        .filter((t) => t);
    },
    formattedData() {
      const result = {};
      this.item.formatted_data.forEach((t) => (result[t.uuid] = t));
      return result;
    },
    galleryData() {
      return this.item.formatted_data.filter(
        (field) => field.type == "MediaField" && field.value
      );
    },
    thumbData() {
      return this.galleryData.slice(0, 5);
    },
    bodyData() {
      return this.item.formatted_data.filter(
        (value) => value.type != "MediaField"
      );
    },
    uploadedAtMsg() {
      const dayDiff = Math.floor(
        (new Date().setUTCHours(0, 0, 0, 0) -
          new Date(this.item.created_at).setUTCHours(0, 0, 0, 0)) /
          86400000
      );
      return dayDiff < 1 ? "Uploaded today" : `Uploaded ${dayDiff} days ago`;
    },
  },
};
</script>

<style scoped lang="scss">
.review-item {
  flex-grow: 1;

  &__preview {
    float: right;
    width: 50%;
    margin: 0 calc(25% - 30px) 30px 30px;
    cursor: pointer;
    @media screen and (max-width: 960px) {
      width: 100%;
      margin: 0 0 30px 0;
    }
  }
  &__content {
    color: #262729;
  }
  &__body {
    a {
      text-decoration: none;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    &__avatar {
      width: 65px;
      height: 65px;
      margin-right: 9px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-color: gray;
    }
    color: #262729 !important;
    margin-bottom: 1.43em;
    &__name {
      font-size: 36px;
      line-height: 1.25;
      margin-bottom: -8px;
      font-style: normal;
      font-weight: 900;
      color: var(--v-primary-base);
    }
    &__uploadedat {
      font-size: 11px;
      font-style: normal;
      font-weight: 900;
    }
  }

  &__field {
    color: #262729;
    margin-right: 15px;
    margin-bottom: 1.43em;

    &--TextArea {
      height: auto;
    }
    &--TextArea &__value {
      white-space: pre-wrap;
    }

    .v-icon {
      color: #262729;
    }

    &__label {
      font-size: 14px;
      font-weight: 700;
    }
    &__value {
      position: relative;
      top: -4px;
      font-size: 20px;
      line-height: 1.2;
      font-weight: 300;
    }
  }

  &__gallery {
    float: left;
    position: relative;
    z-index: 1;
    width: 56%;
    max-width: 526px;
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(6, 1fr);
    margin-right: 30px;
    margin-bottom: 20px;
    @media screen and (max-width: 960px) {
      width: 100%;
      max-width: unset;
    }

    > div {
      &:first-child {
        grid-column: 1 / span 3;
      }
      &:nth-child(2) {
        grid-column: 4 / span 3;
      }
      &:nth-child(n + 3) {
        grid-column: auto / span 2;
      }
    }
    &--1 {
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: unset;
      }
      > div:first-child {
        grid-column: 1 / end;
      }
    }
    &--3 {
      > div {
        &:first-child {
          grid-column: 1 / span 2;
        }
        &:nth-child(2) {
          grid-column: 3 / span 2;
        }
        &:nth-child(3) {
          grid-column: 5 / span 2;
        }
      }
    }
    &--4 {
      div:nth-child(n + 3) {
        grid-column: auto / span 3;
      }
    }
  }

  &__thumb {
    background: gray;
    cursor: pointer;
    position: relative;
    &--image {
      background-size: cover;
      background-position: center;
    }
    &__more {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(38, 39, 41, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 900;
      color: white;
    }
  }
}
</style>

<style lang="scss">
.gallery-dialog {
  height: calc(100vh - 80px);
  max-height: unset;
  background: black;
  border-radius: 10px;
  &-container,
  &__content {
    height: 100%;
    background: black;
  }
  &__content {
    z-index: 0;
    position: relative;
    padding: 0 !important;
  }
  .dialog-close {
    background: white !important;
    z-index: 1;
    border-radius: 18px;
    top: 26px;
    right: 31px;
    height: 32px;
    width: 32px;
  }

  &__swiper {
    height: 100%;
    z-index: 0;
    &-slide {
      height: 100%;
    }
  }

  &__user {
    position: absolute;
    top: 27px;
    left: 34px;
    z-index: 1;
    &__avatar {
      width: 32px;
      height: 32px;
      margin-right: 9px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-color: gray;
    }
    color: #262729 !important;
    margin-bottom: 1.43em;
  }

  &__control {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    .v-btn {
      width: 28px;
      height: 28px;
      background-position: center;
      background-size: contain;
    }
    &-prev {
      left: 30px;
      .v-btn {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0gMjcuNjg5IDE0IEMgMjcuNjg5IDE0LjQ4MiAyNy41MDkgMTQuOTQ0IDI3LjE4OCAxNS4yODUgQyAyNi44NjcgMTUuNjI2IDI2LjQzMiAxNS44MTcgMjUuOTc4IDE1LjgxNyBMIDYuMTU1IDE1LjgxNyBMIDEzLjUwMiAyMy42MTUgQyAxMy44MjMgMjMuOTU3IDE0LjAwNCAyNC40MTkgMTQuMDA0IDI0LjkwMiBDIDE0LjAwNCAyNS4zODQgMTMuODIzIDI1Ljg0NyAxMy41MDIgMjYuMTg4IEMgMTMuMTgxIDI2LjUyOSAxMi43NDUgMjYuNzIxIDEyLjI5MSAyNi43MjEgQyAxMS44MzYgMjYuNzIxIDExLjQgMjYuNTI5IDExLjA3OSAyNi4xODggTCAwLjgxNCAxNS4yODYgQyAwLjY1NCAxNS4xMTggMC41MjggMTQuOTE3IDAuNDQyIDE0LjY5NiBDIDAuMzU1IDE0LjQ3NiAwLjMxMSAxNC4yMzkgMC4zMTEgMTQgQyAwLjMxMSAxMy43NjEgMC4zNTUgMTMuNTI0IDAuNDQyIDEzLjMwNCBDIDAuNTI4IDEzLjA4MyAwLjY1NCAxMi44ODIgMC44MTQgMTIuNzE0IEwgMTEuMDc5IDEuODEyIEMgMTEuNCAxLjQ3MSAxMS44MzYgMS4yNzkgMTIuMjkxIDEuMjc5IEMgMTIuNzQ1IDEuMjc5IDEzLjE4MSAxLjQ3MSAxMy41MDIgMS44MTIgQyAxMy44MjMgMi4xNTMgMTQuMDA0IDIuNjE2IDE0LjAwNCAzLjA5OCBDIDE0LjAwNCAzLjU4MSAxMy44MjMgNC4wNDMgMTMuNTAyIDQuMzg1IEwgNi4xNTUgMTIuMTgzIEwgMjUuOTc4IDEyLjE4MyBDIDI2LjQzMiAxMi4xODMgMjYuODY3IDEyLjM3NCAyNy4xODggMTIuNzE1IEMgMjcuNTA5IDEzLjA1NiAyNy42ODkgMTMuNTE4IDI3LjY4OSAxNCBaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=");
      }
    }
    &-next {
      right: 30px;
      .v-btn {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcy8+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0gMC4yOTkgMTQuMDA2IEMgMC4yOTkgMTMuNTI0IDAuNDc5IDEzLjA2MiAwLjggMTIuNzIxIEMgMS4xMjEgMTIuMzggMS41NTYgMTIuMTg5IDIuMDEgMTIuMTg5IEwgMjEuODMzIDEyLjE4OSBMIDE0LjQ4NiA0LjM5MSBDIDE0LjE2NSA0LjA0OSAxMy45ODQgMy41ODcgMTMuOTg0IDMuMTA0IEMgMTMuOTg0IDIuNjIyIDE0LjE2NSAyLjE1OSAxNC40ODYgMS44MTggQyAxNC44MDcgMS40NzcgMTUuMjQzIDEuMjg1IDE1LjY5NyAxLjI4NSBDIDE2LjE1MiAxLjI4NSAxNi41ODggMS40NzcgMTYuOTA5IDEuODE4IEwgMjcuMTc0IDEyLjcyIEMgMjcuMzM0IDEyLjg4OCAyNy40NiAxMy4wODkgMjcuNTQ2IDEzLjMxIEMgMjcuNjMzIDEzLjUzIDI3LjY3NyAxMy43NjcgMjcuNjc3IDE0LjAwNiBDIDI3LjY3NyAxNC4yNDUgMjcuNjMzIDE0LjQ4MiAyNy41NDYgMTQuNzAyIEMgMjcuNDYgMTQuOTIzIDI3LjMzNCAxNS4xMjQgMjcuMTc0IDE1LjI5MiBMIDE2LjkwOSAyNi4xOTQgQyAxNi41ODggMjYuNTM1IDE2LjE1MiAyNi43MjcgMTUuNjk3IDI2LjcyNyBDIDE1LjI0MyAyNi43MjcgMTQuODA3IDI2LjUzNSAxNC40ODYgMjYuMTk0IEMgMTQuMTY1IDI1Ljg1MyAxMy45ODQgMjUuMzkgMTMuOTg0IDI0LjkwOCBDIDEzLjk4NCAyNC40MjUgMTQuMTY1IDIzLjk2MyAxNC40ODYgMjMuNjIxIEwgMjEuODMzIDE1LjgyMyBMIDIuMDEgMTUuODIzIEMgMS41NTYgMTUuODIzIDEuMTIxIDE1LjYzMiAwLjggMTUuMjkxIEMgMC40NzkgMTQuOTUgMC4yOTkgMTQuNDg4IDAuMjk5IDE0LjAwNiBaIiBmaWxsPSJ3aGl0ZSIgc3R5bGU9InRyYW5zZm9ybS1vcmlnaW46IDEzLjk4OHB4IDE0LjAwNnB4OyIvPgo8L3N2Zz4=");
      }
    }
  }
}
</style>
