<template>
  <v-dialog
    content-class="work-example-dialog"
    max-width="1353"
    v-model="dialog"
    v-if="dialog"
    light
  >
    <v-card>
      <v-btn class="dialog-close" icon @click="$emit('close')">
        <v-icon v-if="$size == 'xs'" size="62" color="white">$close_alt</v-icon>
        <v-icon v-else>$close</v-icon>
      </v-btn>
      <v-responsive
        class="work-example-dialog__lede"
        :aspect-ratio="ledeAspectRatio"
        v-if="item.content_type != 'pdf_other'"
      >
        <WorkExampleSlides
          v-if="item.content_type == 'images'"
          :slides="item.slides"
        />
        <div
          v-else-if="item.content_type == 'video'"
          class="work-example-video"
        >
          <div
            class="work-example-video__bg"
            :style="{ backgroundImage: `url(${videoPreview})` }"
          >
          </div>
          <VimeoPlayer
            v-if="item.vimeo_id"
            :vimeoId="item.vimeo_id"
            :vimeoH="item.vimeo_h"
          />
          <YoutubePlayer
            v-else-if="item.youtube_id"
            :youtubeId="item.youtube_id"
          />
        </div>
      </v-responsive>
      <div v-else-if="item.document && item.document.url" class="work-example-dialog__lede-document">
        <a :href="item.document.url" download>
          <v-icon color="white" size="28">$document</v-icon>
          <span>Download Document</span>
        </a>
      </div>
      <div class="work-example-dialog__body">
        <div class="work-example-dialog__body-top">
          <div class="work-example-dialog__title"> {{ item.title }}</div>
          <div class="work-example-dialog__specialty" v-if="item.skill"> {{ item.skill }}</div>
        </div>
        <div class="work-example-dialog__description" v-if="item.description">
          {{ item.description }}
        </div>
        <div class="work-example-dialog__website" v-if="item.website">
          <a :href="item.website" target="_blank" @click.stop>View Project
            <v-icon color="primary">$arrow_right_short</v-icon>
          </a>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: Object,
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const { swiper } = this.$refs;
    if (swiper) {
      this.swiperInstance = this.$refs.swiper.swiperInstance;
    }
  },
  computed: {
    videoPreview() {
      return this.item.video_preview?.large?.url || this.item.preview?.large?.url;
    },
    ledeAspectRatio() {
      const aspectRatio = {
        sm: 1353 / 609,
        xs: 357 / 218
      };

      return (
        aspectRatio[this.$size] ||
        1353 / 609
      );
    }
  },
}
</script>

<style lang="scss" scoped>
.work-example {
  &-dialog {
    &.v-dialog{
      @media screen and (max-width: 960px) {
        margin: 9px;
      }
    }
    &__lede {
      width: 100%;
      &-document {
        text-align: center;
        font-weight: 900;
        color: white;
        background: var(--v-primary-base);
        padding: 2em;
        font-size: 20px;
        a {
          color: var(--v-primary-base);
          text-decoration: none;
          color: white;
          &:hover {
            span {
              text-decoration: underline;
            }
          }
          .v-icon {
            margin-bottom: .125em;
          }
        }
      }
    }

    &__body {
      padding: 60px 69px 54px;
      @media screen and (max-width: 960px) {
        padding: 24px 30px;
      }
      &-top {
        margin-bottom: 46px;
      }
    }

    &__title {
      font-weight: 900;
      font-size: 42px;
      line-height: 1.07;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      border-bottom: solid 0.04762em #9a9a9a;
      padding-bottom: 0.1em;
      margin-bottom: 0.2381em;
      width: max-content;
      padding-right: 2.262em;
      max-width: 100%;
      @media screen and (max-width: 960px) {
        font-size: 32px;
      }
      @supports (-webkit-text-stroke: 0.047619em) {
        opacity: 1.0;
        -webkit-text-stroke: 0.047619em;
        -webkit-text-fill-color: transparent;
        font-weight: 900;
      }
    }

    &__specialty {
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
      @media screen and (max-width: 960px) {
        font-size: 19px;
      }
    }

    &__description {
      white-space: break-spaces;
      @media screen and (max-width: 960px) {
        font-size: 13.3px;
      }
    }

    &__website {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.79;
      @media screen and (max-width: 960px) {
        font-size: 13.3px;
      }
      a {
        color: var(--v-primary-base);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-video {
    height: 100%;
    width: 100%;
    &__bg {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
    }
  }
}

.dialog-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #DCDCE1;
  z-index: 2;
  @media screen and (max-width: 960px) {
    background: none;
  }
}
</style>

<style lang="scss">
.work-example-dialog.v-dialog {
  @media screen and (max-width: 960px) {
    margin: 9px;
    max-height: calc(100vh - 18px);
  }
}
</style>
