import _ from "lodash";

export default {
  data() {
    return {
      searchInput: null,
      filtersMenu: false,
      filterPanels: [],
      reloadKey: 1,
    };
  },
  methods: {
    filterUpdate(event) {
      if (this.page) this.page = 1;
      this.listOptions[event.fieldName] = event.option;
      this.fetchList();
      this.reloadKey++;
    },
    filterMenuUpdate(event) {
      if (event) this.filterUpdate(event);
      else this.optionsUpdate();
      this.closeFilters();
    },
    removeFromFilter(listOption, value) {
      this.listOptions[listOption] = this.listOptions[listOption].filter(
        (item) => value != item
      );
    },
    closeFilters() {
      this.filtersMenu = false;
      this.filterPanels = [];
    },
    orderUpdate(event) {
      if (this.page) this.page = 1;
      this.listOptions.order_by = event.fieldName;
      this.listOptions.order_direction = event.direction;
      this.fetchList();
      this.reloadKey++;
    },
    optionsUpdate() {
      if (this.page) this.page = 1;
      this.fetchList();
      this.reloadKey++;
    },
    setSearch: _.debounce(function () {
      this.listOptions.search_text = this.searchInput;
      this.fetchList();
      this.reloadKey++;
    }, 300),
    dateParam(dateOption) {
      const dates = dateOption.filter((date) => date);
      if (dates.length == 0) return null;
      if (dates.length == 1) return dates[0];
      else return dates;
    },
  },
  watch: {
    searchInput: {
      handler() {
        this.setSearch();
      },
    },
  },
};
