<template>
  <v-footer app class="footer" :class="{ static: isStatic }">
    <div class="footer-container">
      <div class="footer__start">
        <a :href="homeUrl" class="footer__logo">
          <img src="@/assets/footer_logo.svg" alt="iU Community ™" />
        </a>

        <div class="footer__separator"></div>

        <div class="copyright">© {{ year }} Ideas United</div>
      </div>
      <div class="footer__menu">
        <div class="footer__menu-item" v-if="supportForms.length">
          <a @click.prevent="supportDialog = true">Support</a>
        </div>
        <div class="footer__menu-item">
          <router-link to="/faq">FAQ</router-link>
        </div>
        <div class="footer__menu-item">
          <a target="_blank" href="https://ideasunited.com/connect"
            >Contact Us</a
          >
        </div>
        <div class="footer__menu-item">
          <a target="_blank" href="https://ideasunited.com/terms"
            >Terms of Use</a
          >
        </div>

        <div class="footer__separator"></div>
        <div class="footer__menu-item">
          <a target="_blank" href="https://ideasunited.com/privacy">Privacy</a>
        </div>
      </div>
    </div>
    <v-dialog
      width="630"
      v-model="supportDialog"
      light
      @click:outside="closeSupportDialog"
    >
      <v-card class="support__dialog">
        <v-btn
          class="dialog-close"
          icon
          @click="closeSupportDialog()"
          color="black"
        >
          <v-icon>$close</v-icon>
        </v-btn>

        <v-tabs
          ref="supportTabs"
          v-model="supportTab"
          class="support-tabs"
          hide-slider
        >
          <v-tab
            class="support-tab"
            v-for="supportForm in supportForms"
            :key="`tab_${supportForm.id}`"
          >
            {{ supportForm.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="supportTab"
          class="support-tabs__items"
        >
          <v-tab-item
            v-for="supportForm in supportForms"
            :key="`item_${supportForm.id}`"
          >
          <SupportForm :support-form="supportForm" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import { profileToFrontend } from "@/lib/domains";

export default {
  props: {
    isStatic: Boolean,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      supportDialog: false,
      supportForms: [],
      homeUrl: profileToFrontend()[window.location.origin],
      supportTab: 0,
    };
  },
  created() {
    this.fetchSupportForms();
  },
  mounted() {
    this.$root.$on("openSupportDialog", () => {
      this.supportDialog = true;
    });
  },
  methods: {
    async fetchSupportForms() {
      const { data } = await this.$api.Support.getForms();
      this.supportForms = [
        data.support,
        data.feature_request
      ];
    },
    closeSupportDialog(){
      this.supportDialog = false;
      this.supportTab = 0;
    },
  },
  watch: {
    supportDialog: function (newValue) {
      if (!newValue) {
        this.$root.$emit("resetSupportForm", "support");
        this.$root.$emit("resetSupportForm", "feature_request");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  position: relative;
  z-index: 7;
  padding: 22px;
  background-color: #56565a;
  color: white;
  font-size: 14px;
  font-family: Source Sans Pro, sans-serif;
  &::v-deep {
    &.v-sheet.v-footer:not(.v-sheet--outlined) {
      box-shadow: none;
    }
  }

  &.static {
    position: relative;
  }

  @media screen and (max-width: 960px) {
    position: relative;
  }

  &-container {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
  }

  &__start {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .copyright {
    text-transform: none;
    font-weight: 700;
  }

  &__logo {
    text-decoration: none;
    display: block;
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-item {
      margin: 0 11px;
      font-weight: 700;

      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__separator {
    align-self: stretch;
    width: 2px;
    margin: 0 20px;
    background-color: white;
  }

  &__logo {
    width: 220px;
    max-width: 100%;
  }
}

.support__dialog {
  font-family: Source Sans Pro, sans-serif;
  border: 2px solid #262729;
  border-radius: 10px;
  .support-tabs {
    padding: 10px 20px 0;
    &__items {
      background: white;
    }
    .v-tabs-slider-wrapper {
      display: none;
    }
  }
  .support-tab {
    letter-spacing: 0;
    text-transform: none;
    font-weight: 700;
    border-radius: 8px;
    transition: background .3s;
    margin-right: 1em;
    &.v-tab--active {
      background: var(--v-primary-base);
      color: white;
    }
    &.v-tab:before {
      border-radius: 8px;
    }
  }
}

.dialog-close {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
}

@media screen and (max-width: 960px) {
  .footer {
    position: static !important;
    &__separator {
      display: none;
    }
    &__start {
      flex-direction: column;
    }
    &__menu {
      padding-bottom: 32px;
      border-bottom: 2px solid white;
      margin-bottom: 32px;
      justify-content: center;
    }
  }
  .footer-container {
    flex-direction: column-reverse;
    flex-direction: column;
    padding-bottom: 20px;
    padding-top: 5px;
  }

  .footer__socials {
    margin-top: 16px;
    margin-right: 0;
  }

  .footer-menu {
    margin: 10px 0;
  }

  .footer__socials {
    width: auto;
  }

  .copyright {
    margin: 10px 0;
  }
}
</style>
