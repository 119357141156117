<template>
  <div class="reference-examples">
    <div
      v-for="(item, idx) in localValue"
      :key="`refex_${idx}`"
      class="reference-example"
      :class="{ deleted: item._destroy }"
    >
      <Select
        v-model="item.content_type"
        :items="referenceExamplesTypes"
        class="reference-example__type"
      ></Select>
      <FileField
        placeholder="Upload"
        v-model="item.file"
        v-if="item.content_type == 'file'"
        class="reference-example__file"
      />
      <TextField
        placeholder="URL"
        v-model="item.link"
        v-if="item.content_type == 'link'"
        class="reference-example__link"
        :rules="[validation.url]"
      />
      <v-icon
        class="reference-example__delete"
        @mousedown="removeRefEx(idx)"
        >$delete</v-icon
      >
    </div>
    <v-btn color="primary" elevation="0" rounded small @click="addRefEx">
      {{ localValue.length ? 'Add Another Reference' : 'Add a Reference' }}
    </v-btn>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [{ slides: [] }],
    },
  },
  data() {
    return {
      localValue: [...this.value],
      referenceExamplesTypes: [
        { name: "File Upload", value: "file" },
        { name: "Link", value: "link" },
      ]
    }
  },
  methods: {
    addRefEx() {
      this.localValue.push({ content_type: 'file' });
    },
    removeRefEx(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx]._destroy = true;
      } else {
        this.localValue.splice(idx, 1);
      }
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit("input", [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
}
</script>

<style scoped lang="scss">
.reference-example {
  display: flex;
  margin-bottom: 1em;
  &__type {
    max-width: 130px;
    margin-right: 1em;
  }
  &__file,
  &__link {
    flex-basis: 100%;
    margin-right: 1em;
  }
}
</style>