<template>
  <div class="payment-view" v-if="invoice">
    <div class="page-title">INVOICE DETAILS</div>
    <InvoicePersonalInfo :user="invoice.user" />

    <v-component :is="$size == 'xs' ? 'div' : 'table'" class="pd-invoice-items">
      <div
        class="pd-invoice-item"
        v-for="item in invoice.invoice_items"
        :key="item.id"
      >
        <v-component
          :is="$size == 'xs' ? 'div' : 'tr'"
          class="pd-invoice-item__details"
        >
          <v-component :is="$size == 'xs' ? 'div' : 'td'">
            <div class="field-label">Gig</div>
            <a
              v-if="item.hire_request_monday_com_item_id"
              class="field-value payment-view__detail-link"
              :href="`/hire-requests/${item.hire_request_monday_com_item_id}`"
              target="_blank"
              >{{ item.gig }}</a
            >
            <div v-else class="field-value">{{ item.gig }}</div>
          </v-component>
          <v-component :is="$size == 'xs' ? 'div' : 'td'">
            <div class="field-label">Flat Fee</div>
            <div class="field-value">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3.49219"
                  y="3.73828"
                  width="13.25"
                  height="13.25"
                  rx="6.625"
                  :stroke="item.rate_type == 'flat_fee' ? '#E12D1B' : '#DCDCE1'"
                />
                <path
                  d="M6.15885 9.87918L8.8532 12.7383L14.0755 7.19661"
                  :stroke="item.rate_type == 'flat_fee' ? '#E12D1B' : '#DCDCE1'"
                />
              </svg>
            </div>
          </v-component>
          <v-component :is="$size == 'xs' ? 'div' : 'td'">
            <div class="field-label">Quantity</div>
            <div class="field-value">
              {{
                item.rate_type == "flat_fee"
                  ? "N/A"
                  : item.rate_units_labelled || 0
              }}
            </div>
          </v-component>
          <v-component
            :is="$size == 'xs' ? 'div' : 'td'"
            v-if="item.rate_type != 'flat_fee'"
          >
            <div class="field-label">Unit Price</div>
            <div class="field-value">
              {{ item.rate | currency }}
            </div>
          </v-component>
          <v-component :is="$size == 'xs' ? 'div' : 'td'">
            <div class="field-label">Payment Amount</div>
            <div class="field-value">
              {{
                (item.rate_type == "flat_fee"
                  ? item.amount
                  : item.rate_units * item.rate) | currency
              }}
            </div>
          </v-component>
        </v-component>
        <v-component
          :is="$size == 'xs' ? 'div' : 'tr'"
          v-if="item.description"
          class="pd-invoice-item__description"
        >
          <v-component :is="$size == 'xs' ? 'div' : 'td'" colspan="5">{{
            item.description
          }}</v-component>
        </v-component>
      </div>
    </v-component>

    <br />

    <div class="field-label">Summary</div>
    <div class="field-value">{{ invoice.description }}</div>

    <div class="fields-row">
      <div class="field-item">
        <div class="field-label">Total Amount</div>
        <div class="field-value">{{ invoice.total_amount | currency }}</div>
      </div>
      <div class="field-item">
        <div class="field-value">
          <v-btn
            type="submit"
            elevation="0"
            color="white"
            class="download-btn"
            @click="pdfPreviewDialog = true"
          >
            <v-icon light size="28">$document_light</v-icon>
            {{
              `${invoice.user.display_name.replace(/\s/g, "")}_Invoice-${
                invoice.int_id
              }.PDF`
            }}
          </v-btn>
        </div>
      </div>
    </div>

    <div class="fields-row" v-if="invoice.attachment">
      <div class="field-item">
        <div class="field-label">Attachment</div>
        <div class="field-value field-value--wide">
          <a class="field-download" :href="invoice.attachment" target="_blank">
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2422 8.16797C9.44654 8.16797 8.68348 7.8519 8.12087 7.28929C7.55826 6.72668 7.24219 5.96362 7.24219 5.16797V1.16797H3.24219C2.71175 1.16797 2.20305 1.37868 1.82797 1.75376C1.4529 2.12883 1.24219 2.63754 1.24219 3.16797V16.168C1.24219 16.6984 1.4529 17.2071 1.82797 17.5822C2.20305 17.9573 2.71175 18.168 3.24219 18.168H12.2422C12.7726 18.168 13.2813 17.9573 13.6564 17.5822C14.0315 17.2071 14.2422 16.6984 14.2422 16.168V8.16797H10.2422ZM8.24219 5.16797C8.24219 5.6984 8.4529 6.20711 8.82797 6.58218C9.20305 6.95725 9.71175 7.16797 10.2422 7.16797H13.8282L8.24219 1.58197V5.16797ZM3.24219 0.167969H8.24219L15.2422 7.16797V16.168C15.2422 16.9636 14.9261 17.7267 14.3635 18.2893C13.8009 18.8519 13.0378 19.168 12.2422 19.168H3.24219C2.44654 19.168 1.68348 18.8519 1.12087 18.2893C0.558258 17.7267 0.242188 16.9636 0.242188 16.168V3.16797C0.242187 2.37232 0.558258 1.60926 1.12087 1.04665C1.68348 0.484039 2.44654 0.167969 3.24219 0.167969Z"
                fill="#56565A"
              />
            </svg>

            <span>
              {{ filename }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="field--attachments"
      v-if="invoice.invoice_attachments && invoice.invoice_attachments.length"
    >
      <div class="field-label">Attachment(s)</div>
      <div class="field-value field-value--wide">
        <a
          class="field-download"
          v-for="attachment in invoice.invoice_attachments"
          :key="`invoice_attachment-${attachment.id}`"
          :href="attachment.url"
          target="_blank"
        >
          <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2422 8.16797C9.44654 8.16797 8.68348 7.8519 8.12087 7.28929C7.55826 6.72668 7.24219 5.96362 7.24219 5.16797V1.16797H3.24219C2.71175 1.16797 2.20305 1.37868 1.82797 1.75376C1.4529 2.12883 1.24219 2.63754 1.24219 3.16797V16.168C1.24219 16.6984 1.4529 17.2071 1.82797 17.5822C2.20305 17.9573 2.71175 18.168 3.24219 18.168H12.2422C12.7726 18.168 13.2813 17.9573 13.6564 17.5822C14.0315 17.2071 14.2422 16.6984 14.2422 16.168V8.16797H10.2422ZM8.24219 5.16797C8.24219 5.6984 8.4529 6.20711 8.82797 6.58218C9.20305 6.95725 9.71175 7.16797 10.2422 7.16797H13.8282L8.24219 1.58197V5.16797ZM3.24219 0.167969H8.24219L15.2422 7.16797V16.168C15.2422 16.9636 14.9261 17.7267 14.3635 18.2893C13.8009 18.8519 13.0378 19.168 12.2422 19.168H3.24219C2.44654 19.168 1.68348 18.8519 1.12087 18.2893C0.558258 17.7267 0.242188 16.9636 0.242188 16.168V3.16797C0.242187 2.37232 0.558258 1.60926 1.12087 1.04665C1.68348 0.484039 2.44654 0.167969 3.24219 0.167969Z"
              fill="#56565A"
            />
          </svg>

          <span>
            {{ attachment.label }}
          </span>
        </a>
      </div>
    </div>
    <div class="pd-status">Status: {{ status }}</div>

    <slot name="actions" />
    <slot name="dialogs" />

    <InvoicePdfPreviewDialog
      :value="invoice"
      :dialog="pdfPreviewDialog"
      @close="closePdfPreviewDialog()"
      :concur="concur"
    />
  </div>
</template>

<script>
import InvoicePersonalInfo from "./InvoicePersonalInfo";
import InvoicePdfPreviewDialog from "./InvoicePdfPreviewDialog";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import _ from "lodash";

export default {
  mixins: [paymentsTableFormats],
  components: {
    InvoicePersonalInfo,
    InvoicePdfPreviewDialog,
  },
  props: {
    invoice: Object,
    concur: Boolean,
  },
  data() {
    return {
      pdfPreviewDialog: false,
    };
  },
  methods: {
    closePdfPreviewDialog() {
      this.pdfPreviewDialog = false;
    },
  },
  computed: {
    filename() {
      return _.last((this.invoice.attachment || "").split("/"));
    },
    status() {
      const map = {
        submitted: "Submitted",
        hm_approved: "Pending Payment", // DEPRECATED
        hm_rejected: "Changes Needed", // DEPRECATED
        fi_approved: "In Flight", // DEPRECATED
        fi_rejected: "Changes Needed", // DEPRECATED
        paid: "Payment Completed",
        canceled: "Canceled",
        processing: "Processing",
      };

      return map[this.invoice.status];
    },
  },
};
</script>

<style scoped lang="scss">
.payment-view {
  background: white;
  padding: 20px 34px;

  .download-btn {
    &::before,
    &::v-deep .v-ripple__container {
      display: none !important;
    }
  }
  &__detail-link {
    color: #262729;
    text-decoration: none;
  }
}
.pd-invoice-items {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}
.pd-invoice-item {
  display: table-row-group;
  &__details {
    > * {
      padding: 22px 0;
    }
    &:not(:last-child) td {
      padding-bottom: 11px;
    }
  }
  &__description td {
    padding: 0 0 22px 0;
  }
  > *:last-child {
    border-bottom: 1px solid #dcdce1;
  }
}

.pd-status {
  color: #e12d1b;
  font-weight: bold;
}
.field-label {
  color: #262729;
  font-weight: 700;
  font-size: 14px;
}
.fields-row {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.field--attachments {
  margin: 25px 0;
}
.field-item {
  width: 33.33%;
}

.field-value {
  margin-left: 10px;
  &--wide {
    width: 100%;
  }
}

.field-download {
  display: flex;
  align-items: center;
  color: #262729;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;

  svg {
    margin-right: 5px;
  }
}

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

@media screen and (max-width: 600px) {
  .pd-invoice-item {
    &__details {
      > * {
        padding: 0 0 10px;
      }
    }
    > *:last-child {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .field-value {
    margin-left: 0;
  }
  .fields-row {
    flex-direction: column;
    align-items: flex-start;
    .download-btn {
      padding-left: 0;
      margin-left: -5px;
    }
  }
}
</style>
