import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      cancelAreYouSure: false,
    };
  },
  methods: {
    async hmMarkAsCanceled(invoice) {
      const transaction_id = uuidv4();
      this.$store.commit("invoice/setTransactionId", transaction_id);
      const params = {
        id: invoice.id,
        transaction_id,
      };
      const response = await this.$api.Invoice.markAsCanceled(params);
      this.handleResponse(response);
    },
    handleResponse(response) {
      if (response.data.error) {
        this.$alert(response.data.error, "error");
      } else {
        this.dialog = false;
        this.$alert("Success", "success");
        this.$emit("refresh");
      }
    },
  },
};
