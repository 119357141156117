<template>
  <svg class="payments-reports-chart totals-chart" :class="chartClass"></svg>
</template>

<script>
import * as d3 from "d3";
export default {
  props: {
    data: Array,
    chartClass: String,
    leftPadding: {
      type: Number,
      default: 120,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const dataReady = new Date();

      const leftPadding = this.leftPadding;
      const rightPadding = 100;
      const bottomPadding = 35;
      const yGap = 10;
      const width = 900;
      const barHeight = 20;
      let chartWidth = width - leftPadding - rightPadding;
      const chartHeight = barHeight * this.data.length;
      const height = chartHeight + yGap + bottomPadding;
      const innerPadding = 0.1;
      const svg = d3
        .select(`.${this.chartClass}`)
        .attr("viewBox", `0 0 ${width} ${height}`);
      const g = svg.append("g");

      const yExtent = d3.extent(this.data, (d) => d.sum);
      yExtent[0] = 0;
      const xAxis = d3
        .scaleLinear()
        .domain(yExtent)
        .rangeRound([leftPadding, width - rightPadding]);
      const yAxis = d3
        .scaleBand()
        .rangeRound([0, chartHeight])
        .domain(this.data.map((d) => d.group))
        .paddingInner(innerPadding);

      this.drawBars(svg, this.data, xAxis, yAxis);

      const dollarFormat = d3.format("$,.0f");
      this.drawValues(svg, this.data, leftPadding, xAxis, yAxis, dollarFormat);

      // X Axis
      g.append("g")
        .attr("transform", `translate(0, ${height - bottomPadding})`)
        .call(d3.axisBottom(xAxis).tickFormat(dollarFormat));
      g.append("text")
        .attr("class", "payments-reports-chart__axis-label")
        .attr("text-anchor", "middle")
        .attr("x", leftPadding + chartWidth / 2)
        .attr("y", height - 6)
        .text("Amount");

      // Y Axis
      g.append("g")
        .attr("transform", `translate(${leftPadding - 0.5}, 0)`)
        .call(d3.axisLeft(yAxis));

      const chartRendered = new Date();
      console.info(
        "TotalsChart chartRendered time:",
        chartRendered - dataReady
      );
    },
    drawBars(svg, data, xAxis, yAxis) {
      svg
        .append("g")
        .attr("fill", "#4282ff")
        .selectAll()
        .data(data)
        .join("rect")
        .attr("y", (d) => yAxis(d.group))
        .attr("x", xAxis(0))
        .attr("width", (d) => xAxis(d.sum) - xAxis(0))
        .attr("height", yAxis.bandwidth());
    },
    drawValues(svg, data, xOffset, xAxis, yAxis, textF) {
      svg
        .append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "payments-reports-chart__value-labels")
        .append("text")
        .attr("class", (d) => {
          return xAxis(d.sum) < 30 + xOffset
            ? "payments-reports-chart__value-label--flipped"
            : "";
        })
        .attr("fill", "white")
        .attr("x", (d) => xAxis(d.sum))
        .attr("y", (d) => yAxis(d.group) + yAxis.bandwidth() / 2 + 3)
        .text((d) => textF(d.sum));
    },
  },
};
</script>

<style lang="scss">
.totals-chart {
  width: 100%;
  max-width: 900px;
  height: auto;
  .payments-reports-chart__value-labels {
    text:not(.payments-reports-chart__value-label--flipped) {
      text-anchor: end;
      transform: translateX(-0.5em);
    }
    text.payments-reports-chart__value-label--flipped {
      text-anchor: start;
      transform: translateX(0.5em);
      fill: #4282ff;
    }
  }
}
</style>
