<template>
  <div class="auth-wrapper">
    <div class="auth">
      <div>
        <div class="auth-title">
          <span> iU Creator Login </span>
        </div>
        <v-form @submit.prevent="login" ref="form" :disabled="disabled">
          <TextField
            v-model="loginForm.email"
            block
            :rules="[validation.required]"
            label="Email"
            type="email"
            name="email"
          />
          <TextField
            :rules="[validation.required]"
            v-model="loginForm.password"
            block
            label="Password"
            type="password"
            name="password"
          />
          <div class="auth-footer">
            <div><Button block :refs="$refs" type="submit">Log In</Button></div>
            <a @click="goToForgot" class="forgot-password-link">
              Forgot password?
            </a>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { profileToFrontend } from "@/lib/domains";

export default {
  props: {
    disabled: Boolean,
    loginFormRequest: Boolean,
  },
  data() {
    return {
      loginForm: {},
    };
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("auth/login", this.loginForm);
          this.$root.$emit("authModal/close");
          if (this.$route.params.to) {
            this.$router.push(this.$route.params.to);
          } else this.$router.push("/");
        } catch (e) {
          console.error(e);
        }
      }
    },
    goToForgot() {
      window.location.href =
        profileToFrontend()[window.location.origin] + "/forgot-password";
    },
  },
  watch: {
    loginFormRequest: {
      handler() {
        if (this.loginFormRequest) {
          if (!this.loginForm.email) this.$emit("loginFormResponse", false);
          const res = { email: this.loginForm.email };
          if (this.loginForm.password) res.password = this.loginForm.password;
          this.$emit("loginFormResponse", res);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password-link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--v-primary-base);
  font-size: 14px;
}

.auth {
  &-title {
    color: #262729;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &,
  &-wrapper {
    margin: auto;
    width: 100%;
    max-width: 590px;
  }

  &-tabs {
    display: flex;
  }

  &-h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 0 !important;
    padding-top: 40px;
  }

  &-tab {
    height: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: #26272974;
    background: rgba(0, 0, 0, 0.05);

    &:not(.active):hover {
      background: rgba(0, 0, 0, 0.1);
    }

    &:not(.active) {
      cursor: pointer;
    }

    &.active {
      cursor: default;
      color: #262729;
      background: white;
    }
  }

  &-separate {
    border-top: 1px solid #dcdce1;
    margin: 40px 0;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    flex-direction: column;

    .v-btn {
      margin-bottom: 10px;
    }
  }

  .v-form {
    > div {
      width: 100%;
      margin-bottom: 1em;
    }
  }
}

@media screen and (max-width: 960px) {
  .auth {
    padding: 0;
  }
}
</style>
