<template>
  <div class="my-agreements-page agreements-page">
    <div class="agreements-page__header">
      <a class="back-btn" @click="$router.back()">
        <v-icon size="50">$arrow_left</v-icon>
      </a>
      <div class="my-agreements-page__header__actions">
        <div class="my-agreements-page__header__action">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="requestMyAgreement('w9')"
                elevation="0"
                :color="
                  currentUser.onboarding_details.ach_status != 'SIGNED'
                    ? 'primary'
                    : '#262729'
                "
              >
                <v-icon
                  size="18"
                  color="white"
                  v-if="currentUser.onboarding_details.w9_status != 'SIGNED'"
                  >$error</v-icon
                >{{
                  currentUser.onboarding_details.w9_status != "SIGNED"
                    ? "Complete W-9"
                    : "Update W-9"
                }}
              </v-btn>
            </template>
            {{
              currentUser.onboarding_details.w9_status != "SIGNED"
                ? "You have not filled out a W-9. Click here to sign one now."
                : "If your address or any other tax info has changed, you can submit an updated W-9."
            }}
          </v-tooltip>
        </div>

        <div class="my-agreements-page__header__action">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="requestMyAgreement('ach_authorization')"
                elevation="0"
                :color="
                  currentUser.onboarding_details.ach_status != 'SIGNED'
                    ? 'primary'
                    : '#262729'
                "
                class="my-agreements-page__header__action"
              >
                <v-icon
                  size="18"
                  color="white"
                  v-if="currentUser.onboarding_details.ach_status != 'SIGNED'"
                  >$error</v-icon
                >{{
                  currentUser.onboarding_details.ach_status != "SIGNED"
                    ? "Complete ACH Authorization"
                    : "Update ACH Authorization"
                }}
              </v-btn>
            </template>
            {{
              currentUser.onboarding_details.ach_status != "SIGNED"
                ? "You have not filled out an ACH Authorization. Click here to sign one now."
                : "If your banking information has changed, you can submit an updated ACH Authorization form."
            }}
          </v-tooltip>
        </div>
      </div>
    </div>
    <MyAgreementsTable
      :items="list"
      @refresh="refreshAll"
      :class="{ 'table-loading': loadingList }"
      :listOptions="listOptions"
      @orderUpdate="orderUpdate($event)"
      @filterUpdate="filterUpdate($event)"
      :reloadKey="reloadKey"
    >
      <template #actions>
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Project
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InternalProjectsSelector
                        v-model="listOptions.internal_project_id"
                        emptyLabel="All"
                        :params="{
                          agreements: true,
                          user_id: currentUser.id,
                        }"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        multiple
                        chipText="code"
                        @remove="
                          removeFromFilter('internal_project_id', $event);
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="agreement_type"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Type"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="status"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Status"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Signed"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Requested"
                  />
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="payments-table__action">
          <div class="payments-table__action__label"></div>
          <TextField
            v-model="searchInput"
            emptyLabel="All"
            :params="{ agreements: true }"
            placeholder="Search"
          />
        </div>
        <div class="payments-table__secondary-actions">
          <div
            class="payments-table__action-btn payments-table__action-btn--reset"
            v-if="anyActiveListOptions"
          >
            <div @click="resetListOptions()">
              <v-icon size="14">$close</v-icon> Reset
            </div>
          </div>
        </div>
      </template>
      <template #pagination>
        <TablePagination
          :page="page"
          :totalPages="totalPages"
          @input="page = $event"
          :key="`pagination-${reloadKey}`"
        />
      </template>
    </MyAgreementsTable>
    <PleaseWaitDialog :dialog="pleaseWait" />
    <v-dialog
      v-model="requestMySuccessDialog"
      @click:outside="requestMySuccessDialog = false"
      max-width="400"
    >
      <v-card class="request-my-success-dialog">
        <v-btn
          class="dialog-close"
          icon
          @click="requestMySuccessDialog = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <p>{{ requestMySuccessMsg }}</p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyAgreementsTable from "./my-agreements/table.my-agreements";
import agreementsTableActions from "@/mixins/agreements/agreements-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  components: {
    MyAgreementsTable,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
  },
  mixins: [agreementsTableActions, paginatedTable],
  head: {
    title: "My Agreements",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      list: [],
      loadingList: false,
      reloadKey: 1,
      pleaseWait: false,
      filterFields: [
        { name: "internal_project_id", label: "Project" },
        { name: "agreement_type", label: "Type" },
        { name: "status", label: "Status" },
        { name: "signed_at", label: "Date Signed" },
        { name: "created_at", label: "Date Requested" },
      ],
      requestMySuccessDialog: false,
      requestMySuccessMsg: null,
    };
  },
  created() {
    this.fetchList();
    this.$cable.subscribe({
      channel: "AgreementCreatorChannel",
      token: this.authToken,
      env: this.isProduction() ? "production" : "staging",
    });
  },
  methods: {
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = ["sm", "xs"].includes(this.$size) ? 5 : 10;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.Agreement.my(params);
      this.list = data.agreements;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
    async refreshAll() {
      this.fetchList();
      this.reloadKey++;
    },
    async handleAgreementBroadcast() {
      await this.refreshAll();
      this.$store.dispatch("user/fetch");
    },
    async requestMyAgreement(agreement_type) {
      this.pleaseWait = true;
      try {
        const result = await this.$api.Agreement.requestMy({
          agreement_type: agreement_type,
        });
        console.info("result", result);
        if (result.status == 200) {
          if (result.data.url) {
            window.open(result.data.url, "_blank");
            this.$store.dispatch("user/fetch");
            await this.refreshAll();
          } else if (result.data.msg) {
            this.requestMySuccessMsg = result.data.msg;
            this.requestMySuccessDialog = true;
          } else {
            let alertMsg = "Please check your email to fill out your form.";
            if (agreement_type == "w9")
              alertMsg =
                "A new W-9 form is being generated for you. Check your email or refresh this page shortly to sign the document.";
            if (agreement_type == "ach_authorization")
              alertMsg =
                "A new ACH Authorization form is being generated for you. Check your email or refresh this page shortly to sign the document.";
            this.requestMySuccessMsg = alertMsg;
            this.requestMySuccessDialog = true;
          }
        }
        this.pleaseWait = false;
      } catch (e) {
        console.info(e);
        this.$alert(e.response.data.alert || "Something went wrong", "error");
        this.pleaseWait = false;
      }
    },
  },
  channels: {
    AgreementCreatorChannel: {
      connected() {},
      received() {
        this.handleAgreementBroadcast();
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "AgreementCreatorChannel",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/agreements/agreements-page.scss";
@import "@/styles/table-filters-menu.scss";

.my-agreements {
  &-page {
    &__header {
      &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      &__action {
        display: block;
        &:not(:last-child) {
          margin-bottom: 1em;
        }
        .v-btn {
          color: white !important;
        }
      }
    }
    &__action {
      width: 250px;
      margin-left: 1em;
      &__label {
        font-weight: bold;
      }

      ::v-deep {
        .v-input fieldset {
          border-color: #262729;
          border-width: 2px;
        }
      }
    }
  }
}

.request-my-success-dialog {
  font-weight: bold;
  text-align: center;
  padding: 35px;
}

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
