<template>
  <v-form class="basic-info-page" ref="form">
    <FormPanels light>
      <FormPanel label="Basic Info">
        <div class="form-toptips">
          <div class="form-toptip">
            <span class="form-toptips__icon form-toptips__required">*</span>
            Indicates required fields
          </div>
          <div class="form-toptip">
            <div class="form-toptips__icon form-toptips__encouraged">
              <v-icon size="16" color="#7B61FF">$encouraged</v-icon>
            </div>
            {{ encouragedTip }}
          </div>
        </div>
        <!-- <FormRow required label="First Name">
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name">
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow> -->
        <FormRow label="Business" light>
          <TextField placeholder="Business" v-model="form.company"></TextField>
        </FormRow>
        <FormRow label="Legal Street Address" light>
          <TextArea
            :rows="3"
            placeholder="1234 Anystreet Rd."
            v-model="form.street"
            light
          ></TextArea>
        </FormRow>
        <FormRow label="Location" required light>
          <LocationSelector
            source="place_name_en"
            placeholder="City, State, Country"
            type="city"
            v-model="form.location"
            light
          />
        </FormRow>
        <FormRow label="Postal Code" required light>
          <TextField
            placeholder="Postal Code"
            v-model="form.zip"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow label="Phone Number" required light>
          <TextField
            placeholder="Phone Number"
            v-model="form.phone"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow label="Race / Ethnicity " encouraged light>
          <Select
            :items="datasources.races"
            v-model="form.races_multi"
            multiple
            light
          ></Select>
        </FormRow>
        <FormRow
          label="Other Race"
          v-if="form.races_multi && form.races_multi.includes('other')"
          light
        >
          <TextField
            placeholder="Other Race"
            v-model="form.custom_race"
          ></TextField>
        </FormRow>
        <FormRow label="Payment Preference" encouraged light>
          <Select
            :items="datasources.payment_preferences"
            v-model="form.payment_preference"
            light
          />
        </FormRow>
        <FormRow label="Birthday" encouraged light>
          <DatePicker
            v-model="form.birthday"
            :start-year="new Date().getFullYear() - 40"
            :end-year="new Date().getFullYear() - 6"
            :rounded="false"
            light
          />
        </FormRow>
      </FormPanel>
      <FormPanel :closed="true" v-if="full" label="Email & Password">
        <FormRow label="Account Email" light>
          <TextField
            placeholder="Account Email"
            v-model="form.email"
            disabled
            style="max-width: 256px"
          ></TextField>
        </FormRow>
        <div v-if="!unlocked">
          <FormRow clean label="Current Password" light>
            <div class="login-unlock">
              <TextField
                placeholder="Current Password"
                v-model="form.current_password"
                type="password"
              ></TextField>
              <v-btn @click="unlock" color="primary" outlined>Unlock</v-btn>
            </div>
          </FormRow>
        </div>
        <div v-else>
          <FormRow required label="Email" light>
            <TextField placeholder="Email" v-model="form.email"></TextField>
          </FormRow>
          <FormRow label="New Password" light>
            <TextField
              type="password"
              placeholder="New Password"
              v-model="form.new_password"
            ></TextField>
          </FormRow>
          <FormRow label="New Password Confirmation" light>
            <TextField
              type="password"
              placeholder="New Password Confirmation"
              v-model="form.new_password_confirmation"
            ></TextField>
          </FormRow>
        </div>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" v-if="btnText" :submit="submit">{{
      btnText
    }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "./_extends/form.extends.js";

export default {
  head: {
    title: "Account Details",
    titleTemplate: "%s | iU Community",
  },
  extends: mixin,
  props: {
    full: Boolean,
  },
  data() {
    return {
      unlocked: false,
      encouragedTip:
        "Indicates required for verification of Silver or Gold creator status. Silver or Gold creators get priority access and consideration for iU Opportunities.",
    };
  },
  methods: {
    async unlock() {
      await this.$api.User.unlock({
        password: this.form.current_password,
      });

      this.unlocked = true;
    },
  },
};
</script>

<style scoped lang="scss">
.basic-info-page {
  .form-panels {
    @media screen and (max-width: 960px) {
      margin: 38px 0;
      width: auto;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      margin-bottom: 0;
    }

    ::v-deep .v-expansion-panel {
      .v-expansion-panel-content {
        .v-input input,
        .v-input textarea {
          color: rgba(0, 0, 0, 0.87);
        }
        .v-input fieldset {
          border-color: #9a9a9a;
        }
        .v-input:hover fieldset {
          border-color: rgba(0, 0, 0, 0.3);
        }
        .v-autocomplete.primary--text:not(.tag-selector):not(.location-selector)
          fieldset {
          border-color: currentColor;
        }
        .v-text-field .v-input__slot {
          background: white;
        }
      }
    }
  }

  .submit-footer {
    @media screen and (max-width: 960px) {
      margin: 0;
      border-width: 0;
      border-radius: 0;
      padding: 0 40px 40px;
    }
  }

  .login-unlock {
    display: flex;
    align-items: center;
    max-width: 350px;

    .v-btn {
      margin-left: 15px;
    }
  }

  .form {
    &-toptips {
      font-size: 11px;
      line-height: 1.36;
      font-style: italic;
      color: #56565a;
      margin-bottom: 20px;
      &__icon {
        margin-right: 0.4em;
      }
      &__required {
        color: var(--v-primary-base);
        margin-left: 3px;
        font-size: 14px;
        font-weight: 700;
      }
    }
    &-toptip {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }
}
</style>
