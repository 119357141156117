export default {
  props: {
    value: Object,
  },
  data() {
    return {
      localValue: { ...this.value },
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.localValue)) {
          this.$emit("input", this.localValue);
        }
      },
    },
  },
};
