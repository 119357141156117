/* eslint-disable */
import Vue from "vue";

const store = {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    set(state, data) {
      state.data = data;
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await Vue.prototype.$api.Datasources.get();
      commit("set", data);

      return data;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
};

export default store;
