<template>
  <TagSelector
    :rules="rules"
    :items="timezones"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timezones: [
        { id: "America/New_York", title: "Eastern Time (ET)" },
        { id: "America/Chicago", title: "Central Time (CT)" },
        { id: "America/Los_Angeles", title: "Pacific Time (PT)" },
        { id: "America/Denver", title: "Mountain Time (MT)" },
        { id: "America/Anchorage", title: "Alaska Time (AST)" },
        { id: "Pacific/Honolulu", title: "Hawaii-Aleutian Time (HAT)" },
      ],
    };
  },
};
</script>

<style></style>
