<template>
  <div class="for-review-page" v-if="currentUser">
    <div class="page-title">For Review</div>
    <div class="page-subtitle">
      Below are active Creators, Gigs, Ideations, and Submissions for your
      review.
    </div>
    <ReviewMyToggle :value="my" @input="toggleMy($event)" />
    <div v-if="creatorsForReview" class="for-review__creators">
      <CreatorsSection
        :preview="true"
        :value="creatorsForReview"
        light
        review
      />
    </div>
    <div v-if="loaded && !loadingSubmissions">
      <SubmissionFormForReviewGroup
        :value="gigGroups"
        title="Gigs for Review"
      />
      <SubmissionFormForReviewGroup
        :value="otherGroups"
        title="Submissions for Review"
      />
    </div>
    <div class="no-entries" v-else-if="noSubmissions && !loadingSubmissions">
      No Submissions
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import IndexPage from "../index-page";
import SubmissionFormForReviewGroup from "@/components/review/SubmissionFormForReviewGroup.vue";
import ReviewMyToggle from "./shared/ReviewMyToggle.vue";

export default {
  name: "ForReviewPage",
  extends: IndexPage,
  components: { SubmissionFormForReviewGroup, ReviewMyToggle },
  head: {
    title: "For Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      gigGroups: null,
      otherGroups: null,
      creatorsForReview: null,
      creatorsForReviewInc: 0,
      my: true,
      loadingSubmissions: false,
    };
  },
  mounted() {
    this.fetchCreatorsForReview();
  },
  methods: {
    async fetchList() {
      this.loadingSubmissions = true;
      const params = {};
      if (this.my) params.my = true;
      const { data } = await this.$api.SubmissionForm.forReview(params);
      this.gigGroups = data.gig_groups;
      this.otherGroups = data.other_groups;
      this.loadingSubmissions = false;
    },
    async fetchCreatorsForReview() {
      const { data } = await this.$api.Creator.sections({
        section_name: "for_review",
      });
      this.creatorsForReview = data[0];
    },
    toggleMy(value) {
      this.my = value;
      this.fetchList();
    },
  },
  computed: {
    loaded() {
      return (
        (this.gigGroups && this.gigGroups.length > 0) ||
        (this.otherGroups && this.otherGroups.length > 0)
      );
    },
    noSubmissions() {
      return (
        this.gigGroups &&
        this.gigGroups.length == 0 &&
        this.otherGroups &&
        this.otherGroups.length == 0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.for-review {
  &__creators {
    margin-top: 77px;
  }
  &__my-toggle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__label {
      font-size: 14px;
      font-weight: bold;
      margin-right: 0.75em;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>
