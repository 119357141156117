<template>
  <tr class="payments-row">
    <td class="center-align">
      {{
        types[item.agreement_type] +
        (["msa_contractor", "msa_crew"].includes(item.agreement_type)
          ? ", " + item.msa_year
          : "")
      }}
    </td>
    <td class="center-align">
      {{
        item.gigs && item.gigs.length
          ? item.gigs.map((gig) => gig.title).join(", ")
          : "N/A"
      }}
    </td>
    <td
      class="agreement__status center-align"
      :class="`agreement__status--${item.status}`"
    >
      <span>{{ statuses[item.status] }}</span>
      <span v-if="item.email_viewed_at || item.documents_viewed_at">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18">$eye</v-icon>
          </template>
          <div>
            <div v-if="item.documents_viewed_at">
              Documents viewed at
              {{ item.documents_viewed_at | moment("hh:mm, dddd M/D/YYYY") }}
            </div>
            <div v-else-if="item.email_viewed_at">
              Email viewed at
              {{ item.email_viewed_at | moment("hh:mm, dddd M/D/YYYY") }}
            </div>
          </div>
        </v-tooltip>
      </span>
      <span v-if="item.name_changed_at">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="15">$pencil</v-icon>
          </template>
          <div>
            Name Changed
            {{ item.name_changed_to ? "to " + item.name_changed_to : "" }}
          </div>
        </v-tooltip>
      </span>
    </td>
    <td class="agreement__signed_at center-align">
      {{ signedAtFormatted(item) }}
    </td>
    <td>
      <TableRowAvatar :user="item.requested_by" />
    </td>
    <td class="agreement__requested_at center-align">
      {{ item.created_at | moment("timezone", currentTimezone, "MMM D, YYYY") }}
    </td>
    <td class="center-align">
      <ActionsMenu
        class="payments-row__actions-toggle"
        @close="menu = false"
        @toggle="menu = !menu"
        :menu="menu"
        :themeDisabledPage="themeDisabledPage"
        v-if="['OUT_FOR_SIGNATURE', 'SIGNED'].includes(item.status)"
      >
        <v-btn
          v-if="!item.has_signed_upload && item.status == 'SIGNED'"
          @click="downloadPdf(item)"
          color="#262729"
          dark
          elevation="0"
        >
          Download PDF
        </v-btn>
        <v-btn
          v-if="item.has_signed_upload && item.status == 'SIGNED'"
          @click="downloadPdf(item)"
          color="#262729"
          dark
          elevation="0"
        >
          Download
        </v-btn>
        <v-btn
          v-if="item.status == 'OUT_FOR_SIGNATURE'"
          @click="getSigningUrl(item)"
          color="primary"
          dark
          elevation="0"
        >
          Sign
        </v-btn>
      </ActionsMenu>
    </td>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </tr>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";

export default {
  mixins: [themeDisabledPageMixin, paymentsTableFormats],
  components: { TableRowAvatar },
  props: {
    item: Object,
  },
  data() {
    return {
      types: {
        msa_contractor: "MSA-Contractor",
        msa_crew: "MSA-Crew",
        gig_contract: "Gig Contract",
        gig_extension: "Gig Extension",
        w9: "W-9",
        ach_authorization: "ACH Authorization",
        other: "Other",
      },
      statuses: {
        CREATED: "Created",
        OUT_FOR_SIGNATURE: "Delivered",
        SIGNED: "Signed",
        SIGNED_OFFSITE: "Signed",
        CANCELLED: "Canceled",
        EXPIRED: "Expired",
      },
      menu: false,
      pleaseWait: false,
    };
  },
  methods: {
    async downloadPdf(item) {
      this.pleaseWait = true;
      const result = await this.$api.Agreement.documentUrl({ id: item.id });
      this.pleaseWait = false;
      if (result.status == 200) {
        window.open(result.data.url, "_blank");
      } else {
        this.$alert(result.data.alert || "Something went wrong", "error");
      }
    },
    async getSigningUrl(item) {
      this.pleaseWait = true;
      const result = await this.$api.Agreement.signingUrl({ id: item.id });
      this.pleaseWait = false;
      if (result.status == 200) {
        window.open(result.data.url, "_blank");
      } else {
        this.$alert(result.data.alert || "Something went wrong", "error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
@import "@/styles/agreements/payments-row.scss";
</style>
