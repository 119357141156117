<template>
  <DateTimePicker
    :placeholder="label + (required && markRequired ? ' *' : '')"
    :value="value"
    @input="$emit('input', $event)"
    only-date
    :start-year="new Date().getFullYear() - 100"
    :end-year="new Date().getFullYear() - 13"
    @focus.stop="onFocus($event)"
    :rules="[...rules, ...moreRules]"
  ></DateTimePicker>
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, Date],
    rules: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
    markRequired: Boolean,
  },
  data() {
    return {
      moreRules: [],
    };
  },
  created() {
    if (this.$attrs.short_name == "birthday") {
      this.moreRules.push(this.validation.birthday);
    }
  },
  methods: {
    onFocus($event) {
      this.$root.$emit("fullpageScrollFocus", $event.srcElement);
    },
  },
};
</script>

<style></style>
