export default {
  computed: {
    canSendForSigning() {
      return this.item.status == "CREATED" && this.currentUser.is_people_team;
    },
    canSendReminder() {
      return (
        this.item.status == "OUT_FOR_SIGNATURE" &&
        (this.currentUser.is_people_team ||
          this.currentUser.is_hiring_manager ||
          this.currentUser.is_finance)
      );
    },
    canDownloadPdf() {
      return (
        !this.item.has_signed_upload &&
        ["CREATED", "SIGNED"].includes(this.item.status) &&
        (this.currentUser.is_people_team ||
          this.currentUser.is_finance ||
          (this.currentUser.is_hiring_manager &&
            [
              "gig_contract",
              "gig_extension",
              "msa_contractor",
              "msa_crew",
            ].includes(this.item.agreement_type)))
      );
    },
    canDownloadSignedUpload() {
      return (
        this.item.has_signed_upload &&
        this.item.status == "SIGNED" &&
        (this.currentUser.is_people_team ||
          this.currentUser.is_finance ||
          (this.currentUser.is_hiring_manager &&
            [
              "gig_contract",
              "gig_extension",
              "msa_contractor",
              "msa_crew",
            ].includes(this.item.agreement_type)))
      );
    },
    canReplaceAgreementUpload() {
      return (
        this.item.has_signed_upload &&
        (this.currentUser.is_people_team || this.currentUser.is_finance)
      );
    },
    canCancel() {
      return (
        this.item.status == "OUT_FOR_SIGNATURE" &&
        (this.currentUser.is_people_team || this.currentUser.is_finance)
      );
    },
    canDoAnyAction() {
      return (
        this.canSendForSigning ||
        this.canSendReminder ||
        this.canDownloadPdf ||
        this.canDownloadSignedUpload ||
        this.canReplaceAgreementUpload ||
        this.canCancel
      );
    },
  },
};
