import _ from "lodash";

export default {
  methods: {
    addInvoiceItem(default_rate_type) {
      this.form.invoice_items.push({
        work_assignment_id: null,
        rate_type: default_rate_type,
        rate_units: null,
        amount: null,
        rate: null,
      });
      this.form.invoice_items = [...this.form.invoice_items];
    },
    removeInvoiceItem(idx) {
      if (this.form.invoice_items[idx].id) {
        this.form.invoice_items[idx]._destroy = true;
        this.form.invoice_items = [...this.form.invoice_items];
      } else {
        this.form.invoice_items.splice(idx, 1);
        this.form.invoice_items = [...this.form.invoice_items];
      }
    },
    updateInvoiceItem(item, idx) {
      this.form.invoice_items[idx] = item;
      this.form.invoice_items = [...this.form.invoice_items];
    },
    updateItemWorkAssignment(work_assignment_id, idx) {
      this.form.invoice_items[idx].work_assignment_id = work_assignment_id;
      this.form.invoice_items = [...this.form.invoice_items];
    },
    updateInvoiceField(object) {
      this.form[object.field] = object.value;
    },
    addInvoiceAttachment() {
      this.form.invoice_attachments.push({
        body: null,
      });
      this.form.invoice_attachments = [...this.form.invoice_attachments];
    },
    removeInvoiceAttachment(idx) {
      this.form.invoice_attachments.splice(idx, 1);
      this.form.invoice_attachments = [...this.form.invoice_attachments];
    },
  },
  computed: {
    total() {
      let arr = this.form.invoice_items.map((t) => {
        if (t._destroy) {
          return undefined;
        } else if (t.rate_type == "flat_fee") {
          return parseFloat(t.amount);
        } else if (t.rate_units && t.rate) {
          return t.rate_units * t.rate;
        }
        return 0;
      });
      arr = _.compact(arr);
      return +arr.reduce((a, b) => a + b, 0).toFixed(2);
    },
  },
};
