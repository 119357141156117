<template>
  <div class="theme-switch">
    <span>{{ currentTheme === 'dark' ? 'light' : 'dark' }} mode</span>
    <v-switch
      color="dark"
      inset
      hide-details
      v-model="isDark"
      @change="toggleTheme"
      aria-label="Theme switch"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      isDark: true
    }
  },

  mounted() {
    this.isDark = this.currentTheme === 'dark';
  },

  methods: {
    ...mapActions('theme', ['toggleTheme']),
  },

  computed: {
    ...mapGetters('theme', {
      currentTheme: 'current'
    })
  }
}
</script>

<style lang="scss" scoped>
.theme-switch {
  > span {
    text-transform: capitalize;
    font-size: 8px;
    color: var(--v-dark-base);
    -webkit-font-smoothing: subpixel-antialiased;

    & + * {
      margin-top: 0;
    }
  }

  ::v-deep .v-input {
    .v-input--switch__track {
      height: 17px;
      left: -3px;
      width: 35px;
      background-color: var(--v-dark-base);
      opacity: 1;
    }
    .v-input--switch__thumb {
      top: calc(50% - 11px);
      height: 11px;
      width: 11px;
      background-color: #DCDCE1;

      &.theme--dark {
        background-color: #56565A;
      }
    }
    .v-input--selection-controls__ripple {
      width: 23px;
      height: 23px;
      left: -13px;
      top: calc(50% - 23px);
    }

    &.v-input--is-dirty {
      .v-input--switch__thumb {
        transform: translate(18px, 0) !important;
      }
    }
  }
}
</style>
