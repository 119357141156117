<template>
  <div class="support-form">
    <h2 v-if="supportForm.headline" class="support-form__title page-title">{{
      supportForm.headline
    }}</h2>
    <div
      v-if="supportForm.description"
      class="support-form__description"
      v-html="supportForm.description"
    >
    </div>
    <DynamicForm
      :submit="submit"
      :fields="supportForm.support_form"
      v-model="form"
      v-if="!submitted"
      :btn-text="supportForm.btn_text"
      :key="updateKey"
      :hide-contact-fields="hideContactFields"
    />
    <div v-else class="support-form__submitted">
      Thank you! We will process your request and reply to you shortly.
    </div>
  </div>
</template>

<script>
import DynamicForm from "./DynamicFields/DynamicForm";

export default {
  components: { DynamicForm },
  props: {
    supportForm: Object,
    page: Object,
    hideContactFields: Boolean
  },
  data() {
    return {
      form: {
        support_form_id: null,
        data: {}
      },
      submitted: false,
      updateKey: 0,
    };
  },
  mounted(){
    this.prePopulate();
    this.$root.$on('resetSupportForm', (slug) => {
      if(this.submitted && this.supportForm.slug == slug) {
        setTimeout(() => {
          this.form.data = {};
          this.submitted = false;
          this.prePopulate();
        }, 250);
      }
    });
    this.$root.$on('requestReferralsPopulate', () => {
      if(this.supportForm.slug == "request_referrals" && this.currentUser) {
        const uuids = {
          firstName: this.findFieldUuid("first_name"),
          lastName: this.findFieldUuid("last_name"),
          email: this.findFieldUuid("email"),
        }
        if( !this.form.data[uuids.firstName] &&
            !this.form.data[uuids.lastName] &&
            !this.form.data[uuids.email] ) {
          this.form.data[uuids.firstName] = this.currentUser.first_name;
          this.form.data[uuids.lastName] = this.currentUser.last_name;
          this.form.data[uuids.email] = this.currentUser.email;
          this.updateKey++;
        }
      }
    });
  },
  methods: {
    prePopulate(){
      if(this.supportForm.populate_from_user && this.currentUser) {
        this.supportForm.support_form.forEach( (field) => {
          if(field.short_name == "first_name") {
            this.form.data[field.uuid] = this.currentUser.first_name;
          } else if(field.short_name == "last_name") {
            this.form.data[field.uuid] = this.currentUser.last_name;
          } else if(field.short_name == "email") {
            this.form.data[field.uuid] = this.currentUser.email;
          }
        });
        this.formKey++;
      }
    },
    findFieldUuid(short_name) {
      return this.supportForm.support_form.find((field) => field.short_name == short_name)?.uuid;
    },
    async submit() {
      try {
        this.form.support_form_id = this.supportForm.id;
        const user_agent = { userAgent: navigator.userAgent };
        if(this.$ua.isWindows) user_agent.os = "Windows";
        if(this.$ua.isMacOS) user_agent.os = "Mac OSX";
        if(this.$ua.isLinux) user_agent.os = "Linux";
        if(this.$ua.isUbuntu) user_agent.os = "Ubuntu";
        if(this.$ua.isAndroid) user_agent.os = "Android";
        if(this.$ua.isIOS) user_agent.os = "IOS";
        
        if(this.$ua.isChrome) {
          user_agent.name = "Chrome";
          user_agent.vendor = "Google Inc.";
        }
        if(this.$ua.isFirefox) {
          user_agent.name = "Firefox";
          user_agent.vendor = "Mozilla";
        }
        if(this.$ua.isSafari) {
          user_agent.name = "Safari";
          user_agent.vendor = "Apple Computer, Inc.";
        }
        if(this.$ua.isEdge) {
          user_agent.name = "Edge";
          user_agent.vendor = "Microsoft Inc.";
        }
        if(this.$ua.isIE) {
          user_agent.name = "Internet Explorer";
          user_agent.vendor = "Microsoft Inc.";
        }

        this.form.user_agent = user_agent;
        await this.$api.Support.create(this.form);
        this.submitted = true;
      } catch {
        //
      }
    }
  }
};
</script>

<style scoped lang="scss">
.support-form {
  max-width: 800px;
  margin: auto;
  width: 100%;
  padding: 20px;
  font-family: Source Sans Pro, sans-serif;

  &__title {
    font-family: Source Sans Pro, sans-serif;
    font-size: 36px !important;
    margin-bottom: 0.5em;
  }

  &__description {
    font-size: 18px;
    margin: 10px 0 20px 0;
  }

  &__submitted {
    font-size: 24px;
  }

  ::v-deep {
    .v-text-field__details {
      min-height: 0;
    }
    .v-messages {
      display: none;
      &.error--text {
        display: block;
      }
    }
  }
}
</style>
