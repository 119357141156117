export default function({ kind, id, parent_id }) {
  const match = {
    Theme: "/themes/{{id}}",
    Page: "/pages/{{id}}/edit",
    Event: "/events/{{id}}/edit",
    Chat: "/chats/{{id}}/edit",
    SupportForm: "/support/{{id}}/edit",
    User: "/users/participants/{{id}}/edit",
    "Widget::ContentSection": "/widgets/content-sections/{{id}}/edit",
    "Widget::ContentBlock":
      "/widgets/content-sections/{{parent_id}}/blocks/{{id}}/edit",
    "Widget::Countdown": "/widgets/countdowns/{{id}}/edit",
    "Widget::CustomComponent": "/dev-tools/custom-components/{{id}}/edit",
    "Widget::HtmlBlock": "/widgets/html-blocks/{{id}}/edit",
    "Widget::List": "/widgets/lists/{{id}}/edit",
    "Widget::Notification": "/widgets/notifications/{{id}}/edit",
    "Widget::Presentation": "/dev-tools/presentations/{{id}}/edit",
    "Widget::Schedule": "/widgets/schedules/{{id}}/edit",
    "Widget::ScheduleItem":
      "/widgets/schedules/{{parent_id}}/items/{{id}}/edit",
    "Widget::SubmissionForm": "/widgets/submission-forms/{{id}}/edit",
    "Widget::SubmissionContent": "/widgets/submission-contents/{{id}}/edit",
    "Widget::TextBlock": "/widgets/text-blocks/{{id}}/edit",
  };

  const res = (match[kind] || "")
    .replace("{{id}}", id)
    .replace("{{parent_id}}", parent_id);

  return res;
}
