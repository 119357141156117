<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0">Invoice #</th>
            <th>
              <div>Date Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Project(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="status"
                fieldLabel="Status"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">Actions</th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <MyPaymentsTableRow
            v-for="item in items"
            :key="`${item.id}-${reloadKey}`"
            :item="item"
            @openDialog="
              dialog = true;
              activeInvoiceId = item.id;
            "
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="center-align">No payments</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn
          class="dialog-close"
          icon
          @click="
            dialog = false;
            activeInvoiceId = null;
          "
        >
          <v-icon>$close</v-icon>
        </v-btn>
        <MyInvoice
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`myinvoice-${activeInvoiceId}-${reloadKey}`"
          @refresh="refresh"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyInvoice from "./MyInvoice";
import MyPaymentsTableRow from "./table-row.my-payments.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";

export default {
  components: {
    MyInvoice,
    MyPaymentsTableRow,
    TableHeaderActions,
  },
  props: {
    title: String,
    items: Array,
    listOptions: Object,
  },
  data() {
    return {
      dialog: false,
      activeInvoiceId: null,
      reloadKey: 0,
    };
  },
  methods: {
    refresh() {
      this.dialog = false;
      this.reloadKey++;
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
.payments-table {
  td,
  th {
    text-align: center;
  }
  &-wrapper {
    margin-top: 100px;
  }

  &-head {
    background: #7b61ff;
    color: white;
  }
}
</style>
