<template>
  <div>
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <CreatorHireRequestsTable
      :creator="currentUser"
      :list="list"
      :class="{ 'table-loading': loadingList }"
      :listOptions="listOptions"
      @orderUpdate="orderUpdate($event)"
      @filterUpdate="filterUpdate($event)"
      :reloadKey="reloadKey"
      :title="'My Gigs'"
      my
    >
      <template #actions>
        <div class="payments-table__action">
          <div class="payments-table__action__label">Filter by:</div>
          <v-overlay :value="filtersMenu" @click.native.stop="closeFilters()" />
          <v-menu
            bottom
            offset-y
            :close-on-click="false"
            :close-on-content-click="false"
            v-model="filtersMenu"
            light
            class="filters-menu"
            content-class="filters-menu__content"
          >
            <template v-slot:activator="{ on }">
              <TableActiveFilters
                v-on="on"
                @openMenu="filtersMenu = true"
                :listOptions="listOptions"
                :fields="filterFields"
                @resetFilter="resetFilter($event)"
                :activeListOption="activeListOption"
              />
            </template>
            <v-card class="filters-menu__content__card">
              <div class="filters-menu__body">
                <v-expansion-panels v-model="filterPanels" multiple flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Project
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <InternalProjectsSelector
                        v-model="listOptions.internal_project_id"
                        emptyLabel="All"
                        :params="{ hire_requests: true, my: true }"
                        @input="
                          optionsUpdate();
                          closeFilters();
                        "
                        multiple
                        chipText="code"
                        @remove="
                          removeFromFilter('internal_project_id', $event);
                          optionsUpdate();
                          closeFilters();
                        "
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <TableOptionsFilterPanel
                    :listOptions="listOptions"
                    fieldName="contract_status"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Contract Status"
                    selectAll
                    reset
                    :defaultListOptions="getDefaultListOptions()"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="created_at"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Date Created"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="start_date"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="Start Date"
                  />
                  <TableDateFilterPanel
                    :listOptions="listOptions"
                    fieldName="end_date"
                    @filterUpdate="filterMenuUpdate($event)"
                    fieldLabel="End Date"
                  />
                </v-expansion-panels>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div class="payments-table__action">
          <div class="payments-table__action__label"></div>
          <TextField
            v-model="searchInput"
            emptyLabel="All"
            :params="{ hire_requests: true }"
            placeholder="Search"
          />
        </div>
        <div class="payments-table__secondary-actions">
          <div
            class="payments-table__action-btn payments-table__action-btn--reset"
            v-if="anyActiveListOptions"
          >
            <div @click="resetListOptions()">
              <v-icon size="14">$close</v-icon> Reset
            </div>
          </div>
        </div>
      </template>
      <template #pagination>
        <TablePagination
          :page="page"
          :totalPages="totalPages"
          @input="page = $event"
          :key="`pagination-${reloadKey}`"
        />
      </template>
    </CreatorHireRequestsTable>
  </div>
</template>

<script>
import IndexPage from "./index-page";
import CreatorHireRequestsTable from "./creator-hire-requests/table.creator-hire-requests";
import hireRequestsTableActions from "@/mixins/hire-requests/hire-requests-table-actions.mixin";
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";
import TableDateFilterPanel from "@/components/tables/TableDateFilterPanel";
import TableOptionsFilterPanel from "@/components/tables/TableOptionsFilterPanel";
import TableActiveFilters from "@/components/tables/TableActiveFilters";

export default {
  components: {
    CreatorHireRequestsTable,
    TablePagination,
    TableDateFilterPanel,
    TableOptionsFilterPanel,
    TableActiveFilters,
  },
  extends: IndexPage,
  mixins: [hireRequestsTableActions, paginatedTable],
  head() {
    return {
      title: "My Gigs",
      titleTemplate: "%s | iU Community",
    };
  },
  data() {
    return {
      list: [],
      loadingList: false,
      reloadKey: 1,
      filterFields: [
        { name: "internal_project_id", label: "Project" },
        { name: "contract_status", label: "Status" },
        { name: "start_date", label: "Start Date" },
        { name: "end_date", label: "End Date" },
        { name: "created_at", label: "Date Requested" },
      ],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async refreshAll() {
      this.sendAgreementDialog = false;
      this.fetchList();
      this.reloadKey++;
    },
    getParams() {
      const params = { ...this.listParams };
      params.page = this.page;
      params.per = ["sm", "xs"].includes(this.$size) ? 5 : 10;
      return params;
    },
    async fetchList() {
      this.loadingList = true;
      const params = this.getParams();
      const { data } = await this.$api.HireRequest.my(params);
      this.list = data.hire_requests;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table-filters-menu.scss";

.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
