<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="payments-table-container">
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0 center-align">Invoice</th>
            <th>
              <div>Date Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
            <th class="w0 center-align">Amount</th>
            <th class="w0 center-align" v-if="completedPayments">
              <div>Date Paid</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="paid_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Paid"
                isDate
              />
            </th>
            <th>
              <div>Project(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">
              <div>Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="status"
                fieldLabel="Status"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th>
              <div>Creator</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="user"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="items && items.length">
          <PaymentsTableRow
            v-for="item in items"
            :key="item.id"
            :item="item"
            @openDialog="
              dialog = true;
              activeInvoiceId = item.id;
            "
            @openPayDirectlyDialog="
              payDirectlyDialogOpen = true;
              activeInvoiceId = item.id;
            "
            @refresh="$emit('refresh')"
            @openAuditDialog="
              auditDialog = true;
              activeInvoiceId = item.id;
            "
            :completedPayments="completedPayments"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100" class="center-align">No payments</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
    <v-dialog max-width="700" v-model="dialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="dialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <InternalInvoice
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`hminvoice-${activeInvoiceId}`"
          @refresh="
            dialog = false;
            $emit('refresh');
          "
        />
      </v-card>
    </v-dialog>
    <HmPayDirectlyDialog
      v-if="activeInvoiceId"
      :dialog="payDirectlyDialogOpen"
      :value="activeInvoiceId"
      :key="`payinvoice-${activeInvoiceId}`"
      @refresh="
        payDirectlyDialogOpen = false;
        $emit('refresh');
      "
      @close="
        payDirectlyDialogOpen = false;
        activeInvoiceId = null;
      "
    />
    <v-dialog max-width="800" v-model="auditDialog">
      <v-card light>
        <v-btn class="dialog-close" icon @click="auditDialog = false">
          <v-icon>$close</v-icon>
        </v-btn>
        <InvoiceAuditTrail
          v-if="activeInvoiceId"
          :value="activeInvoiceId"
          :key="`hminvoice-${activeInvoiceId}`"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InternalInvoice from "@/components/work-assignments/InternalInvoice";
import PaymentsTableRow from "./table-row.payments.vue";
import HmPayDirectlyDialog from "../HmPayDirectlyDialog";
import TableHeaderActions from "@/components/tables/TableHeaderActions";
import InvoiceAuditTrail from "@/components/work-assignments/InvoiceAuditTrail";

export default {
  components: {
    InternalInvoice,
    PaymentsTableRow,
    HmPayDirectlyDialog,
    TableHeaderActions,
    InvoiceAuditTrail,
  },
  props: {
    title: String,
    items: Array,
    listOptions: Object,
    completedPayments: Boolean,
  },
  data() {
    return {
      dialog: false,
      activeInvoiceId: null,
      payDirectlyDialogOpen: false,
      auditDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";

.payments-table {
  &-wrapper {
    margin-top: 100px;
  }

  &-head {
    color: white;
    background: #4282ff;
  }

  &__actions-toggle {
    position: absolute;
    top: 16px;
    right: 11px;
  }
  &__actions {
    position: relative;
    > .v-icon {
      display: block;

      &.theme--dark ~ .creator-item__actions-menu {
        background-color: var(--v-secondary-base);
      }
    }
    &-menu {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      width: 245px;
      border-radius: 10px;
      text-align: center;
      box-shadow: none;
      &-content {
        position: relative;
        background: #dcdce1;
      }
      .dialog-close {
        position: absolute;
        top: 0;
        right: 0;
      }
      .v-btn {
        font-weight: bold;
      }
      &-header {
        width: 100%;
        background: white;
        min-height: 36px;
        border-radius: 10px 10px 0 0;
        font-size: 20px;
        font-weight: 900;
        padding: 12px;
      }
      &__main {
        .v-btn:not(.v-btn--icon) {
          min-width: 131px;
          padding: 0 10px;
        }
      }
      &__main {
        padding-top: 27px;
        padding-bottom: 29px;
        .v-btn:not(.v-btn--icon):not(:last-child) {
          margin-bottom: 21px;
        }
      }
    }
  }
}
</style>
