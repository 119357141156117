<template>
  <div class="profile-internal" v-if="loaded">
    <div class="profile-internal__projects" v-if="internalProjects.length">
      <div class="profile-internal__header">All iU Projects</div>
      <div class="profile-internal__content">
        <div
          v-for="item in internalProjects"
          :key="`internal-project--${item}`"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      loaded: false,
      internalProjects: [],
    };
  },
  mounted() {
    if (this.currentUserIsInternal) this.fetchInternalDetails();
  },
  methods: {
    async fetchInternalDetails() {
      try {
        const { data } = await this.$api.User.profileInternal({
          id: this.id,
        });
        this.internalProjects = data.internal_projects;
        this.loaded = true;
      } catch (e) {
        console.info("ProefileInternalSection error", e);
      }
    },
  },
  computed: {
    currentUserIsInternal() {
      return (
        this.currentUser &&
        _.intersection(this.currentUser.roles, [
          "reviewer",
          "finance",
          "people_team",
          "hiring_manager",
        ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-internal {
  padding: 86px 70px 67px 70px;
  background: #262729;
  color: white;
  font-size: 14px;
  line-height: 1.79;
  font-weight: 900;
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    max-width: 536px;
  }
  &__header {
    color: #e12d1b;
    font-size: 20px;
    line-height: 1.4;
  }

  &__projects &__content {
    column-count: 3;
  }
}

@media screen and (max-width: 960px) {
  .profile-internal {
    padding: 34px 31px;
    > div {
      width: 100%;
    }
  }
}
@media screen and (max-width: 960px) {
  .profile-internal {
    &__projects &__content {
      column-count: 2;
    }
  }
}
</style>
