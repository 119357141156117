<template>
  <div>
    <v-dialog
      width="700"
      v-model="dialog"
      @click:outside="cancel"
      light
      content-class="send-agreement-dialog reupload-agreement-dialog"
    >
      <v-card class="reupload-agreement__content">
        <div class="reupload-agreement__header">
          <div class="reupload-agreement__title">Replace Agreement Upload</div>
          <v-btn class="close" icon @click="cancel">
            <v-icon size="30">$close</v-icon>
          </v-btn>
        </div>
        <div class="reupload-agreement__info">
          <div style="width: 100%">
            <div class="form-row form-row--static">
              <div class="form-label">Creator:</div>
              <div class="form-field">
                <TableRowAvatar
                  class="reupload-agreement__form__avatar"
                  :user="item.user"
                />
              </div>
            </div>
            <div class="form-row form-row--simple">
              <div class="form-label">Agreement Type:</div>
              <div class="form-field">
                {{ agreementTypes[item.agreement_type] }}
              </div>
            </div>
            <div
              class="form-row form-row--simple"
              v-if="item.hire_request_monday_com_item_id"
            >
              <div class="form-label">Hire Request:</div>
              <div class="form-field">
                #{{ item.hire_request_monday_com_item_id }}
              </div>
            </div>
            <div
              class="form-row form-row--simple"
              v-if="['SIGNED_OFFSITE', 'SIGNED'].includes(item.status)"
            >
              <div class="form-label">Date Requested:</div>
              <div class="form-field">
                {{ dateFormatted(item.created_at, "MMM D, YYYY") }}
              </div>
            </div>
            <div
              class="form-row form-row--simple"
              v-if="['SIGNED_OFFSITE', 'SIGNED'].includes(item.status)"
            >
              <div class="form-label">Date Signed:</div>
              <div class="form-field">
                {{ signedAtFormatted(item) }}
              </div>
            </div>
            <div class="form-row form-row--simple" v-if="item.uploaded_at">
              <div class="form-label">Date Uploaded:</div>
              <div class="form-field">
                {{ dateFormatted(item.uploaded_at, "MMM D, YYYY") }}
              </div>
            </div>
            <div
              class="form-row form-row--simple"
              v-if="item.signed_upload_filename"
            >
              <div class="form-label">Signed Upload:</div>
              <div class="form-field">
                <div
                  class="reupload-agreement__form__signed-upload"
                  @click="downloadPdf(item)"
                >
                  {{ item.signed_upload_filename }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reupload-agreement__form">
          <v-form @submit.prevent="submit" ref="form">
            <div>
              <div class="form-row">
                <div class="form-label">Replacement Upload*</div>
                <div class="form-field signed-upload">
                  <div class="signed-upload__body">
                    <FileField
                      placeholder="Upload"
                      v-model="form.signed_upload"
                      :rules="[validation.required]"
                    />
                  </div>
                  <div class="signed-upload__actions">
                    <v-btn icon @click="form.signed_upload = null">
                      <v-icon size="24"> $delete </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="invoice-form__footer">
              <v-btn
                type="submit"
                color="#7B61FF"
                elevation="0"
                class="btn-submit"
              >
                Submit
              </v-btn>
              <v-btn
                @click="cancel"
                elevation="0"
                v-ripple="false"
                color="#56565A"
                class="btn-cancel"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <PleaseWaitDialog :dialog="pleaseWait" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import peopleTeamActions from "@/mixins/agreements/people-team-actions.mixin";

export default {
  components: { TableRowAvatar },
  mixins: [paymentsTableFormats, peopleTeamActions],
  props: {
    dialog: Boolean,
    item: Object,
  },
  data() {
    return {
      form: {
        signed_upload: null,
      },
      agreementTypes: {
        gig_contract: "Gig Contract",
        gig_extension: "Gig Extension",
        other: "Other",
        msa_contractor: "MSA, Contractor",
        msa_crew: "MSA, Crew",
        w9: "W-9",
        ach_authorization: "ACH Authorization",
      },
      pleaseWait: false,
    };
  },
  methods: {
    async submit() {
      this.pleaseWait = true;
      const transaction_id = uuidv4();
      const params = { ...this.form, transaction_id, id: this.item.id };
      this.$store.commit("agreement/setTransactionId", transaction_id);
      try {
        const result = await this.$api.Agreement.replaceUpload(params);
        this.pleaseWait = false;
        if (result.status == 200) {
          this.$alert("Uploaded agreement file replaced.", "success");
          this.cancel();
          this.$emit("refresh");
        }
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
    cancel() {
      this.$emit("close");
      this.form = {};
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/payments/payments-dialog.scss";
</style>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";

.reupload-agreement {
  &__header {
    padding: 35px 44px 30px;
    border-bottom: 2px solid #262729;
  }

  &__title {
    color: #e12d1b;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 900;
  }

  &__info,
  &__form {
    padding: 21px 64px 30px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    form {
      width: 100%;
    }
    .form-label {
      text-align: left;
      font-weight: bold;
      color: #56565a;
      margin-right: 0.35em;
    }
    .form-row {
      margin-top: 1em;
      &--static {
        display: flex;
        flex-direction: row;
        align-items: center;
        .form-label {
          margin-right: 1em;
          margin-bottom: 0;
          margin-top: 0;
        }
        .form-field {
          width: auto;
        }
      }
      &--simple {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-top: 0.5em;
        .form-label {
          margin-right: 0.25em;
          margin-top: 0;
        }
      }
    }
    &__signed-upload {
      cursor: pointer;
      text-decoration: underline;
    }

    .invoice-form__footer .v-btn:not(:last-child) {
      margin-right: 1em;
    }
  }

  &__info {
    padding: 21px 64px 0px 44px;
  }
}

.signed-upload {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    flex-grow: 1;
    margin-right: 1em;
    &::v-deep .uploader {
      border-color: #56565a !important;
      border-width: 2px !important;
      border-radius: 10px;
    }
  }
}
</style>
