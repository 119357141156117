const profileToFrontend = () => {
  const redirectTo = {};
  redirectTo["http://localhost:8081"] = "http://localhost:8000";
  redirectTo["https://profile.community.ideasunited.com"] =
    "https://community.ideasunited.com";
  redirectTo["https://profile.staging-community.ideasunited.com"] =
    "https://staging-community.ideasunited.com";

  return redirectTo;
};

export { profileToFrontend };
