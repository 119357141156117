<template>
  <v-navigation-drawer
    close-on-click
    right
    floating
    fixed
    temporary
    clipped
    :value="value"
    @input="$emit('input', $event)"
    app
    :width="300"
    class="sidebar"
    :hide-overlay="isMobile"
    touchless
    light
  >
    <v-btn icon class="sidebar__close" @click="$emit('input', false)">
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 1.31152L19.6777 18.9892"
          stroke="#E12D1B"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 19.3115L19.6777 1.63385"
          stroke="#E12D1B"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </v-btn>

    <v-list dense class="sidebar-list">
      <router-link
        :to="`/p/${currentUser.username || currentUser.id}`"
        color="primary"
        class="elevation-0 sidebar__item sidebar__item-profile"
        v-if="currentUser"
      >
        <div
          class="sidebar__item-preview"
          :style="{
            backgroundImage: avatarImage,
          }"
        ></div>
      </router-link>
      <template v-for="menuItem in menuPages">
        <v-list-item
          :to="menuItem.to"
          :key="menuItem.id"
          class="sidebar__item"
          :class="{ 'sidebar__item-nohighlight': menuItem.no_highlight }"
          @click.prevent="clickItem(menuItem)"
        >
          <span class="sidebar__item-title">
            {{ menuItem.menu_title }}
          </span>
          <transition name="fade" v-if="menuItem.to === '/opportunities'">
            <div v-if="newOpportunityCount > 0" class="sidebar__item-opp-count">
              {{ newOpportunityCount }}
            </div>
          </transition>
        </v-list-item>
        <v-list
          dense
          :key="menuItem.id"
          v-if="menuItem.to === '/' && currentUser && currentUser.confirmed"
        >
          <v-list-item
            v-for="item in userPortalPages"
            :to="item.to"
            :key="item.id"
            class="sidebar__item__subitem"
            @click.prevent="clickItem(item)"
          >
            <span class="sidebar__item__subitem-title">
              {{ item.menu_title }}
            </span>
            <v-tooltip left top>
              <template v-slot:activator="{ on }">
                <transition name="fade" v-if="item.to === '/my-agreements'">
                  <div
                    class="sidebar__item-opp-count"
                    v-if="currentUser.unsigned_agreement_count > 0"
                    v-on="on"
                  >
                    {{ currentUser.unsigned_agreement_count }}
                  </div>
                </transition>
              </template>
              <div>
                {{
                  currentUser.unsigned_agreement_count == 1
                    ? "You have an agreement that needs your signature."
                    : "You have agreements that need your signature."
                }}
              </div>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </template>
      <v-list-item
        v-if="!currentUser"
        class="sidebar__item"
        @click.prevent="clickLogin()"
      >
        <span class="sidebar__item-title"> Login/Register </span>
      </v-list-item>
      <v-list-item v-else class="sidebar__item" @click.prevent="logout()">
        <span class="sidebar__item-title"> Logout </span>
      </v-list-item>
    </v-list>
    <div class="sidebar__footer">
      <div class="sidebar__logo">
        <img src="@/assets/sidebar_logo.svg" alt="Logo" @click="logoClick()" />
        <div class="sidebar__copyright">© {{ year }} Ideas United</div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { profileToFrontend } from "@/lib/domains";

export default {
  name: "RightBar",
  props: {
    value: Boolean,
  },
  data() {
    return {
      logo: require("@/assets/sidebar_logo.svg"),
      year: new Date().getFullYear(),
    };
  },
  created() {
    if (this.currentUser) {
      this.$cable.subscribe({
        channel: "AgreementCreatorChannel",
        token: this.authToken,
        env: this.isProduction() ? "production" : "staging",
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$emit("input", false);
    },
    clickLogin() {
      this.$root.$emit(`authModal/open`);
    },
    clickItem(item) {
      if (item.logout) this.logout();
    },
    logoClick() {
      window.location.href = profileToFrontend()[window.location.origin];
      this.$emit("input", false);
    },
    async handleAgreementBroadcast() {
      await this.$store.dispatch("user/fetch");
    },
  },
  computed: {
    ...mapGetters("opportunity", {
      newOpportunityCount: "newCount",
    }),
    isMobile() {
      return ["xs", "sm"].includes(this.$size);
    },
    menuPages() {
      let menuPages = [
        {
          menu_title: "Opportunities",
          to: "/opportunities",
        },
      ];
      if (this.currentUser && this.currentUser.confirmed) {
        menuPages.push(
          {
            menu_title: "Creators",
            to: "/creators",
          },
          {
            menu_title: "User Portal",
            to: "/",
            no_highlight: true,
          }
        );
      }
      return menuPages.filter((t) => !t.parent_menu_id);
    },
    userPortalPages() {
      let items = [
        {
          menu_title: "Edit Profile",
          to: "/profile",
        },
      ];

      if (this.currentUser.is_reviewer) {
        items.push({
          menu_title: "For Review",
          to: "/for-review",
        });
      }

      items = items.concat([
        {
          menu_title: "Submissions",
          to: "/submissions",
        },
        ...(this.currentUser.badges.find((t) => t.slug == "referrer")
          ? [
              {
                menu_title: "Referrals",
                to: "/referral",
              },
            ]
          : []),
        ...(this.currentUser.roles.find((t) => t.name == "opportunity_creator")
          ? [
              {
                menu_title: "Create Opportunity",
                to: "/create-opportunity",
              },
            ]
          : []),
        ...(!this.currentUser.roles.find((t) => t.name == "hiring_manager") &&
        !this.currentUser.roles.find((t) => t.name == "finance") &&
        !this.currentUser.roles.find((t) => t.name == "opportunity_creator") &&
        !this.currentUser.roles.find((t) => t.name == "people_team")
          ? [
              {
                menu_title: "My Gigs",
                to: "/my-gigs",
              },
              {
                menu_title: "My Payments",
                to: "/my-payments",
              },
              {
                menu_title: "My Agreements",
                to: "/my-agreements",
              },
            ]
          : []),
        ...(this.currentUser.roles.find((t) => t.name == "hiring_manager")
          ? [
              {
                menu_title: "Payments",
                to: "/payments",
              },
            ]
          : []),
        ...(this.currentUser.roles.find((t) => t.name == "finance") ||
        this.currentUser.roles.find((t) => t.name == "people_team")
          ? [
              {
                menu_title: "Payments Dashboard",
                to: "/payments-dashboard",
              },
            ]
          : []),
        ...(this.currentUser.roles.find((t) => t.name == "people_team") ||
        this.currentUser.roles.find((t) => t.name == "hiring_manager") ||
        this.currentUser.roles.find((t) => t.name == "finance")
          ? [
              {
                menu_title: "Agreements",
                to: "/agreements",
              },
              {
                menu_title: "Hire Requests",
                to: "/hire-requests",
              },
            ]
          : []),
        ...(this.currentUser.roles.find((t) => t.name == "finance") ||
        this.currentUser.roles.find((t) => t.name == "people_team")
          ? [
              {
                menu_title: "Onboarding Dashboard",
                to: "/onboarding-dashboard",
              },
            ]
          : []),
      ]);

      return items;
    },
    fullpage() {
      return [
        "Opportunities",
        "ProfileView",
        "ClaimAccount",
        "Creators",
        "BadgeInfo",
        "WildcardPage",
      ].includes(this.$route.name);
    },
    avatarImage() {
      return this.currentUser?.avatar?.url
        ? `url(${this.currentUser.avatar.thumb.url})`
        : `url("https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/75a8c299-edb9-41c1-9071-3101364c39af/default_user_avatar.svg")`;
    },
  },
  channels: {
    AgreementCreatorChannel: {
      connected() {},
      received() {
        this.handleAgreementBroadcast();
      },
    },
  },
  beforeDestroy() {
    this.$cable.unsubscribe({
      channel: "AgreementCreatorChannel",
    });
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  z-index: 8;
  &::v-deep {
    .v-navigation-drawer__content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }
  &__logo {
    max-width: 269px;
    margin: 10px 0;
    cursor: pointer;

    img {
      width: 230px;
    }
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &-list {
    padding-top: 70px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__item {
    padding: 14px 30px;
    text-transform: uppercase;
    justify-content: center;
    width: 100%;
    &-title {
      font-weight: bold;
      font-size: 24px;
      font-weight: 700;
      color: #56565a !important;
    }
    &-profile {
      display: flex;
    }
    &-preview {
      height: 107px;
      width: 107px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
    &__subitem {
      text-transform: none;
      justify-content: center;
      &-title {
        color: #56565a;
        font-size: 20px;
        text-align: center;
      }
    }
    &-nohighlight:before {
      opacity: 0;
    }
    &-opp-count {
      display: inline-block;
      color: white;
      background: var(--v-primary-base);
      font-size: 14px;
      min-width: 21px;
      height: 21px;
      border-radius: 50%;
      text-align: center;
      margin-bottom: 0.1em;
      margin-left: 0.25em;
      letter-spacing: 0;
      font-weight: 400;
    }
  }

  &__mobile-separator {
    width: auto;
    height: 2px;
    background: black;
    margin: 32px 46px 18px;
  }

  &__copyright {
    color: #56565a;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
  }
}

@media screen and (max-width: 680px) {
  .sidebar {
    z-index: 8;
    height: auto !important;
    bottom: 0;
  }
}
</style>
