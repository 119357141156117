<template>
  <div class="dynamic-textarea">
    <div class="dynamic-textarea__label" v-if="showLabel">
      {{ label
      }}<span v-if="required && markRequired" class="select__required">*</span>
    </div>
    <v-textarea
      class="dynamic-textarea__input"
      :placeholder="
        showLabel ? '' : label + (required && markRequired ? ' *' : '')
      "
      :value="value"
      @input="$emit('input', $event)"
      :rules="rules"
      auto-grow
      outlined
      light
    />
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    rules: {
      type: Array,
      default: () => [],
    },
    required: Boolean,
    markRequired: Boolean,
    showLabel: Boolean,
  },
};
</script>

<style scoped lang="scss">
.dynamic-textarea {
  &__label {
    font-weight: bold;
  }
  &::v-deep {
    textarea {
      line-height: 1.2;
    }
  }
}
</style>
