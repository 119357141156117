<template>
  <div class="notification-center">
    <div class="swiper-button-prev" v-if="showControls()" @click="swipePrev()">
      <v-icon color="primary" size="50">
        {{
          ["xs", "sm"].includes($size) ? "$chevron_left" : "$chevron_left_large"
        }}
      </v-icon>
    </div>
    <div class="swiper-button-next" v-if="showControls()" @click="swipeNext()">
      <v-icon color="primary" size="50">
        {{
          ["xs", "sm"].includes($size)
            ? "$chevron_right"
            : "$chevron_right_large"
        }}
      </v-icon>
    </div>
    <div class="notification-center-wrapper" v-if="list.length">
      <component
        :is="enableSwiper ? 'swiper' : 'div'"
        ref="swiper"
        class="notification-center__list swiper"
        :options="swiperOption"
      >
        <component
          :is="enableSwiper ? 'swiper-slide' : 'div'"
          class="notification"
          v-for="(item, idx) in enableSwiper ? list : mobileList"
          :key="item.id"
        >
          <v-btn
            class="notification__mark-as-read"
            icon
            @click="markAsRead(item)"
          >
            <v-icon color="primary">$close</v-icon>
          </v-btn>
          <v-btn
            class="notification__open-dialog"
            icon
            @click="item.site ? false : openDialog(idx)"
            :href="item.site ? item.site : false"
            :target="item.site ? '_blank' : false"
          >
            <v-icon color="primary">$eye</v-icon>
          </v-btn>
          <component
            :is="item.site ? 'a' : 'div'"
            :href="item.site ? item.site : false"
            :target="item.site ? '_blank' : false"
            class="notification__content"
            @click="item.site ? false : openDialog(idx)"
          >
            <div class="notification__top">
              <div
                class="notification__thumb"
                v-if="previewUrl(item)"
                :style="{ backgroundImage: `url(${previewUrl(item)})` }"
              ></div>
              <div class="notification__top-right">
                <div class="notification__type">{{ typeLabel(item) }}</div>
                <div class="notification__title">{{ item.title }}</div>
              </div>
            </div>
            <div
              class="notification__description"
              v-html="item.og_description"
            ></div>
          </component>
        </component>
      </component>
      <v-btn
        v-if="!enableSwiper && mobileListLength < list.length"
        @click="mobileShowMore"
        large
        color="primary"
        elevation="0"
      >
        See More
      </v-btn>
    </div>
    <ProfileNotificationDialog
      :dialog="dialog"
      :item="selectedItem"
      @close="closeDialog()"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      swiperOption: {
        keyboard: {
          enabled: true,
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
        slidesPerView: 4,
        spaceBetween: 20,
      },
      dialog: false,
      selectedItem: {},
      mobileListLength: 2,
    };
  },
  created() {
    this.setList();
  },
  mounted() {},
  methods: {
    async setList() {
      const { data } = await this.$api.ProfileNotification.get({
        page: this.page,
      });
      this.list = data;
    },
    previewUrl(notification) {
      if (!notification.preview) return;
      if (notification.preview.thumb && notification.preview.thumb.url)
        return notification.preview.thumb.url;
      return notification.preview.url;
    },
    async markAsRead(item) {
      await this.$api.ProfileNotification.markAsRead({
        id: item.notification_type == "Opportunity" ? item.project_id : item.id,
        type: item.notification_type,
      });
      await this.setList();
      this.$store.dispatch("opportunity/fetchNewCount", null, { root: true });
    },
    typeLabel(item) {
      if (item.notification_type == "Opportunity")
        return item.project_type == "gig" ? "New Gig" : "New opportunity";
      else return item.small_text;
    },
    openDialog(idx) {
      this.selectedItem = this.list[idx];
      this.dialog = true;
    },
    closeDialog() {
      this.selectedItem = {};
      this.dialog = false;
    },
    showControls() {
      if (this.$size == "xs") return false;
      const maxSlides = this.$size == "sm" ? 3 : 4;
      return this.list.length > maxSlides;
    },
    swipePrev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    swipeNext() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
    mobileShowMore() {
      this.mobileListLength += 4;
    },
  },
  computed: {
    maxSlides() {
      return this.$size == "sm" ? 3 : 4;
    },
    mobileList() {
      return this.list.slice(0, this.mobileListLength);
    },
    enableSwiper() {
      return this.$size != "xs";
    },
  },
  watch: {
    enableSwiper(newValue) {
      if (newValue) {
        const { swiper } = this.$refs;
        if (swiper) {
          this.swiperInstance = this.$refs.swiper.swiperInstance;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-center {
  position: relative;

  &::v-deep .swiper-wrapper {
    align-items: stretch;
  }

  &-wrapper {
    margin-bottom: 66px;
    width: 100%;
    @media screen and (max-width: 960px) {
      margin-bottom: 48px;
    }
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 1;

    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: -67.5px;
    @media screen and (max-width: 960px) {
      left: -45px;
    }
  }
  .swiper-button-next {
    right: -67.5px;
    @media screen and (max-width: 960px) {
      right: -45px;
    }
  }

  &__list {
    width: 100%;
  }
}

.notification {
  padding: 10px 40px 10px 10px;
  border: 2px solid #262729;
  border-radius: 10px;
  position: relative;
  height: auto;
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__type {
    font-size: 11px;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
  }
  &__description {
    font-size: 12px;
    line-height: 1.1667;
  }

  &__thumb {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__mark-as-read {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__open-dialog {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

a.notification__content {
  text-decoration: none;
  color: #56565a;
}
</style>
