<template>
  <div :class="{ deleted: localValue._destroy }">
    <div class="delete-row">
      <v-btn
        icon
        @click="$emit('removeInvoiceItem', idx)"
        :disabled="deleteDisabled"
      >
        <v-icon>$delete</v-icon>
      </v-btn>
    </div>
    <div class="invoice-form__item__row">
      <div class="form-row form-row-wide">
        <div class="form-label">Gig*</div>
        <div class="form-field">
          <slot name="workAssignmentSelector" />
        </div>
      </div>

      <div class="form-row form-row-wide">
        <div class="form-label">Rate Type*</div>
        <div class="form-field">
          <Select v-model="localValue.rate_type" :items="rateTypes" light />
        </div>
      </div>
    </div>

    <div class="invoice-form__item__row">
      <div
        class="form-row form-row-wide"
        v-if="localValue.rate_type != 'flat_fee'"
      >
        <div class="form-label">
          {{ `${rateUnitsLabels[localValue.rate_type]} Worked*` }}
        </div>
        <div class="form-field">
          <TextField
            v-model="localValue.rate_units"
            :rules="[validation.required]"
            block
            type="number"
            name="rate_units"
          />
        </div>
      </div>
      <div
        class="form-row form-row-wide"
        v-if="localValue.rate_type != 'flat_fee'"
      >
        <div class="form-label">
          {{ rateTypeLabels[localValue.rate_type] }} Rate*
        </div>
        <div class="form-field">
          <TextField
            v-model="localValue.rate"
            :rules="[validation.required]"
            block
            type="number"
            name="rate"
          />
        </div>
      </div>

      <div
        class="form-row form-row-wide"
        v-if="localValue.rate_type == 'flat_fee'"
      >
        <div class="form-label">Payment Amount*</div>
        <div class="form-field">
          <TextField
            v-model="localValue.amount"
            :rules="[validation.required]"
            block
            type="number"
            name="amount"
            prefix="$"
          />
        </div>
      </div>
      <div
        class="form-row form-row-description"
        :class="{
          'form-row-description--flat_fee': localValue.rate_type == 'flat_fee',
        }"
      >
        <div class="form-label">Description</div>
        <div class="form-field">
          <TextField
            v-model="localValue.description"
            block
            name="description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";

export default {
  mixins: [InvoiceLabelsMixin],
  props: {
    item: Object,
    idx: Number,
    deleteDisabled: Boolean,
  },
  data() {
    return {
      localValue: { ...this.item },
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.item)) {
          this.$emit("input", { ...this.localValue });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-form.scss";
</style>
