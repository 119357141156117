import Vue from 'vue';

const store = {
  namespaced: true,
  state: {
    newCount: 0
  },
  mutations: {
    setNewCount(state, count) {
      state.newCount = count;
    }
  },
  getters: {
    newCount: state => state.newCount
  },
  actions: {
    async fetchNewCount({ commit }) {
      const { data } = await Vue.prototype.$api.ProfileNotification.get();
      commit('setNewCount', data.length);
    }
  },
};

export default store;
