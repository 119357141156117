<template>
  <div class="search" :style="{ minWidth }">
    <v-autocomplete
      append-icon=""
      v-model="selected"
      :items="entries"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-details
      item-text="title"
      item-value="id"
      placeholder="Search"
      return-object
      solo
      rounded
      flat
      clearable
    >
      <template v-slot:prepend-inner>
        <v-icon size="20">$search</v-icon>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              <div v-html="data.item.highlight" class="search-title"></div>
              <div class="search-group">
                {{ data.item.group }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import _ from "lodash";
import { buildSearchLink } from "@/lib";

export default {
  props: {
    placeholder: String,
    minWidth: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {
      search: null,
      entries: [],
      isLoading: false,
      selected: null,
    };
  },
  methods: {
    searchCallback: _.debounce(async function (q) {
      try {
        this.isLoading = true;
        const { data } = await this.$api.Search.search({ q });
        this.entries = data;
      } finally {
        this.isLoading = false;
      }
    }, 300),
    goToResource(val) {
      this.$router.push(buildSearchLink(val));
    },
  },
  watch: {
    search(val) {
      this.searchCallback(val);
    },
    selected: {
      deep: true,
      handler(val) {
        if (val) {
          this.goToResource(val);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  &-title {
  }
  &-group {
    color: red;
    font-size: 10px;
    font-weight: bold;
  }
  &::v-deep {
    .v-input {
      position: relative;
    }
    .v-input:before {
      content: "";
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border: 1px solid transparent;
      border-radius: 28px;
      transition: 0.3s;
    }

    .v-input--is-focused {
      &.v-input:before {
        border: 1px solid var(--v-primary-base);
      }
      .v-icon__svg {
        fill: var(--v-primary-base);
      }
    }

    .v-input__slot {
      padding: 0 34px !important;
    }

    .v-input__prepend-inner {
      padding-right: 16px !important;
    }

    .v-label {
      color: var(--v-text-base);
      opacity: 0.5;
      font-size: 14px;
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 960px) {
  .search {
    min-width: 0 !important;
  }
}
</style>
