<template>
  <v-card light elevation="0" class="retro" v-if="hireRequest" max-width="1200">
    <a class="back-btn" @click="$router.back()">
      <v-icon size="50">$arrow_left</v-icon>
    </a>
    <div class="retro__title page-title">Retrospective Survey</div>
    <div class="retro__subhead">
      For Contract
      <router-link :to="`/my-gig/${mondayComItemId}`"
        >#{{ mondayComItemId }}</router-link
      >
    </div>
    <div class="retro__intro">
      <ul>
        <li v-for="gig in hireRequest.work_assignments" :key="`gig-${gig.id}`">
          {{ gig.title }}
        </li>
      </ul>
    </div>
    <div class="retro__form" v-if="retrospectiveOpen">
      <div v-if="submissionForm && form">
        <DynamicForm
          :submit="submit"
          :fields="submissionForm.submission_form"
          v-model="form"
          show-label
          :customComponents="submissionForm.custom_components"
        />
      </div>
      <Spinner v-else />
    </div>
    <div
      class="retro__completed"
      v-else-if="hireRequest.retrospective_completed"
    >
      <div class="retro__completed__header">Retrospective Completed</div>
      <div>Thanks for your feedback!</div>
    </div>
    <div class="retro__completed" v-else>
      This form will be available after
      {{
        dateFormatted(
          hireRequest.extended_date_end || hireRequest.end_date,
          "MM/DD/YYYY"
        )
      }}
    </div>
  </v-card>
</template>

<script>
import DynamicForm from "@/components/DynamicFields/DynamicForm";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  components: { DynamicForm },
  mixins: [paymentsTableFormats],
  head() {
    return {
      title: "Retrospective Form",
      titleTemplate: "%s | iU Community",
    };
  },
  data() {
    return {
      hireRequest: null,
      form: null,
      submissionForm: null,
      mondayComItemId: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.mondayComItemId = this.$route.params.id;
      this.fetchInit();
    }
  },
  methods: {
    async fetchInit() {
      await this.fetchHireRequest();
      this.fetchForm();
    },
    async fetchHireRequest() {
      try {
        const response = await this.$api.HireRequest.getMy({
          monday_com_item_id: this.mondayComItemId,
        });
        this.hireRequest = response.data;
      } catch (e) {
        if (e.message == "Request failed with status code 401")
          this.$router.push("/my-gigs");
        else this.$alert("Something went wrong", "error");
      }
    },
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.getForm({
        slug: "retrospective_survey",
      });
      this.submissionForm = data;

      this.initForm();
    },
    initForm() {
      let data = {};
      data[
        this.submissionForm.submission_form.find(
          (field) => field.short_name == "hire_request"
        ).uuid
      ] = this.hireRequest.monday_com_item_id;
      data[
        this.submissionForm.submission_form.find(
          (field) => field.short_name == "gigs"
        ).uuid
      ] = this.hireRequest.work_assignments
        .map((wa) => wa.project_code)
        .join(", ");
      this.form = {
        widget_submission_form_id: this.submissionForm.id,
        data: data,
        timestamp: new Date(),
      };
    },
    async submit() {
      try {
        await this.$api.Submission.create(this.form);
        this.fetchHireRequest();
      } catch (e) {
        this.$alert("Something went wrong", "error");
      }
    },
  },
  computed: {
    retrospectiveOpen() {
      return (
        this.hireRequest &&
        !this.hireRequest.retrospective_completed &&
        new Date(
          this.hireRequest.extended_date_end || this.hireRequest.end_date
        ) < new Date()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.retro {
  line-height: 1.2;
  background-color: transparent;
  margin: 0 auto;
  &::v-deep {
    fieldset {
      border-width: 2px;
      border-color: #56565a !important;
    }
    .dynamic-select .dynamic-select__control {
      max-width: 250px;
      margin-top: 0.5em;
    }
    .dynamic-textarea .dynamic-textarea__input {
      margin-top: 0.5em;
    }
  }
  &__title {
    margin-bottom: 0.75em;
  }
  &__subhead {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  &__intro {
    margin-bottom: 2em;
  }
  &__completed {
    text-align: center;
    font-weight: bold;
    &__header {
      font-size: 24px;
      margin-bottom: 0.5em;
    }
  }
}
</style>
