<template> 
  <div class="page-wrapper">
    <ProfileProjectPage/>
  </div>
</template>

<script>
import ProfileProjectPage from "@/components/ProfileProjectPage";
import _ from "lodash";

export default {
  head() {
    if(!this.page) {
      return {
        title: "Profile",
        titleTemplate: '%s | iU Community'
      }
    } else {
      let meta = this.page
        ? [
          ...this.page.meta_tags,
          ...this.currentProject.meta_tags,
        ]
      : this.currentProject.meta_tags;

      meta = _.uniqBy(meta, function(tag) { return (tag.name || "") + "_" + (tag.property || "") });

      return {
        title: this.page.headline || this.currentProject?.seo_title || this.currentProject?.headline,
        titleTemplate: '%s | iU Community',
        meta,
        link: [
          { rel: "icon", href: this.currentProject?.favicon.url || this.activeTheme.favicon.url || "/favicon.ico" }
        ]
      };
    }
  },
  data() {
    return {
      page: null,
    };
  },
  mounted(){
    this.fetchPage();
  },
  methods: {
    async fetchPage(){
      const { data } = await this.$api.Page.get({ url: this.$route.path.slice(1) });
      this.page = data;
    }
  },
  components: { ProfileProjectPage },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: relative;
}
</style>
