export default {
  data() {
    return {
      types: {
        msa_contractor: "MSA-Contractor",
        msa_crew: "MSA-Crew",
        gig_contract: "Gig Contract",
        gig_extension: "Gig Extension",
        w9: "W9",
        ach_authorization: "ACH Authorization",
        other: "Other",
      },
      statuses: {
        CREATED: "Created",
        OUT_FOR_SIGNATURE: "Delivered",
        SIGNED: "Signed",
        SIGNED_OFFSITE: "Signed",
        CANCELLED: "Canceled",
        EXPIRED: "Expired",
      },
      menu: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.cancelConfirm = false;
      this.menu = false;
    },
  },
  computed: {
    gigList() {
      return this.item.gigs && this.item.gigs.length
        ? this.item.gigs.map((gig) => gig.title).join(", ")
        : "N/A";
    },
  },
};
