var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideHeader)?_c('div',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.title || "EDIT INVOICE DETAILS")+" ")]):_vm._e(),(!_vm.hideHeader && _vm.localValue.user)?_c('InvoicePersonalInfo',{attrs:{"user":_vm.localValue.user}}):_vm._e(),(!_vm.hideHeader)?_c('div',[_vm._v(" * "),_c('span',{staticClass:"invoice-form__required-hint"},[_vm._v("Indicates required fields")])]):_vm._e(),_vm._t("invoiceItems"),_c('div',{staticClass:"new-gig-wrapper"},[_c('v-btn',{staticClass:"new-gig",attrs:{"color":"#4282FF","elevation":"0","rounded":""},on:{"click":function($event){return _vm.$emit('addInvoiceItem', _vm.defaultItemType)}}},[_vm._t("btn",[_vm._v(" + New Gig/Item ")])],2),_c('v-tooltip',{attrs:{"right":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"new-gig-tooltip",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("$question_round")])]}}])},[_c('span',[_vm._t("tooltip",[_vm._v("Add multiple gigs and select the same gig multiple times for different payment items (labor, reimbursements, or expenses).")])],2)])],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-label"},[_vm._v("Invoice Summary*")]),_c('TextArea',{attrs:{"rules":[_vm.validation.required],"block":"","name":"description"},on:{"input":function($event){return _vm.$emit('updateField', {
          field: 'description',
          value: _vm.localValue.description,
        })}},model:{value:(_vm.localValue.description),callback:function ($$v) {_vm.$set(_vm.localValue, "description", $$v)},expression:"localValue.description"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-label"},[_vm._v("Attachment(s)")]),_c('div',{staticClass:"invoice-form__attachments"},[_vm._l((_vm.localValue.invoice_attachments),function(invoiceAttachment,idx){return _c('InvoiceAttachmentForm',{key:("invoiceAttachment-" + idx + "-" + (invoiceAttachment.id)),attrs:{"placeholder":"Upload","value":invoiceAttachment},on:{"input":function($event){return _vm.updateAttachment(idx, $event)},"delete":function($event){return _vm.$emit('removeInvoiceAttachment', idx)}}})}),(
          _vm.localValue.invoice_attachments.length == 0 ||
          _vm.localValue.invoice_attachments[
            _vm.localValue.invoice_attachments.length - 1
          ].body
        )?_c('v-btn',{staticClass:"new-attachment",attrs:{"small":"","color":"#4282FF","elevation":"0","rounded":""},on:{"click":function($event){return _vm.$emit('addInvoiceAttachment')}}},[_vm._t("btn",[_vm._v(" + Add Another Attachment ")])],2):_vm._e()],2)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-label"},[_vm._v("Total Amount")]),_c('div',{staticClass:"form-field"},[_c('TextField',{attrs:{"placeholder":"Total Amount of All Gigs","type":"number","disabled":"","value":_vm.total,"prefix":"$"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }