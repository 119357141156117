export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localValue: { ...this.value },
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit("input", this.localValue);
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = { ...this.value };
        }
      },
    },
  },
};
