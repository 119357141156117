<template>
  <v-text-field
    type="email"
    :placeholder="label + (required && markRequired ? ' *' : '')"
    :value="value"
    @input="$emit('input', $event)"
    :rules="[...rules, validationRules.email]"
    outlined
    :disabled="disabled"
    light
  />
</template>

<script>
import validationRulesMixin from "@/mixins/validation-rules.mixin";

export default {
  mixins: [validationRulesMixin],
  props: {
    label: String,
    value: String,
    rules: {
      type: Array,
      default: () => []
    },
    required: Boolean,
    markRequired: Boolean,
    disabled: Boolean,
    addonData: {
      type: [Object, Boolean],
      default: false
    }
  },
};
</script>

<style></style>
