<template>
  <tr class="payments-row" v-if="item">
    <td class="center-align">
      <router-link
        v-if="item.hire_request_monday_com_item_id"
        :to="`/hire-requests/${item.hire_request_monday_com_item_id}`"
      >
        {{ item.title }}
      </router-link>
      <span v-else>{{ item.title }}</span>
    </td>
    <td class="center-align gig__status" :class="`gig__status--${item.status}`">
      {{ item.status }}
    </td>
    <td>
      <TableRowAvatar
        :user="item.user"
        :path="`/p/${item.user.username || item.user.id}/payments`"
      />
    </td>
    <td class="center-align">{{ item.role }}</td>
    <td class="center-align">
      <TableRowAvatar
        :user="item.hiring_manager"
        :path="`/p/${
          item.hiring_manager.username || item.hiring_manager.id
        }/payments`"
      />
    </td>
    <td class="center-align">
      {{ item.start_date ? dateFormatted(item.start_date, "MM/DD/YYYY") : "" }}
    </td>
    <td class="center-align">
      {{
        item.extended_end_date
          ? dateFormatted(item.extended_end_date, "MM/DD/YYYY")
          : ""
      }}
    </td>
    <td>
      <div class="td-action">
        <v-btn icon :to="`/p/${item.username || item.user_id}/payments`">
          <v-icon size="35">$arrow_right</v-icon>
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import TableRowAvatar from "@/components/work-assignments/TableRowAvatar.vue";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";

export default {
  mixins: [paymentsTableFormats],
  components: { TableRowAvatar },
  props: {
    item: Object,
  },
  mounted() {
    console.info("item", this.item);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
.gig {
  &__status {
    font-weight: bold;
    &--Canceled {
      color: #e12d1b;
    }
    &--Active {
      color: #4282ff;
    }
    &--Completed {
      color: #61b90c;
    }
  }
}
</style>
