<template>
  <v-btn
    @click="click"
    :to="to"
    :large="large"
    :block="block"
    :xLarge="xLarge"
    color="#E12D1B"
    :type="type"
    :outlined="outlined"
    :text="text"
    class="elevation-0 btn"
    :href="href"
    :target="target"
    :disabled="disabled"
    :light="light"
  >
    <slot></slot>
  </v-btn>
</template>

<script>

export default {
  props: {
    color: String,
    large: Boolean,
    xLarge: Boolean,
    outlined: Boolean,
    block: Boolean,
    to: String,
    type: String,
    refs: Object,
    text: Boolean,
    href: String,
    target: String,
    disabled: Boolean,
    light: Boolean
  },
  methods: {
    click() {
      if (this.type == "submit") {
        if (this.refs?.form && !this.refs.form.validate()) {
          return this.scrollToInvalidInput(this.refs.form.inputs);
        }
      }

      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="scss">
.btn.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #e7e7e7 !important;
  color: #928d8d !important;
}

.btn::v-deep span {
  font-weight: bold;
}
.btn:not(.v-btn--disabled)::v-deep span {
  color: white;
}
</style>
