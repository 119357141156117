<template>
  <div class="slides">
    <div
      v-for="(slide, index) in localValue"
      :key="index"
      :class="{ deleted: slide._destroy }"
      class="slide"
    >
      <div class="slide__header">
        <div class="slide__content">
          <FileField
            placeholder="Image Upload"
            v-model="slide.preview"
            @input="update"
            acceptFiletypes="image/*"
            enableCropper
          />
        </div>
        <div class="slide__actions">
          <v-btn icon @click="moveUp(index)">
            <v-icon size="24"> $arrow_up </v-icon>
          </v-btn>
          <v-btn icon @click="moveDown(index)">
            <v-icon size="24"> $arrow_down </v-icon>
          </v-btn>
          <v-btn icon @click="destroy(index)">
            <v-icon size="24"> $delete </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-btn color="primary" elevation="0" rounded large @click="addSlide">
      Add Another Image
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [{ open_url: '_self' }],
    },
    title: String,
    simple: Boolean,
  },
  data() {
    return {
      localValue: [...this.value],
      openUrlItems: [
        { name: 'Current Tab', value: '_self' },
        { name: 'New Tab', value: '_blank' },
      ],
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit('input', [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
  methods: {
    update(){
      this.localValue = [...this.localValue];
    },
    addSlide() {
      this.localValue.push({ open_url: '_self' });
      this.localValue = [...this.localValue];
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx + 1, 0, element);
    },
    destroy(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx] = { ...this.localValue[idx], _destroy: true };
      } else {
        this.localValue.splice(idx, 1);
      }
      this.localValue = [...this.localValue];
    },
    restore(idx) {
      this.localValue[idx] = { ...this.localValue[idx], _destroy: false };
      this.localValue = [...this.localValue];
    },
  },
};
</script>

<style scoped lang="scss">
.slides {
  margin-bottom: 0.5em;
}
.slide {
  margin: 20px 0;
  position: relative;
  overflow: hidden;

  &__content {
    flex-grow: 1;
  }

  &__deleted-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &.deleted {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
  }

}
</style>
