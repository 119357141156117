<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :items="list"
      outlined
      min-height="50"
      class="ge-selector"
      item-text="title"
      item-value="id"
      clearable
      hide-details="auto"
      :rules="rules"
      dense
      elevation="0"
      append-icon="$chevron_down"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content
            class="ge-selector__item-content"
            :class="{ 'ge-selector__item-content--na': !data.item.id }"
          >
            <div>
              {{ data.item.title }}
              <span
                class="ge-selector__item-status"
                :class="`ge-selector__item-status--${data.item.agreement_status}`"
                >{{ statuses[data.item.agreement_status] }}</span
              >
            </div>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import hireRequestsTableRow from "@/mixins/hire-requests/hire-requests-table-row.mixin";

export default {
  mixins: [hireRequestsTableRow],
  props: {
    value: [String, Number, Array],
    rules: Array,
    params: Object,
  },
  data() {
    return {
      list: [],
      statuses: {
        UNKNOWN: "",
        SIGNED: "Signed",
        SIGNED_OFFSITE: "Signed",
        OUT_FOR_SIGNATURE: "Sent",
        NONE: "Contract Needed",
        CANCELLED: "Canceled",
      },
    };
  },
  created() {
    this.setGigExtensions();
  },
  methods: {
    async setGigExtensions() {
      const { data } = await this.$api.GigExtension.lite(this.params);
      this.list = [{ title: this.emptyLabel || "N/A", id: null }, ...data];
    },
  },
  computed: {
    itemMondayComItemIdNotAPhoneNumber() {
      // For blocking some browsers' misguided phone links based on format detection
      if (!this.item || !this.item.monday_com_item_id) return "";
      return (
        this.item.monday_com_item_id.substring(0, 2) +
        "&zwj;" +
        this.item.monday_com_item_id.substring(2)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.ge-selector {
  &::v-deep {
    .v-text-field--enclosed .v-input__append-inner {
      margin-top: 14px !important;
    }
  }

  &__item-status {
    font-style: italic;
    &--NONE,
    &--UNKNOWN {
      color: #e12d1b;
    }
    &--SIGNED,
    &--SIGNED_OFFSITE {
      color: #61b90c;
    }
    &--OUT_FOR_SIGNATURE {
      color: #e8852b;
    }
  }
}
.v-input__slot:hover {
  width: 100%;

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    &.hidden-input input {
      display: none;
    }
    &.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
      .v-select__selections {
      min-height: 40px;
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      min-height: 50px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
      margin-top: 14px !important;
    }

    fieldset {
      border-color: var(--v-border-base);
      border-width: 2px;
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }

    label {
      font-size: 14px !important;
      color: rgba(0, 0, 0, 0.38);
    }
    &.v-text-field--outlined.v-input--dense {
      .v-label {
        top: 14px;
      }
      .v-label--active {
        transform: translateY(-21px) scale(0.75);
      }
    }
  }
}
</style>

<style lang="scss">
.v-autocomplete__content
  .v-list-item--disabled
  .ge-selector__item-content:not(.ge-selector__item-content--na) {
  font-style: italic;
  color: gray;
}
.v-autocomplete__content .v-list-item--disabled .v-ripple__container {
  display: none !important;
}
.ge-selector__item-content--alert {
  pointer-events: all;
}
</style>
