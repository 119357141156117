<template>
  <div>
    <div class="radio-group__title" v-if="label">
      <b>{{ label }}</b>
    </div>
    <v-radio-group
      class="radio-group"
      row
      :value="value"
      hide-details="auto"
      @change="$emit('input', $event)"
      :rules="rules"
    >
      <div v-for="item in items" :key="item[itemValue]">
        <v-tooltip
          :disabled="!item.tooltip"
          open-delay="500"
          max-width="300"
          top
        >
          <template v-slot:activator="{ on }">
            <v-radio
              v-on="on"
              :label="item[itemText]"
              :value="item[itemValue]"
              off-icon="$circle_checkbox_off"
              on-icon="$circle_checkbox_on"
            ></v-radio>
          </template>
          <span v-if="item.tooltip">{{ item.tooltip }}</span>
        </v-tooltip>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    label: String,
    value: [String, Boolean, Number],
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.radio-group {
  &.v-input--radio-group--row {
    margin: 8px 0 !important;
  }

  &__label {
  }

  &::v-deep .v-label {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
