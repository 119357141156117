<template>
  <p>You do not need to install or authorize the iU Community Onboarding Monday.com app. If you have any questions, please reach out to the iU web dev team.</p>
</template>

<script>
export default {
  mounted(){ 
    this.$router.replace({'query': null});
  }
}
</script>