<template>
  <div>
    <div class="page-title">Waiting for approval</div>
    <div>
      Please, contact administrator to approve your account and add to a team.
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
