<template>
  <div class="cp-header">
    <InvoicePersonalInfo :user="value">
      <template v-slot:actions>
        <div class="cp-header__actions">
          <v-btn
            color="#262729"
            dark
            class="elevation-0"
            :to="`/p/${value.username || value.id}`"
            >View Public Profile</v-btn
          >
          <v-btn
            color="primary"
            @click="payDirectlyDialog = true"
            elevation="0"
          >
            Pay Directly
          </v-btn>
        </div>
      </template>
    </InvoicePersonalInfo>
    <div v-if="currentUser.is_finance || currentUser.is_people_team">
      <FiPayDirectlyDialog
        v-if="payDirectlyDialog"
        :dialog="payDirectlyDialog"
        :user="value"
        @close="payDirectlyDialog = false"
        @refresh="$emit('refresh')"
      />
    </div>
    <div v-else-if="currentUser.is_hiring_manager">
      <HmPayDirectlyDialog
        v-if="payDirectlyDialog"
        :dialog="payDirectlyDialog"
        :user="value"
        @close="payDirectlyDialog = false"
        @refresh="$emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
import InvoicePersonalInfo from "@/components/work-assignments/InvoicePersonalInfo";
import HmPayDirectlyDialog from "../hm/HmPayDirectlyDialog";
import FiPayDirectlyDialog from "../fin/FiPayDirectlyDialog";

export default {
  props: {
    value: Object,
  },
  components: {
    InvoicePersonalInfo,
    HmPayDirectlyDialog,
    FiPayDirectlyDialog,
  },
  data() {
    return {
      payDirectlyDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.cp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__actions {
    margin-top: 15px;
    .v-btn {
      margin-right: 17px;
      margin-bottom: 8px;
    }
  }
}
</style>
