<template>
  <div class="payments-table-wrapper">
    <div class="payments-table__header">
      <div class="page-title" v-if="title">{{ title }}</div>
      <div class="payments-table__actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div
      class="payments-table-container"
      :key="`creator-hire-requests--${creator ? creator.id : ''}--${reloadKey}`"
    >
      <table border="2" class="payments-table">
        <thead class="payments-table-head">
          <tr>
            <th class="w0">
              <div>ID</div>
            </th>
            <th class="w45">
              <div>Gig(s)</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="work_assignment"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w20">
              <div>Hiring Manager</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="submitter"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
              />
            </th>
            <th class="w0">
              <div>Contract<br />Status</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="contract_status"
                fieldLabel="Contract Status"
              />
            </th>
            <th class="w0">
              <div>Start Date</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="start_date"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Start Date"
                isDate
              />
            </th>
            <th class="w0">
              <div>End Date</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="end_date"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="End Date"
                isDate
              />
            </th>
            <th class="w0">
              <div>Date Created</div>
              <TableHeaderActions
                :listOptions="listOptions"
                fieldName="created_at"
                orders
                @orderUpdate="$emit('orderUpdate', $event)"
                fieldLabel="Date Created"
                isDate
              />
            </th>
          </tr>
        </thead>
        <tbody v-if="list && list.length">
          <CreatorHireRequestsTableRow
            v-for="item in list"
            :key="item.id"
            :item="item"
            :my="my"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="center-align">No Hire Requests</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
import CreatorHireRequestsTableRow from "./table-row.creator-hire-requests.vue";
import TableHeaderActions from "@/components/tables/TableHeaderActions";

export default {
  components: {
    CreatorHireRequestsTableRow,
    TableHeaderActions,
  },
  props: {
    creator: Object,
    list: Array,
    listOptions: Object,
    reloadKey: Number,
    title: String,
    my: Boolean,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/payments/payments-table.scss";
.payments-table {
  &-head {
    background: #7b61ff;
    color: white;
  }
}
</style>
