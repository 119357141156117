<template>
  <div>
    <div class="page-title">Custom Youtube Settings</div>
    <TextField label="Application Token" v-model="localValue.data.token" />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style></style>
