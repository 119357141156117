<template>
  <div>
    <div class="actions-menu-wrapper">
      <v-overlay :value="menu" @click.native.stop="$emit('close')" />
      <v-menu
        content-class="actions-menu"
        bottom
        min-width="427"
        right
        :offset-y="true"
        nudge-top="-30"
        :offset-x="true"
        nudge-right="0"
        :close-on-click="false"
        :close-on-content-click="false"
        v-model="menu"
        :light="themeDisabledPage"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#4282FF"
            @click="$emit('toggle')"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            class="create-from-btn"
          >
            Create From Previous Invoice
          </v-btn>
        </template>
        <v-card class="actions-menu__content">
          <div class="actions-menu__header">
            <div>Select Invoice</div>
            <v-btn class="dialog-close" icon @click="$emit('close')">
              <v-icon>$close</v-icon>
            </v-btn>
          </div>
          <div class="actions-menu__body">
            <div class="actions-menu__main">
              <div
                class="actions-menu__item"
                v-for="item in list"
                :key="item.id"
                @click="$emit('selectInvoice', item)"
              >
                <span class="actions-menu__item__int-id">
                  {{ item.int_id }}
                </span>
                -
                <span class="actions-menu__item__gigd">
                  {{ item.gigs.map((gig) => gig.title).join(", ") }}
                </span>
              </div>
              <div class="actions-menu__main__footer">
                <TablePagination
                  :page="page"
                  :totalPages="totalPages"
                  @input="page = $event"
                />
              </div>
            </div>
          </div>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import paginatedTable from "@/mixins/paginated-table.mixin";
import TablePagination from "@/components/tables/TablePagination";

export default {
  mixins: [paginatedTable],
  components: { TablePagination },
  props: {
    menu: Boolean,
    themeDisabledPage: Boolean,
    color: String,
  },
  data() {
    return {
      list: [],
      page: 1,
      loadingList: false,
      listDone: false,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      this.loadingList = true;
      const { data } = await this.$api.Invoice.my({ page: this.page });
      if (data.invoices.length < 5) this.listDone = true;
      this.list = data.invoices;
      this.totalPages = data.total_pages;
      this.loadingList = false;
    },
  },
};
</script>

<style scoped lang="scss">
.actions-menu-wrapper {
  position: relative;
  > .v-icon {
    display: block;
  }
}

.create-from-btn {
  color: white !important;
}

.actions-menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  width: 245px;
  border-radius: 10px;
  text-align: center;
  box-shadow: none;
  border: 2px solid #262729;
  &__content {
    position: relative;
    background: white;
  }
  .dialog-close {
    position: absolute;
    top: 4px;
    right: 0;
  }
  .v-btn {
    font-weight: bold;
  }
  &__header {
    width: 100%;
    background: white;
    min-height: 36px;
    border-radius: 10px 10px 0 0;
    font-size: 13.5px;
    font-weight: 900;
    padding: 12px;
    padding-left: 31px;
    text-align: left;
    border-bottom: 2px solid #262729;
  }
  &__main {
    .v-btn:not(.v-btn--icon) {
      padding: 0 10px;
    }
  }
  &__main {
    padding: 20px 22px;
    &__footer {
      margin-top: 16px;
    }
  }
  &__toggle-icon {
    transform: none !important;
    display: block;
  }
  &__item {
    text-align: left;
    font-size: 14px;
    cursor: pointer;
    line-height: 18px;
    margin-bottom: 8px;
    &__int-id {
      font-weight: bold;
    }
  }
}
</style>
