<template>
  <div class="my-toggle">
    <div class="my-toggle__label">Assigned to me:</div>
    <v-switch
      inset
      hide-details
      v-model="localValue"
      @change="$emit('input', $event)"
      aria-label="Filter to my opportunities, or show all"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      localValue: this.value,
    };
  },
};
</script>

<style scoped lang="scss">
.my-toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__label {
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.75em;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
