<template>
  <v-dialog v-model="dialog" width="930" light @click:outside="$emit('close')">
    <v-card class="pdf-preview">
      <div class="pdf-preview__header">
        <div class="page-title">PDF Preview</div>
        <v-btn class="close" icon @click="$emit('close')">
          <v-icon size="30">$close</v-icon>
        </v-btn>
      </div>
      <div class="pdf-preview__content">
        <InvoicePdfPreview
          class="pdf-preview__preview"
          :invoice="value"
          v-if="!concur"
        />
        <InvoiceConcurPdfPreview
          class="pdf-preview__preview"
          :invoice="value"
          v-else
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InvoicePdfPreview from "./InvoicePdfPreview";
import InvoiceConcurPdfPreview from "./InvoiceConcurPdfPreview";

export default {
  components: { InvoicePdfPreview, InvoiceConcurPdfPreview },
  props: {
    value: Object,
    dialog: Boolean,
    concur: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.pdf-preview {
  background: white;
  padding: 20px 35px;

  &__preview {
    margin: 5px;
  }
}

.pdf-doc {
  width: 850px;
  min-width: 850px;
  &__inner {
    position: relative;
    z-index: 1;
    padding: 20px 40px 34px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 850px;
      height: 1100px;
      background: white;
      z-index: -1;
      left: 0;
      top: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid gray;
    margin-bottom: 18px;
  }

  &__h1 {
    font-size: 32px;
    font-weight: bold;
  }

  &__top {
    font-weight: 600;
    table {
      width: 100%;
      border-spacing: 0 20px;
    }
    td {
      vertical-align: top;
    }
    &__label {
      color: #666666;
    }
  }
  &__details {
    table {
      width: 100%;
      border-spacing: 0;
      border: 1px solid #c0c0c0;
      margin: 0.75em 0;
      th,
      td {
        border: 1px solid #c0c0c0;
        padding: 12px 7px;
      }
      th {
        text-align: left;
      }
      td {
        text-align: right;
      }
      .td-center {
        text-align: center;
      }
    }
  }
  &__total {
    font-size: 1.25em;
    font-weight: bold;
    text-align: right;
  }
  &__details-2,
  &__details-3 {
    margin-top: 1em;
    &__label {
      color: #666666;
      font-weight: 600;
    }
    table {
      width: 100%;
    }
    td:first-child {
      width: 30%;
    }
  }
  &__page-break {
    &__inner {
      float: left;
      clear: both;
      width: 100%;
      height: 74px;
    }
    &:before {
      content: "";
      display: block;
      float: right;
      height: 1046px;
    }
    &:after {
      content: "";
      display: block;
      float: right;
      height: 400px;
      content: "";
      display: block;
      position: absolute;
      width: 850px;
      height: 1100px;
      background: white;
      z-index: -1;
      left: 0;
      top: 1120px;
    }
  }
}
</style>
