<template>
  <div class="radius-selector">
    <TagSelector
      :label="label"
      :items="datasources.radius"
      :value="value"
      :disabled="disabled"
      :multiple="false"
      @input="$emit('input', $event)"
      itemText="title"
      itemValue="value"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    disabled: Boolean,
    label: String,
  },
};
</script>

<style scoped lang="scss">
.radius-selector {
  &::v-deep {
    .v-input--is-disabled {
      opacity: 0.5 !important;
    }
  }
}
</style>
