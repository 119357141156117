<template>
  <div class="mp-header">
    <InvoicePersonalInfo :user="currentUser">
      <template v-slot:actions>
        <div class="mp-header__actions">
          <v-btn to="/profile" color="#E12D1B" dark class="elevation-0"
            >Edit Profile</v-btn
          >
          <v-btn
            color="#262729"
            dark
            class="elevation-0"
            :to="`/p/${currentUser.username || currentUser.id}`"
            >View Profile</v-btn
          >
        </div>
      </template>
    </InvoicePersonalInfo>
    <div class="mp-header__stats">
      <div class="mp-header__earned" v-if="earned !== null">
        <div class="mp-header__earned-total">{{ earned | currency }}</div>
        <div class="mp-header__earned-label">Total Amount <br />earned!</div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoicePersonalInfo from "@/components/work-assignments/InvoicePersonalInfo";

export default {
  props: {
    earned: Number,
  },
  components: {
    InvoicePersonalInfo,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.mp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &__extra {
  }
  &__stats {
  }

  &__earned {
    width: 233px;
    height: 233px;
    border-radius: 50%;
    background: #e12d1b;
    border: 2px solid #262729;
    position: relative;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:before {
      content: "";
      position: absolute;
      left: 12px;
      right: 12px;
      top: 12px;
      bottom: 12px;
      border: 2.05229px solid #ffffff;
      border-radius: 50%;
    }

    &-total {
      font-family: "Source Sans Pro";
      font-weight: 900;
      font-size: 44.9px;
      border-bottom: 2.54988px solid #ffbc10;
      line-height: 1;
      margin-bottom: 15px;
      text-align: center;
      min-width: 170px;
    }
    &-label {
      font-family: "Source Sans Pro";
      font-size: 20px;
      text-transform: capitalize;
      position: absolute;
      bottom: 40px;
      line-height: 1.2;
    }
  }

  &__actions {
    margin-top: 15px;
    .v-btn {
      margin-right: 17px;
      margin-bottom: 8px;
    }
  }
}
</style>
