<template>
  <div class="stories">
    <router-link v-if="createPath" :to="createPath" class="story story--create">
      <v-icon>$plus</v-icon>
      <div class="story__title">
        {{ addBtnText }}
      </div>
    </router-link>
    <router-link
      v-for="story in list"
      :key="story.id"
      :to="clickPath(story)"
      class="story"
    >
      <div class="story__title">
        {{ story.name || story.title }}
      </div>
      <v-icon>$pencil</v-icon>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    createPath: {
      type: String,
    },
    clickPath: {
      type: Function,
    },
    addBtnText: String,
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.stories {
  background: white;
  border-radius: 10px;
  border: 1px solid var(--v-border-base);
  padding: 15px 25px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
}

.story {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid var(--v-border-base);
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  color: var(--v-text-base);

  .story__title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  &:hover {
    background: var(--v-background-base);
  }

  &--create {
    background: var(--v-background-base);
    padding: 10px;
    text-align: center;

    .story__title {
      margin-top: 0;
    }

    &:hover {
      background: var(--v-primary-base);
      border-color: var(--v-primary-base);

      .story__title {
        color: white;
      }
      &::v-deep .v-icon__svg {
        fill: white;
      }
    }
  }
}
</style>
