<template>
  <div class="avatar" :style="styles" v-bind="$attrs">
    {{ initals }}
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    id: String,
  },
  data() {
    return {
      colors: ["#BECEE0", "#CABAE6", "#CCE8CE", "#FFE78F"],
    };
  },
  methods: {
    getHash(input) {
      var hash = 0,
        len = input.length;
      for (var i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
      }
      return Math.abs(hash);
    },
  },
  computed: {
    styles() {
      return {
        backgroundColor: this.color,
      };
    },
    initals() {
      return (this.name || "Anon")
        .split(" ")
        .map((n) => n[0])
        .join("")
        .substr(0, 2)
        .toUpperCase();
    },
    color() {
      return this.colors[this.getHash(this.id) % this.colors.length];
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  margin-right: 10px;
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
}
</style>
