import Vue from "vue";
import Router from "vue-router";

import routes from "./routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
});

router.afterEach((from, to) => {
  if (from.path != to.path) {
    window.scrollTo(0, 0);
  }
});

export default router;
