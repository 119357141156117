<template>
  <div class="work-examples-wrapper">
    <div class="work-examples">
      <div
        class="work-example"
        v-for="(item, idx) in localValue"
        :key="item.uuid"
        :class="{ deleted: item._destroy }"
      >
        <div class="work-example__controls">
          <v-btn class="work-example__close" icon @click="destroy(idx)">
            <v-icon>$close</v-icon>
          </v-btn>
          <v-btn class="work-example__moveup" icon @click="moveUp(idx)">
            <v-icon>$arrow_up</v-icon>
          </v-btn>
          <v-btn class="work-example__movedown" icon @click="moveDown(idx)">
            <v-icon>$arrow_down</v-icon>
          </v-btn>
        </div>
        <div class="work-example__content">
          <Select
            placeholder="Content Type"
            :items="contentTypes"
            item-value="value"
            item-text="name"
            v-model="item.content_type"
          />
          <FileField
            placeholder="Header Image (1500 / 245)"
            v-model="item.preview"
            acceptFiletypes="image/*"
            enableCropper
            :ratio="1500 / 245"
          ></FileField>
          <TextField
            counter="50"
            :rules="[validation.workExampleTitle]"
            :hide-details="false"
            placeholder="Title"
            v-model="item.title"
            class="mb-5"
          ></TextField>
          <Select
            class="specialty__select"
            placeholder="Specialty"
            itemText="title"
            itemValue="id"
            :items="datasources.skills"
            v-model="item.skill_id"
          ></Select>
          <TextArea
            :counter="750"
            :rules="[validation.workExampleDescription]"
            :hide-details="false"
            placeholder="Project Description"
            v-model="item.description"
          ></TextArea>
          <TextField
            :rules="[validation.url]"
            placeholder="Website"
            v-model="item.website"
          ></TextField>

          <div v-if="item.content_type == 'images'">
            <WorkExampleEditorSlides v-model="item.slides" title="Images" />
          </div>
          <div class="video-options" v-else-if="item.content_type == 'video'">
            <TextField
              placeholder="Video URL (Vimeo or YouTube)"
              v-model="item.video_url"
              :rules="[validation.url, validation.videoUrl]"
              @change="videoUrlToId(idx)"
            ></TextField>
            <FileField
              placeholder="Custom Thumbnail"
              v-model="item.video_preview"
              acceptFiletypes="image/*"
              enableCropper
            ></FileField>
          </div>
          <FileField
            v-else-if="item.content_type == 'pdf_other'"
            placeholder="Document Upload"
            v-model="item.document"
            :accept-filetypes="false"
          ></FileField>
        </div>
      </div>
    </div>
    <v-btn
      color="primary"
      elevation="0"
      rounded
      large
      @click="addWorkExample"
      :disabled="canAddWorkExample"
    >
      <slot name="btn">
        <v-icon>$plus</v-icon>
        Add work example
      </slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [{ slides: [] }],
    },
  },
  data() {
    return {
      localValue: [...this.value],
      contentTypes: [
        { name: "Images", value: "images" },
        { name: "Video", value: "video" },
        { name: "PDF/Other", value: "pdf_other" },
      ],
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.$emit("input", [...this.localValue]);
        }
      },
    },
    value: {
      deep: true,
      handler() {
        this.localValue = [...this.value];
      },
    },
  },
  methods: {
    addWorkExample() {
      if (!this.localValue) this.localValue = [];
      this.localValue.push({
        title: "",
        website: "",
        slides: [],
      });
      this.localValue = [...this.localValue];
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.localValue.length - 1) return;
      const element = { ...this.localValue[idx] };
      this.localValue.splice(idx, 1);
      this.localValue.splice(idx + 1, 0, element);
    },
    destroy(idx) {
      if (this.localValue[idx].id) {
        this.localValue[idx]._destroy = true;
      } else {
        this.localValue.splice(idx, 1);
      }
      this.localValue = [...this.localValue];
    },
    videoUrlToId(idx) {
      const item = this.localValue[idx];
      item.video_url = item.video_url.trim();
      const publicVimeoRegex =
        /^(?:http|https)?:\/\/(?:www\.)?vimeo\.com\/(\d+)$/;
      const privateVimeoRegex =
        /^(?:http|https)?:\/\/(?:www\.)?vimeo\.com\/(\d+)\/((?:\d|[a-z])+)$/;
      const youtubeRegex =
        /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(?:&(?:amp;)?[\w?=]*)?$/;
      const vimeoMatch = item.video_url.match(publicVimeoRegex);
      const privateVimeoMatch = item.video_url.match(privateVimeoRegex);
      const youtubeMatch = item.video_url.match(youtubeRegex);
      if (vimeoMatch) {
        item.vimeo_id = vimeoMatch[1];
        item.vimeo_h = null;
        item.youtube_id = null;
      } else if (privateVimeoMatch) {
        item.vimeo_id = privateVimeoMatch[1];
        item.vimeo_h = privateVimeoMatch[2];
        item.youtube_id = null;
      } else if (youtubeMatch) {
        item.vimeo_id = null;
        item.vimeo_h = null;
        item.youtube_id = youtubeMatch[1];
      } else {
        item.vimeo_id = null;
        item.vimeo_h = null;
        item.youtube_id = null;
      }
    },
  },
  computed: {
    canAddWorkExample() {
      return this.localValue.filter((item) => !item._destroy).length >= 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-example {
  border: thin solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
  margin-bottom: 1em;
  position: relative;
  &__content {
    padding: 1em 2.5em 1em 1em;
    > div {
      margin-bottom: 0.5em;
    }
    > .video-options > div {
      margin-bottom: 0.5em;
    }
  }
  &__controls {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  &.deleted {
    display: none;
  }
}

.video-options {
  &__ids {
    display: flex;
    align-items: center;
    &_separator {
      white-space: nowrap;
      padding: 0 0.5em 0.5em;
      font-size: 75%;
    }
  }
}
</style>
