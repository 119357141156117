<template>
  <div class="profile-page">
    <div class="creator-profile">
      <div class="creator-profile__header">
        <div class="page-title">Creator Profile</div>
        <v-btn
          large
          color="#262729"
          dark
          class="private-profile-link elevation-0"
          :to="`/p/${currentUser.username || currentUser.id}`"
          target="_blank"
          >View Profile</v-btn
        >
      </div>
      <ExtraInfo :submit="submit" v-model="form" full btnText="Save" />
    </div>
    <div class="account-details">
      <div class="account-details__header">
        <div class="page-title">Account Details</div>
        <div class="account-details__subheader">
          All contents below is private, and will not show up on your public profile. Please still note required areas below!
        </div>
      </div>
      <BasicInfo :submit="submit" v-model="form" full btnText="Save" />
    </div>
  </div>
</template>

<script>
import ExtraInfo from "./extra-info.page";
import BasicInfo from "./basic-info.page";
import _ from "lodash";

export default {
  head() {
    let meta = [...this.currentProject.meta_tags];

    meta = _.uniqBy(meta, function(tag) { return (tag.name || "") + "_" + (tag.property || "") });

    return {
      title: "Creator Profile",
      titleTemplate: "%s | iU Community",
      meta,
      link: [
        { rel: "icon", href: this.currentProject?.favicon.url || this.activeTheme.favicon.url || "/favicon.ico" }
      ]
    };
  },
  components: { ExtraInfo, BasicInfo },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      this.form = { ...this.currentUser };
    },
    async submit() {
      await this.$api.User.update(this.form);
      await this.$store.dispatch("user/fetch");
      this.setForm();
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.$alert("Profile has been successfully updated");
    },
  },
};
</script>

<style scoped lang="scss">
.profile-page {
  margin: auto;
}

.creator-profile {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 49px;
    @media screen and (max-width: 960px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 30px;
      margin-bottom: 31px;
    }
  }
}


.account-details {
  margin-top: 72px;
  padding-top: 50px;
  border-top: 2px solid #56565A;
  @media screen and (max-width: 960px) {
    margin-top: 0;
    padding-top: 43px;
  }
  &__header {
    margin-bottom: 50px;
    @media screen and (max-width: 960px) {
      padding: 0 30px;
    }
  }
  &__subheader {
    font-weight: 900;
    font-size: 20px;
  }
}

#app.disabled-theme .creator-profile .theme--dark.v-btn {
  color: white;
}
</style>
