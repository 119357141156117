<template>
  <div class="dynamic-select">
    <div class="dynamic-select__label" v-if="showLabel">
      {{ label
      }}<span v-if="required && markRequired" class="dynamic-select__required"
        >*</span
      >
    </div>
    <v-select
      class="dynamic-select__control"
      :value="value"
      :rules="rules"
      @input="$emit('input', $event)"
      :items="options"
      item-text="title"
      item-value="uuid"
      :outlined="outlined"
      hide-details="auto"
    >
      <template v-slot:append>
        <v-icon
          v-if="value"
          @mouseup.stop
          @click.prevent.stop="$emit('input', 0)"
          >$close</v-icon
        >
        <v-icon>$chevron_down</v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    showLabel: Boolean,
    value: [String, Object, Number, Boolean],
    options: {
      type: Array,
      default: () => [],
    },
    rules: Array,
    required: Boolean,
    markRequired: Boolean,
    outlined: Boolean,
    placeholder: String,
    row: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.dynamic-select {
  margin-bottom: 30px;
  &__required {
    color: red;
    margin-left: 3px;
  }
  &__control {
    margin-top: 0;
  }
  &__label {
    font-weight: bold;
  }
}
</style>
