<template>
  <div class="eligibles">
    <router-link
      v-for="badge in value"
      :key="badge.id"
      class="eligible"
      :class="[`eligible--${badge.slug}`]"
        to="/badge-info"
        target="_blank"
    >
      <div
        class="eligible-preview"
        :style="{ backgroundImage: `url(${badge.preview.url})` }"
      ></div>
      <div class="eligible-label">{{ badge.title }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
};
</script>

<style scoped lang="scss">
.eligibles {
  display: flex;
}
.eligible {
  display: flex;
  margin-right: 20px;
  line-height: 1.2;
  text-decoration: none;
  @media screen and (max-width: 960px) {
    margin-right: 0;
  }
  &--bronze {
    color: #c6864c;
  }
  &--gold {
    color: #ffbc10;
  }
  &--silver {
    color: #a7b7c0;
  }
  &--campus {
    color: #7b61ff;
  }
  &--iustaff {
    color: #E12D1B;
  }
  &-label {
  }
  &-preview {
    margin-right: 5px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    background-size: cover;
    background-position: center;
  }
}
</style>
