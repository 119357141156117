<template>
  <div class="error-page">
    <div class="error-content">
      <div class="error-title">
        Page not found...
      </div>
      <div class="error-description">
        We're unable to find the page you're looking for.
      </div>

      <Button large :href="currentUser ? '/' : homeUrl">Back to Home</Button>
    </div>
  </div>
</template>

<script>
import { profileToFrontend } from "@/lib/domains";
export default {
  data() {
    return {
      homeUrl: profileToFrontend()[window.location.origin],
    }
  }
};
</script>

<style></style>
