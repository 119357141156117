<template>
  <div>
    <div class="submission-fields" v-if="formattedData">
      <div class="submission-fields__title">
        <span>
          {{ title }}
        </span>
        <span>
          <b>Average Rating: {{ rating }}</b>
        </span>
      </div>
      <component
        class="submission-field"
        :key="idx"
        v-for="(field, idx) in formattedData"
        :class="'submission-field--' + field.type"
        :href="field.value ? (field.value.url || field.value) : ''"
        target="_blank"
        :is="
          (['MediaField', 'LinkField'].includes(field.type) &&
            field.value && field.value.url) ||
          field.value
            ? 'a'
            : 'div'
        "
      >
        <div>
          <div class="submission-field__title">
            {{ field.short_name || field.label }}
          </div>
          <div
            class="submission-field__value"
            v-if="field.type != 'MediaField'"
            v-text="field.value"
          ></div>
        </div>
        <div class="submission-field__icon">
          <v-icon v-if="field.type == 'MediaField'">$paperclip</v-icon>
          <v-icon v-if="field.type == 'LinkField'">$link</v-icon>
        </div>
      </component>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      formattedData: null,
      title: "",
      rating: 0,
    };
  },
  created() {
    this.setSubmission();
  },
  methods: {
    async setSubmission() {
      const { data } = await this.$api.Submission.get({
        id: this.$route.params.id,
      });
      this.formattedData = data.formatted_data;
      this.title = data.submission_form.title;
      this.rating = data.avg_rating;
    },
  },
};
</script>

<style scoped lang="scss">
.submission-fields {
  background: white;
  border: 2px solid #262729;
  border-radius: 10px;

  &__title {
    color: #262729;
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    border-bottom: 2px solid #262729;
    padding: 15px 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.submission-field {
  text-decoration: none;
  padding: 5px 15px 5px 30px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #262729;
  text-decoration: none;

  &--TextArea {
    height: auto;
  }
  &--TextArea &__value {
    white-space: pre-wrap;
  }

  .v-icon {
    color: #262729;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }
  &__value {
    position: relative;
    top: -4px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 300;
  }
}
</style>
