<template>
  <tr class="payments-row" v-if="item">
    <td class="center-align">
      <span class="payments-row__detail-link" @click="$emit('openDialog')">{{
        item.int_id
      }}</span>
    </td>
    <td class="center-align">
      {{ item.created_at ? dateFormatted(item.created_at, "MM/DD/YYYY") : "" }}
    </td>
    <td class="td-amount">{{ item.total_amount | currency }}</td>
    <td v-if="completedPayments">
      {{ item.paid_at ? dateFormatted(item.paid_at, "MM/DD/YYYY") : "" }}
    </td>
    <td class="center-align td-nowrap">
      {{ item.rate_units.length ? item.rate_units.join(" + ") : "" }}
    </td>
    <td class="center-align">
      <router-link
        :to="`/project/${project.id}/members`"
        v-for="project in uniqProjects(item)"
        :key="project.id"
      >
        {{ project.code }}
      </router-link>
    </td>
    <td class="center-align">
      <router-link
        :to="
          gig.hire_request_monday_com_item_id
            ? `/hire-requests/${gig.hire_request_monday_com_item_id}`
            : `/gig/${gig.id}`
        "
        v-for="gig in uniqGigs(item)"
        :key="gig.id"
      >
        {{ gig.title }}
      </router-link>
    </td>
    <td
      class="invoice__status center-align"
      :class="`invoice__status--${item.status}`"
    >
      {{ statuses[item.status] }}
    </td>
    <td>
      <div class="td-actions">
        <ActionsMenu
          class="payments-row__actions-toggle"
          v-if="
            currentUser.is_hiring_manager ||
            currentUser.is_finance ||
            currentUser.is_people_team
          "
          @close="menu = false"
          @toggle="menu = !menu"
          :menu="menu"
          :themeDisabledPage="themeDisabledPage"
        >
          <v-btn
            @click="$emit('openDialog')"
            color="#262729"
            elevation="0"
            dark
          >
            View Invoice
          </v-btn>
          <v-btn @click="download(item)" color="#56565A" elevation="0" dark>
            Download PDF
          </v-btn>
          <v-btn
            @click="cancelAreYouSure = true"
            color="#E12D1B"
            elevation="0"
            dark
          >
            Cancel Invoice
          </v-btn>
          <v-btn
            @click="$emit('openAuditDialog')"
            color="#262729"
            elevation="0"
            dark
          >
            View Audit Trail
          </v-btn>
        </ActionsMenu>
      </div>
    </td>
    <AreYouSureDialog
      :dialog="cancelAreYouSure"
      @yes="markAsCanceled"
      @no="cancelAreYouSure = false"
    >
      <p>
        If this invoice was created by mistake, or can not be processed, this
        will remove it from payments lists on Community.
      </p>
      <p><b>Are you sure you want to mark this invoice as canceled?</b></p>
    </AreYouSureDialog>
  </tr>
</template>

<script>
import themeDisabledPageMixin from "@/mixins/theme-disabled-page.mixin";
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import hmActionsMixin from "@/mixins/invoices/hm-actions.mixin";

export default {
  mixins: [themeDisabledPageMixin, paymentsTableFormats, hmActionsMixin],
  props: {
    item: Object,
    completedPayments: Boolean,
  },
  data() {
    return {
      menu: false,
      statuses: {
        submitted: "Submitted",
        hm_approved: "HM Approved", // DEPRECATED
        hm_rejected: "HM Rejected", // DEPRECATED
        fi_approved: "Approved", // DEPRECATED
        fi_rejected: "Finance Rejected", // DEPRECATED
        paid: "Completed",
        canceled: "Canceled",
        processing: "Processing",
      },
    };
  },
  methods: {
    refresh() {
      this.menu = false;
      this.$emit("refresh");
    },
    async markAsCanceled() {
      await this.hmMarkAsCanceled(this.item);
      this.refresh();
    },
    download({ id }) {
      this.$api.Invoice.download({ id, concur: true });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";
</style>
