export default {
  data() {
    return {
      agreementStatuses: {
        NONE: "Gig Contract Needed",
        OUT_FOR_SIGNATURE: "Gig Contract Sent",
        SIGNED: "Gig Contract Signed",
        SIGNED_OFFSITE: "Gig Contract Signed Offsite",
        EXTENSION_OUT_FOR_SIGNATURE: "Gig Extension Sent",
      },
      extensionStatuses: {
        OUT_FOR_SIGNATURE: "Gig Extension Sent",
        SIGNED: "Gig Extension Signed",
        SIGNED_OFFSITE: "Gig Extension Signed Offsite",
      },
      extensionStatusesShort: {
        OUT_FOR_SIGNATURE: "Sent",
        SIGNED: "Signed",
        SIGNED_OFFSITE: "Signed",
      },
    };
  },
};
