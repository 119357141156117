import Vue from "vue";

/* eslint-disable no-useless-escape */
const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* eslint-disable no-useless-escape */
const urlRegexp =
  /^(?:|http(s)?:\/\/?)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

/* eslint-disable no-useless-escape */
const strictUrlRegexp =
  /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

/* eslint-disable no-useless-escape */
const instagramHandleRegexp =
  /^(@)?([\w](?:(?:[\w]|(?:\.(?!\.))){0,28}(?:[\w]))?)$/;

const intRegexp = /^[0-9]+$/;

const publicVimeoRegex = /^(?:http|https)?:\/\/(?:www\.)?vimeo\.com\/(\d+)$/;
const privateVimeoRegex =
  /^(?:http|https)?:\/\/(?:www\.)?vimeo\.com\/(\d+)\/(?:(?:\d|[a-z])+)$/;
const youtubeRegex =
  /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(?:&(?:amp;)?[\w?=]*)?$/;

const getAge = (v) => {
  const birthday = new Date(v);
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

Vue.prototype.validation = {
  email: (v) => !v || emailRegexp.test(v) || "Incorrect email",
  birthday: (v) =>
    !v || getAge(v) >= 13 || "Community is for creators age 13 and up",
  required: (v) => {
    return !!(v || "").toString().trim() || "Required field";
  },
  workExampleDescription: (v) => {
    if (!v) return true;
    return (v && v.length <= 750) || "Work Example descriptions can't exceed 750 characters";
  },
  workExampleTitle: (v) => {
    if (!v) return true;
    return (v && v.length <= 50) || "Work Example titles can't exceed 50 characters";
  },
  urlOrMailto: (v) => {
    if (!v) return true;
    if (v.indexOf("mailto:") == 0) return true;
    return Vue.prototype.validation.url(v);
  },
  url: (v) => {
    if (!v) return true;
    if (v.toString().trim().indexOf("/") == 0) return true;
    if (!urlRegexp.test(v)) return "Incorrect URL";
    return true;
  },
  strictUrl: (v) => {
    if (!v) return true;
    if (!strictUrlRegexp.test(v)) return "Url must start with “https://”";
    return true;
  },
  instagramHandle: (v) => {
    if (!v) return true;
    if (!strictUrlRegexp.test(v) && !instagramHandleRegexp.test(v))
      return "Incorrect URL or Instagram handle (Url must start with “https://”)";
    return true;
  },
  password: (v) =>
    (v && v.length >= 6) || "Password must be at least 6 characters long",
  passwordConfirmation: (password) => (v) =>
    v == password || "Password and confirmation are different",
  project: {
    stagingDomain: (v) => {
      if (!v) return "Can not be empty";
      const notAllowed = v.match(/[^a-zA-Z0-9-]/);
      if (notAllowed && notAllowed[0]) {
        return `Incorrect Character "${notAllowed[0]}"`;
      }
      return true;
    },
    productionDomain: (v) => {
      if (!v) return true;

      const valid = v.match(
        /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/
      );

      if (valid) {
        return true;
      }
      return "Invalid Domain";
    },
  },
  integer: (v) => !v || intRegexp.test(v) || "That is not a number",
  videoUrl: (v) => {
    if (!v) return true;
    const valid = v.match(publicVimeoRegex) || v.match(privateVimeoRegex) || v.match(youtubeRegex);
    if (valid) {
      return true;
    }
    return "Invalid Video URL";
  },
};
