<template>
  <div class="gig-extension-review">
    <p>
      Review the fields below. This information will appear in the contract
      extension pdf, emailed to
      <span class="gig-extension-review__name-in-body">{{
        hr.creator.display_name
      }}</span>
      at
      <a :href="`mailto:${hr.adobe_agreement_fields.email}`" target="_blank">{{
        hr.creator.email
      }}</a
      >.
    </p>
    <FormRow label="Contractor’s Name:" light class="form-row--no-input">
      {{ hr.creator.display_name }}
    </FormRow>
    <FormRow label="Extended Date:" light class="form-row--no-input">
      {{ form.extended_date | moment("timezone", currentTimezone, "M/D/YYYY") }}
    </FormRow>
    <FormRow label="Rate Changes?:" light class="form-row--no-input">
      {{ form.rate_changes ? "Yes" : "No" }}
    </FormRow>
    <FormRow label="Rate of Pay:" light class="form-row--no-input">
      {{ rateTypeLabels[form.rate_changes ? form.rate_type : hr.rate_type] }}
    </FormRow>
    <FormRow label="Rate Amount:" light class="form-row--no-input">
      {{ (form.rate_changes ? form.rate : hr.rate) | currency }}
    </FormRow>
    <FormRow label="Related Gigs:" light class="form-row--no-input">
      <div
        v-for="item in form.work_assignments"
        :key="`work_assignment--${item.id}`"
      >
        {{ item.title }}
      </div>
    </FormRow>
    <div class="gig-extension-review__actions">
      <v-btn
        @click="$emit('back')"
        elevation="0"
        color="#56565A"
        class="gig-extension-review__back-btn"
        large
        >Back</v-btn
      >
      <v-btn @click="$emit('submit')" elevation="0" color="primary" large
        >Send for Signing</v-btn
      >
      <v-btn
        @click="$emit('signedOffsite')"
        elevation="0"
        color="#56565A"
        large
        class="gig-extension-review__signed-offsite-btn"
        >Extension Signed Offsite</v-btn
      >
    </div>
  </div>
</template>

<script>
import InvoiceLabelsMixin from "@/mixins/invoices/invoice-labels.mixin";
import GigExtensionLabelsMixin from "@/mixins/gig-extension-labels.mixin";
export default {
  mixins: [InvoiceLabelsMixin, GigExtensionLabelsMixin],
  props: {
    hr: Object,
    form: Object,
  },
};
</script>

<style lang="scss" scoped>
.gig-extension-review {
  &__name-in-body {
    font-weight: bold;
    white-space: nowrap;
  }
  &__actions {
    margin-top: 2em;
    > * {
      margin-right: 2em;
    }
  }
  &__back-btn,
  &__signed-offsite-btn {
    color: white !important;
  }
}
</style>
