<template>
  <v-expansion-panels
    class="form-panels"
    accordion
    v-model="panel"
    multiple
    :light="light"
  >
    <slot></slot>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    light: Boolean,
  },
  data() {
    const panel = this.$slots.default
      .map((t, i) => [t, i])
      .filter((ti) => !ti[0].componentOptions.propsData.closed)
      .map((ti) => ti[1]);
    return {
      panel,
    };
  },
  created() {},
};
</script>

<style scoped lang="scss">
.form-panels {
  margin-top: 30px;
  border: 2px solid #262729;
  position: static !important;

  @media screen and (max-width: 960px) {
    width: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-width: 0;
  }
}

.form-panels::v-deep .form-panel:last-child .v-expansion-panel-content {
  border-bottom: none !important;
}
</style>
