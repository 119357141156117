<template>
  <div>
    <div class="custom-cmp-item" v-for="(id, idx) in value" :key="idx">
      <TagSelector
        @input="setItem(idx, $event)"
        :multiple="false"
        :items="calcComponentsList(id)"
        :value="id"
        label="Component"
      />
      <div v-if="id" class="custom-cmp-item__tag-name">
        <b>Usage:</b>
        {{ getSfcName(id) }}
      </div>
      <v-btn
        color="primary"
        @click="deleteItem(idx)"
        fab
        small
        style="margin-left: 30px"
      >
        <v-icon>$delete</v-icon>
      </v-btn>
    </div>
    <v-btn @click="addCmp" color="primary">Import Component</v-btn>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: [Array],
  },
  data() {
    return {
      customComponents: [],
      customComponentsIndexed: null,
    };
  },
  created() {
    this.setCustomComponent();
  },
  methods: {
    deleteItem(idx) {
      const value = [...this.value];
      value.splice(idx, 1);
      this.$emit("input", value);
    },
    getSfcName(id) {
      return `<${this.customComponentsIndexed[id].sfc_name} />`;
    },
    calcComponentsList(id) {
      return this.customComponents.filter(
        (t) => !this.value.includes(t.id) || t.id == id
      );
    },
    setItem(idx, id) {
      const value = [...this.value];
      value[idx] = id;
      this.$emit("input", value);
    },
    addCmp() {
      this.$emit("input", [...this.value, null]);
    },
    async setCustomComponent() {
      const { data } = await this.$api.CustomComponent.lite();
      this.customComponents = data;
      this.customComponentsIndexed = _.keyBy(data, "id");
    },
  },
};
</script>

<style scoped lang="scss">
.custom-cmp-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &__tag-name {
    color: #170;
    margin-left: 40px;
    b {
      color: black;
      margin-right: 10px;
    }
  }
}
</style>
