export default {
  data() {
    return {
      rateTypes: [
        { name: 'Hourly', value: 'hourly' },
        { name: 'Flat Fee', value: 'flat_fee' },
        { name: 'Daily', value: 'daily' },
        { name: 'Weekly', value: 'weekly' },
        { name: 'Monthly', value: 'monthly' },
      ],
      rateTypeLabels: {
        "hourly": "Hourly",
        "flat_fee": "Flat Fee",
        "daily": "Daily",
        "weekly": "Weekly",
        "monthly": "Monthly",
      },
      rateUnitsLabels: {
        "hourly": "Hours",
        "flat_fee": "Flat Fee",
        "daily": "Days",
        "weekly": "Weeks",
        "monthly": "Months",
      }
    }
  }
}