<template>
  <div class="review-page">
    <router-link class="back-btn" to="/for-review">
      <v-icon size="50">$arrow_left</v-icon>
    </router-link>
    <div v-if="submissionForm && list">
      <div class="page-title">
        {{ submissionForm.project_code || submissionForm.title }}
        Submissions
      </div>
      <component
        :is="
          submissionForm.project_type == 'gig'
            ? 'GigSubmissionsReviewTable'
            : 'SubmissionsReviewTable'
        "
        :submissionForm="submissionForm"
        :items="list"
        :listOptions="listOptions"
        @orderUpdate="orderUpdate($event)"
        :reloadKey="reloadKey"
        @refresh="refresh()"
        :class="{ 'table-loading': loadingList }"
      />
    </div>
  </div>
</template>

<script>
import SubmissionsReviewTable from "./shared/SubmissionsReviewTable";
import GigSubmissionsReviewTable from "./shared/GigSubmissionsReviewTable";
import submissionsReviewTableActions from "@/mixins/submissions/submissions-review-table-actions.mixin";

export default {
  components: { SubmissionsReviewTable, GigSubmissionsReviewTable },
  mixins: [submissionsReviewTableActions],
  head: {
    title: "Review",
    titleTemplate: "%s | iU Community",
  },
  data() {
    return {
      submissionForm: null,
      list: null,
      loadingList: false,
    };
  },
  mounted() {
    this.fetchForm();
    this.fetchList();
  },
  methods: {
    async fetchForm() {
      const { data } = await this.$api.SubmissionForm.review({
        id: this.$route.params.id,
      });
      this.submissionForm = data;
    },
    async fetchList() {
      this.loadingList = true;
      const params = { ...this.listParams };
      params["id"] = this.$route.params.id;
      const { data } = await this.$api.SubmissionForm.reviewSubmissions(params);
      this.list = data.submissions;
      this.loadingList = false;
    },
    async refresh() {
      this.fetchForm();
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss">
.table-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
