<template>
  <div class="work-example-slides">
    <v-btn
      class="work-example-slides__swiper-control work-example-slides__swiper-control-left"
      icon
      @click="slidePrev()"
      large
      :class="{ visible: visibleControls }"
    >
      <v-icon size="90" color="white">$arrow_left_short</v-icon>
    </v-btn>
    <swiper
      ref="swiper"
      class="work-example-slides__swiper"
      :options="swiperOption"
    >
      <swiper-slide
        class="work-example-slides__swiper-slide"
        v-for="slide in slides"
        :key="slide.id"
        :style="{ backgroundImage: `url(${slide.preview.large.url})` }"
      >
      </swiper-slide>
    </swiper>
    <v-btn
      class="work-example-slides__swiper-control work-example-slides__swiper-control-right"
      icon
      @click="slideNext()"
      large
    >
      <v-icon size="90" color="white">$arrow_right_short</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    slides: Array,
  },
  data() {
    const maxSlides = this.slides.length > 1 ? 2 : 1;
    return {
      swiperOption: {
        speed: 400,
        keyboard: {
          enabled: true
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          600: {
            slidesPerView: maxSlides,
            spaceBetween: 20
          },
        },
        observer: true,
        observeParents: true,
      },
    };
  },
  methods: {
    slidePrev() {
      this.$refs.swiper.swiperInstance.slidePrev();
    },
    slideNext() {
      this.$refs.swiper.swiperInstance.slideNext();
    },
  },
  computed: {
    visibleControls() {
      return this.slides.length > 2;
    }
  },
}
</script>

<style lang="scss" scoped>
.work-example-slides {
  height: 100%;
  position: relative;

  &__swiper {
    height: 100%;
    &-slide {
      height: 100%;
      margin-right: 9px;
      background-position: center;
      background-size: cover;
    }
    &-control {
      position: absolute;
      top: calc(50% - 35px);
      width: 70px;
      height: 70px;
      margin: 0 10px;
      z-index: 2;
      &-right {
        right: 0;
      }
      &-left {
        left: 0;
      }
      &:not(.visible) {
        display: none;
      }
    }
  }

}
</style>