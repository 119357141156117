<template>
  <CodeEditor lang="xml" v-model="localValue.html" />
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
};
</script>

<style></style>
