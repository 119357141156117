<template>
  <panZoom class="pdf-doc__frame">
    <v-responsive
      class="pdf-doc"
      :aspect-ratio="8.5 / (pages * 11 + (pages > 1 ? (pages - 1) * 0.2 : 0))"
    >
      <div class="pdf-doc__inner" ref="innerContent">
        <div class="pdf-doc__page-break" v-if="pages == 2">
          <div class="pdf-doc__page-break__inner"></div>
        </div>
        <div class="pdf-doc__header">
          <h1>Invoice</h1>
          <div class="pdf-doc__branding">
            <img
              width="150"
              src="@/assets/pdf/iu_logo.svg"
              alt="Ideas United logo"
            />
          </div>
        </div>
        <div class="pdf-doc__top">
          <div class="pdf-doc__top-row">
            <div>
              <div class="pdf-doc__top__label">Member</div>
              <div>{{ invoice.user.display_name }}</div>
              <div>{{ invoice.user.contact_email }}</div>
              <div>
                <span v-if="invoice.user.street"
                  >{{ invoice.user.street }},
                </span>
                <span v-if="invoice.user.location"
                  >{{ invoice.user.location }}
                </span>
                <span v-if="invoice.user.zip">{{ invoice.user.zip }}</span>
              </div>
            </div>
            <div>
              <div class="pdf-doc__top__label">Received date</div>
              <div>{{ dateFormatted(invoice.created_at, "MMM D, YYYY") }}</div>
            </div>
          </div>
          <div class="pdf-doc__top-row">
            <div>
              <div class="pdf-doc__top__label">Invoice #</div>
              <div>{{ invoice.int_id }}</div>
            </div>
            <div>
              <div class="pdf-doc__top__label">Bill To</div>
              <div>Ideas United</div>
            </div>
          </div>
        </div>
        <div class="pdf-doc__details">
          <h2>Invoice Details</h2>
          <table>
            <thead>
              <tr>
                <th>Gig</th>
                <th>Flat Fee</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Payment Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoice.invoice_items" :key="item.id">
                <td>{{ item.gig }}</td>
                <td class="td-center">
                  <img
                    v-if="item.rate_type == 'flat_fee'"
                    width="20"
                    height="20"
                    src="https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/f88c9a48-d9e4-43ba-87d6-45ac41eceb89/mailer_checkbox_true.png"
                  />
                  <img
                    v-else
                    width="20"
                    height="20"
                    src="https://nyc3.digitaloceanspaces.com/evp/uploads/widget/image_asset/asset/3eac091b-05a4-4b19-9da0-c4fec6faa947/mailer_checkbox_false.png"
                  />
                </td>
                <td>
                  {{
                    item.rate_type == "flat_fee"
                      ? "N/A"
                      : item.rate_units_labelled || 0
                  }}
                </td>
                <td
                  class="pdf-doc__td--currency"
                  v-if="item.rate_type != 'flat_fee'"
                >
                  {{ item.rate | currency }}
                </td>
                <td class="pdf-doc__td--currency" v-else>N/A</td>
                <td class="pdf-doc__td--currency">
                  {{
                    (item.rate_type == "flat_fee"
                      ? item.amount
                      : item.rate_units * item.rate) | currency
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pdf-doc__total">
            Total Amount: USD {{ invoice.total_amount | currency }}
          </div>
        </div>
        <div class="pdf-doc__details-2">
          <h3>Payment Details</h3>
          <div class="pdf-doc__details-2__row">
            <div class="pdf-doc__details-2__label">Status</div>
            <div>
              {{ statuses[invoice.status] }}
            </div>
          </div>
          <div class="pdf-doc__details-2__row">
            <div class="pdf-doc__details-2__label">Note</div>
            <div>
              {{ invoice.description }}
            </div>
          </div>
          <div class="pdf-doc__details-2__row">
            <div class="pdf-doc__details-2__label">Paid date</div>
            <div>
              {{ invoice.paid_at || "-" }}
            </div>
          </div>
          <div class="pdf-doc__details-2__row">
            <div class="pdf-doc__details-2__label">Created by</div>
            <div>
              {{ invoice.created_by_name || "-" }}
            </div>
          </div>
        </div>
        <div class="pdf-doc__details-3">
          <h3>Gig Details</h3>
          <div
            class="pdf-doc__details-3__gig"
            v-for="gig in uniqGigs(invoice)"
            :key="gig.id"
          >
            <div class="pdf-doc__details-3__row">
              <div class="pdf-doc__details-3__label">Project name</div>
              <div>{{ gig.project_title }}</div>
            </div>
            <div class="pdf-doc__details-3__row">
              <div class="pdf-doc__details-3__label">Gig</div>
              <div>{{ gig.title }}</div>
            </div>
            <div class="pdf-doc__details-3__row">
              <div class="pdf-doc__details-3__label">Start date</div>
              <div>{{ dateFormatted(gig.start_date, "MMM D, YYYY") }}</div>
            </div>
            <div class="pdf-doc__details-3__row">
              <div class="pdf-doc__details-3__label">End date</div>
              <div>{{ dateFormatted(gig.end_date, "MMM D, YYYY") }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-responsive>
  </panZoom>
</template>

<script>
import paymentsTableFormats from "@/mixins/payments-table-formats.mixin";
import invoicePdfPreview from "@/mixins/invoices/invoice-pdf-preview.mixin";

export default {
  mixins: [paymentsTableFormats, invoicePdfPreview],
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/invoice-pdf-preview.scss";
</style>
