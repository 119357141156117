<template>
  <div>
    <TagSelector
      :multiple="multiple"
      :items="list"
      :value="value"
      @input="selectResultById($event)"
      itemText="title"
      itemValue="id"
    />
    <v-btn
      small
      outlined
      color="primary"
      @click="dialog = true;"
      class= "qb-project-search-btn"
    >
      Find a new QB Project
    </v-btn>
    <v-dialog
      v-model="dialog"
      width="700"
    >
      <v-card light>
        <v-btn class="dialog-close" icon @click="cancel">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-title>Search for a Quickbooks Project</v-card-title>
        <v-card-text>
          <div class="qb-project-search-holder">
            <TextField
              placeholder="Try a project code or partner name"
              v-model="search"
              class= "qb-project-dialog__search-input"
            ></TextField>
            <v-btn
              color="primary"
              @click="searchQbProjects()"
              class= "qb-project-dialog-btn"
            >
              Search
            </v-btn>
          </div>
          <v-data-table
            class="qb-project__search-results elevation-0 data-table"
            :headers="headers"
            hide-default-footer
            :items="searchResults"
            item-key="Id"
            v-if="searchResults.length"
          >
            <template v-slot:body="{ items }">
              <tbody v-for="item in items" :key="item.Id">
                <tr class="data-table__row">

                  <td>{{ item.qb_title }}</td>
                  <td >{{ item.code }}</td>
                  <td >{{ item.qb_id }}</td>
                  <td>
                    <v-btn
                      small
                      outlined
                      color="primary"
                      @click="selectResult(item)"
                    >
                      {{ item.id ? "Select" : "Import and Select" }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <Spinner v-else-if="searching" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextField from '../TextField.vue';
export default {
  components: { TextField },
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    codeSuggestion: String
  },
  data() {
    return {
      list: [],
      dialog: false,
      search: "",
      searching: false,
      searchResults: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'qb_title',
        },
        {
          text: 'Code',
          align: 'start',
          value: 'code',
        },
        {
          text: 'QB Id',
          align: 'start',
          value: 'qb_id',
        },
      ],
    };
  },
  created() {
    this.setQbProjects();
  },
  methods: {
    async setQbProjects() {
      const { data } = await this.$api.QbProject.lite();
      this.list = [
        { title: "N/A", id: null , code: null},
        ...data,
      ];
      console.info('setQbProjects this.list', this.list);
      if(this.codeSuggestion) {
        const result = this.list.find((item) => item.code?.toLowerCase() == this.codeSuggestion.toLowerCase())
        if(result) this.selectResult(result);
      }
    },
    async searchQbProjects() {
      this.searchResults = [];
      this.searching = true;
      const { data } = await this.$api.QbProject.search({search: this.search});
      this.searching = false;
      this.searchResults = data;
    },
    selectResultById(project_id) {
      const result = this.list.find((item) => item.id == project_id);
      this.$emit("input", result || project_id);
    },
    async selectResult(item) {
      console.info("selectResult item", item);
      if(!item.id){
        const { data } = await this.$api.QbProject.create({
          qb_id: item.qb_id,
          title: item.qb_title,
          code: item.code
        });
        await this.setQbProjects();
        this.dialog = false;
        this.$emit("input", data);

      } else {
        await this.setQbProjects();
        this.dialog =  false;
        this.$emit("input", item);
      }
    },
    cancel() {
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
.qb-project-search-holder {
  display: flex;
  flex-wrap: wrap;
}
.qb-project-search-btn,
.qb-project-dialog-btn {
  margin-top: .75em;
}
.qb-project-dialog__search-input {
  margin-right: 1em;
}
.spinner.relative {
  min-height: 100px;
}
</style>
