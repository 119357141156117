<template>
  <div class="not-authorized">
    <AuthPage
      @disableAuth="disableAuth"
      v-show="!currentUser && !authDisabled"
    />
    <div class="error-page" v-show="currentUser || authDisabled">
      <div class="error-content">
        <div class="error-title">
          Restrict access...
        </div>
        <div class="error-description">
          You don't have access to view this page.
        </div>

        <Button large :href="currentUser ? '/' : homeUrl">Back to Home</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { profileToFrontend } from "@/lib/domains";
export default {
  data() {
    return {
      homeUrl: profileToFrontend()[window.location.origin],
      authDisabled: false
    };
  },
  methods: {
    disableAuth(val) {
      this.authDisabled = val;
    }
  }
};
</script>

<style scoped lang="scss">
.not-authorized {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin: auto;
  height: 100%;
}
</style>
