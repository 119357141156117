<template>
  <tr class="payments-row">
    <td class="center-align">
      <router-link
        :to="
          my
            ? `/my-gig/${item.monday_com_item_id}`
            : `/hire-requests/${item.monday_com_item_id}`
        "
        v-html="itemMondayComItemIdNotAPhoneNumber"
      />
    </td>
    <td class="center-align">
      {{
        item.work_assignments && item.work_assignments.length
          ? item.work_assignments.map((gig) => gig.title).join(", ")
          : "N/A"
      }}
    </td>
    <td>
      <TableRowAvatar
        :user="hiringManager"
        v-for="(hiringManager, idx) in hiringManagers"
        :key="`hm-${idx}-${hiringManager ? hiringManager.id : ''}`"
      />
    </td>
    <td
      class="center-align"
      :class="[
        'payments-row__td--status',
        `payments-row__td--status--${contractStatus}`,
      ]"
    >
      {{ statuses[contractStatus] }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.start_date, "MM/DD/YYYY") }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.extended_end_date, "MM/DD/YYYY") }}
    </td>
    <td class="center-align">
      {{ dateFormatted(item.created_at, "MM/DD/YYYY") }}
    </td>
  </tr>
</template>

<script>
import hireRequestsTableRow from "@/mixins/hire-requests/hire-requests-table-row.mixin";

export default {
  mixins: [hireRequestsTableRow],
};
</script>

<style lang="scss" scoped>
@import "@/styles/payments/payments-row.scss";

.payments-row__td {
  &--link {
    padding: 6px 20px !important;
  }
  &--status {
    font-weight: bold;
    &--NONE,
    &--UNKNOWN {
      color: #e12d1b;
    }
    &--SIGNED,
    &--SIGNED_OFFSITE {
      color: #61b90c;
    }
    &--OUT_FOR_SIGNATURE,
    &--EXTENSION_OUT_FOR_SIGNATURE {
      color: #e8852b;
    }
  }
}
</style>
