<template>
  <component
    :is="link ? 'router-link' : 'div'"
    class="countable-badge"
    :style="{ backgroundImage: `url(${badge.preview.url})` }"
    :to="link ? '/badge-info' : false"
    target="_blank"
  ></component>
</template>

<script>
export default {
  props: {
    badge: Object,
    link: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.countable-badge {
  display: block;
  position: relative;
  background-position: center;
  background-size: calc(100% + 2px);
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 100%;
}
</style>
