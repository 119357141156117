/* eslint-disable */
import Vue from "vue";
import cookies from "vue-cookies";
import { profileToFrontend } from "@/lib/domains";

const store = {
  namespaced: true,
  state: {
    token: cookies.get("authToken"),
  },
  mutations: {
    set(state, user) {
      state.current = user;
    },
    setToken(state, token) {
      state.token = token;
      const cookieDomain =
        process.env.NODE_ENV === "development"
          ? "localhost"
          : window.location.hostname.replace("profile", "");
      if (token) {
        cookies.set("authToken", token, null, null, cookieDomain);
      } else {
        cookies.remove("authToken", null, cookieDomain);
      }
    },
  },
  actions: {
    async refreshToken({ commit, dispatch }, params) {
      const { data } = await this._vm.$api.Auth.refreshToken();
      commit("setToken", data.token);
      return await dispatch("user/fetch", null, { root: true });
    },
    async login({ commit, dispatch }, params) {
      sessionStorage.removeItem("cachedCreatorSearch");
      const { data } = await this._vm.$api.Auth.login(params);
      commit("setToken", data.token);
      return await dispatch("user/fetch", null, { root: true });
    },
    async register({ commit, dispatch }, params) {
      sessionStorage.removeItem("cachedCreatorSearch");
      const { data } = await this._vm.$api.Auth.register(params);
      commit("setToken", data.token);
      return await dispatch("user/fetch", null, { root: true });
    },
    async logout({ commit }) {
      sessionStorage.removeItem("cachedCreatorSearch");
      commit("setToken", null);
      commit("user/set", null, { root: true });
      const cookieDomain =
        process.env.NODE_ENV === "development"
          ? "localhost"
          : window.location.hostname.replace("profile", "");
      cookies.remove("authToken", null, cookieDomain); // need to test

      window.location.href = profileToFrontend()[window.location.origin];
    },
  },
  getters: {
    token: (state) => state.token,
    isAuthenticated: (state) => !!state.token,
  },
};

export default store;
