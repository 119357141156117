<template>
  <div
    class="profile-reviews"
    v-if="reviews"
    :class="{ 'profile-reviews__light': light }"
  >
    <div class="profile-reviews__header">
      <div class="profile-reviews__title">Reviewed</div>
      <div class="profile-reviews__nav" v-if="reviews.length">
        <span> {{ idx + 1 }} of {{ reviews.length }} </span>
        <div class="profile-review__nav__btn" @click="prev()">
          <v-icon>$chevron_left_filled</v-icon>
        </div>
        <div class="profile-review__nav__btn" @click="next()">
          <v-icon>$chevron_right_filled</v-icon>
        </div>
      </div>
      <div v-else>No reviews</div>
    </div>
    <div class="profile-review" v-if="reviews.length">
      <div class="profile-review__top">
        <div class="profile-review__project-code">
          <div class="profile-review__label">Project Code</div>
          <div class="profile-review__value profile-review__project-code-value">
            {{ current ? current.project_code : "" }}
          </div>
        </div>
        <div class="profile-review__rating">
          <div class="profile-review__label">Rating</div>
          <ProfileRating :value="current.rating" />
        </div>
      </div>
      <div class="profile-review__bottom">
        <div class="profile-review__label">Reviewer Notes</div>
        <div class="profile-review__value">{{ current.notes }}</div>
        <div class="profile-review__reviewed-by">
          Reviewed by {{ current.reviewer_name }} on
          {{
            current.created_at
              | moment("timezone", currentTimezone, "MMMM Do YYYY, h:mma")
          }}
          EST
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProfileRating from "@/components/profile-review/ProfileRating.vue";

export default {
  name: "ProfileReviews",
  components: { ProfileRating },
  props: {
    reviews: Array,
    light: Boolean,
  },
  data() {
    return { idx: 0 };
  },
  methods: {
    prev() {
      this.idx = (this.idx + this.reviews.length - 1) % this.reviews.length;
    },
    next() {
      this.idx = (this.idx + 1) % this.reviews.length;
    },
  },
  computed: {
    current() {
      return this.reviews[this.idx];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-reviews {
  color: var(--v-dark-base);

  &__header {
    margin-bottom: 33px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__title {
    color: var(--v-dark-base);
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    margin-right: 1em;
  }

  &__nav {
    display: flex;
    position: relative;
    width: max-content;
    align-items: center;
    color: var(--v-dark-base);
    font-size: 14px;
    &__btn:hover {
      cursor: pointer;
    }
  }

  &__light {
    color: #262729;
    .profile-reviews__title,
    .profile-reviews__nav {
      color: #262729;
    }
  }
}
.profile-review {
  &__top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 33px;
  }
  &__project-code {
    min-width: 168px;
    margin-right: 40px;
    &-value {
      font-weight: 300;
      font-size: 20px;
    }
  }

  &__label {
    font-weight: 700;
  }

  &__reviewed-by {
    color: var(--v-primary-base);
    margin-top: 24px;
  }
}
</style>
