<template>
  <div class="review-form">
    <div class="review-form__header">
      <div class="review-form__title">
        {{ submissionForm.title }}
      </div>
      <div class="review-form__nav">
        <div class="review-form__nav__position-indicator">
          Submission {{ submissionIndex + 1 }} of
          {{ submissionsLength }}
        </div>
        <div class="review-form__nav__btn" @click="clickPrevNext('prev')">
          <v-icon color="#e12d1b">$chevron_left_filled</v-icon>
        </div>
        <div class="review-form__nav__btn" @click="clickPrevNext('next')">
          <v-icon color="#e12d1b">$chevron_right_filled</v-icon>
        </div>
      </div>
    </div>
    <div class="review-form__content">
      <SubmissionReviewItem
        class="review-form__submission"
        :item="submission"
        :submissionForm="submissionForm"
        :key="`review-form-item__${submission.id}`"
      />
      <v-form
        class="review-form__form"
        @submit.prevent="submitReview('next')"
        v-if="formEnabled"
      >
        <v-radio-group
          class="review-form__ratings"
          row
          v-model="form.rating"
          hide-details="auto"
          @change="onFormTouched()"
          :rules="[validation.required]"
        >
          <v-radio
            v-for="option in ratings"
            :key="`rating--${option.value}`"
            :label="option.label"
            :value="option.value"
            off-icon=""
            on-icon=""
            class="review-form__rating"
          >
            <template v-slot:label>
              <div class="review-form__rating-value">
                {{ option.value }}
              </div>
              <div class="review-form__rating-label">
                {{ option.label }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <div class="review-form__fields">
          <div class="review-form__inputs">
            <div class="review-form__field">
              <div class="review-form__field__label">My Reviewer Notes</div>
              <div class="review-form__notes">
                <TextArea
                  v-model="form.notes"
                  @input="onFormTouched()"
                  placeholder="Notes"
                  :rows="3"
                />
                <div
                  class="review-form__actions"
                  v-if="this.submission.project_type == 'gig'"
                >
                  <SubmissionRequestForm
                    :submissionId="submission.id"
                    :key="`request-form--${submission.id}`"
                    :disabled="!formEnabled"
                  />
                  <SubmissionOfferForm
                    :submissionId="submission.id"
                    :key="`offer-form--${submission.id}`"
                    :formState="submissionForm.state"
                    :disabled="!formEnabled"
                  />
                </div>
              </div>
            </div>
            <input
              type="hidden"
              name="submission_id"
              :value="form.submission_id"
            />
          </div>
        </div>
        <div class="review-form__submit-section">
          <v-btn
            type="submit"
            class="review-form__submit"
            elevation="0"
            :disabled="!formValid"
            color="white"
            >Save</v-btn
          >
        </div>
      </v-form>
      <div class="review-form__closed" v-else>
        Review is closed for this opportunity
      </div>
    </div>
  </div>
</template>

<script>
import SubmissionRequestForm from "./SubmissionRequestForm";
import SubmissionOfferForm from "./SubmissionOfferForm";
import SubmissionReviewItem from "@/components/review/SubmissionReviewItem.vue";

export default {
  components: {
    SubmissionRequestForm,
    SubmissionOfferForm,
    SubmissionReviewItem,
  },
  props: {
    submission: Object,
    submissionForm: Object,
    submissionIndex: Number,
    submissionsLength: Number,
  },
  data() {
    return {
      form: {
        notes: null,
        submission_id: this.submission.id,
        rating: null,
      },
      formTouched: false,
      ratings: [
        {
          value: 1,
          label: "Poor",
        },
        {
          value: 2,
          label: "Decent",
        },
        {
          value: 3,
          label: "Good",
        },
        {
          value: 4,
          label: "Great",
        },
        {
          value: 5,
          label: "Superb",
        },
      ],
      requestDialog: false,
      offerDialog: false,
    };
  },
  mounted() {
    this.fetchReview();
  },
  methods: {
    async fetchReview() {
      const { data } = await this.$api.SubmissionRating.getMy({
        submission_id: this.submission.id,
      });
      if (data != "none") this.form = { ...data };
    },
    async submitReview(dir) {
      const { status, data } = await this.$api.SubmissionRating.save(this.form);
      if (status == 200) {
        this.$emit(dir || "next", data);
      }
    },
    onFormTouched() {
      this.formTouched = true;
    },
    async clickPrevNext(dir) {
      if (this.formTouched) {
        await this.submitReview(dir);
      } else {
        this.$emit(dir);
      }
    },
  },
  computed: {
    formEnabled() {
      return (
        this.submissionForm &&
        ["submitted", "review"].includes(this.submissionForm.state)
      );
    },
    formValid() {
      return (
        this.submissionForm &&
        ["submitted", "review"].includes(this.submissionForm.state)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.review-form {
  color: #262729;

  &__header {
    background: #ffbc10;
    padding: 40px 30px;
    border-bottom: 2px solid #262729;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__title {
    font-size: 36px;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 0.03em;
    margin-right: 56px;
  }

  &__nav {
    display: flex;
    position: relative;
    color: #e12d1b;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 1;
    &__position-indicator {
      margin-right: 1.7em;
    }
    &__btn {
      margin-top: -0.1em;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
  }

  &__submission {
    padding: 35px !important;
    display: flex;
    flex-direction: column;
  }

  &__form {
    border-top: 2px solid #262729;
  }

  &__ratings {
    display: flex;
    border-bottom: 2px solid #262729;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    &.v-input--is-disabled {
      opacity: 0.5;
    }
  }
  &__ratings::v-deep &__rating {
    flex-basis: 20%;
    flex-grow: 1;
    border-style: solid;
    border-color: #262729;
    border-width: 0 2px 0 0;
    padding: 13px;
    margin: 0;
    color: #262729;
    &:last-child {
      border-width: 0;
    }
    &:hover {
      cursor: pointer;
    }
    .v-label {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #262729;
      transition: 0s;
    }
    .v-input--selection-controls__input {
      display: none;
    }
    &.v-item--active {
      background: var(--v-primary-base);
      .v-label {
        color: white;
      }
    }
  }

  &__fields {
    padding: 30px;
    display: flex;
  }
  &__inputs {
    flex-grow: 1;
  }
  &__field {
    &__label {
      font-size: 14px;
      font-weight: 700;
    }
  }
  &__notes {
    display: flex;
    &::v-deep textarea {
      min-height: 110px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
  }

  &__submit-section {
    display: flex;
    text-align: center;
    justify-content: stretch;
  }
  &__submit {
    padding: 28px !important;
    height: 78px !important;
    flex-grow: 1;
    font-weight: 900;
    font-size: 20px;
    background: var(--v-primary-base) !important;
    color: white !important;
    border-radius: 0 !important;
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background: white !important;
      color: #262729 !important;
      opacity: 0.5;
    }
    &:hover {
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      &:hover {
        cursor: default;
      }
    }
  }
  &__closed {
    text-align: center;
    padding: 28px !important;
    font-weight: 900;
    font-size: 20px;
  }
}
@media screen and (max-width: 960px) {
  .review-form {
    &__ratings {
      flex-wrap: wrap;
      > .submission-dialog__form__rating:last-child {
        border-width: 2px 2px 0 0;
      }
    }
  }
}
</style>
